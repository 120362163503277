const BreadcrumbsStyles = {
  padding: { md: '8px 0', lg: '10px 0', xl: '12px 0' },
  '.MuiBreadcrumbs-root': {
    '.MuiBreadcrumbs-ol': {
      '.MuiBreadcrumbs-li': {
        'a.MuiLink-root': {
          fontSize: { md: '14px', lg: '15px', xl: '16px' },
        },
      },
    },
  },
}

const Tabs = {
  marginTop: { md: 1.5, lg: 2, xl: 3 },
  width: 'max-content',
  '.MuiTabs-flexContainer': {
    borderRadius: '12px',
    overflow: 'hidden',
    backgroundColor: '#fff',
    border: 'solid 1px #DAD8DF',
    '.MuiButtonBase-root': {
      fontFamily: 'Source Sans Pro',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      textTransform: 'none',
      padding: { md: '5px 16px', lg: '6px 18px', xl: '8px 22px' },
      letterSpacing: 'unset',
      '&.Mui-selected': {
        backgroundColor: '#2257C5',
        color: '#fff',
        borderRadius: '8px',
        fontWeight: 400,
      },
    },
  },
}

const TabContent = {
  backgroundColor: '#fff',
  border: 'solid 1px #DADBDF',
  padding: '16px 24px',
  borderRadius: '8px',
  fontFamily: 'Source Sans Pro',
  fontSize: '16px',
  fontWeight: 400,
  textAlign: 'left',
  marginTop: { md: 1.5, lg: 2, xl: 3 },
  overflow: 'auto',
}

const Loader = {
  width: '100%',
  transform: 'translateX(-21px)',
  position: 'absolute',
  zIndex: 10,
}

const LoaderOverlay = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.4)',
  transform: 'translateX(-21px)',
  zIndex: 9,
}

const styles = {
  BreadcrumbsStyles,
  Tabs,
  TabContent,
  Loader,
  LoaderOverlay,
}

export default styles
