import { useInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { fetchCompanyOverviewEmployeesData } from 'services/verticalsApi'
import { Employee } from './types'
import { Filter } from 'components/FilterDrawer/FilterDrawer.config'

interface useEmployeesQueryProps {
  caseId: string
  zoneActivityIndices: string | null
  pageSize: number
  searchValue: string
  filters: Filter[]
  sort?: string | qs.ParsedQs | string[] | qs.ParsedQs[]
}

export const useEmployeesQuery = ({
  caseId,
  zoneActivityIndices,
  pageSize,
  searchValue,
  filters,
  sort,
}: useEmployeesQueryProps) => {
  const {
    data: verticalActivities,
    fetchNextPage,
    isFetching,
    isLoading,
  } = useInfiniteQuery<any>({
    queryKey: [caseId, zoneActivityIndices, pageSize, searchValue, filters, sort],
    queryFn: async ({ pageParam = 0 }) => {
      if (!zoneActivityIndices) return null
      const fromIndex = pageParam * pageSize
      return await fetchCompanyOverviewEmployeesData(
        caseId,
        fromIndex,
        zoneActivityIndices,
        searchValue,
        filters,
        pageSize,
      )
    },
    getNextPageParam: (_lastGroup, groups) => groups.length,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const flatVerticalActivities: any | null = useMemo(() => {
    const flatEntities = verticalActivities?.pages.flatMap((page: Employee) => page) || null
    return flatEntities?.[0] ? flatEntities : null
  }, [verticalActivities])

  return {
    verticalDocumentActivity: flatVerticalActivities,
    fetchNextPage,
    isFetching,
    isLoading,
    totalFetched: flatVerticalActivities?.length || 0,
  }
}
