export const gridColumns = [
  {
    key: 'listingSiteId',
    fixedWidth: 100,
  },
  {
    key: 'listingSites',
    size: 1,
    fixedWidth: 50,
  },
  {
    key: 'listingAddress',
    size: 1,
    minWidth: 700,
  },
  {
    key: 'documentedIncome',
    fixedWidth: 160,
  },
]
