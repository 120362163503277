import React, { useState } from 'react'
import GenericLineChart from 'components/common/GenericLineChart'
import { Line, ResponsiveContainer, Tooltip } from 'recharts'
import { LineChartDatum } from 'components/common/types'
import { useTheme } from '@mui/material/styles'
import { EmployeeOverviewDataKeys } from 'containers/VerticalPage/company-overview/OverviewTab/types'
import EmployeeOvertimeTooltip from 'containers/VerticalPage/company-overview/OverviewTab/EmployeeOvertimeTooltip/EmployeeOvertimeTooltip'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { legendData } from './utils'
import CircleIcon from '@mui/icons-material/Circle'

interface EmployeeOverviewChartProps {
  chartData: LineChartDatum[]
}

const EmployeeOverviewChart = ({ chartData }: EmployeeOverviewChartProps) => {
  const [hoveredDot, setHoveredDot] = useState<EmployeeOverviewDataKeys | null>(null)
  const theme = useTheme()
  return (
    <>
      <ResponsiveContainer width='100%' height='100%'>
        <GenericLineChart
          chartData={chartData}
          xAxisDataKey='year'
          xLabel='Year Of Date Year'
          yLabel='Number Of Employees'
          yAxisDistanceX={-40}
          xAxisDistanceY={15}
          labelstyle={{ fontSize: '12px' }}
          yLabelAngle={-90}
          tick={{ fill: '#1C2842' }}
        >
          <Line
            type='linear'
            dot={{ r: 4 }}
            dataKey={EmployeeOverviewDataKeys.localAmount}
            stroke={theme.palette.companyOverview.localCountry}
            strokeWidth={3}
            activeDot={{
              onMouseEnter: () => setHoveredDot(EmployeeOverviewDataKeys.localAmount),
              onMouseLeave: () => setHoveredDot(null),
            }}
            name='Local'
          />
          <Line
            type='linear'
            dot={{ r: 4 }}
            dataKey={EmployeeOverviewDataKeys.restOfTheWorldAmount}
            stroke={theme.palette.companyOverview.restOfTheWorld}
            strokeWidth={3}
            activeDot={{
              onMouseEnter: () => setHoveredDot(EmployeeOverviewDataKeys.restOfTheWorldAmount),
              onMouseLeave: () => setHoveredDot(null),
            }}
            name='Non-local'
          />
          <Tooltip
            isAnimationActive={false}
            content={<EmployeeOvertimeTooltip chartData={chartData} hoveredDot={hoveredDot} localCountry={'Israel'} />} //! remove hard code
            cursor={{ stroke: 'none', strokeWidth: 0 }}
          />
        </GenericLineChart>
      </ResponsiveContainer>
      <Stack flexDirection='row' mt={1}>
        {legendData.map((data, index) => (
          <Stack key={index} flexDirection='row' alignItems='center' mr={4}>
            <CircleIcon htmlColor={data!.color} sx={{ width: '10px', height: '10px', marginRight: '6px' }} />
            <Typography color='#1C2842' fontSize={{ md: 15, lg: 15, xl: 16 }} fontFamily='Source Sans Pro'>
              {data!.label}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </>
  )
}

export default EmployeeOverviewChart
