import { searchableColumns } from 'containers/NFTMapper/specs'
import { BaseFilter, NFT_MAPPER_KEYS, DISPLAY_AS_OPTIONS, DateRange } from 'utils/filters/common'
import moment from 'moment'

const { MONTH_PICKER, MULTI_SELECT } = DISPLAY_AS_OPTIONS
const { SEARCH, SEARCHFIELDS, ACTIVITY_TYPE } = NFT_MAPPER_KEYS

export interface NFTMapperUsableFilters {
  search: string
  searchFields: string[]
  activityType: string[]
  firstActivity?: DateRange
}

export interface NFTMapperUsableFilters {
  search: string
  searchFields: string[]
}

export type NFTMapperSearch = BaseFilter<NFT_MAPPER_KEYS.SEARCH, string>

export type NFTMapperSerachfields = BaseFilter<NFT_MAPPER_KEYS.SEARCHFIELDS, (string | undefined)[]>

export interface NFTMapperActivityType extends BaseFilter<NFT_MAPPER_KEYS.ACTIVITY_TYPE, string[]> {
  displayAs: DISPLAY_AS_OPTIONS.MULTI_SELECT
  options: string[]
  hasSelectAll: boolean
  disableSearch: boolean
  activityTypes: string[]
}
// export interface NFTMapperFirstActivity extends BaseFilter<NFT_MAPPER_KEYS.FIRST_ACTIVITY, DateRange> {
//   displayAs: DISPLAY_AS_OPTIONS.MONTH_PICKER;
// }

export type NFTMapperFilters = [NFTMapperSearch, NFTMapperSerachfields, NFTMapperActivityType]

enum DATE_FORMATS {
  FIRST_ACTIVITY = 'YYYY/MM',
}

export const nftMapperFilters: NFTMapperFilters = [
  {
    key: SEARCH,
    initialValue: '',
    isActivated: false,
  },
  {
    key: SEARCHFIELDS,
    initialValue: searchableColumns,
    isActivated: false,
  },
  {
    key: ACTIVITY_TYPE,
    displayAs: MULTI_SELECT,
    initialValue: [],
    options: [],
    disableSearch: false,
    hasSelectAll: false,
    isActivated: true,
    activityTypes: [],
  },
  // {
  //   key: FIRST_ACTIVITY,
  //   displayAs: MONTH_PICKER,
  //   initialValue: {
  //     start: '2007/01/01',
  //     end: moment().format(DATE_FORMATS.FIRST_ACTIVITY)
  //   }
  // }
]

export interface NFTMapperFiltersGroups {
  type: NFTMapperActivityType[]
  // firstActivity?: NFTMapperFirstActivity[];
}

export interface NFTMapperFilterOptions {
  firstActivity?: string
  activityType: string[]
}
