import React from 'react'
import { injectIntl } from 'react-intl'
import cx from 'classnames'
import PropTypes from 'prop-types'
import TitledContent from 'components/TitledContent'
import Income from 'components/Income'
import NoData from 'components/NoData'
import messages from './messages'
import styles from './RentalsItemAnalytics.scss'

const fieldsPerType = {
  host: ['documentedIncomeForHost', 'reportedIncomeForHost', 'reportedLastYear', 'taxRate', 'taxGapForHost'],
  listing: ['documentedIncome', 'reportedIncome', 'occupancyTaxRate', 'taxGap'],
}

const RentalsItemAnalytics = ({ intl, isSpaced = false, type, item }) => (
  <div className={styles.container}>
    <ul className={styles.fields}>
      {fieldsPerType[type].map(key => (
        <li key={key} className={cx(styles.field, { [styles.spaced]: isSpaced })}>
          <TitledContent
            title={intl.formatMessage(messages.fields[key])}
            titleClassName={styles.title}
            contentClassName={styles.content}
          >
            {key !== 'documentedIncomeForHost' ? (
              <NoData />
            ) : (
              <Income isFormatted={false} value={item.totalRevenue || 0} />
            )}
          </TitledContent>
        </li>
      ))}
    </ul>
  </div>
)

RentalsItemAnalytics.propTypes = {
  intl: PropTypes.object.isRequired,
  isSpaced: PropTypes.bool,
  type: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
}

export default injectIntl(RentalsItemAnalytics)
