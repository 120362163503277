import React, { FC, useEffect, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import { SxProps } from '@mui/material/styles'
import Slider, { CustomArrowProps } from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styles from './Common.scss'

const activeArrowIcon = require('./images/activeArrow.svg')

enum ARROW_TYPES {
  NEXT = 'next',
  PREV = 'prev',
}

interface ArrowsState {
  isPrevDisabled: boolean
  isNextDisabled: boolean
}

interface SlidesConfig {
  slidesToShow: number
  slidesToScroll: number
}

interface GenericCarouselProps {
  slidesCount: number
  children: React.ReactNode
}

interface IvixCustomArrowProps extends CustomArrowProps {
  onClick: () => void
  sx?: SxProps
  isDisabled?: boolean
}

const CustomArrow: FC<IvixCustomArrowProps> = ({ onClick, sx, isDisabled }) => (
  <Button
    disabled={isDisabled}
    variant='text'
    sx={{ position: 'relative', maxWidth: '20px', padding: '5px', minWidth: '20px', minHeight: '20px', ...sx }}
    className={styles.carouselNavigation}
    onClick={onClick}
  >
    <img src={activeArrowIcon} className={styles.carouselNavigationIcon} alt='Arrow' />
  </Button>
)

interface CarouselHeaderProps {
  scrollCounter: number
  slidesCount: number
  slidesConfig: SlidesConfig
}

const CarouselHeader: FC<CarouselHeaderProps> = ({ slidesCount, slidesConfig, scrollCounter }) => {
  const [displayingRange, setDisplayingRange] = useState({
    from: 0,
    to: 0,
  })
  const { slidesToShow, slidesToScroll } = slidesConfig

  useEffect(() => {
    const isEndOfSlides = scrollCounter * slidesToScroll >= slidesCount
    const endItem = isEndOfSlides ? slidesCount : scrollCounter * slidesToScroll
    setDisplayingRange({
      from: Math.max(endItem - slidesToShow + 1, 1),
      to: isEndOfSlides ? slidesCount : scrollCounter * slidesToScroll,
    })
  }, [slidesCount, slidesConfig.slidesToShow, scrollCounter])

  return (
    <Stack
      direction='row'
      spacing={{ lg: 1, xxxl: 2 }}
      sx={{
        alignItems: 'center',
        fontWeight: '600',
        fontFamily: 'Dosis',
        fontSize: { md: 15, lg: 16, xl: 18, xxl: 24, xxxl: 42 },
        color: '#303032',
        letterSpacing: '0.5px',
      }}
    >
      <Box>REVENUE SOURCES</Box>
      <Divider
        orientation='vertical'
        sx={{ height: { lg: 15, xxxl: 30 }, alignSelft: 'center', bgcolor: 'textColor.main' }}
      />
      <Box>DISPLAYING</Box>
      <Stack spacing={1} direction='row'>
        <Box sx={{ fontWeight: '700' }}>
          {displayingRange.from} - {displayingRange.to}
        </Box>
        <Box>OF</Box>
        <Box sx={{ fontWeight: '700' }}>{slidesCount}</Box>
      </Stack>
    </Stack>
  )
}

interface CarouselNavigationProps {
  arrowsState: ArrowsState
  isSliding: boolean
  handleArrowClick: (arrowType: ARROW_TYPES) => void
}

const CarouselNavigation: FC<CarouselNavigationProps> = ({ arrowsState, isSliding, handleArrowClick }) => (
  <Stack direction='row' sx={{ justifyContent: 'center', alignItems: 'center' }}>
    <CustomArrow
      onClick={() => !arrowsState.isPrevDisabled && !isSliding && handleArrowClick(ARROW_TYPES.PREV)}
      isDisabled={arrowsState.isPrevDisabled || isSliding}
      sx={{
        mr: { md: '12px', lg: '12px', xl: '12px', xxl: '24px', xxxl: '32px' },
        transform: 'rotate(180deg)',
      }}
    />
    <CustomArrow
      onClick={() => !arrowsState.isNextDisabled && handleArrowClick(ARROW_TYPES.NEXT)}
      isDisabled={arrowsState.isNextDisabled || isSliding}
    />
  </Stack>
)

const slidesConfig: SlidesConfig = {
  slidesToShow: 3,
  slidesToScroll: 3,
}

const GenericCarousel: FC<GenericCarouselProps> = ({ children, slidesCount }) => {
  const [scrollCounter, setScrollCounter] = useState(1)
  const [arrowsState, setArrowsState] = useState({
    isPrevDisabled: true,
    isNextDisabled: false,
  })
  const [isSliding, setIsSliding] = useState<boolean>(false)

  const carouselRef = useRef<Slider>(null)

  const handleArrowClick = (arrowType: ARROW_TYPES) => {
    // if clicked arrow is disabled - return
    if (!carouselRef?.current && isSliding) return

    setIsSliding(true)
    if (arrowType === ARROW_TYPES.NEXT) {
      carouselRef?.current?.slickNext()
      setScrollCounter(prevCount => ++prevCount)
    } else {
      carouselRef?.current?.slickPrev()
      setScrollCounter(prevCount => --prevCount)
    }
  }

  useEffect(() => {
    const isEmptyCarousel = slidesCount === 0 || slidesCount <= slidesConfig.slidesToShow
    const isAllSlidesVisited = scrollCounter * slidesConfig.slidesToShow >= slidesCount
    const isBetween = scrollCounter > 1 && scrollCounter * slidesConfig.slidesToShow <= slidesCount
    const isAtFirstSlide = scrollCounter === 1

    setArrowsState(prevArrowsState =>
      isEmptyCarousel
        ? { isPrevDisabled: true, isNextDisabled: true }
        : isAllSlidesVisited
        ? { isPrevDisabled: false, isNextDisabled: true }
        : isAtFirstSlide
        ? { isPrevDisabled: true, isNextDisabled: false }
        : isBetween // Can naviate both ways
        ? { isPrevDisabled: false, isNextDisabled: false }
        : prevArrowsState,
    )
  }, [scrollCounter, slidesCount])

  const defaultSettings = {
    speed: 500,
    initialSlide: 0,
    infinite: false,
    arrows: false,
    ...slidesConfig,
  }

  return (
    <Box
      className={styles.genericCarousel}
      sx={{
        position: 'relative',
        gridColumn: '1 / 3',
        gridRow: '2 / 3',
      }}
    >
      <Stack
        direction='row'
        width='100%'
        justifyContent='space-between'
        alignItems='center'
        mb={{ md: '8px', xlPlus: '16px', xxxl: '24px' }}
      >
        <CarouselHeader scrollCounter={scrollCounter} slidesCount={slidesCount} slidesConfig={slidesConfig} />
        <CarouselNavigation isSliding={isSliding} arrowsState={arrowsState} handleArrowClick={handleArrowClick} />
      </Stack>
      <Stack sx={{ flex: 1, flexDirection: 'column', height: '100%' }}>
        <Slider ref={carouselRef} {...defaultSettings} afterChange={() => setIsSliding(false)}>
          {children}
        </Slider>
      </Stack>
    </Box>
  )
}

export default GenericCarousel
