import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import cx from 'classnames'
import PropTypes from 'prop-types'
import RentalsActions from 'components/rentals/RentalsActions'
import RentalsList from 'components/rentals/RentalsList'
import RentalsHostPanel from 'components/rentals/RentalsHostPanel'
import { sizeToGridHeight, sizeToHostPanelHeight } from 'containers/Rentals/specs'
import {
  fetchEntitiesForExport,
  fetchHostListings,
  fetchRentalsHosts,
  selectDisplayedHost,
  selectEntitiesCSV,
  selectHosts,
  selectHostsAllowScrolling,
  selectHostsDisplayedColumns,
  selectHostsFilters,
  selectIsLoadingInitial,
  selectIsLoadingNextBatch,
  selectSelectedHostsIds,
  selectTotalDisplayedHosts,
  selectTotalHosts,
  setDisplayedHost,
  setEntitiesCSV,
  setHostsDisplayedColumns,
  setIsLoadingNextBatch,
  setSelectedHostsListings,
  toggleSelectAllHosts,
  toggleSelectedHost,
} from 'store/rentals'

import { selectCurrentZone } from 'store/global'
import usePrevious from '@rooks/use-previous'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { gridColumns, toggleableColumns } from './specs'
import styles from './RentalsHosts.scss'
import messages from './messages'
import { downloadCSV } from '../../../utils/downloadCSV'

export default function RentalsHosts({
  size,
  onFiltersChange,
  showFilters,
  setShowFilters,
  onFieldsChange,
  search,
  searchFields,
  setSearch,
  setSearchFields,
}) {
  const dispatch = useDispatch()

  const isLoadingInitial = useSelector(selectIsLoadingInitial)
  const isLoadingNextBatch = useSelector(selectIsLoadingNextBatch)
  const allowScrolling = useSelector(selectHostsAllowScrolling)
  const currentZone = useSelector(selectCurrentZone)
  const previousZone = usePrevious(currentZone)

  const entitiesCSV = useSelector(selectEntitiesCSV)

  const hostsFilters = useSelector(selectHostsFilters)
  const totalHosts = useSelector(selectTotalHosts)
  const totalDisplayedHosts = useSelector(selectTotalDisplayedHosts)
  const hosts = useSelector(selectHosts)

  const selectedIds = useSelector(selectSelectedHostsIds)
  const displayedColumnsKeys = useSelector(selectHostsDisplayedColumns)
  const displayedColumns = gridColumns.filter(
    ({ key, isPersistent, isShown = true }) => isShown && (isPersistent || displayedColumnsKeys.includes(key)),
  )

  const displayedHost = useSelector(selectDisplayedHost)

  const { id: displayedHostId } = displayedHost

  const [isHostDisplayed, setIsHostDisplayed] = useState(false)
  const [isShareDisabled, setIsShareDisabled] = useState(false)

  useEffect(() => {
    setIsHostDisplayed(!!displayedHost && !!displayedHost.id)
  }, [displayedHost])

  const onLoadMore = () => {
    const scrollLimit = parseInt(process.env.SCROLL_LIMIT)
    if (!allowScrolling || isLoadingNextBatch || (scrollLimit !== 0 && hosts.length >= scrollLimit)) return
    dispatch(setIsLoadingNextBatch(true))
    dispatch(fetchRentalsHosts({ filters: hostsFilters, from: totalDisplayedHosts }))
  }

  const onCSVExport = () => {
    setIsShareDisabled(true)

    const payload = {
      filters: hostsFilters,
    }

    // @ts-ignore - gotta give type inside slice, but it's a js file
    dispatch(fetchEntitiesForExport({ ...payload }))
    // trackEvent({ category: "Export", action: "User requested for activity CSV" })
  }

  useEffect(() => {
    if (!entitiesCSV) return
    const csvFileName = 'Hosts.csv'
    downloadCSV(entitiesCSV, csvFileName)
    setIsShareDisabled(false)
    dispatch(setEntitiesCSV({ csv: null }))
  }, [entitiesCSV])

  const onSelectAll = nextIsAllSelected => dispatch(toggleSelectAllHosts({ isAllSelected: nextIsAllSelected }))

  const onColumnsSelection = columns => dispatch(setHostsDisplayedColumns({ columns }))

  return (
    <section
      className={cx(styles.gridContainer, styles.hosts, {
        [styles.withHost]: isHostDisplayed,
      })}
    >
      <div className={styles.details}>
        <div className={styles.gridHeader}>
          <h3 className={styles.title}>
            <FormattedMessage
              {...messages.header}
              values={{
                displayed: <span className={styles.displayed}>1 - {totalDisplayedHosts}</span>,
                total: (
                  <span className={styles.total}>
                    <FormattedNumber value={totalHosts} />
                  </span>
                ),
              }}
              tagName='span'
            />
          </h3>
          <Button color='primary' onClick={() => setShowFilters(!showFilters)} className={styles.filterButton}>
            <Icon name='filters' size='regular'>
              <FormattedMessage {...messages.filters} />
            </Icon>
          </Button>
          <span className='dashed-line' />
          <RentalsActions
            search={search}
            searchFields={searchFields}
            setSearch={setSearch}
            setSearchFields={setSearchFields}
            onSearch={search => onFiltersChange({ ...hostsFilters, search })}
            toggleableColumns={toggleableColumns}
            displayedColumns={displayedColumnsKeys}
            onSelection={onColumnsSelection}
            isHostDisplayed={isHostDisplayed}
            onFieldsChange={onFieldsChange}
            isShareDisabled={isShareDisabled}
            onExport={onCSVExport}
          />
        </div>
        <RentalsList
          listType='hosts'
          isGridLoading={isLoadingInitial}
          isLoadingNextBatch={isLoadingNextBatch}
          height={sizeToGridHeight[size]}
          columns={displayedColumns}
          items={hosts}
          selectedIds={selectedIds}
          highlightedItemId={displayedHostId}
          onScroll={onLoadMore}
          onSelectAll={onSelectAll}
          onItemSelect={hostId => dispatch(toggleSelectedHost({ hostId }))}
          onRowClick={host => {
            dispatch(setSelectedHostsListings(host.enrichedListings))
            dispatch(setDisplayedHost({ host }))
            dispatch(fetchHostListings({ hostId: host.hostSiteId }))
          }}
          onSortClick={sort => {
            onFiltersChange({ ...hostsFilters, sort })
          }}
          activeSort={hostsFilters.sort}
        />
      </div>
      {displayedHost.id && (
        <div className={styles.host}>
          <RentalsHostPanel height={sizeToHostPanelHeight[size]} host={displayedHost} previousZone={previousZone} />
        </div>
      )}
    </section>
  )
}

RentalsHosts.propTypes = {
  size: PropTypes.string,
  showFilters: PropTypes.bool,
  setShowFilters: PropTypes.func,
  onFiltersChange: PropTypes.func.isRequired,
  onFieldsChange: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  searchFields: PropTypes.array.isRequired,
  setSearch: PropTypes.func.isRequired,
  setSearchFields: PropTypes.func.isRequired,
}
