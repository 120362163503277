import React, { useEffect, useMemo, useState } from 'react'
import { Option } from 'react-multi-select-component/dist/lib/interfaces'
import filtersSerivce from 'services/filters'
import { ISelection, TSubject } from '../Filters'
import NestedDropdownInput from 'components/NestedDropdownInput/NestedDropdownInput'
import { FixedNestedOptions, NestedOptions, RentalsNestedLocations } from '../../../utils/filters'
import cx from 'classnames'
import styles from './NestedMultiSelect.scss'
import Icon from 'components/Icon'
import { SELECT_ALL, TChangeEvent, THandleOptionClick } from './utils'

type TOnNestedMultiSelectChange = (
  subject: TSubject,
  selections: Array<ISelection>,
  selectionsMap: FixedNestedOptions,
  currentNestedFilter: string,
) => void

interface NestedMultiSelectProps {
  data: RentalsNestedLocations
  options: NestedOptions
  renderOption: (option: string) => string
  onNestedMultiSelectChange: TOnNestedMultiSelectChange
  currentNestedFilter: string
  selectedOptions: string[]
  didClear: boolean
  maxShownSelections: number
}

const NestedMultiSelect: React.FC<NestedMultiSelectProps> = ({
  data,
  options,
  renderOption,
  onNestedMultiSelectChange,
  currentNestedFilter,
  selectedOptions,
  didClear,
  maxShownSelections,
}) => {
  const { key, disableSearch, hasSelectAll } = data
  const [selectionsMap, setSelectionsMap] = useState<FixedNestedOptions | {}>({})
  const [isDropdownOpen, setIsDropDownOpen] = useState<boolean>(false)

  useEffect(() => {
    const nestedOptions = Object.entries(options).reduce((newOptions, [groupHeader, groupOptions]) => {
      newOptions[groupHeader] = {
        groupOptions,
        itemSelections: selectedOptions.reduce((currentItemSelections: string[], selectedOption: string) => {
          const shouldAddHeader = groupOptions.length === 0 && groupHeader === selectedOption
          if (groupOptions.includes(selectedOption) || shouldAddHeader) {
            currentItemSelections.push(selectedOption)
          }
          return currentItemSelections
        }, []),
      }
      return newOptions
    }, {})

    setSelectionsMap(nestedOptions)
  }, [options])

  useEffect(() => {
    if (!didClear) return

    const clearSelectionsMap = () => {
      const clearedSelectionsMap = Object.entries(selectionsMap).reduce(
        (nextSelectionsMap, [groupHeader, groupData]) => {
          const { groupOptions } = groupData
          return { ...nextSelectionsMap, [groupHeader]: { groupOptions, itemSelections: [] } }
        },
        {},
      )
      setSelectionsMap(clearedSelectionsMap)
    }

    clearSelectionsMap()
  }, [didClear])

  const dropdownOptions = useMemo(() => {
    const selectAllOption = { value: SELECT_ALL.VALUE, label: SELECT_ALL.VALUE, key: SELECT_ALL.KEY }

    return Object.entries(selectionsMap).reduce(
      (flattendOptions: Option[], [groupHeader, groupHeaderData]) => {
        // Create header & Handle flat groups
        const groupHeaderOptionKey = { groupHeader }

        const groupHeaderOption = {
          value: groupHeader,
          label: renderOption(groupHeader),
          key: JSON.stringify(groupHeaderOptionKey),
        }

        flattendOptions.push(groupHeaderOption)

        // Create header's options
        groupHeaderData.groupOptions.forEach(groupOption => {
          const groupOptionKey = { groupHeader, groupOption }
          flattendOptions.push({
            value: groupOption,
            label: renderOption(groupOption),
            key: JSON.stringify(groupOptionKey),
          })
        })

        return flattendOptions
      },
      [selectAllOption],
    )
  }, [selectionsMap])

  const selectionOptions: Array<ISelection> = selectedOptions.map((value: string) => ({
    value,
    label: value,
  }))

  const parseOptionKey = (option: Option): Record<string, string> | null => {
    return option?.key && option.key !== SELECT_ALL.KEY ? JSON.parse(option.key) : null
  }

  const handleSelectAllClick = (): FixedNestedOptions => {
    const isSelectAllActive = filtersSerivce.checkIsSelectAllActive(selectionsMap)

    // Activate or deactivate all options
    return Object.entries(selectionsMap).reduce((updatedSelections, [groupHeader, { groupOptions }]) => {
      const isFlatGroup = groupOptions.length === 0

      const nextItemSelections: string[] = isSelectAllActive ? [] : isFlatGroup ? [groupHeader] : [...groupOptions]

      updatedSelections[groupHeader] = { groupOptions, itemSelections: nextItemSelections }

      return updatedSelections
    }, {})
  }

  const handleGroupHeaderClick = (parsedOptionKey: Record<string, string>): FixedNestedOptions => {
    const selectionsMapCopy = { ...selectionsMap }
    const currentGroup = selectionsMapCopy[parsedOptionKey.groupHeader]
    const isNestedGroup = currentGroup?.groupOptions.length > 0 // A group that contains header and options

    if (isNestedGroup) {
      const isAllGroupOptionsSelected = currentGroup.itemSelections.length === currentGroup.groupOptions.length

      currentGroup.itemSelections = isAllGroupOptionsSelected ? [] : [...currentGroup.groupOptions]
    } else {
      // Handle flat group click
      const isNotNestedGroupSelected = currentGroup.itemSelections.length === 1
      currentGroup.itemSelections = isNotNestedGroupSelected ? [] : [parsedOptionKey.groupHeader]
    }

    return selectionsMapCopy
  }

  const handleGroupOptionClick = (parsedOptionKey: Record<string, string>): FixedNestedOptions => {
    const selectionsMapCopy = { ...selectionsMap }

    const { groupHeader, groupOption } = parsedOptionKey
    const clickedOptionIndex = selectionsMapCopy[groupHeader].itemSelections.findIndex(
      (selectedItem: string) => groupOption === selectedItem,
    )
    if (clickedOptionIndex === -1) {
      selectionsMapCopy[groupHeader].itemSelections.push(groupOption)
    } else if (clickedOptionIndex > -1) {
      selectionsMapCopy[groupHeader].itemSelections.splice(clickedOptionIndex, 1)
    }

    return selectionsMapCopy
  }

  const handleOptionClick = (e: TChangeEvent, clickedOption: Option, onClick: (e: TChangeEvent) => void) => {
    // * selectionsMap controls the UI.
    // * currentFilters activates the filter.

    if (!clickedOption?.key) return

    let updatedSelectionsMap: FixedNestedOptions | {} = {}

    if (clickedOption?.value !== SELECT_ALL.VALUE) {
      const parsedOptionKey = parseOptionKey(clickedOption)
      if (parsedOptionKey) {
        updatedSelectionsMap =
          'groupOption' in parsedOptionKey
            ? handleGroupOptionClick(parsedOptionKey)
            : handleGroupHeaderClick(parsedOptionKey)
      }
    } else updatedSelectionsMap = handleSelectAllClick()

    setSelectionsMap(updatedSelectionsMap)
    // multi-select-cmp onclick handler
    onClick(e)
  }

  const buttonRenderer = (selectionOptions: ISelection[], _options: Option[]): JSX.Element => {
    const placeholderText = (
      <div className={cx('customPlaceholder', { ['withOpenDropdown']: !isDropdownOpen })}>
        <span className='placeholderText'>SEARCH & SELECT</span>
      </div>
    )
    const maxDetailedSelections = 2
    const detailedSelections = selectionOptions.map(selection => selection?.label).join(', ')
    const selectionsSummary = (
      <span className='nestedSelectionsSummary'>{selectionOptions.length} items are selected</span>
    )

    return (
      <span className='customNestedButtonRenderer'>
        {selectionOptions.length === 0
          ? placeholderText
          : selectionOptions.length > maxDetailedSelections
            ? selectionsSummary
            : detailedSelections}
        <Icon
          key={isDropdownOpen ? 'dropdownArrowUp' : 'dropdownArrowDown'}
          size='small'
          name={isDropdownOpen ? 'dropdownArrowUp' : 'dropdownArrowDown'}
          className='placeholderArrowIcon'
        />
      </span>
    )
  }

  return (
    <div className={styles.nestedMultiSelect}>
      <NestedDropdownInput
        hasSelectAll={hasSelectAll}
        selectionsMap={selectionsMap}
        options={dropdownOptions}
        handleOptionClick={handleOptionClick}
        selections={selectionOptions}
        onChange={(selections: Array<ISelection>) =>
          onNestedMultiSelectChange(key, selections, selectionsMap, currentNestedFilter)
        }
        disableSearch={disableSearch}
        parseOptionKey={parseOptionKey}
        className={'nestedMultiSelectDropdown'}
        buttonRenderer={buttonRenderer}
        onToggle={() => setIsDropDownOpen(!isDropdownOpen)}
      />
    </div>
  )
}

export default NestedMultiSelect
