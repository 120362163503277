import axios from 'axios'
import toCamelCaseKeys from 'camelcase-keys'

class AxiosService {
  constructor() {
    // axios.defaults.baseURL = 'https://ivix-api.herokuapp.com/';
    axios.defaults.baseURL = process.env.API_URL
    // console.log('AXIOS BASEURL', process.env.API_URL);
    // console.log('AXIOS LOCAL_LOGIN', process.env.LOCAL_LOGIN);
    // console.log('AXIOS ENV', process.env);

    axios.defaults.params = axios.defaults.params || {}

    axios.defaults.withCredentials = true

    axios.interceptors.response.use(this.ensureKeysCase(toCamelCaseKeys), undefined)
    axios.interceptors.response.use(undefined, this.errorHandler)
  }

  ensureKeysCase(handler) {
    const forbiddenUrls = ['rentals/hostsAndListingsLocations']

    return config => {
      if (forbiddenUrls.includes(config.config.url)) return config
      if (config.data && !config.config.skipKeysConversion) {
        config.data = handler(config.data, { deep: true })
      }

      return config
    }
  }

  errorHandler(error) {
    const isAxiosError = ![typeof error, typeof error.response, typeof error.config].includes('undefined')
    console.error(`[Request Interceptor] ${isAxiosError ? 'API' : 'Non-API'} Error | `, error)
    return Promise.reject(error)
  }

  request(config, isCrashable = true, isErrorable = true) {
    return axios({
      ...config,
      isCrashable,
      isErrorable,
    })
  }

  get(url, config) {
    // console.log('AXIOS URL', url);
    // console.log('AXIOS CONFIG', config);
    return axios.get(url, config)
  }

  post(url, data, config) {
    return axios.post(url, data, config)
  }

  put(url, data, config) {
    return axios.put(url, data, config)
  }

  delete(url, config) {
    return axios.delete(url, config)
  }
}

export default new AxiosService()
