import { createSelector } from 'reselect'
import { initialState } from './slice'

const selectSettingsState = state => state.settings || initialState

export const selectIsModalDisplayed = createSelector(selectSettingsState, state => state.isModalDisplayed)
export const selectIsUserPlatformsModalDisplayed = createSelector(
  selectSettingsState,
  state => state.isUserPlatformsModalDisplayed,
)
