import React, { FC, ReactNode } from 'react'
import Stack from '@mui/material/Stack'
import { SxProps, useTheme } from '@mui/material/styles'

interface VerticalOverviewProps {
  sx?: SxProps
  children: ReactNode
}

const VerticalOverview: FC<VerticalOverviewProps> = ({ children, sx = {} }) => {
  const theme = useTheme()

  return (
    <Stack
      sx={{
        gridRow: '2 / 3',
        overflow: 'hidden',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1fr',
        gap: '24px',
        ...sx,
      }}
    >
      {children}
    </Stack>
  )
}

export default VerticalOverview
