const Container = {
  marginLeft: 'auto',
}

const Button = {
  borderRadius: '8px',
  backgroundColor: '#2257C5',
  padding: { md: '4px 13px', lg: '5px 15px', xl: '6px 16px' },
  fontFamily: 'Source Sans Pro',
  fontSize: { md: '14px', lg: '15px', xl: '16px' },
  fontWeight: 400,
  color: '#FFFFFF',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: '#2257c5c9',
  },
}

const CloseButton = {
  position: 'absolute',
  top: 15,
  right: 15,
}

const OverviewContainer = {
  border: 'none',
  borderRadius: 0,
  borderBottom: 'solid 1px #DADBDF',
  padding: { md: '14px 12px', lg: '18px 14px', xl: '24px' },
}

const Modal = {
  display: 'block',
  padding: 0,
  backgroundColor: '#E9ECF1',
  borderRadius: '8px',
  overflow: 'hidden',
}

const GraphContainer = {
  flex: 1,
  position: 'relative',
}

const GraphOverviewContainer = {
  flex: { md: '0 0 300px', lg: '0 0 380px', xl: '0 0 480px' },
}

const SearchContainer = {
  position: 'relative',
  marginTop: '25px',
  marginRight: { md: '12px', lg: '14px', xl: '25px' },
  '& > img': {
    position: 'absolute',
    width: '18px',
    height: '18px',
    top: '11px',
    left: '13px',
    zIndex: 2,
  },
  '.MuiInputBase-root': {
    input: {
      padding: '0 0 0 40px',
      fontFamily: 'Source Sans Pro',
      fontSize: '16px',
      color: '#1C2842',
      height: '38px',
      borderRadius: '8px',
      backgroundColor: '#fff',
      border: '1px solid #D1D1D6',
      '&::placeholder': {
        color: '#7E8BA6',
      },
    },
  },
}

const GraphOverview = {
  border: '1px solid #DAD8DF',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginTop: { md: '12px', lg: '14px', xl: '25px' },
  marginRight: { md: '12px', lg: '14px', xl: '25px' },
  flex: 1,
  marginBottom: '25px',
  overflow: 'auto',
}

const GraphOverviewTitle = {
  fontFamily: 'Dosis',
  fontSize: { md: '16px', lg: '18px', xl: '20px' },
  fontWeight: 600,
  textTransform: 'uppercase',
  padding: '12px 18px',
}

const LabelsContainer = {
  padding: '15px 20px',
}

const LabelsContainerTitle = {
  fontFamily: 'Dosis',
  fontSize: { md: '15px', lg: '15px', xl: '16px' },
  fontWeight: 600,
  textTransform: 'uppercase',
  span: {
    color: '#DAD8DF',
    padding: '8px',
  },
}

const LabelItem = {
  backgroundColor: '#7E8BA6',
  borderRadius: '8px',
  color: '#fff',
  fontFamily: 'Source Sans Pro',
  fontSize: { md: '14px', lg: '15px', xl: '16px' },
  padding: '4px 12px',
  textTransform: 'capitalize',
}

const styles = {
  Container,
  Button,
  CloseButton,
  OverviewContainer,
  Modal,
  GraphContainer,
  GraphOverviewContainer,
  SearchContainer,
  GraphOverview,
  GraphOverviewTitle,
  LabelsContainer,
  LabelsContainerTitle,
  LabelItem,
}

export default styles
