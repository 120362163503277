import React, { FC } from 'react'
import Button from 'components/common/Button'
import Tabs from 'components/common/Tabs'
import CloseIcon from 'components/common/icons/Close'
import { tabsList, tabsIds } from './utils'
import styles from './HeaderActions.scss'

interface HeaderActionsProps {
  tabItem: number
  setTabItem: React.Dispatch<React.SetStateAction<number>>
  onCloseGraph: () => void
}

const HeaderActions: FC<HeaderActionsProps> = ({ tabItem, setTabItem, onCloseGraph }) => (
  <div className={styles.container}>
    <Tabs disabledIds={[tabsIds.MAP]} value={tabItem} tabs={tabsList} onChange={(event, value) => setTabItem(value)} />
    <Button
      styleType='tertiary'
      label='Close'
      svgIcon={<CloseIcon />}
      className={styles.close}
      onClick={onCloseGraph}
    />
  </div>
)

export default HeaderActions
