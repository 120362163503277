// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ActiveFilters-module__rangeFilters--yZjJM span{margin:0 2px;color:#7e8ba6;font-family:Source Sans Pro;font-size:16px;font-weight:400}.ActiveFilters-module__rangeFilters--yZjJM span.ActiveFilters-module__number--amnW_{color:#1c2842}", "",{"version":3,"sources":["webpack://./app/components/ActiveFilters/ActiveFilters.module.scss"],"names":[],"mappings":"AACE,gDACE,YAAA,CACA,aAAA,CACA,2BAAA,CACA,cAAA,CACA,eAAA,CACA,oFACE,aAAA","sourcesContent":[".rangeFilters {\n  span {\n    margin: 0 2px;\n    color: #7E8BA6;\n    font-family: Source Sans Pro;\n    font-size: 16px;\n    font-weight: 400;\n    &.number {\n      color: #1C2842;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rangeFilters": "ActiveFilters-module__rangeFilters--yZjJM",
	"number": "ActiveFilters-module__number--amnW_"
};
export default ___CSS_LOADER_EXPORT___;
