import { defineMessages } from 'react-intl'

const scope = 'app.containers.CDTFATablePage'

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Data Science',
  },
  count: {
    id: `${scope}.count`,
    defaultMessage: 'Data Science: {displayed} of {total}',
  },
  filters: {
    id: `${scope}.filters`,
    defaultMessage: 'Filters',
  },
})
