import React, { FC } from 'react'
import Stack from '@mui/material/Stack'
import { SxProps, useTheme } from '@mui/material/styles'
import styles from './styles.scss'

interface ChartWrapperProps {
  sx?: SxProps
  children: React.ReactNode
}

const ChartWrapper: FC<ChartWrapperProps> = ({ children, sx }) => {
  const theme = useTheme()
  return (
    <Stack className={styles.chartWrapperContainer}>
      <Stack
        sx={{
          ...theme.mixins.customScrollBar(),
          flexGrow: 1,
          alignItems: 'center',
          height: '100%',
          maxHeight: '100%',
          padding: {
            md: '8px 8px 4px 8px',
            lg: '10px 10px 4px 10px',
            xl: '14px 18px 1px 18px',
            xxl: '32px 40px 12px 40px',
            xxxl: '40px 50px 16px 50px',
          },
          overflow: 'auto',
          ...(sx as object),
        }}
      >
        {children}
      </Stack>
    </Stack>
  )
}

export default ChartWrapper
