import { defineMessages } from 'react-intl'

const scope = 'app.containers.App'

export default defineMessages({
  loggingIn: {
    id: `${scope}.loggingIn`,
    defaultMessage: 'Logging you in...',
  },
})
