const gridColumnGroups = ['selection', 'identificationDetails', 'income', 'hostInfo', 'taxGap']
const [GROUP_SELECTION, GROUP_DETAILS, GROUP_INCOME, GROUP_HOST_INFO, GROUP_TAX] = gridColumnGroups

export const COLUMN_SELECT = 'select'
export const COLUMN_NAME = 'hostName'

export const gridColumns = [
  {
    group: GROUP_SELECTION,
    key: COLUMN_SELECT,
    size: 1,
    fixedWidth: 80,
    isPersistent: true,
  },
  {
    group: GROUP_DETAILS,
    key: COLUMN_NAME,
    firstInGroup: true,
    groupHeader: 'Identification Details',
    fixedWidth: 240,
    isSearchable: true,
    isPersistent: true,
  },
  {
    group: GROUP_DETAILS,
    key: 'verifiedHostName',
    fixedWidth: 400,
    isSearchable: true,
    isPersistent: true,
  },
  {
    group: GROUP_DETAILS,
    key: 'reportedStatus',
    size: 2,
    fixedWidth: 250,
    isHiddenByDefault: true,
  },
  {
    group: GROUP_DETAILS,
    key: 'verifiedHostNameOther',
    fixedWidth: 240,
    isPersistent: true,
    isShown: String(process.env.PRESENT_TOTO_INFORMATION).toLowerCase() === 'true',
  },
  {
    group: GROUP_DETAILS,
    key: 'birthday',
    fixedWidth: 240,
    isPersistent: true,
    isShown: String(process.env.PRESENT_TOTO_INFORMATION).toLowerCase() === 'true',
  },
  {
    group: GROUP_DETAILS,
    key: 'hostType',
    fixedWidth: 130,
    isPersistent: false,
    isShown: String(process.env.DEMO_MODE).toLowerCase() === 'true',
  },
  {
    group: GROUP_DETAILS,
    key: 'tin',
    size: 2,
    fixedWidth: 140,
    isHiddenByDefault: true,
  },
  {
    group: GROUP_DETAILS,
    key: 'numberOfListings',
    fixedWidth: 150,
    minWidth: 130,
    isSortable: true,
  },
  {
    group: GROUP_DETAILS,
    key: 'numberOfTotalListings',
    fixedWidth: 150,
    minWidth: 130,
    isSortable: true,
  },
  {
    group: GROUP_DETAILS,
    key: 'numberOfReviews',
    fixedWidth: 170,
    minWidth: 130,
    isSortable: true,
  },
  {
    group: GROUP_HOST_INFO,
    firstInGroup: true,
    groupHeader: 'Host Details',
    key: 'address',
    size: 1,
    fixedWidth: 210,
  },
  {
    group: GROUP_HOST_INFO,
    key: 'extendedAddress',
    size: 1,
    fixedWidth: 210,
    isShown: String(process.env.PRESENT_TOTO_INFORMATION).toLowerCase() === 'true',
  },
  {
    group: GROUP_HOST_INFO,
    key: 'siteProfile',
    size: 1,
    fixedWidth: 130,
  },
  {
    group: GROUP_HOST_INFO,
    key: 'phoneNumber',
    size: 1,
    minWidth: 140,
    isHiddenByDefault: true,
  },
  {
    group: GROUP_HOST_INFO,
    key: 'email',
    size: 1,
    minWidth: 140,
    isHiddenByDefault: true,
  },
  {
    group: GROUP_HOST_INFO,
    key: 'webpage',
    size: 2,
    fixedWidth: 130,
  },
  {
    group: GROUP_INCOME,
    key: 'documentedRevenue',
    firstInGroup: true,
    groupHeader: 'Revenue',
    size: 2,
    fixedWidth: 200,
    isSortable: true,
  },
  {
    group: GROUP_INCOME,
    key: 'reportedRevenue',
    size: 2,
    fixedWidth: 200,
  },
  {
    group: GROUP_TAX,
    firstInGroup: true,
    groupHeader: 'Tax Gap',
    headerIcons: ['arrowUp'],
    key: 'taxGap',
    fixedWidth: 240,
    isPersistent: true,
  },
]

export const toggleableColumns = gridColumns.filter(column => !column.isPersistent).map(column => column.key)

export const searchableColumns = gridColumns.filter(column => column.isSearchable).map(column => column.key)

export const defaultHostsDisplayedColumns = gridColumns
  .filter(column => !column.isPersistent && !column.isHiddenByDefault)
  .map(column => column.key)
