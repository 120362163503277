import { Filter, FilterTypes } from 'components/FilterDrawer/FilterDrawer.config'

export const filtersInitialState: Filter[] = [
  {
    type: FilterTypes.RANGE,
    name: 'date_year',
    label: 'Years Range',
    value: [],
    step: 1,
  },
  {
    type: FilterTypes.CHIPS,
    name: 'job_department',
    label: 'Departments',
    value: [],
  },
  {
    type: FilterTypes.CHIPS,
    name: 'display_country',
    label: 'Locations',
    value: [],
  },
  {
    type: FilterTypes.BOOL,
    name: 'num_executives',
    label: 'Present Executives Only',
    activeFilterLabel: 'Executives Only',
    value: [],
  },
]

export const legendData = [
  { label: 'Local', color: '#FF5837' },
  { label: 'Non-local', color: '#2257C5' },
]
