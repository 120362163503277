// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchBar-module__container--OEv95{position:relative;margin-left:auto;display:flex;align-items:center;gap:6px;width:424px;height:40px;border-radius:5px;padding:0 12px;border:1px solid #d1d1d6}.SearchBar-module__container--OEv95 .SearchBar-module__searchButton--R3s7B{width:24px;height:24px;display:flex;justify-content:center;align-items:center;box-shadow:none;opacity:1}.SearchBar-module__container--OEv95 input.SearchBar-module__input--pt_ZF{border:none;width:375px;color:#7e8ba6;font-family:\"Source Sans Pro\";font-size:16px;font-weight:400;line-height:24px;text-align:left}.SearchBar-module__container--OEv95 input.SearchBar-module__input--pt_ZF:focus{outline:none}", "",{"version":3,"sources":["webpack://./app/containers/VerticalPage/company-overview/EmployeesTab/SearchBar.module.scss"],"names":[],"mappings":"AAAA,oCACE,iBAAA,CACA,gBAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA,CACA,WAAA,CACA,WAAA,CACA,iBAAA,CACA,cAAA,CACA,wBAAA,CAEA,2EACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,eAAA,CACA,SAAA,CAGF,yEACE,WAAA,CACA,WAAA,CACA,aAAA,CACA,6BAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,eAAA,CAEA,+EACE,YAAA","sourcesContent":[".container {\n  position: relative;\n  margin-left: auto;\n  display: flex;\n  align-items: center;\n  gap: 6px;\n  width: 424px;\n  height: 40px;\n  border-radius: 5px;\n  padding: 0 12px;\n  border: 1px solid #d1d1d6;\n\n  .searchButton {\n    width: 24px;\n    height: 24px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    box-shadow: none;\n    opacity: 1;\n  }\n\n  input.input {\n    border: none;\n    width: 375px;\n    color: #7e8ba6;\n    font-family: 'Source Sans Pro';\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    text-align: left;\n\n    &:focus {\n      outline: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SearchBar-module__container--OEv95",
	"searchButton": "SearchBar-module__searchButton--R3s7B",
	"input": "SearchBar-module__input--pt_ZF"
};
export default ___CSS_LOADER_EXPORT___;
