import { createSelector } from 'reselect'
import { initialState } from './slice'

const selectBusinessesState = state => state.businesses || initialState

export const selectBusinessTypes = createSelector(selectBusinessesState, state => state.businessTypes)

export const selectSummary = createSelector(selectBusinessesState, state => state.summary)

export const selectBusinesses = createSelector(selectBusinessesState, state =>
  state.businesses.map((business, index) => ({
    ...business,
    index,
  })),
)

export const selectFetchFromIndex = createSelector(selectBusinessesState, state => state.from)

export const selectBusinessesForExport = createSelector(selectBusinessesState, state =>
  state.businessesForExport
    ? state.businessesForExport.map((business, index) => ({
        ...business,
        index,
      }))
    : [],
)

export const selectScrollID = createSelector(selectBusinessesState, state => state.scrollId)

export const selectAllowScrolling = createSelector(selectBusinessesState, state => state.allowScrolling)

export const selectInitiallySelectedBusinesses = createSelector(
  selectBusinessesState,
  state => state.initiallySelectedBusinesses,
)

export const selectIsLoadingSummary = createSelector(selectBusinessesState, state => state.isLoadingSummary)
export const selectIsLoadingInitial = createSelector(selectBusinessesState, state => state.isLoadingInitial)

export const selectIsLoadingNextBatch = createSelector(selectBusinessesState, state => state.isLoadingNextBatch)

export const selectSelectedBusinessesIds = createSelector(selectBusinessesState, state => state.selectedBusinessesIds)

export const selectFilters = createSelector(selectBusinessesState, state => state.filters)

export const selectDisplayedColumns = createSelector(selectBusinessesState, state => state.displayedColumns)

export const selectTotalBusinesses = createSelector(selectBusinessesState, state => state.totalBusinesses)

export const selectShowMap = createSelector(selectBusinessesState, state => state.showMap)

export const selectAllBusinessesById = createSelector(
  selectSelectedBusinessesIds,
  selectBusinesses,
  (ids, businesses) => businesses.filter(business => ids.includes(business.id)),
)

export const selectAllEmployeesLocations = createSelector(selectAllBusinessesById, businesses =>
  businesses.map(business => business.enrichedEmployeeLocations).flat(),
)

export const selectAllBusinessesLocations = createSelector(selectAllBusinessesById, businesses =>
  businesses.map(({ businessGeopoints = [] }) => businessGeopoints).flat(),
)

export const selectAllMarketingLocations = createSelector(selectAllBusinessesById, businesses =>
  businesses.map(({ adsGeopoints = [] }) => adsGeopoints).flat(),
)

export const selectAllReviewsLocations = createSelector(selectAllBusinessesById, businesses =>
  businesses.map(({ reviewsGeopoints = [] }) => reviewsGeopoints).flat(),
)

export const selectAllPermitsLocations = createSelector(selectAllBusinessesById, businesses =>
  businesses.map(({ permitsGeos = [] }) => permitsGeos).flat(),
)
