import { SxProps, ThemeOptions } from '@mui/material/styles'

export const globalTheme: ThemeOptions = {
  palette: {
    primary: {
      main: '#2257C5',
      dark: '#0D2558',
      light: '#7687AA',
    },
    secondary: {
      main: '#1C2842',
    },
    textColor: {
      main: '#1C2842',
      subTitle: '#707991',
      lightGray: '#979A9F',
      lighterGray: '#7E8BA6',
      tableColumnHeader: '#7e7e7e',
      tableGroupHeader: '#303032',
    },
    border: {
      main: '#C6D8FF',
      button: '#D1D1D6',
      tableBorder: '#dfdfdf',
    },
    platforms: {
      youtube: '#FF6464',
      facebook: '#5989EC',
      instagram: '#C833D9',
      tiktok: '#82D8D6',
      twitter: '#3EC9FF',
      etsy: '#F28149',
      shopify: '#95BF47',
      opensea: '#2081e2',
      upwork: '#A1EA83',
      fiverr: '#1FDD84',
      midrag: '#B02658',
    },
    chartsTheme: {
      youtube: '#FF6464',
      facebook: '#5989EC',
      instagram: '#C833D9',
      tiktok: '#82D8D6',
      twitter: '#3EC9FF',
      documented: '#2257C5',
      estimated: '#A3C1FF',
      reported: '#7F8897',
      external: '#7B1AFF',
      etsy: '#F28149',
      shopify: '#95BF47',
      exact: '#0D2558',
      extrapolated: '#C69CFF',
      opensea: '#2081e2',
      upwork: '#A1EA83',
      fiverr: '#1FDD84',
      midrag: '#B02658',
    },
    companyOverview: {
      localCountry: '#FF5837',
      restOfTheWorld: '#2436BA',
    },
  },
  typography: {
    fontFamily: `SourceSansPro, Dosis, Ubuntu`,
    CustomFontSize: {
      tableText: 14,
      tableGroupHeader: 16,
    },
  },
  mixins: {
    lineClamp: (lines: number, height: string) => ({
      height,
      WebkitLineClamp: lines,
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
      overflow: 'hidden',
    }),
    customScrollBar: () => ({
      '&::-webkit-scrollbar': {
        width: '0.4rem',
        height: '0.4rem',
        backgroundColor: 'white',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#9ab9fb',
        borderRadius: '10px',
      },
    }),
    textTruncate: () => ({
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
  },
  shape: {
    borderRadius: 16,
  },
  breakpoints: {
    values: {
      md: 1280,
      lg: 1440,
      lgPlus: 1600,
      xl: 1920,
      xlplus: 2320,
      xxl: 2560,
      xxxl: 3840,
    },
  },
}

// TS support for custom theme
export interface Platforms {
  youtube: string
  facebook: string
  instagram: string
  tiktok: string
  twitter: string
  etsy: string
  shopify: string
  opensea: string
  upwork: string
  fiverr: string
  midrag: string
}

// Palette
declare module '@mui/material/styles' {
  interface Palette {
    platforms: Platforms
    border: {
      main: string
      tableBorder: string
      button: string
    }
    textColor: {
      main: string
      subTitle: string
      lightGray: string
      lighterGray: string
      tableColumnHeader: string
      tableGroupHeader: string
    }
    chartsTheme: {
      youtube: string
      facebook: string
      instagram: string
      tiktok: string
      twitter: string
      estimated: string
      documented: string
      reported: string
      external: string
      etsy: string
      shopify: string
      exact: string
      extrapolated: string
      opensea: string
      upwork: string
      fiverr: string
      midrag: string
    }
    companyOverview: {
      localCountry: string
      restOfTheWorld: string
    }
  }

  interface PaletteOptions {
    platforms: Platforms
    border: {
      main: string
      tableBorder: string
      button: string
    }
    textColor: {
      main: string
      subTitle: string
      lightGray: string
      lighterGray: string
      tableColumnHeader: string
      tableGroupHeader: string
    }
    chartsTheme: {
      youtube: string
      facebook: string
      instagram: string
      tiktok: string
      twitter: string
      estimated: string
      documented: string
      reported: string
      external: string
      etsy: string
      shopify: string
      exact: string
      extrapolated: string
      opensea: string
      upwork: string
      fiverr: string
      midrag: string
    }
    companyOverview: {
      localCountry: string
      restOfTheWorld: string
    }
  }

  interface BreakpointOverrides {
    xs: false
    sm: false
    md: true
    lg: true
    lgPlus: true
    xl: true
    xlplus: true
    xxl: true
    xxxl: true
  }
}

//Mixins
declare module '@mui/material/styles/createMixins' {
  // Allow for custom mixins to be added
  interface Mixins {
    lineClamp: (lines: number, height: string) => SxProps
    customScrollBar: () => SxProps
    textTruncate: () => SxProps
  }
}

declare module '@mui/material/styles/createTypography' {
  interface TypographyOptions {
    CustomFontSize: {
      tableText: number
      tableGroupHeader: number
    }
  }

  interface Typography {
    CustomFontSize: {
      tableText: number
      tableGroupHeader: number
    }
  }
}
