import React, { FC, useContext, useEffect, useState } from 'react'
import { VerticalContext } from 'store/verticals/Context'
import { VerticalsDetailsContext } from 'store/verticalsDetails/Context'

import ActivityMenuItem from 'containers/VerticalPage/common/ActivityDropdown/ActivityMenuItem'

import type { HandleOptionClickFunc, ToggleDropdownFunc } from 'containers/VerticalPage/common/utils'
import type { DropdownOption, InfluencersFilters } from 'store/verticalsDetails/types'
import { PLATFORMS } from 'store/verticals/types'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { useTheme, styled } from '@mui/material/styles'
import { isEqual } from 'lodash'

import styles from './ActivityDropdown.scss'
const caretIcon = require('components/common/images/activeArrow.svg')

const Menu = styled(Paper)(({ theme }) => ({
  bgcolor: 'white',
  borderRadius: '4px',
  position: 'absolute',
  padding: '8px',
  top: '100%',
  left: '0',
  width: 250,
  maxHeight: 250,
  overflowY: 'auto',
  overflowX: 'hidden',
  cursor: 'pointer',
  zIndex: 9,
  [theme.breakpoints.up('xlplus')]: {
    width: 350,
    maxHeight: 650,
    padding: '16px',
  },
  [theme.breakpoints.up('xxxl')]: {
    width: 650,
    maxHeight: 680,
    padding: '26px',
  },
}))

interface IActivityDropdown {
  onSubmitCallback?: () => Promise<void>
}

const ActivityDropdown: FC<IActivityDropdown> = ({ onSubmitCallback }) => {
  const { verticalDocumentActivityStats } = useContext(VerticalContext)
  const {
    setDetailsDropdownOptions,
    setIsDetailsDropdownOpen,
    setDidSubmitDetailsDropdown,
    setDetailsFilters,
    setSubmittedDropdownOptions,
    detailsDropdownOptions,
    isDetailsDropdownOpen,
    didSubmitDetailsDropdown,
    detailsFilters,
  } = useContext(VerticalsDetailsContext)
  const theme = useTheme()

  const [savedOptions, setSavedOptions] = useState<DropdownOption[] | null>(null)

  useEffect(() => {
    if (detailsDropdownOptions.length > 0 && savedOptions === null) {
      setSavedOptions([...detailsDropdownOptions])
    } else if (detailsDropdownOptions.length > 0 && savedOptions !== null) {
      const isValueChanged = detailsDropdownOptions.some(
        (option, index) => option.isChecked !== savedOptions[index].isChecked,
      )

      if (isValueChanged) {
        setSavedOptions(
          prev =>
            prev?.map((option, index) => {
              if (option.isChecked !== detailsDropdownOptions[index].isChecked)
                return { ...option, isChecked: !option.isChecked }
              return option
            }) || null,
        )
      }
    }
  }, [detailsDropdownOptions, savedOptions])

  useEffect(() => {
    const { activitySources } = verticalDocumentActivityStats
    if (!activitySources || !activitySources?.length) return
    // Compare detailsDropdownOptions values and verticalDocumentActivityStats
    // If there are diffs we need to update
    const isEqual =
      detailsDropdownOptions?.length > 0 &&
      detailsDropdownOptions.every(
        (el, index) =>
          el.count === activitySources?.[index]?.count && el.revenueValue === activitySources?.[index]?.revenueValue,
      )

    if (!isEqual) {
      const dropdownOptions = activitySources?.map(activitySource => ({
        ...activitySource,
        isChecked:
          detailsDropdownOptions.find(option => option.revenueSource === activitySource.revenueSource)?.isChecked ||
          false,
      }))
      setDetailsDropdownOptions(dropdownOptions)
      setSubmittedDropdownOptions(dropdownOptions)
    }
  }, [verticalDocumentActivityStats, detailsDropdownOptions, setDetailsDropdownOptions, setSubmittedDropdownOptions])

  const toggleDropdown: ToggleDropdownFunc = () => {
    if (isDetailsDropdownOpen) removeUnsubmitedOptions()
    setIsDetailsDropdownOpen(!isDetailsDropdownOpen)
  }

  const removeUnsubmitedOptions = () => {
    // In case the user selected options but didn't submit,
    // filter out the options that are not active as filters
    if (didSubmitDetailsDropdown) return
    const dropdownOptions = detailsDropdownOptions.map(option => {
      const shouldKeepCheckedStatus = detailsFilters.some(filter => {
        return filter.activitySource === option.platform && filter.revenueSource === option.revenueSource
      })
      return {
        ...option,
        isChecked: shouldKeepCheckedStatus,
      }
    })
    setDetailsDropdownOptions(dropdownOptions)
  }

  const handleOptionClick: HandleOptionClickFunc = (event, optionIndex) => {
    const dropdownOptions = [...detailsDropdownOptions]
    const selectedOption = dropdownOptions[optionIndex]
    selectedOption.isChecked = !selectedOption.isChecked

    setDetailsDropdownOptions(dropdownOptions)
  }

  const handleSubmit = () => {
    setDidSubmitDetailsDropdown(true)

    const updatedFilters: InfluencersFilters = []
    detailsDropdownOptions.forEach(option => {
      if (option.isChecked) {
        updatedFilters.push({
          revenueSource: option.revenueSource,
          activitySource: option.platform as PLATFORMS,
        })
      }
    })

    const shouldUpdateFilters = !isEqual(updatedFilters, detailsFilters)
    if (shouldUpdateFilters) {
      setDetailsFilters(updatedFilters)
      setIsDetailsDropdownOpen(false)
      setSubmittedDropdownOptions(detailsDropdownOptions)
      onSubmitCallback?.()
    }

    setDidSubmitDetailsDropdown(false)
  }

  return (
    <Box sx={{ width: 'fit-content' }}>
      {isDetailsDropdownOpen && (
        <Paper
          sx={{
            position: 'fixed',
            top: '0',
            left: '0',
            zIndex: 1,
            width: '100%',
            height: '100%',
            bgcolor: 'transparent',
            opacity: 0,
          }}
          onClick={toggleDropdown}
        />
      )}
      <Stack direction='row' alignItems='center' spacing={1}>
        <Box position='relative'>
          <Button
            sx={{
              padding: '4px',
              height: '25px',
              minHeight: '25px',
              width: '25px',
              minWidth: '25px',
            }}
            onClick={toggleDropdown}
          >
            <img
              src={caretIcon}
              alt='Caret'
              style={{ transform: `rotate(${isDetailsDropdownOpen ? '-90deg' : '90deg'})`, cursor: 'pointer' }}
              className={styles.caret}
            />
          </Button>

          {isDetailsDropdownOpen && (
            <Menu
              elevation={6}
              sx={{
                maxWidth: 270,
                width: 270,
                ...theme.mixins.customScrollBar(),
                [theme.breakpoints.down('xl')]: {
                  maxHeight: 200,
                },
              }}
            >
              <Stack>
                {savedOptions?.map((optionData, index) => (
                  <ActivityMenuItem
                    key={`${optionData.revenueSource}-${index}`}
                    optionValue={optionData}
                    optionIndex={index}
                    handleClick={handleOptionClick}
                  />
                ))}
              </Stack>
              {/* //TODO: change to mui button  */}
              <button type='submit' onClick={handleSubmit} className={styles.submitBtn}>
                Submit
              </button>
            </Menu>
          )}
        </Box>
      </Stack>
    </Box>
  )
}

export default ActivityDropdown
