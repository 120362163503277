import React, { FC, useState } from 'react'
import { Associates } from 'store/verticals/types'
import { getUrlPageValues } from 'containers/VerticalPage/common/utils'
import styles from './UserTooltipItem.scss'

const linkArrowIcon = require('../../common/images/linkArrow.svg')

interface UserTooltipItemProps {
  data: Associates
}

const UserTooltipItem: FC<UserTooltipItemProps> = ({ data }) => {
  const [, verticalType] = getUrlPageValues()
  const [open, setOpen] = useState(false)

  return (
    <div className={styles.container} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      <div className={styles.userImageContainer} onClick={() => setOpen(!open)}>
        <img src={data.image} alt={data.name} />
      </div>
      {open && (
        <div className={styles.tooltipContainer}>
          <div className={styles.tooltip}>
            <img className={styles.user} src={data.image} alt={data.name} />
          </div>
          <button
            className={styles.link}
            onClick={() => {
              window.open(`/vertical/${verticalType}/${data.id}`, '_blank')
            }}
          >
            {data.name.length >= 18 ? `${data.name.slice(0, 18)}...` : data.name}
            <img src={linkArrowIcon} alt='Arrow' className={styles.linkArrowIcon} />
          </button>
        </div>
      )}
    </div>
  )
}

export default UserTooltipItem
