import React from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint'
import { FormattedMessage } from 'react-intl'
import { Grid } from 'components/Grid'
import RentalsListCell from 'components/rentals/RentalsListCell'
import messages from './messages'
import styles from './RentalsItemGrid.scss'

const defaultRowHeight = 40
const defaultHeaderRowHeight = 60

export default function RentalsItemGrid({
  columns,
  rows,
  rowHeight,
  headerRowHeight,
  onRowClick = () => {},
  onScroll,
}) {
  const renderGridHeader = key => (
    <h3 className={styles.columnHeader}>
      <FormattedMessage {...messages.columns[key]} />
    </h3>
  )

  return (
    <Grid
      withBoxShadow={false}
      withHeaders
      columns={columns}
      rowHeight={rowHeight || defaultRowHeight}
      headerRowHeight={headerRowHeight || defaultHeaderRowHeight}
      rows={rows}
      className={styles.grid}
      innerClassName={styles.inner}
    >
      {(item, column, rowIndex, isHeader = false) =>
        isHeader ? (
          renderGridHeader(column.key)
        ) : (
          <RentalsListCell cellName={column.key} item={item} onClick={() => onRowClick(item, column)}>
            {column.key === 'listingSiteId' && rowIndex === rows.length - 10 && <Waypoint onEnter={onScroll} />}
          </RentalsListCell>
        )
      }
    </Grid>
  )
}

RentalsItemGrid.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  rowHeight: PropTypes.number,
  headerRowHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  onScroll: PropTypes.func,
}
