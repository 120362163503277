import { useQuery } from '@tanstack/react-query'
import { fetchFatcaTableOverviewData } from 'services/verticalsTablesApi'
import { IFatcaOverviewData } from 'containers/FatcaTablePage/BusinessSummary/utils'
import { Filter } from 'components/FilterDrawer/FilterDrawer.config'

interface UseVerticalOverviewQueryProps {
  verticalIndices: string
  currentQueryKey: string
  searchValue?: string
  filters?: Filter[]
}

export const useFatcaOverviewQuery = <T extends IFatcaOverviewData>({
  verticalIndices,
  currentQueryKey,
  searchValue,
  filters,
}: UseVerticalOverviewQueryProps) =>
  useQuery<T | null>({
    queryKey: [currentQueryKey, verticalIndices, searchValue, filters],
    queryFn: () => (verticalIndices ? fetchFatcaTableOverviewData(verticalIndices, searchValue, filters) : null),
  })
