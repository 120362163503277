import { Filter } from 'components/FilterDrawer/FilterDrawer.config'

export interface FetchEOGraphDataProps {
  caseId: string
  zoneEndpoint: string
  localCountry?: string
  filters: Filter[]
}

export type FilterOptions = Record<string, { value: string; label: string }[]>

export enum EmployeeOverviewDataKeys {
  localAmount = 'totalLocalAmount',
  restOfTheWorldAmount = 'totalRestOfTheWorldAmount',
}
