export const DetailedLegendContainerStyles = {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridTemplateRows: 'repeat(3, auto)',
  gap: '10px',
}

export const DetailedLegendItemStyles = {
  maxWidth: '13.125em',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '4px',
}

export const LegendCircleStyles = { borderRadius: '50%', mr: '6px', width: '0.625em', height: '0.625em' }

export const DetailedChartLegendTextStyles = {
  fontSize: '1em',
  maxWidth: '8.125em',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  mr: '4px',
}

export const MoreResultContainerStyles = {
  maxWidth: '13.125em',
  flexDirection: 'row',
}

export const MoreResultLegendStyles = {
  fontSize: '1em',
  maxWidth: '13.125em',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  mr: '4px',
}
