import { Filter, FilterTypes } from 'components/FilterDrawer/FilterDrawer.config'
import { getYears } from './utils'

export const filtersInitialState: Filter[] = [
  {
    type: FilterTypes.ROBUST_RANGE,
    name: 'totals.unreportedRevenue',
    label: 'Total Unreported Earning',
    value: [],
  },
  {
    type: FilterTypes.CHIPS,
    name: 'overviewDetails.controlPersonTypes',
    label: 'Controlling person type',
    value: [],
  },
  // {
  //   type: FilterTypes.CHECKBOXES,
  //   name: 'entityDetails.match_by',
  //   label: 'Matched By',
  //   value: [],
  // },
  {
    type: FilterTypes.YEARS_RANGE_SELECTOR,
    name: 'overviewDetails.firstYear',
    label: 'Years Range',
    value: [],
    list: getYears(20).map(year => `${year}`),
  },
]
