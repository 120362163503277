import React, { FC, useContext, useMemo } from 'react'
import { VerticalContext } from 'store/verticals/Context'
import ContactsSection from 'components/ContactsSection'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import { IrrelevantContactSections } from 'containers/VerticalPage/common/types'
import { contactInfoSections } from 'containers/VerticalPage/common/utils'

interface ContactInfoProps {
  irrelevantSections?: IrrelevantContactSections
}

const ContactInfo: FC<ContactInfoProps> = ({ irrelevantSections }) => {
  const { verticalEntity } = useContext(VerticalContext)
  const theme = useTheme()

  const relevantSections = useMemo(() => {
    const irrelevantSectionsSet = new Set(irrelevantSections)
    return contactInfoSections.filter(section => !irrelevantSectionsSet.has(section))
  }, [])

  return (
    <Stack
      sx={{
        border: `1px solid ${theme.palette.border.main}`,
        borderRadius: '16px',
        bgcolor: 'white',
        maxHeight: '100%',
        minHeight: '100%',
        height: '100%',
        padding: '12px 0px 12px 12px',
      }}
    >
      <Stack sx={{ overflowY: 'auto', ...theme.mixins.customScrollBar() }}>
        <ContactsSection
          visibleSections={relevantSections}
          websiteLinks={verticalEntity?.contactInfo?.weblinks || {}}
          phones={verticalEntity?.contactInfo?.phones || []}
          emails={verticalEntity?.contactInfo?.emails || []}
          addresses={verticalEntity?.contactInfo?.addresses || []}
          wallets={verticalEntity?.contactInfo?.wallets || []}
          isNFT={false}
          isInfluencers
        />
      </Stack>
    </Stack>
  )
}

export default ContactInfo
