const Title = {
  fontFamily: 'Dosis',
  fontSize: { md: '16px', lg: '18px', xl: '20px' },
  fontWeight: 600,
  textTransform: 'uppercase',
}

const Item = {
  backgroundColor: '#F1F6F9',
  fontWeight: 400,
  borderRadius: '8px',
  marginRight: 2,
  padding: { md: '8px 12px', lg: '10px 12px', xl: '12px 16px' },
  'p.MuiTypography-root': {
    fontSize: { md: '14px', lg: '15px', xl: '16px' },
  },
  'img.MuiBox-root': {
    width: { md: '18px', lg: '20px', xl: '24px' },
    height: { md: '18px', lg: '20px', xl: '24px' },
  },
  span: {
    'img.iconName': {
      width: { md: '18px', lg: '20px', xl: '24px' },
      height: { md: '18px', lg: '20px', xl: '24px' },
      marginRight: 1,
    },
  },
}

const styles = {
  Title,
  Item,
}

export default styles
