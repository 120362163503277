import { defineMessages } from 'react-intl'

const scope = 'app.components.RentalsAnalyticsDistribution'

export default defineMessages({
  hostsWithMostListings: {
    header: {
      id: `${scope}.listingsPerHost.header`,
      defaultMessage: 'Hosts with most Listings',
    },
    yAxisLabel: {
      id: `${scope}.listingsPerHost.yAxisLabel`,
      defaultMessage: 'Listings per host',
    },
    xAxisLabel: {
      id: `${scope}.listingsPerHost.xAxisLabel`,
      defaultMessage: 'Host Site ID',
    },
    tooltip: {
      id: `${scope}.listingsPerHost.tooltip`,
      defaultMessage: 'Number of Listings',
    },
  },
  listingsPerHost: {
    header: {
      id: `${scope}.listingsPerHost.header`,
      defaultMessage: 'Listings per host distribution',
    },
    yAxisLabel: {
      id: `${scope}.listingsPerHost.yAxisLabel`,
      defaultMessage: 'Number of Hosts',
    },
    xAxisLabel: {
      id: `${scope}.listingsPerHost.xAxisLabel`,
      defaultMessage: 'Listings per Host',
    },
    tooltip: {
      id: `${scope}.listingsPerHost.tooltip`,
      defaultMessage: 'Number of Hosts',
    },
  },
  taxEvaders: {
    header: {
      id: `${scope}.taxEvaders.header`,
      defaultMessage: 'Tax evaders per listings distribution',
    },
    yAxisLabel: {
      id: `${scope}.taxEvaders.yAxisLabel`,
      defaultMessage: '% tax evaders',
    },
    xAxisLabel: {
      id: `${scope}.taxEvaders.xAxisLabel`,
      defaultMessage: '% Tax Evaders',
    },
  },
  taxes: {
    header: {
      id: `${scope}.taxes.header`,
      defaultMessage: 'Documented and Paid Occupancy Tax',
    },
    yAxisLabel: {
      id: `${scope}.taxes.yAxisLabel`,
      defaultMessage: 'Tax',
    },
    xAxisLabel: {
      id: `${scope}.taxes.xAxisLabel`,
      defaultMessage: 'Month',
    },
    tooltip: {
      id: `${scope}.listingsPerHost.tooltip`,
      defaultMessage: 'Tax',
    },
  },
  clear: {
    id: `${scope}.clear`,
    defaultMessage: 'Clear Filter',
  },
})
