import React, { useEffect, useState } from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'

import LoginForm from 'components/LoginForm'
import localStorageService from 'services/storage'
import {
  LOGIN_REJECTED,
  setGlobalLoading,
  setIsLoggingIn,
  login,
  addAlert,
  selectIsLoggedIn,
  selectCurrentUserStatus,
  CURRENT_USER_STATUS,
  selectIsUserPlatformInitialized,
  setUserPlatform,
  setIsUserPlatformInitialized,
  selectUserPlatform,
  setCurrentUserStatus,
  dispatchLoginStates,
} from 'store/global'
import { setIsUserPlatformsModalDisplayed } from 'store/settings'
import { selectCurrentUser } from 'store/global'
import messages from './messages'
import styles from './LoginPage.scss'
import OTPForm from '../../components/OTPForm/OTPForm'
import axios from 'services/axios'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function LoginPage({ intl }) {
  const [isOTPInvalid, setIsOTPInvalid] = useState(false)
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isUserPlatformInitialized = useSelector(selectIsUserPlatformInitialized)
  const currentUserStatus = useSelector(selectCurrentUserStatus)
  const userPlatform = useSelector(selectUserPlatform)
  const history = useHistory()
  const location = useLocation()
  const currentUser = useSelector(selectCurrentUser)

  // The router defines /dashboard as its default for '/' so we're elevating that
  const { from = { pathname: '/' } } = location.state || {}

  const query = useQuery()
  const expired = query.get('expired')

  // In case of redirect to login page from failed /businesses GET request, turn off globalLoader
  useEffect(() => {
    // console.log({ SERVICE: process.env.SERVICE, LOGIN_URL: process.env.LOGIN_URL, LOCAL_LOGIN: process.env.LOCAL_LOGIN });
    if (
      process.env.SERVICE === 'main' &&
      currentUserStatus === CURRENT_USER_STATUS.LOGGED_OUT &&
      process.env.LOCAL_LOGIN !== 'true'
    ) {
      window.location.href = process.env.LOGIN_URL

      // keeping things safe
      return
    }

    // In case of navigating to login service while logged in without userPlatform, display PlatformsModal
    if (
      process.env.SERVICE === 'login' &&
      currentUserStatus === CURRENT_USER_STATUS.LOGGED_IN &&
      process.env.LOCAL_LOGIN !== 'true' &&
      !userPlatform
    ) {
      dispatch(setIsUserPlatformsModalDisplayed({ isDisplayed: true }))
    }

    dispatch(setGlobalLoading(false))

    if (expired) {
      dispatch(
        addAlert({
          type: 'warning',
          message: intl.formatMessage(messages.sessionExpired),
        }),
      )
    }
  }, [currentUserStatus])

  useEffect(() => {
    if (isLoggedIn && isUserPlatformInitialized) {
      const { returnUrl } = localStorageService
      if (!returnUrl) {
        history.replace(from)
        return
      }

      if (returnUrl.includes('/login')) {
        history.replace(from)
      } else {
        const [pathname = from?.pathname, search] = returnUrl?.split('?')
        const futureLocation = { pathname }

        if (search) {
          futureLocation.search = `?${search}`
        }
        history.replace(futureLocation)
      }
    }
  }, [isLoggedIn, isUserPlatformInitialized, history, from])

  const handleUserPlatformDisplay = payload => {
    if (payload.platforms?.length === 1) {
      dispatch(setUserPlatform({ platform: payload.platforms[0] }))
      dispatch(setIsUserPlatformInitialized({ isInitialized: true }))
    } else if (payload.platforms?.length > 1) {
      // Initializing userPlatform on PlatformsModal
      dispatch(setIsUserPlatformsModalDisplayed({ isDisplayed: true }))
    }
  }

  const showAlert = (responseType, payload, error) => {
    if (responseType === LOGIN_REJECTED || !payload.platforms) {
      const dangerAlert = key => dispatch(addAlert({ type: 'danger', message: intl.formatMessage(messages[key]) }))
      // User is Blocked
      if (error?.message === 'BLOCKED') return dangerAlert('userIsBlocked')
      // User is on the MFA/OTP Screen
      if (currentUserStatus === CURRENT_USER_STATUS.PROVISIONAL) return dangerAlert('mfaFailed')

      // User failed initial login (email/password)
      return dangerAlert('loginFailed')
    } else {
      dispatch(
        addAlert({
          type: 'success',
          message: intl.formatMessage(messages.loginSuccess),
        }),
      )

      handleUserPlatformDisplay(payload)
    }
  }

  const handleLogin = async credentials => {
    dispatch(setGlobalLoading(true))
    dispatch(setIsLoggingIn(true))
    const d = await dispatch(login(credentials))
    const { type: responseType, payload, error } = d
    dispatch(setGlobalLoading(false))
    dispatch(setIsLoggingIn(false))
    if (payload?.isProvisional) {
      return dispatch(setCurrentUserStatus(CURRENT_USER_STATUS.PROVISIONAL))
    }
    return showAlert(responseType, payload, error)
  }

  const handleOTPLogin = async code => {
    dispatch(setGlobalLoading(true))
    dispatch(setIsLoggingIn(true))
    let data
    try {
      const { data: loginData } = await axios.post('auth/verify-otp', { code })
      data = loginData
    } catch (e) {
      dispatch(setGlobalLoading(false))
      dispatch(setIsLoggingIn(false))
      setIsOTPInvalid(true)
      return showAlert(LOGIN_REJECTED, null, e.response.data)
    }

    // This breaks because dispatch(login... does too much. it needs to be refactored
    dispatch(dispatchLoginStates(data))
    showAlert(null, data)
  }

  if (currentUserStatus === CURRENT_USER_STATUS.LOGGED_IN) {
    if (!isUserPlatformInitialized) {
      handleUserPlatformDisplay(currentUser)
    }
    return <div />
  }

  return (
    <div className={styles.loginPage}>
      <header className={styles.header}>
        <h3 className={styles.title}>
          <FormattedMessage {...messages.logIn} />
        </h3>
      </header>
      <main className={styles.content}>
        {currentUserStatus === CURRENT_USER_STATUS.PROVISIONAL ? (
          <OTPForm onSubmit={handleOTPLogin} invalid={isOTPInvalid} />
        ) : (
          <LoginForm onSubmit={handleLogin} className={styles.form} />
        )}
      </main>
    </div>
  )
}

export default injectIntl(LoginPage)
