import React, { useState, useContext, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { VerticalContext } from 'store/verticals/Context'
import Container from '@mui/material/Container'
import LinearProgress from '@mui/material/LinearProgress'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import Overview from '../Overview/Overview'
import Tabs from 'components/common/Tabs'
import Box from '@mui/system/Box'
import { tabsIds, getTabsList } from '../utils'
import type { CurrentZone } from 'utils/types'
import { selectCurrentZone } from 'store/global'
import OverviewTab from '../OverviewTab/OverviewTab'
import ContactInfoTab from '../ContactInfoTab/ContactInfoTab'
import styles from './styles'
import Activity from 'containers/VerticalPage/cdtfa/Activity/Activity'
import { useCalcContainerHeight } from 'containers/VerticalPage/common/useCalcContainerHeight'
import GraphButton from '../GraphContainer/GraphContainer'

const tabsList = getTabsList()
const margins = 25

const CDTFAVertical = () => {
  const { entityId } = useParams<{ entityId: string }>()
  const { getVerticalEntity, verticalEntity } = useContext(VerticalContext)
  const currentZone: CurrentZone = useSelector(selectCurrentZone)

  const containerRef = useRef<HTMLInputElement>(null)
  const tabsContainerRef = useRef<HTMLInputElement>(null)

  const [isLoading, setIsLoading] = useState(false)
  const { calcedHeight: tabsContainerHeight } = useCalcContainerHeight({
    containerRef,
    childContainerRef: tabsContainerRef,
    margins,
    isLoading,
    relevantData: verticalEntity,
  })

  useEffect(() => {
    if (!entityId || !currentZone) return

    if (currentZone.cdtfaIndices) {
      const fetchCardData = async () => {
        setIsLoading(true)
        await getVerticalEntity(entityId, currentZone.cdtfaIndices)
        setIsLoading(false)
      }
      fetchCardData()
    }
  }, [currentZone, entityId])

  const [activeTab, setActiveTab] = useState<number>(tabsIds.OVERVIEW)

  const handleTabClick = (event: React.SyntheticEvent<Element, Event>, value: number) => {
    setActiveTab(value)
  }

  return (
    <Container ref={containerRef} maxWidth='xl' sx={{ height: '100%', maxHeight: '100%', overflow: 'hidden' }}>
      {(isLoading || !verticalEntity) && (
        <>
          <LinearProgress sx={styles.Loader} />
          <Box sx={styles.LoaderOverlay} />
        </>
      )}
      <Breadcrumbs
        containerSx={styles.BreadcrumbsStyles}
        data={[
          { label: 'Home', href: '/' },
          { label: 'Data Science', href: '/data-science' },
          { label: verticalEntity?.entityDetails?.name || 'N/A' },
        ]}
      />
      <Overview>
        <GraphButton />
      </Overview>
      <Tabs tabs={tabsList} value={activeTab} tabsSx={styles.Tabs} onChange={handleTabClick} />
      <Box
        ref={tabsContainerRef}
        sx={{
          ...styles.TabContent,
          height: tabsContainerHeight as number,
          overflowY: activeTab === tabsIds.REVIEWS ? 'hidden' : 'auto',
        }}
      >
        {activeTab === tabsIds.OVERVIEW && <OverviewTab />}
        {activeTab === tabsIds.REVIEWS && <Activity tableHeight={tabsContainerHeight} />}
        {activeTab === tabsIds.CONTACT_INFO && <ContactInfoTab />}
      </Box>
    </Container>
  )
}

export default CDTFAVertical
