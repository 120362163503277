import { EntityDetails, OverviewDetails, VerticalEntity } from 'store/verticals/types'
import { BaseTotalsForTable, PieChartData } from 'utils/types'

export interface CompanyOverviewEntityForTable extends VerticalEntity {
  entityDetails: {
    name: string
    location: string
    founded: string
  } & EntityDetails
  overviewDetails: {
    siteId: string
    allEmployees: string[]
    industry: string[]
    employeesInJurisdiction: string[]
    numJurisdictions: string[]
    jurisdictions: string[]
    companyType: string[]
    dataSources: string[]
  } & OverviewDetails
  totals: {
    localDepartments: string[]
    jurisdictions: string[]
    departments: string[]
    totalLocalPercent: number
    employeesAbroad: number
    employeesAbroadPercent: number
    executivesLocal: number
    executivesLocalPercent: number
    rnDLocal: number
    rnDLocalPercent: number
    nonRnDLocal: number
    nonRnDLocalPercent: number
    employeesLocal: number
    employeesLocalPercent: number
    totalEmployees: 0
    totalExecutives: 0
    totalLocal: 0
    totalAllEmployees: 0
  } & BaseTotalsForTable
}

export enum SummaryTitles {
  employees = 'Total Employees',
  companies = 'Total Companies',
  industries = 'Total Industries',
}

export interface SummaryCompanies {
  title: SummaryTitles.companies
  pieChartData: PieChartData[]
  total: number
  moreResultsCount: number
}

export interface SummaryIndustries {
  title: SummaryTitles.employees
  pieChartData: PieChartData[]
  total: number
  moreResultsCount: number
}

export interface SummaryEmployees {
  title: SummaryTitles.employees
  pieChartData: PieChartData[]
  total: number
  moreResultsCount: number
}

export interface ICompanyOverviewSummary {
  companies: SummaryCompanies
  industries: SummaryIndustries
  employees: SummaryEmployees
}

export type FilterOptions = Record<string, { value: string; label: string }[]>
