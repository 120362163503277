import { OverridableComponent } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import PlaceIcon from '@mui/icons-material/Place'
import EmailIcon from '@mui/icons-material/Email'
import { ContactInfo } from 'store/verticals/types'

interface IContactInfoData {
  value: string
  url?: string
  IconComponent?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string
  }
  color?: string | null
  iconName?: string
}

interface IContactInfo {
  title: string
  data: IContactInfoData[]
}

const getColorBySourceName = (name: string) => {
  switch (name) {
    case 'instagram':
      return 'rgba(240, 221, 162, 0.36)'
    case 'buildzoom':
      return 'rgba(74, 189, 233, 0.12)'
    case 'twitter':
      return 'rgba(52, 204, 255, 0.16)'
    case 'yelp':
      return 'rgba(225, 0, 0, 0.06)'
    case 'facebook':
      return 'rgba(8, 102, 255, 0.1)'
    default:
      return null
  }
}

export const getContactInfoForMapping = (data?: ContactInfo | null) => {
  const mappingData: IContactInfo[] = []

  if (!data) return []

  if (Object.keys(data?.weblinks || {}).length > 0) {
    const weblinksArray: IContactInfoData[] = []
    Object.keys(data.weblinks).forEach(key => {
      if (Array.isArray(data.weblinks[key])) {
        data.weblinks[key].forEach((link: string, linkIndex: number, arr: string[]) => {
          weblinksArray.push({
            value: arr.length > 1 ? `${key} ${linkIndex + 1}` : key,
            url: link,
            iconName: key,
            color: getColorBySourceName(key),
          })
        })
      }
    })
    mappingData.push({
      title: 'Web Links',
      data: weblinksArray,
    })
  }

  if (data?.phones?.length > 0) {
    mappingData.push({
      title: 'Phones',
      data: data.phones.map(phone => ({ value: phone, IconComponent: LocalPhoneIcon })),
    })
  }

  if (data?.addresses?.length > 0) {
    mappingData.push({
      title: 'Addresses',
      data: data.addresses.map(address => ({ value: address, IconComponent: PlaceIcon })),
    })
  }

  if (data?.emails?.length > 0) {
    mappingData.push({
      title: 'E-mails',
      data: data.emails.map(email => ({ value: email, IconComponent: EmailIcon })),
    })
  }

  return mappingData
}
