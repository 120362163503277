import { useMemo } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { fetchCdtfaTableData } from 'services/verticalsTablesApi'
import { BaseEntityForTable } from 'utils/types'
import { MRT_SortingState } from 'material-react-table'
import { Filter } from 'components/FilterDrawer/FilterDrawer.config'

interface UseCDTFAMainTableQuery {
  verticalIndices: string
  currentQueryKey: string
  searchValue?: string
  sorting?: MRT_SortingState
  filters?: Filter[]
}

const PAGE_SIZE = 50

export const useCDTFAMainTableQuery = <T extends BaseEntityForTable>({
  verticalIndices,
  currentQueryKey,
  searchValue,
  sorting,
  filters,
}: UseCDTFAMainTableQuery) => {
  const {
    data: verticalEntities,
    fetchNextPage,
    isFetching,
    isLoading,
    isError,
  } = useInfiniteQuery<T>({
    queryKey: [currentQueryKey, verticalIndices, searchValue, sorting, filters],
    queryFn: async ({ pageParam = 0, signal }) => {
      const fromIndex = pageParam * PAGE_SIZE
      return await fetchCdtfaTableData(verticalIndices, fromIndex, PAGE_SIZE, searchValue, sorting, filters, signal)
    },
    getNextPageParam: (_lastGroup, groups) => groups.length,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: false,
  })

  const flatVerticalEntities: T[] | null = useMemo(() => {
    const flatEntities = verticalEntities?.pages.flatMap((page: T) => page) || null
    return flatEntities?.[0] ? flatEntities : null
  }, [verticalEntities])

  return {
    verticalEntities: flatVerticalEntities,
    fetchNextPage,
    isFetching,
    isLoading,
    searchValue,
    isError,
  }
}
