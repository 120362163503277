import React from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'store/global'
import Input from 'components/Input'
import styles from './PlatformSelect.scss'

interface PlatformSelectProps {
  setSelectedPlatform: (platform: string) => void
}

const PlatformSelect: React.FC<PlatformSelectProps> = ({ setSelectedPlatform }) => {
  const currentUser = useSelector(selectCurrentUser)

  return (
    currentUser?.platforms && (
      <div className={styles.container}>
        <Input type='select' onChange={setSelectedPlatform}>
          {currentUser.platforms.map((platform: string, index: number) => (
            <option key={index} value={platform}>
              {platform}
            </option>
          ))}
        </Input>
      </div>
    )
  )
}

export default PlatformSelect
