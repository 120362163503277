import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Loader from 'components/Loader/Loader'
import { selectCurrentZone } from 'store/global'
import { MaterialReactTable, useMaterialReactTable, MRT_RowVirtualizer } from 'material-react-table'
import { CurrentZone } from 'utils/types'
import { useCountDocuments } from 'hooks/useCountDocuments'
import { useTableInfiniteScroll } from 'containers/VerticalPage/common/useTableInfiniteScroll'
import { useEmployeesQuery } from './useEmployeesQuery'
import useEmployeesColumns from './useEmployeesColumns'
import useEmployeesTableOptions from './useEmployeesTableOptions'
import styles from './EmployeesTab.module.scss'
import useVerticalSearchAndSort from 'hooks/useVerticalSearchAndSort'
import SearchBar from './SearchBar'
import Button from '@mui/material/Button'
import Icon from 'components/Icon'
import { Filter } from 'components/FilterDrawer/FilterDrawer.config'
import FilterDrawer from 'components/FilterDrawer/FilterDrawer'
import { filtersInitialState } from './EmployeesTab.config'
import { useFilterOptionsQuery } from 'hooks/useFilterOptionsQuery'
import { useDebounce } from 'hooks/useDebounce'

interface EmployeesTabProps {
  tableHeight?: number
}

const EmployeesTab = ({ tableHeight }: EmployeesTabProps) => {
  const currentZone: CurrentZone = useSelector(selectCurrentZone)
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const { caseId } = useParams<{ caseId: string }>()

  const { searchValue, searchValueDebounced, setSearchValue } = useVerticalSearchAndSort()

  const [totalDBRowCount, setTotalDBRowCount] = useState(0)
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false)
  const [activeFilters, setActiveFilters] = useState<Filter[]>([])

  const activeFiltersDebounced = useDebounce(activeFilters, 500)

  const { data: docsCountData } = useCountDocuments({
    verticalIndex: currentZone.companyOverviewActivityIndices,
    currentQueryKey: 'company-overview-activities-key',
  })

  const { verticalDocumentActivity, isFetching, fetchNextPage, totalFetched } = useEmployeesQuery({
    caseId,
    zoneActivityIndices: currentZone.companyOverviewActivityIndices,
    pageSize: 10,
    searchValue: searchValueDebounced,
    filters: activeFiltersDebounced,
    sort: [],
  })

  const { filterOptions } = useFilterOptionsQuery({
    verticalIndices: currentZone?.companyOverviewActivityIndices,
    currentQueryKey: 'company-overview-filter-options-key',
    filtersInitialState,
  })

  const { handleScroll } = useTableInfiniteScroll({
    pageSize: 10,
    totalFetched,
    totalDBRowCount,
    isFetching,
    tableContainerRef,
    fetchNextPage,
  })

  useEffect(() => {
    setTotalDBRowCount(docsCountData?.totalCount || 0)
  }, [docsCountData])

  const handleFilterButtonClick = () => {
    setIsFilterDrawerOpen(!isFilterDrawerOpen)
  }

  const columns = useEmployeesColumns()
  const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer<HTMLDivElement, HTMLTableRowElement>>(null)

  const tableOptions = useEmployeesTableOptions(
    columns,
    verticalDocumentActivity,
    isFetching,
    handleScroll,
    tableHeight,
    rowVirtualizerInstanceRef,
  )

  const table = useMaterialReactTable(tableOptions)

  return (
    <Stack direction='column' sx={{ position: 'relative' }} className={styles.mainStack}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>Employees List</h2>

        <Box sx={{ display: 'flex', gap: 1 }}>
          <SearchBar searchValue={searchValue} setSearchValue={setSearchValue} />
          <Button className={styles.actionsBarButton} onClick={handleFilterButtonClick}>
            <Icon size='standard' name='filterBlue' />
          </Button>
        </Box>
      </Box>
      {filterOptions && (
        <FilterDrawer
          open={isFilterDrawerOpen}
          onClose={() => setIsFilterDrawerOpen(false)}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          filtersInitialState={filtersInitialState}
          filterOptions={filterOptions}
        />
      )}
      <MaterialReactTable table={table} />
      {isFetching && (
        <Box sx={{ position: 'absolute', bottom: '20px', left: '50%' }}>
          <Loader type='scrolling' />
        </Box>
      )}
    </Stack>
  )
}

export default EmployeesTab
