import React from 'react'
import cx from 'classnames'
import style from './Indicator.scss'

export type IIndicatorType =
  | 'collection-a'
  | 'collection-b'
  | 'collection-c'
  | 'collection-d'
  | 'physical'
  | 'employees'
  | 'online-marketing'

export interface IIndicator {
  type: IIndicatorType
  className?: string
  size: string
}

const Indicator = ({ type, size, className = '' }: IIndicator) => (
  <span title={type} className={cx(style.indicator, style[type], style[className], style[`indicator-${size}`])} />
)

export default Indicator
