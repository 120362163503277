import { Filter, FilterTypes } from 'components/FilterDrawer/FilterDrawer.config'

export const getSplitFilterMapping = (activeFilters: Filter[]) => {
  const rangeFilters = activeFilters.filter(el => isRangeFilterType(el.type) && (el.value?.[0] || el.value?.[1]))
  const booleanFilters = activeFilters.filter(el => el.type === FilterTypes.BOOL && el.value?.[0] === 'true')
  const defaultFilters = activeFilters.filter(el => el.type !== FilterTypes.BOOL && !isRangeFilterType(el.type))

  return { rangeFilters, booleanFilters, defaultFilters }
}

const isRangeFilterType = (type?: FilterTypes) => type === FilterTypes.ROBUST_RANGE || type === FilterTypes.RANGE
