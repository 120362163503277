import React, { FC, useRef, useCallback, useState, useMemo } from 'react'
import {
  AbstractEventObject,
  Core as CSCore,
  EdgeDefinition,
  NodeDefinition,
  Stylesheet as CSStylesheet,
} from 'cytoscape'
import CytoscapeComponent from 'react-cytoscapejs'
import layout from 'containers/VerticalPage/common/Graph/layout'
import { CSEventHandlers, TCSEventHandlersIncludingInjected } from 'containers/VerticalPage/common/Graph/edgehandles'
import { defaultStyle, alternateStyle } from 'containers/VerticalPage/common/Graph/stylesheet'
import ZoomGraph from 'containers/VerticalPage/common/Graph/ZoomGraph/ZoomGraph'

interface IGraph {
  nodes: NodeDefinition[]
  edges: EdgeDefinition[]
  style?: React.CSSProperties
  enableZoom?: boolean
}

interface HoveredNodeData {
  label: null | string
  x: number
  y: number
}

const styleSheets = {
  default: defaultStyle,
  alternative: alternateStyle,
}

const Graph: FC<IGraph> = ({ nodes, edges, style = {}, enableZoom }) => {
  const cyRef = useRef<CSCore | null>(null)
  const currStyle = useRef<keyof typeof styleSheets>('default')

  const [hoveredNodeData, setHoveredNodeData] = useState<HoveredNodeData>({ label: null, x: 0, y: 0 })

  const layoutState = useRef({
    ...layout,
    randomize: true,
    quality: 'default',
    initialEnergyOnIncremental: 0.3,
  })

  const prevLabelRef = useRef(null)

  const initListeners = () => {
    if (!cyRef.current) return

    cyRef.current.off('dblclick').on('dblclick', e => {
      // Open nodes url on double click
      const clickedElement = e.target.json()
      if (clickedElement.group === 'nodes') {
        if (!clickedElement.data.labels.some((x: string) => x.includes('PHOTO'))) {
          window.open(clickedElement.data.key, '_blank')
        }
      }
    })

    cyRef.current.on('mousemove', e => {
      const element = e.target.json()
      if (element.group === 'nodes') {
        if (!element.data.labels.some((x: string) => x.includes('PHOTO'))) {
          const label = element.data.key

          if (label !== prevLabelRef.current) {
            prevLabelRef.current = label
            const position = {
              x: e.originalEvent.x,
              y: e.originalEvent.y,
            }
            setHoveredNodeData({ label, ...position })
          }
        }
      } else if (prevLabelRef.current !== null) {
        prevLabelRef.current = null
        setHoveredNodeData({ label: null, x: 0, y: 0 })
      }
    })
  }

  const init = useCallback(
    (cy: CSCore) => {
      if (!cyRef.current) cyRef.current = cy
      unbindListeners()
      initListeners()
    },
    [nodes, edges],
  )

  const unbindListeners = () => {
    if (!cyRef.current) return
    CSEventHandlers.forEach(handler => {
      if (cyRef.current) {
        cyRef.current.unbind(handler as TCSEventHandlersIncludingInjected)
      }
    })
  }

  const onZoom = (zoomValue: number) => {
    const cy = cyRef?.current

    if (cy) {
      let zoom = cy.zoom()
      zoom *= zoomValue
      cy.zoom(zoom)
    }
  }

  const addPhotoStyles = useMemo(() => {
    const styles: CSStylesheet[] = []
    nodes.forEach(node => {
      if (node.data?.desc) {
        styles.push({
          selector: `node[desc="${node.data.desc}"]`,
          css: {
            'border-color': '#5d7281',
            'background-image': node.data.key,
            // @ts-ignore
            'background-image-crossorigin': 'null',
            color: 'white',
            'text-background-opacity': 0.6,
            'text-background-shape': 'roundrectangle',
            'text-background-padding': '0.5px',
          },
        })
      }
    })
    return styles
  }, [nodes])

  return (
    <>
      <CytoscapeComponent
        elements={CytoscapeComponent.normalizeElements({
          nodes,
          edges,
        })}
        layout={layoutState.current}
        cy={cy => init(cy)}
        userZoomingEnabled={false}
        zoom={1}
        stylesheet={[...styleSheets[currStyle.current], ...addPhotoStyles]}
        style={{
          width: '550px',
          height: '350px',
          ...style,
        }}
      />
      {enableZoom && <ZoomGraph onZoom={onZoom} />}
    </>
  )
}

export default Graph
