import React from 'react'
import Context from './Context'
import ContextWrapper from './ContextWrapper'
import { VerticalsProviderProps } from './types'

const Main = ({ children }: VerticalsProviderProps) => (
  <ContextWrapper>
    <Context>{children}</Context>
  </ContextWrapper>
)

export default Main
