import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import RentalsAnalyticsMap from 'components/rentals/RentalsAnalyticsMap'
import { MapLoader } from 'components/Map/MapLoader'
import RentalsAnalyticsDistribution from 'components/rentals/RentalsAnalyticsDistribution'

import { selectHostsWithMostListings, selectListingsPerHost, selectHostsFilters } from 'store/rentals'

import { SECTION_HOST } from 'containers/Rentals/specs'
import styles from './RentalsAnalytics.scss'

const listingsIndices = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10-15',
  '15-20',
  '20-30',
  '30-50',
  '50-100',
  '100-300',
  '300-1000',
  '1000-2500',
]

const taxEvaders = listingsIndices.map((listings, index) => ({
  key: listings,
  count: 5000 + 1000 * index,
}))

const allMonths = moment.monthsShort()

const taxes = allMonths.map((month, index) => ({
  key: month,
  count: 10000000 + 10000000 * index,
}))

const modes = {
  hosts: ['hostsWithMostListings', 'listingsPerHost'],
  listings: ['listingsPerHost', 'taxes'],
}

const clearGraphFilter = {
  hostsWithMostListings: false,
  listingsPerHost: false,
}

function RentalsAnalytics({ isLoading = false, mode = SECTION_HOST, clickOnGraph, clearFiltersFromGraph }) {
  const hostsWithMostListings = useSelector(selectHostsWithMostListings)
  const listingsPerHost = useSelector(selectListingsPerHost)
  const hostFilters = useSelector(selectHostsFilters)

  const distributionData = {
    hostsWithMostListings,
    listingsPerHost: listingsPerHost.slice(0, 10),
    taxEvaders,
    taxes,
  }

  useEffect(() => {
    if (!!hostFilters.hostId) {
      clearGraphFilter.hostsWithMostListings = true
    }
  }, [hostFilters])

  const clickOnBar = bar => {
    clearGraphFilter.hostsWithMostListings = true
    const { indexValue } = bar
    clickOnGraph(indexValue)
  }

  const clickOnMarker = hostId => {
    clearGraphFilter.hostsWithMostListings = true
    clickOnGraph(hostId)
  }

  const clickOnClear = () => {
    clearGraphFilter.hostsWithMostListings = false
    clearFiltersFromGraph()
  }

  const clickFunctions = {
    hostsWithMostListings: clickOnBar,
    listingsPerHost: () => {},
  }

  const clearFunctions = {
    hostsWithMostListings: clickOnClear,
    listingsPerHost: () => {},
  }

  return (
    <div className={styles.container}>
      <ul className={styles.distributions}>
        {modes[mode].map(key => (
          <li key={key} className={styles.distribution}>
            <RentalsAnalyticsDistribution
              type={key}
              data={distributionData[key]}
              className={styles.inner}
              clickOnGraph={clickFunctions[key]}
              graphClear={clearGraphFilter[key]}
              clearFiltersFromGraph={clearFunctions[key]}
            />
          </li>
        ))}
      </ul>
      <div className={styles.mapContainer}>
        {isLoading ? (
          <MapLoader />
        ) : (
          <RentalsAnalyticsMap listType='listings' clickOnMarker={clickOnMarker} clusterMode />
        )}
      </div>
    </div>
  )
}

RentalsAnalytics.propTypes = {
  isLoading: PropTypes.bool,
  mode: PropTypes.string,
}

export default RentalsAnalytics
