import React from 'react'
import TableText from 'components/common/TableText'
import { CDTFAEntityForTable } from 'containers/CDTFATablePage/types'
import { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'
import { TABLE_TEXT_TYPES } from 'utils/types'
import { NOT_AVAILABLE } from 'utils/constants'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import { businessTypeUrls } from 'containers/VerticalPage/cdtfa/OverviewTab/utils'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { formattedDate } from 'utils/formatters'

export const useCDTFAColumns = () => {
  const columns = useMemo<MRT_ColumnDef<CDTFAEntityForTable>[]>(
    () => [
      {
        id: 'entityDetails.name.keyword',
        header: 'Site Name',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.siteName} />
          </TableText>
        ),
        accessorFn: rowData => <TableText>{rowData?.entityDetails?.name || NOT_AVAILABLE}</TableText>,
      },
      {
        id: 'entityDetails.siteId.keyword',
        header: 'Site ID',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.siteId} />
          </TableText>
        ),
        accessorFn: rowData => <TableText>{rowData?.entityDetails?.siteId || NOT_AVAILABLE}</TableText>,
        size: 100,
      },
      {
        id: 'overviewDetails.naicsCdtfa.keyword',
        header: 'Data Science NAICS',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.naicsCdtfa} />
          </TableText>
        ),
        accessorFn: rowData => <TableText>{rowData?.overviewDetails?.naicsCdtfa || NOT_AVAILABLE}</TableText>,
        size: 100,
      },
      {
        id: 'overviewDetails.naicsIvix.keyword',
        header: 'IVIX NAICS',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.naicsIvix} />
          </TableText>
        ),
        accessorFn: rowData => (
          <TableText>
            {Array.isArray(rowData?.overviewDetails?.naicsIvix)
              ? rowData?.overviewDetails?.naicsIvix?.join(', ')
              : NOT_AVAILABLE}
          </TableText>
        ),
        size: 100,
      },
      {
        id: 'detailsSources.name',
        header: 'Web Sources',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.webSources} />
          </TableText>
        ),
        enableSorting: false,
        accessorFn: rowData => {
          if (!rowData?.detailsSources?.length) return NOT_AVAILABLE
          const sourcePlatforms = rowData.detailsSources.map(source => source.platform)
          return (
            <Stack gap={1} direction='row'>
              {[...new Set(sourcePlatforms)]
                .sort()
                .map(
                  platform =>
                    businessTypeUrls[platform] && (
                      <Box
                        key={platform}
                        component='img'
                        src={businessTypeUrls[platform]}
                        width={20}
                        height={20}
                        alt={platform}
                      />
                    ),
                )}
            </Stack>
          )
        },
        muiTableBodyCellProps: {
          sx: {
            minWidth: 130,
            maxWidth: 130,
          },
        },
      },
      {
        id: 'aggregatedTotals.first_activity',
        header: 'FIRST ACTIVITY',
        Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>FIRST ACTIVITY</TableText>,
        accessorFn: rowData => <TableText>{rowData?.aggregatedTotals?.firstActivity || NOT_AVAILABLE}</TableText>,
        size: 100,
      },
      {
        id: 'overviewDetails.accountName.keyword',
        header: 'Account Name',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.accountName} />
          </TableText>
        ),
        accessorFn: rowData => <TableText>{rowData?.overviewDetails?.accountName || NOT_AVAILABLE}</TableText>,
      },
      {
        id: 'overviewDetails.customerName.keyword',
        header: 'Customer Name',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.customerName} />
          </TableText>
        ),
        accessorFn: rowData => <TableText>{rowData?.overviewDetails?.customerName || NOT_AVAILABLE}</TableText>,
      },
      {
        id: 'overviewDetails.accountId.keyword',
        header: 'Account ID',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.accountId} />
          </TableText>
        ),
        accessorFn: rowData => <TableText>{rowData?.overviewDetails?.accountId || NOT_AVAILABLE}</TableText>,
      },
      {
        id: 'aggregatedTotals.qtr_yield_ivix',
        header: 'TOTAL TAX ADJUSTMENT IVIX (ACCOUNT)',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.qtrYieldIvix} />
          </TableText>
        ),
        accessorFn: rowData => rowData?.aggregatedTotals?.qtrYieldIvix,
        muiTableHeadCellProps: {
          sx: { minWidth: 300 },
        },
      },
      {
        id: 'aggregatedTotals.sales_taxes_due',
        header: 'TOTAL TAX DUE (ACCOUNT)',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.salesTaxesDue} />
          </TableText>
        ),
        accessorFn: rowData => rowData?.aggregatedTotals?.salesTaxesDue,
        muiTableHeadCellProps: {
          sx: { minWidth: 300 },
        },
      },
      {
        id: 'overviewDetails.accountType.keyword',
        header: 'Business Type',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.businessType} />
          </TableText>
        ),
        accessorFn: rowData => <TableText>{rowData?.overviewDetails?.accountType || NOT_AVAILABLE}</TableText>,
      },
      {
        id: 'aggregatedTotals.site_start_date',
        header: 'Commence',
        Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>COMMENCE</TableText>,
        accessorFn: rowData => (
          <TableText>{formattedDate(rowData?.aggregatedTotals?.siteStartDate) || NOT_AVAILABLE}</TableText>
        ),
      },
      {
        id: 'aggregatedTotals.site_end_date',
        header: 'Ceased',
        Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>CEASED</TableText>,
        accessorFn: rowData => (
          <TableText>{formattedDate(rowData?.aggregatedTotals?.siteEndDate) || NOT_AVAILABLE}</TableText>
        ),
      },
      {
        id: 'aggregatedTotals.num_years_with_qtr_yield',
        header: 'YEARS UNDER-REPORTING',
        Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>YEARS UNDER-REPORTING</TableText>,
        accessorFn: rowData => (
          <TableText>{rowData?.aggregatedTotals?.numYearsWithQtrYield || NOT_AVAILABLE}</TableText>
        ),
      },
      {
        id: 'aggregatedTotals.total_adjusted_of_total_due',
        header: 'PERCENTAGE OF TAX ADJUSTED',
        Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>PERCENTAGE OF TAX ADJUSTED</TableText>,
        enableSorting: false,
        accessorFn: rowData => {
          const totals = rowData?.aggregatedTotals
          if (!totals || typeof totals?.totalAdjustedOfTotalDue !== 'number') {
            return <TableText>{NOT_AVAILABLE}</TableText>
          }
          const percentage =
            totals?.totalAdjustedOfTotalDue !== 0
              ? (totals?.totalAdjustedOfTotalDue * 100).toFixed(2)
              : totals?.totalAdjustedOfTotalDue * 100
          return <TableText>{`${percentage}%`}</TableText>
        },
      },
      {
        id: 'entityDetails.location.keyword',
        header: 'Address',
        Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>ADDRESS</TableText>,
        accessorFn: rowData => <TableText>{rowData?.entityDetails?.location}</TableText>,
      },
      {
        id: 'aggregatedTotals.site_status.keyword',
        header: 'Site Status',
        Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>SITE STATUS</TableText>,
        accessorFn: rowData => <TableText>{rowData?.aggregatedTotals?.siteStatus}</TableText>,
      },
    ],
    [],
  )

  return columns
}
