import React, { createContext, useCallback, useReducer } from 'react'
import {
  fetchGraphData,
  fetchVerticalDocumentActivityStats,
  fetchVerticalEntity,
  fetchVerticalEstimatedRevenue,
  fetchVerticalRevenueBySources,
} from 'services/verticalsApi'
import { VERTICAL_ACTIONS } from 'store/verticals/actions'
import { VerticalsReducer } from 'store/verticals/reducer'
import type { IVerticalContext, VerticalEntity, VerticalsProviderProps, VerticalsState } from 'store/verticals/types'
import type { Period } from 'utils/types'

const initialState: VerticalsState = {
  verticalEntity: null,
  verticalDocumentActivityStats: { activitySources: null, activityTypes: null },
  verticalRevenueBySources: null,
  verticalEstimatedRevenue: null,
  verticalReportedRevenue: null,
  graphData: { nodes: [], edges: [] },
}

export const VerticalContext = createContext({} as IVerticalContext)

const VerticalContextProvider = ({ children, apiZones }: VerticalsProviderProps) => {
  const [state, dispatch] = useReducer(VerticalsReducer, initialState)

  const getVerticalEntity = async (entityId: string, zoneEndpoint: string) => {
    const verticalEntity: VerticalEntity = await fetchVerticalEntity(entityId, apiZones || zoneEndpoint)
    if (!verticalEntity) return null
    dispatch({ type: VERTICAL_ACTIONS.VerticalEntity, verticalEntity })
  }

  const getVerticalDocumentActivityStats = useCallback(
    async (
      entityId: string,
      zoneEndpointActivity: string,
      zoneEndpointEntity: string,
      period?: Period,
      activityType?: string | string[],
      filters?: string,
    ) => {
      const verticalDocumentActivityStats = await fetchVerticalDocumentActivityStats(
        entityId,
        zoneEndpointActivity,
        zoneEndpointEntity,
        period,
        activityType,
        filters,
      )
      if (!verticalDocumentActivityStats) return null
      dispatch({
        type: VERTICAL_ACTIONS.VerticalDocumentActivityStats,
        verticalDocumentActivityStats,
      })
    },
    [],
  )

  const getVerticalPieChartsData = useCallback(async (entityId: string, period: Period, zoneEndpoint: string) => {
    const pieChartsData = await fetchVerticalRevenueBySources(entityId, period, apiZones || zoneEndpoint)
    if (!pieChartsData?.estimatedRevenue) return null
    dispatch({
      type: VERTICAL_ACTIONS.VerticalRevenueBySources,
      verticalRevenueBySources: pieChartsData.revenueBySources,
    })
    dispatch({
      type: VERTICAL_ACTIONS.VerticalEstimatedRevenue,
      verticalEstimatedRevenue: pieChartsData.estimatedRevenue,
    })
  }, [])
  const getVerticalBarChartsData = useCallback(async (entityId: string, period: Period, zoneEndpoint: string) => {
    const barChartData = await fetchVerticalEstimatedRevenue(entityId, period, apiZones || zoneEndpoint)
    if (!barChartData?.barCharts?.activityByYearData) return null
    dispatch({
      type: VERTICAL_ACTIONS.VerticalReportedRevenue,
      verticalReportedRevenue: barChartData?.barCharts?.activityByYearData,
    })
  }, [])

  const getGraphData = useCallback(async (entityId: string, zoneEndpoint: string) => {
    const graphData = await fetchGraphData(entityId, apiZones || zoneEndpoint)
    if (!graphData) return null
    dispatch({
      type: VERTICAL_ACTIONS.GraphData,
      graphData,
    })
  }, [])

  return (
    <VerticalContext.Provider
      value={{
        verticalEntity: state.verticalEntity,
        verticalDocumentActivityStats: state.verticalDocumentActivityStats,
        verticalRevenueBySources: state.verticalRevenueBySources,
        verticalEstimatedRevenue: state.verticalEstimatedRevenue,
        verticalReportedRevenue: state.verticalReportedRevenue,
        graphData: state.graphData,
        getVerticalEntity,
        getVerticalDocumentActivityStats,
        getVerticalPieChartsData,
        getVerticalBarChartsData,
        getGraphData,
      }}
    >
      {children}
    </VerticalContext.Provider>
  )
}

export default VerticalContextProvider
