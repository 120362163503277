import { Filter } from './FilterDrawer.config'
import styles from './FilterDrawer.module.scss'

const resetFilters = (name: string, setFilters?: React.Dispatch<React.SetStateAction<Filter[]>>) => {
  setFilters?.(prevFilters =>
    prevFilters.map(filter => {
      if (filter.name === name)
        return {
          ...filter,
          value: [],
        }
      return filter
    }),
  )
}

export const handleDeleteFilter = (
  nameToDelete: string,
  valueToDelete: string,
  activeFilters: Filter[],
  setActiveFilters: React.Dispatch<React.SetStateAction<Filter[]>>,
  setFilters?: React.Dispatch<React.SetStateAction<Filter[]>>,
) => {
  const updatedActiveFilters = activeFilters
    .map(filter =>
      filter.name === nameToDelete
        ? { ...filter, value: filter.value.filter(value => value !== valueToDelete) }
        : filter,
    )
    .filter(filter => filter.value.length > 0)

  setActiveFilters(updatedActiveFilters)
  resetFilters(nameToDelete, setFilters)
}

export const handleDeleteRangeFilter = (
  name: string,
  activeFilters: Filter[],
  setActiveFilters: React.Dispatch<React.SetStateAction<Filter[]>>,
  setFilters?: React.Dispatch<React.SetStateAction<Filter[]>>,
) => {
  const updatedActiveFilters = activeFilters
    .map(filter => (filter.name === name ? { ...filter, value: [] } : filter))
    .filter(filter => filter.value.length > 0)

  setActiveFilters(updatedActiveFilters)

  resetFilters(name, setFilters)
}

export const selectorClassNames = {
  control: () => styles.control,
  indicatorsContainer: () => styles.indicatorsContainer,
  menu: () => styles.menu,
  menuList: () => styles.menuList,
  option: () => styles.option,
  multiValue: () => styles.multiValue,
  valueContainer: () => styles.valueContainer,
  placeholder: () => styles.placeholder,
  input: () => styles.input,
  singleValue: () => styles.singleValue,
}
