import React, { useState, useContext, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { VerticalContext } from 'store/verticals/Context'
import Container from '@mui/material/Container'
import LinearProgress from '@mui/material/LinearProgress'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import Overview from '../Overview/Overview'
import Tabs from 'components/common/Tabs'
import Box from '@mui/system/Box'
import { tabsIds, getTabsList } from '../utils'
import type { CurrentZone } from 'utils/types'
import { selectCurrentZone } from 'store/global'
import OverviewTab from '../OverviewTab/OverviewTab'
import ContactInfoTab from '../ContactInfoTab/ContactInfoTab'
import styles from './styles'
import { useCalcContainerHeight } from 'containers/VerticalPage/common/useCalcContainerHeight'
import EmployeesTab from '../EmployeesTab/EmployeesTab'
import { useTabs } from 'containers/VerticalPage/common/useTabs'
import GraphButton from '../GraphContainer/GraphContainer'

const tabsList = getTabsList()

const CompanyOverviewVertical = () => {
  const { caseId } = useParams<{ caseId: string }>()
  const { getVerticalEntity, verticalEntity } = useContext(VerticalContext)
  const currentZone: CurrentZone = useSelector(selectCurrentZone)

  const containerRef = useRef<HTMLInputElement>(null)
  const tabsContainerRef = useRef<HTMLInputElement>(null)

  const [isLoading, setIsLoading] = useState(false)
  const { calcedHeight: tabsContainerHeight } = useCalcContainerHeight({
    containerRef,
    childContainerRef: tabsContainerRef,
    margins: 5,
    isLoading,
    relevantData: verticalEntity,
  })

  useEffect(() => {
    if (!caseId || !currentZone) return

    if (currentZone.companyOverviewIndices) {
      const fetchCardData = async () => {
        setIsLoading(true)
        await getVerticalEntity(caseId, currentZone.companyOverviewIndices)
        setIsLoading(false)
      }
      fetchCardData()
    }
  }, [currentZone, caseId])

  const { activeTab, handleTabClick } = useTabs({ defaultTab: tabsIds.OVERVIEW })

  return (
    <Container ref={containerRef} maxWidth='xl' sx={{ height: '100%', maxHeight: '100%', overflow: 'hidden' }}>
      {isLoading && !verticalEntity && (
        <>
          <LinearProgress sx={styles.Loader} />
          <Box sx={styles.LoaderOverlay} />
        </>
      )}
      <Breadcrumbs
        containerSx={styles.BreadcrumbsStyles}
        data={[
          { label: 'Home', href: '/' },
          { label: 'Company Overview', href: '/company-overview' },
          { label: verticalEntity?.entityDetails?.name || 'N/A' },
        ]}
      />
      <Overview>
        <GraphButton />
      </Overview>
      <Tabs tabs={tabsList} value={activeTab} tabsSx={styles.Tabs} onChange={handleTabClick} />
      <Box
        ref={tabsContainerRef}
        sx={{
          ...styles.TabContent,
          height: tabsContainerHeight as number,
          overflowY: activeTab === tabsIds.EMPLOYEES ? 'hidden' : 'auto',
        }}
      >
        {activeTab === tabsIds.OVERVIEW && <OverviewTab tabsContainerHeight={tabsContainerHeight} />}
        {activeTab === tabsIds.EMPLOYEES && <EmployeesTab tableHeight={tabsContainerHeight} />}
        {activeTab === tabsIds.CONTACT_INFO && <ContactInfoTab />}
      </Box>
    </Container>
  )
}

export default CompanyOverviewVertical
