import { useTheme } from '@mui/material/styles'
import { useMemo } from 'react'

export const useBaseCellStyles = () => {
  const theme = useTheme()
  const cellStyles = useMemo(
    () => ({
      padding: '10px',
      minWidth: 150,
      borderInline: `0.5px dashed ${theme.palette.border.tableBorder}`,
    }),

    [theme],
  )
  return cellStyles
}
