import React, { FC, useMemo, useState } from 'react'
import Alert from '@mui/material/Alert'
import { selectCurrentZone } from '../../store/global'
import NavigationHeader from 'components/NavigationHeader/NavigationHeader'
import BusinessesSummary from 'components/BusinessesSummary/BusinessesSummary'
import GridLoader from 'components/GridLoader/GridLoader'
import ServiceProvidersTable from 'components/serviceProviders/ServiceProvidersTable/ServiceProvidersTable'
import TableActionsBars from 'components/verticals/TableActionsBar/TableActionsBar'
import { BaseTableOverview, CurrentZone } from 'utils/types'
import { ServiceProvidersEntityForTable } from './types'
import { useVerticalOverviewQuery } from 'hooks/useVerticalOverviewQuery'
import { useVerticalEntitiesQuery } from 'hooks/useVerticalEntitiesQuery'
import { useDebounce } from 'hooks/useDebounce'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import cx from 'classnames'
import messages from './messages'
import { Filter, FilterAdditionalData } from 'components/FilterDrawer/FilterDrawer.config'
import styles from './ServiceProvidersTablePage.scss'
import useVerticalSearchAndSort from 'hooks/useVerticalSearchAndSort'
import { getUrlPageValues, removeKeywordSuffix } from '../VerticalPage/common/utils'
import { ISelectedColumn } from 'components/verticals/VerticalTableBase/types'
import { MRT_ColumnDef } from 'material-react-table'
import { useEmptyColumnsQuery } from 'hooks/useEmptyColumnsQuery'
import { useFilterOptionsQuery } from 'hooks/useFilterOptionsQuery'
import { filtersInitialState } from './ServiceProvidersTablePage.config'

const csvFileName = 'serviceProviders'

const ServiceProvidersTablePage: FC = () => {
  const { searchValue, searchValueDebounced, sorting, setSearchValue, setSorting } = useVerticalSearchAndSort()
  const [mergedColumns, setMergedColumns] = useState<MRT_ColumnDef<ServiceProvidersEntityForTable>[]>([])
  const [selectedColumns, setSelectedColumns] = useState<ISelectedColumn[]>([])

  const allColumns = useMemo(() => mergedColumns?.flatMap(column => column?.columns ?? []), [mergedColumns]) || []

  const currentZone: CurrentZone = useSelector(selectCurrentZone)

  const [activeFilters, setActiveFilters] = useState<Filter[]>([])
  const [filterAdditionalData, setFilterAdditionalData] = useState<FilterAdditionalData[]>([])

  const activeFiltersDebounced = useDebounce(activeFilters, 500)

  const { data: emptyColumns } = useEmptyColumnsQuery({
    verticalIndices: currentZone?.serviceProvidersIndices,
    currentQueryKey: 'serviceProviders-entities-key',
    keys: removeKeywordSuffix(allColumns.map(el => el.id || '').filter(key => Boolean(key))),
  })

  // Fetch overview data
  const { data: verticalOverviewData, isLoading: isLoadingOverview } = useVerticalOverviewQuery<BaseTableOverview>({
    verticalIndices: currentZone?.serviceProvidersIndices,
    currentQueryKey: 'serviceProviders-overview-key',
    searchValue: searchValueDebounced,
    filters: activeFiltersDebounced,
  })

  // Fetch entities data
  const { verticalEntities, fetchNextPage, isFetching, isLoading, isError } =
    useVerticalEntitiesQuery<ServiceProvidersEntityForTable>({
      verticalIndices: currentZone?.serviceProvidersIndices,
      currentQueryKey: 'serviceProviders-entities-key',
      searchValue: searchValueDebounced,
      sorting,
      filters: activeFiltersDebounced,
    })

  const { filterOptions } = useFilterOptionsQuery({
    verticalIndices: currentZone?.serviceProvidersIndices,
    currentQueryKey: 'serviceProviders-filter-options-key',
    filtersInitialState,
  })

  return (
    <div className={styles.container}>
      <nav className={styles.navigationHeaderWrapper}>
        <NavigationHeader />
      </nav>
      <header className='page-header withNavigationHeader'>
        <h1 className={cx('page-title', 'h1')}>
          <FormattedMessage {...messages.header} />
        </h1>
      </header>
      <main className={styles.main}>
        <div className={styles.contentWrapper}>
          <section className={styles.summary}>
            <BusinessesSummary
              isLoading={isLoadingOverview}
              summary={{
                entityCounts: verticalOverviewData?.summary.entitiesCount,
                incomeCounts: verticalOverviewData?.summary.incomeCounts,
                topIndustries: verticalOverviewData?.summary.platforms.map(({ source, count }) => [source, count]),
                industriesCount: verticalOverviewData?.summary.platforms.reduce((acc, { count }) => acc + count, 0),
              }}
              isInfluencers
            />
          </section>
          <div className={cx(styles.content, { [styles.withFilters]: false })}>
            <div className={styles.gridContainer}>
              <TableActionsBars
                countMessages={messages.count}
                displayedCount={verticalOverviewData ? verticalEntities?.length ?? null : null}
                totalCount={verticalOverviewData && verticalEntities?.length ? verticalOverviewData.total : null}
                zoneEndpoint={currentZone?.serviceProvidersIndices}
                csvFileName={csvFileName}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                filtersInitialState={filtersInitialState}
                filterOptions={filterOptions}
                filterAdditionalData={filterAdditionalData}
                type={getUrlPageValues()[0]}
                mergedColumns={mergedColumns}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                allColumns={allColumns}
                emptyColumns={emptyColumns}
              />
              {isLoading ? (
                <GridLoader />
              ) : isError ? (
                <Alert severity='error' sx={{ fontFamily: 'Source Sans Pro', fontSize: '1rem', alignItems: 'center' }}>
                  Something went wrong
                </Alert>
              ) : (
                <section className={styles.grid}>
                  <ServiceProvidersTable
                    isFetching={isFetching}
                    fetchNextPage={fetchNextPage}
                    totalFetched={verticalEntities?.length || 0}
                    totalDBRowCount={verticalEntities?.length ? verticalOverviewData?.total ?? 0 : 0}
                    verticalEntities={verticalEntities}
                    sorting={sorting}
                    setSorting={setSorting}
                    setMergedColumns={setMergedColumns}
                    selectedColumns={selectedColumns}
                    allColumns={allColumns}
                  />
                </section>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default ServiceProvidersTablePage
