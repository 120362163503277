import { DelimitedArrayParam } from 'use-query-params'
import { BaseFilter, DISPLAY_AS_OPTIONS, NFT_ACTIVITY_KEYS } from './common'

const { MULTI_SELECT } = DISPLAY_AS_OPTIONS
const { EVENT_TYPES } = NFT_ACTIVITY_KEYS

export interface NFTActivityUsableFilters {
  type: string[]
}

export interface NFTEventTypes extends BaseFilter<NFT_ACTIVITY_KEYS.EVENT_TYPES, string[]> {
  displayAs: DISPLAY_AS_OPTIONS.MULTI_SELECT
  options: string[]
  optionsFromProps: NFT_ACTIVITY_KEYS.EVENT_TYPES
  disableSearch: boolean
  hasSelectAll: boolean
}

export type NFTActivityFilters = [NFTEventTypes]

export const nftActivitiesFilters: NFTActivityFilters = [
  {
    key: EVENT_TYPES,
    type: DelimitedArrayParam,
    displayAs: MULTI_SELECT,
    initialValue: [],
    options: [],
    optionsFromProps: EVENT_TYPES,
    disableSearch: false,
    hasSelectAll: false,
  },
]

const [eventType] = nftActivitiesFilters

export interface NFTActivitiesFilterGroups {
  eventType: [NFTEventTypes]
}

export const nftActivitiesFilterGroups: NFTActivitiesFilterGroups = {
  eventType: [eventType],
  // collections: [collections],
  // chains: [chains]
}

interface NFTEntityCollection {
  owner: string
  totalVolume: number
  floorPrice: number
  numOwners: number
  ownerLink: string
  totalSupply: number
  imageUrl: string
  name: string
  description: string
  openseaUrl: string
  collectionUrl: string[]
  logoUrl: string
}

export interface NFTCurrentOverviewForPeriod {
  sale: { count: number; documentedActivity: number }
  buy: { count: number; documentedActivity: number }
  royalties: { count: number; documentedActivity: number }
  options: string[]
}

export interface ActivitiesFilters {
  type: string[]
}
