import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import LoadingPage from 'containers/LoadingPage/LoadingPage'
import { usePermissions } from 'hooks/usePermissions'
import {
  CURRENT_USER_STATUS,
  selectCurrentUser,
  selectCurrentUserStatus,
  selectUserPlatform,
  selectUserRoles,
} from 'store/global'
import { setIsUserPlatformsModalDisplayed } from 'store/settings/slice.js'
import history from './history.js'

export enum ROLES {
  BASIC_USER_ONLY = 'basicUserOnly',
  BASIC_USER = 'basicUser',
  ADMIN = 'admin',
  ORGANIZATION_ADMIN = 'organizationAdmin',
}

interface StoredUserRoles {
  id: number
  v: number
  name: ROLES
}

interface PrivateRouteProps extends RouteProps {
  allowedRoles: ROLES[]
  selectedPlatform: string | null
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = ({ children, allowedRoles, selectedPlatform, ...rest }: PrivateRouteProps) => {
  const dispatch = useDispatch()
  const permissions = usePermissions()
  const allUserRoles: StoredUserRoles[] = useSelector(selectUserRoles)
  const currentUser = useSelector(selectCurrentUser)
  const currentUserStatus = useSelector(selectCurrentUserStatus)
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null)
  const isLocalLogin = process.env.LOCAL_LOGIN === 'true'
  const isLoginService = process.env.LOCAL_LOGIN === 'false' && process.env.SERVICE === 'login'
  const userPlatform = useSelector(selectUserPlatform)

  useEffect(() => {
    if (userPlatform !== null && !isLocalLogin) window.location.href = userPlatform
  }, [userPlatform])

  useEffect(() => {
    if (isLocalLogin) return
    if (currentUserStatus === CURRENT_USER_STATUS.PENDING) return

    if (currentUserStatus === CURRENT_USER_STATUS.LOGGED_OUT) {
      if (isLoginService || isLocalLogin) {
        history.push('/login')
      } else {
        window.location.href = process.env.LOGIN_URL!
      }
    }

    if (currentUserStatus === CURRENT_USER_STATUS.LOGGED_IN) {
      if (userPlatform) {
        const isPlatformAllowed = currentUser.platforms.includes(location.origin)
        if (isLoginService || !isPlatformAllowed) {
          window.location.href = userPlatform
        }
      } else if (!userPlatform && isLoginService) {
        dispatch(setIsUserPlatformsModalDisplayed({ isDisplayed: true }))
        history.push('/login')
      }
    }
  }, [currentUser, currentUserStatus, userPlatform])

  useEffect(() => {
    if (permissions.isInitialized && allUserRoles.length) {
      const derivedRoles = allUserRoles.filter(({ name }) => !!permissions[name]).map(({ name }) => name)
      const derivedAuthorization = derivedRoles.some(role => allowedRoles.includes(role))
      setIsAuthorized(derivedAuthorization)
    }
  }, [permissions, allUserRoles])

  if (
    isAuthorized === null ||
    currentUserStatus === CURRENT_USER_STATUS.PENDING ||
    (currentUserStatus === CURRENT_USER_STATUS.LOGGED_IN && isLoginService && userPlatform) ||
    (currentUserStatus === CURRENT_USER_STATUS.LOGGED_OUT && !isLoginService && !isLocalLogin)
  )
    return <LoadingPage />
  if (currentUserStatus === CURRENT_USER_STATUS.LOGGED_IN && !isLoginService) {
    return <Route {...rest}>{isAuthorized ? children : <Redirect to='/error/401' />}</Route>
  }
  return <Route render={({ location }) => <Redirect to={{ pathname: '/login', state: { from: location } }} />} />
}

export default React.memo(PrivateRoute)
