import React, { FC } from 'react'
import Stack from '@mui/material/Stack'
import { originalSourceToImageMap } from 'components/BusinessSource/specs'
import GenericText from 'components/common/GenericText'
import { HandleOptionClickFunc } from 'containers/VerticalPage/common/utils'
import { formatNumber } from 'utils/formatters'
import { DropdownOption } from 'store/verticalsDetails/types'
const checkedIcon = require('components/Icon/assets/checked.svg')
const uncheckedIcon = require('components/Icon/assets/unchecked.svg')
import styles from './ActivityDropdown.scss'

interface ActivityMenuItemProps {
  optionValue: DropdownOption
  optionIndex: number
  handleClick: HandleOptionClickFunc
}

const ActivityMenuItem: FC<ActivityMenuItemProps> = ({ optionValue, optionIndex, handleClick }) => {
  return (
    <Stack
      direction='row'
      alignItems='center'
      sx={{
        padding: { lg: '8px', xxxl: '20px' },
        '&:hover': {
          backgroundColor: '#C6D8FE',
        },
      }}
      spacing={1}
      onClick={e => handleClick(e, optionIndex)}
    >
      <img src={optionValue.isChecked ? checkedIcon : uncheckedIcon} alt='Status' className={styles.checkedIcons} />
      {optionValue?.platform && (
        <img
          src={originalSourceToImageMap[optionValue?.platform?.toLocaleLowerCase() || 'globe']}
          alt='Platform'
          className={styles.platformIcon}
        />
      )}
      <GenericText
        sx={{
          fontSize: { lg: 14, xl: 16, xlplus: 18, xxl: 20, xxxl: 36 },
          fontWeight: 700,
          textTransform: 'capitalize',
          maxWidth: 155,
        }}
      >
        {optionValue.revenueSource}
      </GenericText>
      <GenericText sx={{ fontSize: { lg: 12, xl: 14, xlplus: 16, xxl: 18, xxxl: 34 }, ml: 'auto !important' }}>
        ({formatNumber(optionValue?.count, null, { style: 'decimal' })})
      </GenericText>
    </Stack>
  )
}

export default ActivityMenuItem
