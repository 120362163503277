import React from 'react'
import PropTypes from 'prop-types'
import { camelCase } from 'lodash'
import cx from 'classnames'
import styles from './ZoneIcon.scss'

const ZoneIcon = ({ name = '', className }) => {
  const isUsState = name.toLowerCase().startsWith('us/')
  const isjpState = name.toLowerCase().startsWith('jp/')

  const refinedZoneName = isUsState || isjpState ? name.slice(3) : name

  const zoneIconName = camelCase(refinedZoneName)

  if (!zoneIconName) return null

  const assetsPath = isUsState ? 'usaStatesFlags' : 'countryFlags'

  try {
    const url = require(`./${assetsPath}/${zoneIconName}.svg`)
    return <img src={url} className={cx(styles.icon, className)} />
  } catch (e) {
    if (e.code !== 'MODULE_NOT_FOUND') {
      throw e
    }
    return null
  }
}

ZoneIcon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default ZoneIcon
