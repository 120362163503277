import { useState } from 'react'
import { MRT_SortingState } from 'material-react-table'
import { useLocation } from 'react-router-dom'
import { useDebounce } from './useDebounce'

const useVerticalSearchAndSort = () => {
  const query = new URLSearchParams(useLocation().search)
  const searchParam = query.get('search')

  const [searchValue, setSearchValue] = useState<string>(searchParam || '')
  const searchValueDebounced = useDebounce(searchValue, 1200)

  const [sorting, setSorting] = useState<MRT_SortingState>([])

  return {
    searchValue,
    searchValueDebounced,
    sorting,
    setSearchValue,
    setSorting,
  }
}

export default useVerticalSearchAndSort
