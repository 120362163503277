import Typography from '@mui/material/Typography'
import React, { FC } from 'react'
import { elemColors } from 'containers/VerticalPage/common/Graph/stylesheet'
import styles from './styles'

interface ILabel {
  data: {
    key: string
    label: string
  }
  borderRadius?: string | number
}

const Label: FC<ILabel> = ({ data, borderRadius }) => {
  const backgroundColor = elemColors[data.key]
  const sx = backgroundColor
    ? { ...styles.LabelItem, backgroundColor, borderRadius }
    : { ...styles.LabelItem, borderRadius }

  return <Typography sx={[...(Array.isArray(sx) ? sx : [sx])]}>{data.label}</Typography>
}

export default Label
