import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import Button from 'components/Button'
import Icon from 'components/Icon'
import Input from 'components/Input'
import Range from 'components/Range/Range'
import CheckboxGroup from 'components/CheckboxGroup'
import MonthPickerInput from './MonthPickerInput/MonthPickerInput'
import { COLUMNS_KEYS } from 'containers/Businesses/specs'
import messages from './messages'
import { FormattedMessage } from 'react-intl'
import { Form } from 'reactstrap'
import cx from 'classnames'
import styles from './Filters.scss'
import MultiSelect from './MultiSelect/MultiSelect'
import NestedMultiSelect from './NestedMultiSelect/NestedMultiSelect'
import { SELECT_ALL } from './NestedMultiSelect/utils'
import filtersService, { UsableFilters } from '../../services/filters'
import {
  BusinessEaseOfCollection,
  BusinessesBusinessType,
  BusinessesEmployeesInJurisdiction,
  BusinessesFilterGroups,
  BusinessesFilters,
  BusinessesLocationInJurisdiction,
  BUSINESSES_KEYS,
  BusinessStatus,
  BusinessUntaxedIncome,
  FixedNestedOptions,
  NestedOptions,
  NFTActivitiesFilterGroups,
  NFTActivityFilters,
  NFTEventTypes,
  NFT_ACTIVITY_KEYS,
  RentalsFilterGroups,
  RentalsFilters,
  RentalsHostId,
  RentalsHostType,
  RentalsJoinedIn,
  RentalsNestedLocations,
  RentalsNumberOfListings,
  RentalsZoneType,
  RENTALS_KEYS,
  SEARCH,
  SEARCHFIELDS,
  SHOW_MAP,
  NFT_MAPPER_KEYS,
} from 'utils/filters'
import {
  NFTMapperActivityType,
  NFTMapperFilterOptions,
  NFTMapperFilters,
  NFTMapperFiltersGroups,
} from 'utils/filters/nftMapper'
import { NFTMapperInitialValues } from 'components/nfts/MapperFilters/MapperFilters'

export type TSubject = BUSINESSES_KEYS | RENTALS_KEYS | NFT_ACTIVITY_KEYS | NFT_MAPPER_KEYS

export interface ISelection {
  value: string
  label: string
}

export interface IDatePickerValue {
  start: string
  end: string
}

export enum NESTED_MULTI_SELECT_FILTERS {
  nestedHostLocations = 'nestedHostLocations',
  nestedListingLocations = 'nestedListingLocations',
}

type NestedMultiSelectFiltersKeys = keyof typeof NESTED_MULTI_SELECT_FILTERS

export type ProcessedNestedValues = {
  [key in NestedMultiSelectFiltersKeys]?: string
}

interface FiltersProps {
  groups: BusinessesFilterGroups | NFTActivitiesFilterGroups | RentalsFilterGroups | NFTMapperFiltersGroups
  filters: UsableFilters
  onChange: (filters: UsableFilters) => void
  onClose: () => void
  renderSubject: (key: TSubject) => string
  renderOption?: (key: string) => string
  type?: string[]
  businessTypes?: string[]
  hostLocations?: NestedOptions
  listingLocations?: NestedOptions
  className?: string
  nftMapperFilterOptions?: NFTMapperFilterOptions //| businesses | rentals | NFTactivity
  initialValues?: NFTMapperInitialValues | {} // | businesses | rentals | NFTactivity
}

const Filters: React.FC<FiltersProps> = props => {
  const {
    groups,
    filters,
    onChange,
    onClose,
    renderSubject = (key: string) => key,
    renderOption = (key: string) => key,
    businessTypes,
    hostLocations,
    listingLocations,
    className,
    nftMapperFilterOptions,
    initialValues,
  } = props
  const [currentFilters, setCurrentFilters] = useState<UsableFilters>({})
  const [didClear, setDidClear] = useState<boolean>(false)

  // The formatted object the server needs to query nested items
  const [processedNestedValues, setProcessedNestedValues] = useState<ProcessedNestedValues>({})

  const MOMENT_FORMAT = 'YYYY-MM-DD'
  const PICKER_FORMAT = 'MMMM, yyyy'

  useEffect(() => {
    if (!filters) return
    setCurrentFilters(filters)
  }, [filters])

  useEffect(() => {
    if (didClear) {
      apply()
      setDidClear(false)
    }
  }, [currentFilters])

  const apply = () => {
    const groupsEntries = Object.entries(groups)
    const allKeys = groupsEntries
      .map(([groupName, groupFilters]) => groupFilters.map(({ key }: { key: COLUMNS_KEYS }) => key))
      .flat()

    const newState = allKeys.reduce((clear, value = 0) => ({ ...clear, [value]: currentFilters[value] }), {})

    // Handle nested multi select filters if activated:
    Object.values(NESTED_MULTI_SELECT_FILTERS).forEach(nestedFilter => {
      if (allKeys.includes(nestedFilter) && processedNestedValues[nestedFilter]) {
        newState[nestedFilter] = processedNestedValues[nestedFilter]
      }
    })

    onChange({ ...newState })
  }

  const clear = () => {
    const groupsEntries = Object.entries(groups)
    const allKeys = groupsEntries
      .map(([groupName, groupFilters]) => groupFilters.map(({ key }: { key: COLUMNS_KEYS }) => key))
      .flat()
    const clearedFilters = allKeys.reduce(
      (clear, value = 0) => ({ ...clear, [value]: (initialValues && initialValues[value]) || undefined }),
      {},
    )

    // Clear nested filters (the formatted object the server need)
    setProcessedNestedValues({})

    setCurrentFilters({ ...clearedFilters })
    setDidClear(true)
  }

  const onNestedMultiSelectChange = (
    subject: TSubject,
    selections: Array<ISelection>,
    selectionsMap: FixedNestedOptions,
    currentNestedFilter: string,
  ) => {
    let activeValues: string[] = []
    let detailedSelections: string[] = []

    const isSelectAllClicked = selections.some(selection => selection.value === SELECT_ALL.VALUE)

    if (isSelectAllClicked) {
      const isSelectAllActive = filtersService.checkIsSelectAllActive(selectionsMap)
      if (!isSelectAllActive) {
        activeValues = Object.entries(selectionsMap).flatMap(([groupHeader, groupData]) => {
          const detailedOptions = groupData.groupOptions.map(currentOption => `${currentOption}, ${groupHeader}`)
          detailedSelections.push(groupHeader, ...detailedOptions)
          return [groupHeader, ...groupData.groupOptions]
        })
      }
    } else {
      activeValues = Object.entries(selectionsMap).flatMap(([groupHeader, { groupOptions, itemSelections }]) => {
        const isFlatGroup = groupOptions.length === 0

        if (isFlatGroup) {
          if (itemSelections.length === 1) {
            detailedSelections.push(groupHeader)
            return [itemSelections[0]]
          } else return []
        }

        if (itemSelections.length > 0) {
          itemSelections.forEach(item => {
            detailedSelections.push(`${item}, ${groupHeader}`)
          })
        }

        return [...itemSelections]
      })
    }

    setProcessedNestedValues(prevValue => ({
      ...prevValue,
      [NESTED_MULTI_SELECT_FILTERS[currentNestedFilter]]: detailedSelections,
    }))

    setCurrentFilters({ ...currentFilters, [subject]: activeValues })
  }

  const onMultiSelectChange = (subject: TSubject, selections: Array<ISelection>) => {
    const values = selections.map(selection => selection.value)
    setCurrentFilters({ ...currentFilters, [subject]: values })
  }

  const onInputChange = (subject: TSubject, value: IDatePickerValue) => {
    setCurrentFilters({ ...currentFilters, [subject]: value })
  }

  const onTextInputChange = (subject: TSubject, value: string) => {
    setCurrentFilters({ ...currentFilters, [subject]: value })
  }

  const onTextInputSubmit = (e: FormEvent) => e.preventDefault()

  const onMultipleSelectionsChange = (subject: TSubject, selectedOption: string) => {
    const currentSelections = currentFilters[subject] || []

    const nextSelections = currentSelections.includes(selectedOption)
      ? currentSelections.filter((value: string) => value !== selectedOption)
      : [...currentSelections, selectedOption]

    setCurrentFilters({ ...currentFilters, [subject]: nextSelections })
  }

  const {
    UNTAXED_INCOME,
    EASE_OF_COLLECTION,
    STATUS,
    BUSINESS_TYPE,
    EMPLOYESS_IN_JURISDICTION,
    LOCATION_IN_JURISDICTION,
  } = BUSINESSES_KEYS

  const {
    ZONE_TYPE,
    NUMBER_OF_LISTINGS,
    HOST_ID,
    HOST_TYPE,
    JOINED_IN,
    SORT,
    NESTED_HOST_LOCATIONS,
    NESTED_LISTING_LOCATIONS,
  } = RENTALS_KEYS

  const { EVENT_TYPES } = NFT_ACTIVITY_KEYS

  const { ACTIVITY_TYPE } = NFT_MAPPER_KEYS

  type TFilterMap = Record<
    BUSINESSES_KEYS | RENTALS_KEYS | NFT_ACTIVITY_KEYS | NFT_MAPPER_KEYS,
    (
      data: BusinessesFilters[number] | RentalsFilters[number] | NFTActivityFilters[number] | NFTMapperFilters[number],
    ) => JSX.Element | null
  >

  const filterMap: TFilterMap = {
    [UNTAXED_INCOME]: data => {
      const { key } = data as BusinessUntaxedIncome
      return <Range value={currentFilters[key]} onChange={value => onInputChange(key, value)} />
    },
    [EASE_OF_COLLECTION]: data => {
      const { withIcons, isRow, typeOfShape, key, options } = data as BusinessEaseOfCollection
      return (
        <CheckboxGroup
          withIcons={withIcons}
          isRow={isRow}
          type={typeOfShape}
          name={key}
          options={options}
          selections={currentFilters[key] || []}
          onChange={option => onMultipleSelectionsChange(key, option)}
        />
      )
    },
    [STATUS]: data => {
      const { withIcons, isRow, typeOfShape, key, options } = data as BusinessStatus
      return (
        <CheckboxGroup
          withIcons={withIcons}
          isRow={isRow}
          type={typeOfShape}
          name={key}
          options={options}
          selections={currentFilters[key] || []}
          onChange={option => onMultipleSelectionsChange(key, option)}
        />
      )
    },
    [BUSINESS_TYPE]: data => (
      <MultiSelect
        data={data as BusinessesBusinessType}
        options={businessTypes || []}
        currentFilters={currentFilters}
        renderOption={renderOption}
        onMultiSelectChange={onMultiSelectChange}
        maxShownSelections={1}
      />
    ),
    [EMPLOYESS_IN_JURISDICTION]: data => {
      const { key } = data as BusinessesEmployeesInJurisdiction
      return <Range value={currentFilters[key]} onChange={value => onInputChange(key, value)} />
    },
    [LOCATION_IN_JURISDICTION]: data => {
      const { key } = data as BusinessesLocationInJurisdiction
      return <Range value={currentFilters[key]} onChange={value => onInputChange(key, value)} />
    },
    [ZONE_TYPE]: data => {
      const { key } = data as RentalsZoneType
      return <Range value={currentFilters[key]} onChange={value => onInputChange(key, value)} />
    },
    [NUMBER_OF_LISTINGS]: data => {
      const { key } = data as RentalsNumberOfListings
      return <Range value={currentFilters[key]} onChange={value => onInputChange(key, value)} />
    },
    [HOST_ID]: data => {
      const { key, isSearch } = data as RentalsHostId
      return (
        <Form onSubmit={onTextInputSubmit}>
          <Input
            type='text'
            icon={isSearch ? 'search' : null}
            value={currentFilters[key]}
            onChange={value => onTextInputChange(key, value)}
          />
        </Form>
      )
    },
    [HOST_TYPE]: data => {
      const { withIcons, isRow, typeOfShape, key, options } = data as RentalsHostType
      return (
        <CheckboxGroup
          withIcons={withIcons}
          isRow={isRow}
          type={typeOfShape}
          name={key}
          options={options}
          selections={currentFilters[key] || []}
          onChange={option => onMultipleSelectionsChange(key, option)}
        />
      )
    },
    [JOINED_IN]: data => (
      <MonthPickerInput
        subject={(data as RentalsJoinedIn).key}
        currentFilters={currentFilters}
        onInputChange={onInputChange}
        momentFormat={MOMENT_FORMAT}
        pickerFormat={PICKER_FORMAT}
      />
    ),
    [EVENT_TYPES]: data => (
      <MultiSelect
        data={data as NFTEventTypes}
        options={(data as NFTEventTypes).options}
        currentFilters={currentFilters}
        renderOption={renderOption}
        onMultiSelectChange={onMultiSelectChange}
        maxShownSelections={3}
      />
    ),
    // TODO: apply the style of multi zone container
    [ACTIVITY_TYPE]: data => (
      <MultiSelect
        data={data as NFTMapperActivityType}
        options={nftMapperFilterOptions?.activityType || []}
        currentFilters={currentFilters}
        renderOption={renderOption}
        onMultiSelectChange={onMultiSelectChange}
        maxShownSelections={2}
      />
    ),
    // [FIRST_ACTIVITY]: data => (
    //   <MonthPickerInput
    //     subject={(data as NFTMapperFirstActivity).key}
    //     currentFilters={currentFilters}
    //     onInputChange={onInputChange}
    //     momentFormat={MOMENT_FORMAT}
    //     pickerFormat={PICKER_FORMAT}
    //   />
    // ),
    [NESTED_HOST_LOCATIONS]: data => {
      const selectedOptions: string[] = getFormattedNestedSelectedOptions(data as RentalsNestedLocations)
      return (
        <NestedMultiSelect
          data={data as RentalsNestedLocations}
          options={hostLocations || {}}
          selectedOptions={selectedOptions}
          renderOption={renderOption}
          onNestedMultiSelectChange={onNestedMultiSelectChange}
          currentNestedFilter={NESTED_HOST_LOCATIONS}
          didClear={didClear}
          maxShownSelections={3}
        />
      )
    },
    [NESTED_LISTING_LOCATIONS]: data => {
      const selectedOptions: string[] = getFormattedNestedSelectedOptions(data as RentalsNestedLocations)
      return (
        <NestedMultiSelect
          data={data as RentalsNestedLocations}
          options={listingLocations || {}}
          selectedOptions={selectedOptions}
          renderOption={renderOption}
          onNestedMultiSelectChange={onNestedMultiSelectChange}
          currentNestedFilter={NESTED_LISTING_LOCATIONS}
          didClear={didClear}
          maxShownSelections={3}
        />
      )
    },
    [SHOW_MAP]: data => null,
    [SEARCH]: data => null,
    [SEARCHFIELDS]: data => null,
    [SORT]: data => null,
  }

  const getFormattedNestedSelectedOptions = (data: RentalsNestedLocations): string[] => {
    if (!currentFilters[data?.key]) return []
    return currentFilters[data?.key].map((value: string) => {
      if (!value.includes(',')) return value
      else return value.split(',')[0]
    })
  }

  const getActiveFilters = useCallback(
    (groupFilters: BusinessesFilters) => groupFilters.filter(({ isActivated = true }) => !!isActivated),
    [groups],
  )

  const renderFilters = (): JSX.Element[] =>
    Object.entries(groups).map(([groupName, groupFilters]: [string, BusinessesFilters]) => (
      <ul key={groupName} className={styles.group}>
        {getActiveFilters(groupFilters).map((activeFilter, index) => (
          <li key={index} className={cx(styles.filter, styles[activeFilter.key])}>
            <h4 className={cx(styles.label, styles[activeFilter.key])}>{renderSubject(activeFilter.key)}</h4>
            {filterMap[activeFilter.key](activeFilter)}
          </li>
        ))}
      </ul>
    ))

  return (
    <div className={cx(styles.filtersWrapper, className)}>
      <section className={cx(styles.container)}>
        <div className={styles.upper}>
          <section className={styles.header}>
            <div className={styles.titleWrapper}>
              <Icon name='filtersCounter' size='medium' className={styles.counterIcon} />
              <h3 className={styles.title}>
                <FormattedMessage {...messages.header} />
              </h3>
            </div>
            <Button className={styles.clear} onClick={clear}>
              <FormattedMessage {...messages.clear} />
            </Button>
            <Button color='link' outline onClick={onClose} className={styles.close}>
              <Icon name='close' size='medium' className={styles.closeIcon} />
            </Button>
          </section>
          <section className={styles.content}>
            <div className={styles.filters}>{renderFilters()}</div>
          </section>
        </div>
        <section className={styles.actions}>
          <Button color='primary' className={styles.applyButton} onClick={apply}>
            <FormattedMessage {...messages.apply} />
          </Button>
        </section>
      </section>
    </div>
  )
}

export default Filters
