import { Theme } from '@mui/material/styles'

const Title = {
  fontFamily: 'Dosis',
  fontSize: { md: '16px', lg: '18px', xl: '20px' },
  fontWeight: 600,
  textTransform: 'uppercase',
  mr: 'auto',
}

const NoDataTitle = {
  fontFamily: 'Dosis',
  fontSize: { md: '14px', lg: '16px', xl: '18px' },
  fontWeight: 500,
  textTransform: 'uppercase',
  margin: { md: '40px auto', lg: '45px auto', xl: '55px auto' },
}

const styles = {
  Title,
  NoDataTitle,
}

export const fontFamily = 'Source Sans Pro'

export const FilterButton = (theme: Theme) => ({
  border: `1px solid ${theme.palette.border.button}`,
  minWidth: '32px',
  height: '32px',
  padding: '8px',
  borderRadius: '8px',
})

export const ActiveDepartments = (theme: Theme) => ({
  fontFamily: 'Dosis',
  fontSize: '16px',
  display: 'inline-block',
  height: 32,
  minWidth: 32,
  maxWidth: 500,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginInlineEnd: '10px',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.border.button}`,
  padding: '4px 8px',
})

export default styles
