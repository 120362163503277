import React, { FC } from 'react'
import GenericText from 'components/common/GenericText'
import Stack from '@mui/material/Stack'
import styles from './EntityCard.scss'
import { useTheme } from '@mui/material/styles'

interface EntityCardDetailsProps {
  svgIcon: string
  value: string
}

const EntityCardDetails: FC<EntityCardDetailsProps> = ({ svgIcon, value }) => {
  const theme = useTheme()
  return (
    <Stack
      direction='row'
      spacing={{ lg: 0.5, xxxl: 1 }}
      alignItems='center'
      sx={{
        marginTop: { md: '4px', lg: '5px', xl: '10px', xxl: '10px', xxxl: '18px' },
      }}
    >
      <img src={svgIcon} alt='Cake' className={styles.detailsIcon} />
      <GenericText
        title={value}
        sx={{
          fontSize: { md: '11px', lg: '12px', xl: '13px', xxl: '20px', xxxl: '28px' },
          lineHeight: { md: '11px', lg: '12px', xl: '14px', xxl: 'initial', xxxl: 'initial' },
          letterSpacing: '0.3px',
          [theme.breakpoints.up('md')]: {
            ...theme.mixins.lineClamp(1, '14px'),
          },
          [theme.breakpoints.up('xlplus')]: {
            ...theme.mixins.lineClamp(1, '28px'),
          },
        }}
      >
        {value}
      </GenericText>
    </Stack>
  )
}

export default EntityCardDetails
