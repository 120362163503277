import { withDefault } from 'use-query-params'
import { NFTMapperFilters, nftMapperFilters } from 'utils/filters/nftMapper'
import {
  BUSINESSES_KEYS,
  businessesFilters,
  BusinessUsableFilters,
  FixedNestedOptions,
  NFT_ACTIVITY_KEYS,
  NFT_MAPPER_KEYS,
  nftActivitiesFilters,
  NFTActivityUsableFilters,
  NFTCurrentOverviewForPeriod,
  RENTALS_KEYS,
  rentalsFilters,
  RentalsUsableFilters,
  NFTEventTypes,
  BusinessesFilters,
  RentalsFilters,
  NFTActivityFilters,
} from '../utils/filters'

const { EVENT_TYPES } = NFT_ACTIVITY_KEYS
const { NESTED_HOST_LOCATIONS } = RENTALS_KEYS

export type UsableFilters = Partial<BusinessUsableFilters | RentalsUsableFilters | NFTActivityUsableFilters>

type DefaultFilters = BusinessesFilters | RentalsFilters | NFTMapperFilters | NFTActivityFilters

class FiltersService {
  initialBusinessesQueryParams() {
    return businessesFilters.reduce(
      (acc, { key, type, initialValue }) => ({
        ...acc,
        [key as BUSINESSES_KEYS]: withDefault(type, initialValue),
      }),
      {},
    )
  }

  initialRentalsQueryParams() {
    return rentalsFilters.reduce(
      (acc, { key, type, initialValue, isActivated = true }) =>
        !isActivated
          ? { ...acc }
          : {
              ...acc,
              [key as RENTALS_KEYS]: withDefault(type, initialValue),
            },
      {},
    )
  }

  initialNftActivitiesQueryParams() {
    return nftActivitiesFilters.reduce(
      (acc, { key, type, initialValue }) => ({
        ...acc,
        [key]: withDefault(type, initialValue),
      }),
      {},
    )
  }

  initialNftMapperQueryParams() {
    return nftMapperFilters.reduce(
      (acc, { key, type, initialValue }) => ({
        ...acc,
        [key as NFT_MAPPER_KEYS]: withDefault(type, initialValue),
      }),
      {},
    )
  }

  resetQueryParams(queryParams: any) {
    const currentQueryParams = { ...queryParams }
    const currentQueryParamsKeys = Object.keys(currentQueryParams)

    return currentQueryParamsKeys.reduce(
      (resetedParams, currentParamKey) => ({
        ...resetedParams,
        [currentParamKey]: undefined,
      }),
      currentQueryParams,
    )
  }

  populateNFTEventTypesOptions(eventByTypeForPeriod: NFTCurrentOverviewForPeriod) {
    const eventTypesFilter = nftActivitiesFilters.find(filter => filter.key === EVENT_TYPES) as NFTEventTypes
    if (eventTypesFilter?.options) {
      eventTypesFilter.options = Object.keys(eventByTypeForPeriod).map(eventType => eventType)
    }
  }

  getActiveFilters(filters: UsableFilters) {
    return Object.fromEntries(Object.entries(filters).filter(([key, value]) => !!value))
  }

  resetFilters(filters: UsableFilters, defaultFilters: DefaultFilters) {
    return Object.keys(filters).reduce((nextFilters, key) => {
      //@ts-ignore - DefaultFilters should be generic?
      const currentFilterInitialValue = defaultFilters.find((filter: DefaultFilters[number]) => filter.key === key)
        ?.initialValue
      nextFilters[key] = currentFilterInitialValue
      return nextFilters
    }, {})
  }

  checkIsSelectAllActive(selectionsMap: FixedNestedOptions) {
    return Object.values(selectionsMap).every(
      ({ groupOptions, itemSelections }: { groupOptions: string[]; itemSelections: string[] }) => {
        const isFlatGroup = groupOptions?.length === 0
        return isFlatGroup ? itemSelections.length === 1 : itemSelections.length === groupOptions.length
      },
    )
  }
}

export default new FiltersService()
