import { defineMessages } from 'react-intl'

const scope = 'app.components.companyOverviewMessages'

export default defineMessages({
  companyName: {
    id: `${scope}.companyName`,
    defaultMessage: 'COMPANY NAME',
  },
  verifiedName: {
    // missing
    id: `${scope}.verifiedName`,
    defaultMessage: 'VERIFIED NAME',
  },
  tin: {
    id: `${scope}.tin`,
    defaultMessage: 'TIN',
  },
  nonComplianceIndicator: {
    id: `${scope}.nonComplianceIndicator`,
    defaultMessage: 'NON-COMPLIANCE-INDICATOR',
  },
  industry: {
    id: `${scope}.industry`,
    defaultMessage: 'INDUSTRY',
  },
  headquarters: {
    id: `${scope}.headquarters`,
    defaultMessage: 'HEADQUARTERS',
  },
  founded: {
    id: `${scope}.founded`,
    defaultMessage: 'FOUNDED',
  },
  localDepartments: {
    id: `${scope}.localDepartments`,
    defaultMessage: 'LOCAL DEPARTMENTS',
  },
  locations: {
    id: `${scope}.locations`,
    defaultMessage: 'LOCATIONS',
  },
  dataSources: {
    id: `${scope}.dataSources`,
    defaultMessage: 'DATA SOURCES',
  },
  companyType: {
    id: `${scope}.companyType`,
    defaultMessage: 'COMPANY TYPE',
  },
  caseId: {
    id: `${scope}.caseId`,
    defaultMessage: 'CASE ID',
  },
  employeesLocal: {
    id: `${scope}.employeesLocal`,
    defaultMessage: 'EMPLOYEES LOCAL',
  },
  employeesLocalPercent: {
    id: `${scope}.employeesLocalPercent`,
    defaultMessage: 'EMPLOYEES LOCAL(%)',
  },
  employeesAbroadPercent: {
    id: `${scope}.employeesAbroadPercent`,
    defaultMessage: 'EMPLOYEES NON-LOCAL(%)',
  },
  employeesAbroad: {
    id: `${scope}.employeesAbroad`,
    defaultMessage: 'EMPLOYEES NON-LOCAL',
  },
  executivesLocal: {
    id: `${scope}.executivesLocal`,
    defaultMessage: 'EXECUTIVES LOCAL',
  },
  executivesLocalPercent: {
    id: `${scope}.executivesLocalPercent`,
    defaultMessage: 'EXECUTIVES LOCAL(%)',
  },
  nonRndLocal: {
    id: `${scope}.nonRndLocal`,
    defaultMessage: 'NON-R&D LOCAL',
  },
  nonRndLocalPercent: {
    id: `${scope}.nonRndLocalPercent`,
    defaultMessage: 'NON-R&D LOCAL(%)',
  },
  rndLocal: {
    id: `${scope}.rndLocal`,
    defaultMessage: 'R&D LOCAL',
  },
  rndLocalPercent: {
    id: `${scope}.rndLocalPercent`,
    defaultMessage: 'R&D LOCAL(%)',
  },
  subsidiaries: {
    // missing
    id: `${scope}.subsidiaries`,
    defaultMessage: 'SUBSIDIARIES',
  },
})
