import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setGlobalLoading, addAlert, DUMMY_ZONE_NAME } from 'store/global'
import apiService from 'services/api'
import filtersService from 'services/filters'
import { nftActivitiesFilters } from '../../utils/filters'

const SLICE_KEY = 'nft'

export const fetchNFTEntity = createAsyncThunk(
  `${SLICE_KEY}/fetchNFTEntity`,
  async ({ entityId }, { getState, dispatch }) => {
    try {
      dispatch(setGlobalLoading(true))

      const { currentZone } = getState().global
      if (!currentZone || currentZone.name === DUMMY_ZONE_NAME) return {}

      const { entity } = await apiService.nftEntity(entityId, currentZone.nftIndices)

      dispatch(setGlobalLoading(false))

      return { entity }
    } catch (err) {
      dispatch(
        addAlert({
          type: 'danger',
          message: 'Unexpected error when fetching entity from API',
        }),
      )
    }
  },
)

export const fetchOverviewRelevantToPeriod = createAsyncThunk(
  `${SLICE_KEY}/fetchOverviewRelevantToPeriod`,
  async ({ entityId, currentPeriod }, { getState, dispatch }) => {
    try {
      const { currentZone } = getState().global
      if (!currentZone || currentZone.name === DUMMY_ZONE_NAME) return {}

      dispatch(setIsLoadingPeriodOverview(true))

      const {
        currentZone: { nftActivityIndices },
      } = getState().global

      const { overview } = await apiService.nftPeriodOverview(entityId, currentPeriod, nftActivityIndices)

      return { overview }
    } catch (err) {
      dispatch(
        addAlert({
          type: 'danger',
          message: 'Unexpected error when fetching entity from API',
        }),
      )
    }
  },
)

export const fetchDocumentedActivityRelevantToPeriodAndFilters = createAsyncThunk(
  `${SLICE_KEY}/fetchDocumentedActivityRelevantToPeriodAndFilters`,
  async ({ entityId, currentPeriod, filters, from = 0 }, { getState, dispatch }) => {
    if (from === 0) {
      dispatch(setIsLoadingInitialActivities(true))
    } else {
      dispatch(setIsLoadingNextBatch(true))
    }

    const {
      currentZone: { nftActivityIndices },
    } = getState().global
    try {
      const { activities } = await apiService.nftDocumentedActivity(
        entityId,
        currentPeriod,
        filters,
        from,
        nftActivityIndices,
      )
      dispatch(setIsLoadingNextBatch(false))
      return { activities }
    } catch (error) {
      dispatch(
        addAlert({
          type: 'danger',
          message: 'Unexpected error when fetching documented activity from API',
        }),
      )
    }
  },
)

export const fetchActivityExport = createAsyncThunk(
  `${SLICE_KEY}/fetchActivityExport`,
  async ({ activityIndices, entityId, filters, currentPeriod }, { dispatch }) => {
    try {
      const csv = await apiService.nftActivityExport(entityId, filters, activityIndices, currentPeriod)
      return { csv }
    } catch (error) {
      dispatch(
        addAlert({
          type: 'danger',
          message: 'Unexpected error when fetching activity CSV from API',
        }),
      )
    }
  },
)

export const fetchEntityExport = createAsyncThunk(
  `${SLICE_KEY}/fetchEntityExport`,
  async ({ entityId }, { getState, dispatch }) => {
    try {
      const {
        currentZone: { nftIndices },
      } = getState().global
      const csv = await apiService.nftEntityExport(entityId, nftIndices)
      return { csv }
    } catch (err) {
      dispatch(
        addAlert({
          type: 'danger',
          message: 'Unexpected error when fetching entity CSV from API',
        }),
      )
    }
  },
)

export const initialState = {
  currentNFTEntity: {},
  currentOverviewForPeriod: {},
  currentGrossForPeriod: 0,
  periodFilter: {},
  isLoadingNextBatch: false,
  isLoadingInitialActivities: true,
  isLoadingPeriodOverview: true,
  currentActivity: [],
  nextBatchStartingIndex: 0,
  activityCSV: null,
  activityFilter: nftActivitiesFilters.reduce((acc, { key, initialValue }) => ({ ...acc, [key]: initialValue }), {}),
  isMapOpen: false,
}

const slice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    setCurrentNFTEntity(state, { payload = {} }) {
      const { entity } = payload
      state.currentNFTEntity = entity
    },
    setCurrentPeriod(state, { payload = {} }) {
      state.periodFilter = payload
    },
    setIsLoadingNextBatch(state, { payload: isLoading = false }) {
      state.isLoadingNextBatch = isLoading
    },
    setIsLoadingInitialActivities(state, { payload: isLoading = false }) {
      state.isLoadingInitialActivities = isLoading
    },
    setIsLoadingPeriodOverview(state, { payload: isLoading = false }) {
      state.isLoadingPeriodOverview = isLoading
    },
    setActivityFilters(state, { payload }) {
      const { filters } = payload
      state.activityFilter = filters
    },
    setNextBatchStartingIndex(state, { payload }) {
      const { index } = payload
      state.nextBatchStartingIndex = index
    },
    setActivityCSV(state, { payload }) {
      const { csv } = payload
      state.activityCSV = csv
    },
    setEntityCSV(state, { payload }) {
      const { csv } = payload
      state.entityCSV = csv
    },
    setIsMapOpen(state, { payload }) {
      const { isMapOpen } = payload
      state.isMapOpen = isMapOpen
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchNFTEntity.fulfilled, (state, { payload = {} }) => {
        if (Object.keys(payload).length === 0) return
        const { entity } = payload
        state.currentNFTEntity = entity
        // filtersService.populateNFTCollectionsOptions(entity.collections);
      })
      .addCase(fetchOverviewRelevantToPeriod.fulfilled, (state, { payload = {} }) => {
        if (Object.keys(payload).length === 0) return
        const {
          overview: { formattedActivityByType, grossActivity },
        } = payload
        state.currentOverviewForPeriod = formattedActivityByType
        state.currentGrossForPeriod = grossActivity
        state.isLoadingPeriodOverview = false

        filtersService.populateNFTEventTypesOptions(formattedActivityByType)
      })
      .addCase(fetchDocumentedActivityRelevantToPeriodAndFilters.fulfilled, (state, { payload = {} }) => {
        if (Object.keys(payload).length === 0) return
        const { activities } = payload
        state.currentActivity =
          state.nextBatchStartingIndex === 0 ? activities : [...state.currentActivity, ...activities]
        state.isLoadingInitialActivities = false
      })
      .addCase(fetchActivityExport.fulfilled, (state, { payload = {} }) => {
        if (Object.keys(payload).length === 0) return
        const { csv } = payload
        state.activityCSV = csv
      })
      .addCase(fetchEntityExport.fulfilled, (state, { payload = {} }) => {
        if (Object.keys(payload).length === 0) return
        const { csv } = payload
        state.entityCSV = csv
      })
  },
})

export const {
  setCurrentNFTEntity,
  setCurrentPeriod,
  setIsLoadingNextBatch,
  setActivityFilters,
  setIsLoadingInitialActivities,
  setIsLoadingPeriodOverview,
  setNextBatchStartingIndex,
  setActivityCSV,
  setEntityCSV,
  setIsMapOpen,
} = slice.actions

export const NFTReducer = slice.reducer
export default slice
