import { Filter, FilterTypes } from 'components/FilterDrawer/FilterDrawer.config'

export const filtersInitialState: Filter[] = [
  {
    type: FilterTypes.ROBUST_RANGE,
    name: 'aggregatedTotals.qtr_yield_ivix',
    label: 'Total Tax Adjustment IVIX',
    value: [],
  },
  {
    type: FilterTypes.RANGE,
    name: 'aggregatedTotals.num_years_with_qtr_yield',
    label: 'Years Under-Reporting',
    value: [],
    step: 1,
  },
  {
    type: FilterTypes.CHIPS,
    name: 'overviewDetails.naicsCdtfa',
    label: 'NAICS Code CDTFA',
    value: [],
  },
  {
    type: FilterTypes.CHIPS,
    name: 'overviewDetails.naicsIvix',
    label: 'NAICS Code IVIX',
    value: [],
  },
  {
    type: FilterTypes.CHIPS,
    name: 'aggregatedTotals.site_status',
    label: 'Site Status',
    value: [],
  },
  {
    type: FilterTypes.BOOL,
    name: 'aggregatedTotals.naics_changed',
    label: 'Present Only Sites with Un matching NAICS',
    activeFilterLabel: 'Un matching NAICS',
    value: [],
  },
  {
    type: FilterTypes.BOOL,
    name: 'aggregatedTotals.post_ceased_web_activity',
    label: 'Present only Ceased Sites with Post-Cessation Web Activity',
    activeFilterLabel: 'Ceased Sites with Post-Cessation',
    value: [],
  },
]
