import React, { useContext, FC, useState, useMemo } from 'react'
import cx from 'classnames'
import Button from 'components/Button'
import Input from 'components/Input'
import Icon from 'components/Icon'
import { VerticalContext } from 'store/verticals/Context'
import { VerticalDocumentActivityStatsActivityType } from 'store/verticals/types'
import { useOutsideClick } from 'hooks/useOutsideClick'
import styles from './ActivityTypesFilter.module.scss'

interface IActivityTypesFilter {
  selectedActivityTypes: string[]
  setSelectedActivityTypes: React.Dispatch<React.SetStateAction<string[]>>
}

const emptyValues = [
  {
    key: 'Buy',
    docCount: 0,
    totalRevenueSum: { value: 0 },
  },
  {
    key: 'Royalties',
    docCount: 0,
    totalRevenueSum: { value: 0 },
  },
  {
    key: 'Sale',
    docCount: 0,
    totalRevenueSum: { value: 0 },
  },
]

const ActivityTypesFilter: FC<IActivityTypesFilter> = ({ selectedActivityTypes, setSelectedActivityTypes }) => {
  const { verticalDocumentActivityStats } = useContext(VerticalContext)
  const [isOpen, setIsOpen] = useState(false)

  const callback = () => {
    setIsOpen(false)
  }

  const ref = useOutsideClick(callback)

  const onCheckboxChange = (key: string) => {
    if (selectedActivityTypes.includes(key)) {
      setSelectedActivityTypes(prev => prev.filter(el => el !== key))
    } else {
      setSelectedActivityTypes(prev => [...prev, key])
    }
  }

  const options = useMemo(() => {
    return emptyValues.map(emptyValue => ({
      ...emptyValue,
      docCount: verticalDocumentActivityStats?.activityTypes?.find(el => el.key === emptyValue.key)?.docCount || 0,
    }))
  }, [verticalDocumentActivityStats.activityTypes])

  return (
    <div ref={ref} className={styles.container}>
      <Button className={styles.filtersButton} color='primary' outline onClick={() => setIsOpen(!isOpen)}>
        <Icon name='filters' size='regular' />
      </Button>
      {isOpen && (
        <div className={styles.optionsContainer}>
          {options.map((activityType: VerticalDocumentActivityStatsActivityType) => {
            const checked = selectedActivityTypes.includes(activityType.key)

            return (
              <div key={activityType.key} className={styles.item}>
                <Input
                  disabled={!activityType.docCount}
                  type='checkbox'
                  checked={checked}
                  onChange={() => onCheckboxChange(activityType.key)}
                />
                <span className={cx(styles.label, { [styles.selected]: checked })}>
                  {`${activityType.key} (${activityType.docCount})`}
                </span>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default ActivityTypesFilter
