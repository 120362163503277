import React from 'react'
import cx from 'classnames'
import { Input as ReactstrapInput } from 'reactstrap'
import Button from 'components/Button'
import Icon from 'components/Icon'
import styles from './Input.scss'
import SearchChips, { SearchChip } from 'components/SearchChips/SearchChips'

const allowedTypes = ['text', 'number', 'search', 'select', 'checkbox', 'range', 'radio', 'password']
const [TYPE_TEXT, TYPE_NUMBER] = allowedTypes

const typeToIconName: { search: string; select: string } = {
  search: 'search',
  select: 'selectArrow',
}

export type OnRemoveChip = (clickedChip: SearchChip) => void

export interface IInput {
  type: 'text' | 'number' | 'search' | 'select' | 'checkbox' | 'range' | 'radio' | 'password'
  icon?: string | null
  prefix?: string
  suffix?: string
  hint?: string
  placeholder?: string
  allowDeselect?: boolean
  onChange: (...args: any[]) => void
  onFocusOrBlur?: () => void
  onBlur?: () => void
  onClear?: () => void
  children?: React.ReactNode
  className?: string
  wrapperClassName?: string
  id?: string
  name?: string
  checked?: boolean
  disabled?: boolean
  value?: string
  searchChips?: SearchChip[]
  onRemoveChip?: OnRemoveChip
  isHalfChecked?: boolean
  invalid?: boolean
}

export default function Input(props: IInput) {
  const {
    type = TYPE_TEXT,
    icon,
    prefix,
    suffix,
    hint,
    children,
    onChange = () => {},
    onClear,
    className,
    wrapperClassName,
    allowDeselect = false,
    searchChips,
    onRemoveChip,
    isHalfChecked,
    ...rest
  } = props

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    const nextValue = type === TYPE_NUMBER ? parseInt(value) : value

    onChange(nextValue, name)
  }

  const iconNameByType: string | undefined = typeToIconName[type]

  return (
    <div className={cx(styles.wrapper, wrapperClassName)}>
      {iconNameByType && <Icon name={iconNameByType} size='small' className={styles[`input-${type}-icon`]} />}
      {icon && <Icon name={icon} size='small' className={styles[`input-${icon}-icon`]} />}
      {prefix && <span className={styles.prefix}>{prefix}</span>}
      <ReactstrapInput
        {...rest}
        type={type as any}
        onChange={onInputChange}
        className={cx(className, styles.input, styles[`input-${type}`], styles[`input-${icon}`], {
          [styles.withPrefix]: !!prefix,
          [styles.deselect]: allowDeselect,
          [styles.halfChecked]: isHalfChecked,
        })}
      >
        {children}
      </ReactstrapInput>
      {searchChips && onRemoveChip && <SearchChips chips={searchChips} onRemoveChip={onRemoveChip} />}
      {onClear && (
        <Button color='link' onClick={onClear} className={styles.clear}>
          <Icon name='close' size='small' />
        </Button>
      )}
      {hint && <span className={styles.hint}>{hint}</span>}
    </div>
  )
}
