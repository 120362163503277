import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import userService from 'services/user'

import { selectCurrentUser } from 'store/global'

export function usePermissions() {
  const currentUser = useSelector(selectCurrentUser)

  const [permissions, setPermissions] = useState({
    isInitialized: false,
  })

  useEffect(() => {
    setPermissions({
      isInitialized: true,
      basicUserOnly:
        userService.isBasicUser(currentUser) &&
        !userService.isSuperuser(currentUser) &&
        !userService.isEitherAdminType(currentUser),
      basicUser: userService.isEitherAdminType(currentUser) || userService.isBasicUser(currentUser),
      admin: userService.isSuperuser(currentUser),
      organizationAdmin: userService.isEitherAdminType(currentUser),
    })
  }, [currentUser])

  return permissions
}
