import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Map from 'components/Map'
import MapHeader from 'components/MapHeader'

import { SECTION_HOST } from 'containers/Rentals/specs'

import { selectMapBaseUrl, selectMapZoomDelta, selectPlatform } from 'store/global'
import { selectDisplayedHost } from 'store/rentals'
import styles from './RentalsMap.scss'

export default function RentalsMap(props) {
  const { listType = SECTION_HOST, header, clusterMode = true, clickOnMarker = () => {}, showHeader = true } = props

  const mapBaseUrl = useSelector(selectMapBaseUrl)
  const mapZoomDelta = useSelector(selectMapZoomDelta)
  const selectedHost = useSelector(selectDisplayedHost)
  const platform = useSelector(selectPlatform)

  const [listingsLocations, setListingLocations] = useState([])

  useEffect(() => {
    if (!selectedHost.enrichedListings || clusterMode) return
    setListingLocations(selectedHost.enrichedListings)
  }, [selectedHost])

  return (
    <div className={styles.container}>
      {showHeader && <MapHeader type={listType} header={header} {...props} />}
      <div className={styles.mapWrapper}>
        <Map
          platform={platform}
          widgets={['zoomLevel']}
          baseUrl={mapBaseUrl}
          zoomDelta={mapZoomDelta}
          locations={[
            {
              list: listingsLocations,
              listColor: styles.listingLocations,
            },
          ]}
          pointsMode
        />
      </div>
    </div>
  )
}

RentalsMap.propTypes = {
  listType: PropTypes.string,
  header: PropTypes.string,
  hosts: PropTypes.array,
  clusterMode: PropTypes.bool,
  clickOnMarker: PropTypes.func,
  showHeader: PropTypes.bool,
}
