import React, { FC } from 'react'
import Content from '../Content/Content'
import VerticalDetailsContextProvider from 'store/verticalsDetails/Context'
import Details from 'containers/VerticalPage/nft/Details/Details'
import VerticalPageWrapper from 'containers/VerticalPage/common/VerticalPageWrapper/VerticalPageWrapper'

const VerticalPage: FC = () => {
  return (
    <VerticalPageWrapper
      gridContainerSx={{
        gridTemplateColumns: '100%',
      }}
    >
      <Content />
      <VerticalDetailsContextProvider>
        <Details />
      </VerticalDetailsContextProvider>
    </VerticalPageWrapper>
  )
}

export default VerticalPage
