import React, { FC, ReactNode } from 'react'
import cx from 'classnames'
import { ResponsivePie } from '@nivo/pie'
import styles from './PieChart.scss'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Stack'
import { NOT_AVAILABLE } from 'utils/constants'
import { formatNumber } from 'utils/formatters'

const chartColors = ['#79A2F8', '#0D47A1', '#2155c0', '#495FA6']

interface ChartDatum {
  id: string
  value: number
  label: string
  color: string
}

interface CDTFAPieChartProps {
  data: ChartDatum[]
  isInteractive: boolean
  children: ReactNode
  className: string
  innerClassName: string
  underReportedAccounts: number
}

export const CDTFAPieChart: FC<CDTFAPieChartProps> = ({
  data,
  isInteractive,
  children,
  className,
  innerClassName,
  underReportedAccounts,
}) => (
  <Stack className={cx(styles.pieContainer, className)}>
    {!!children && <span className={cx(styles.inner, innerClassName)}>{children}</span>}
    <ResponsivePie
      data={data}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      innerRadius={0.95}
      padAngle={2}
      cornerRadius={45}
      colors={chartColors}
      animate
      isInteractive={isInteractive}
      enableArcLabels={false}
    />
    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      {formatNumber(underReportedAccounts, null, { style: 'decimal' }) ?? NOT_AVAILABLE}
    </Box>
  </Stack>
)
