import React, { useContext, FC, ReactNode } from 'react'
import { VerticalContext } from 'store/verticals/Context'
import Box from '@mui/system/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import PlaceIcon from '@mui/icons-material/Place'
import Divider from '@mui/material/Divider'
import InfoIcon from '@mui/icons-material/Info'
import { SxProps, Theme } from '@mui/material'
import { overviewMapping } from './utils'
import { getAbbreviatedNumber } from 'components/Income/Income'
import TextTooltip from 'components/Tooltip'
import {
  Container,
  Logo,
  Title,
  Address,
  OverviewMapping,
  OverviewMappingLabel,
  OverviewMappingValue,
  TooltipText,
} from './styles'

interface IOverview {
  sxContainer?: SxProps<Theme>
  children?: ReactNode
}

const Overview: FC<IOverview> = ({ sxContainer = {}, children }) => {
  const { verticalEntity } = useContext(VerticalContext)

  return (
    <Box
      sx={[
        ...(Array.isArray(Container) ? Container : [Container]),
        ...(Array.isArray(sxContainer) ? sxContainer : [sxContainer]),
      ]}
    >
      <Stack flexDirection='row' alignItems='center'>
        {verticalEntity?.entityDetails?.image && (
          <Box
            component='img'
            src={verticalEntity?.entityDetails?.image}
            alt={verticalEntity?.entityDetails?.name}
            height={40}
            maxHeight={40}
            maxWidth={200}
            sx={Logo}
            mr={3}
          />
        )}
        <Stack>
          <Typography sx={Title}>{verticalEntity?.entityDetails?.name || 'N/A'}</Typography>
          <Stack flexDirection='row' alignItems='center' mt={{ md: 0, lg: 0, xl: 0.5 }}>
            <PlaceIcon htmlColor='#7E8BA6' />
            <Typography ml={1} sx={Address}>
              {verticalEntity?.entityDetails?.location || 'N/A'}
            </Typography>
          </Stack>
        </Stack>
        {children}
      </Stack>
      <Stack
        direction='row'
        alignItems='center'
        spacing={{ md: 1, lg: 1.5, xl: 2 }}
        mt={{ md: 1, lg: 1, xl: 2 }}
        sx={OverviewMapping}
        divider={<Divider orientation='vertical' flexItem sx={{ bgcolor: '#DAD8DF' }} />}
        justifyContent='space-between'
      >
        {overviewMapping.map(item => {
          const combinedData = {
            ...(verticalEntity?.entityDetails ? verticalEntity?.entityDetails : {}),
            ...(verticalEntity?.overviewDetails ? verticalEntity?.overviewDetails : {}),
            ...(verticalEntity?.aggregatedTotals ? verticalEntity?.aggregatedTotals : {}),
          }

          const value = combinedData[item.key]

          const formattedValue = (() => {
            if (Array.isArray(value) && value.every(item => typeof item === 'string')) {
              return value.join(', ')
            }
            if (typeof value === 'number') {
              const formattedNumber = getAbbreviatedNumber(value)
              return `${formattedNumber.finalNumber}${formattedNumber.unit}`
            }
            if (value && typeof value === 'string') {
              return value
            }
            return 'N/A'
          })()
          return (
            <Stack key={item.key}>
              <Typography sx={OverviewMappingLabel}>{item.label}</Typography>
              <Stack flexDirection='row' alignItems='center'>
                <Typography sx={{ ...OverviewMappingValue }}>
                  {formattedValue.length >= 16 ? `${formattedValue.slice(0, 16)}...` : formattedValue}
                </Typography>
                {item.tooltipText && (
                  <TextTooltip
                    position='bottom'
                    html={<Typography sx={TooltipText}>{item.tooltipText}</Typography>}
                    theme='light'
                  >
                    <InfoIcon htmlColor='rgba(126, 139, 166, 1)' sx={{ width: '20px', height: '20px', ml: 1 }} />
                  </TextTooltip>
                )}
              </Stack>
            </Stack>
          )
        })}
      </Stack>
    </Box>
  )
}

export default Overview
