// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchBar-module__container--fnWlB{position:relative;margin-left:auto;display:flex;align-items:center;gap:6px}.SearchBar-module__container--fnWlB .SearchBar-module__searchButton--FLe5g{width:40px;height:40px;display:flex;justify-content:center;align-items:center}.SearchBar-module__container--fnWlB input.SearchBar-module__input--chdnj{width:360px;height:40px;color:#000;font-family:Source Sans Pro;font-size:14px;font-style:normal;font-weight:400;border-radius:5px;padding:0 6px 0 30px;border:solid 1px #9cbcff}.SearchBar-module__container--fnWlB input.SearchBar-module__input--chdnj:focus{outline:none}", "",{"version":3,"sources":["webpack://./app/components/verticals/TableActionsBar/SearchBar/SearchBar.module.scss"],"names":[],"mappings":"AAAA,oCACE,iBAAA,CACA,gBAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA,CAEA,2EACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAGF,yEACE,WAAA,CACA,WAAA,CACA,UAAA,CACA,2BAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,iBAAA,CACA,oBAAA,CACA,wBAAA,CAEA,+EACE,YAAA","sourcesContent":[".container {\n  position: relative;\n  margin-left: auto;\n  display: flex;\n  align-items: center;\n  gap: 6px;\n\n  .searchButton {\n    width: 40px;\n    height: 40px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  input.input {\n    width: 360px;\n    height: 40px;\n    color: #000;\n    font-family: Source Sans Pro;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    border-radius: 5px;\n    padding: 0 6px 0 30px;\n    border: solid 1px #9cbcff;\n\n    &:focus {\n      outline: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SearchBar-module__container--fnWlB",
	"searchButton": "SearchBar-module__searchButton--FLe5g",
	"input": "SearchBar-module__input--chdnj"
};
export default ___CSS_LOADER_EXPORT___;
