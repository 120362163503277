import { defineMessages } from 'react-intl'

const scope = 'app.components.OTPForm'

export default defineMessages({
  OTPCode: {
    id: `${scope}.OTPCode`,
    defaultMessage: 'OTP Code',
  },
  submit: {
    id: `${scope}.submit`,
    defaultMessage: 'Log In',
  },
})
