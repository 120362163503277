import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import apiService from 'services/api'
import { setGlobalLoading, addAlert, DUMMY_ZONE_NAME } from 'store/global'
import _ from 'lodash'

const SLICE_KEY = 'business'

export const fetchBusiness = createAsyncThunk(
  `${SLICE_KEY}/fetchBusiness`,
  async ({ businessId }, { getState, dispatch }) => {
    try {
      dispatch(setGlobalLoading(true))

      const { currentZone } = getState().global
      if (!currentZone || currentZone.name === DUMMY_ZONE_NAME) return {}

      const { business } = await apiService.business(currentZone, businessId)

      dispatch(setGlobalLoading(false))

      return { business }
    } catch (err) {
      dispatch(
        addAlert({
          type: 'danger',
          message: 'Unexpected error when fetching business from API',
        }),
      )
    }
  },
)

export const fetchBusinessReviews = createAsyncThunk(
  `${SLICE_KEY}/fetchBusinessReviews`,
  async ({ businessId, from = 0, filters = [] }, { getState, dispatch }) => {
    if (from > 0) {
      dispatch(setIsLoadingNextBatch(true))
    }
    const {
      currentZone: { businessMapperIndices },
    } = getState().global
    const { reviews } = await apiService.businessReview(businessId, from, filters, businessMapperIndices)
    dispatch(setIsLoadingNextBatch(false))
    return { reviews, from }
  },
)

export const initialState = {
  displayedBusiness: null,
  currentBusiness: {},
  currentBusinessReviews: [],
  isLoadingNextBatch: false,
  currentTab: 0,
  reviewsFilters: [],
  projectsFilters: {
    sources: [],
  },
}

const slice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    setDisplayedBusiness(state, { payload = {} }) {
      const { business } = payload
      state.displayedBusiness = business || null

      // Closed modal - reset current tab
      if (!business) state.currentTab = 0
    },
    setBusinessReviews(state, { payload = [] }) {
      state.currentBusinessReviews = payload
    },
    setIsLoadingNextBatch(state, { payload: isLoading = false }) {
      state.isLoadingNextBatch = isLoading
    },
    setCurrentBusiness(state, { payload = {} }) {
      const { business } = payload
      state.currentBusiness = business
    },
    setCurrentTab(state, { payload = {} }) {
      const { currentTab = 0 } = payload
      state.currentTab = currentTab || 0
    },
    setReviewsFilters(state, { payload = {} }) {
      const { filters } = payload
      state.reviewsFilters = filters
    },
    setProjectsFilters(state, { payload = {} }) {
      const { filters } = payload
      state.projectsFilters = filters
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchBusiness.fulfilled, (state, { payload = {} }) => {
        if (_.isEqual(payload, {})) return
        const { business } = payload
        state.currentBusiness = business
      })
      .addCase(fetchBusinessReviews.fulfilled, (state, { payload = {} }) => {
        const { reviews, from } = payload
        state.currentBusinessReviews = from === 0 ? reviews : [...state.currentBusinessReviews, ...reviews]
      })
  },
})

export const {
  setDisplayedBusiness,
  setCurrentBusiness,
  setIsLoadingNextBatch,
  setCurrentTab,
  setReviewsFilters,
  setProjectsFilters,
  setBusinessReviews,
} = slice.actions

export const businessReducer = slice.reducer
export default slice
