import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query'
import { RefObject, UIEvent, useCallback, useEffect } from 'react'
import { VerticalDocumentActivity } from 'store/verticals/types'

interface UseTableInfiniteScrollProps {
  pageSize: number
  totalFetched: number
  totalDBRowCount: number
  isFetching: boolean
  tableContainerRef: RefObject<HTMLDivElement>
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<VerticalDocumentActivity, unknown>>
}

export const useTableInfiniteScroll = ({
  pageSize,
  totalFetched,
  totalDBRowCount,
  isFetching,
  tableContainerRef,
  fetchNextPage,
}: UseTableInfiniteScrollProps) => {
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement
        // Once the user has scrolled within 100px of the bottom of the table,
        // fetch more data if we can
        const didUserReachBottom = scrollHeight - scrollTop - clientHeight < pageSize
        const areThereMoreRowsToFetch = totalFetched < totalDBRowCount
        if (didUserReachBottom && !isFetching && areThereMoreRowsToFetch) {
          fetchNextPage()
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount, pageSize],
  )

  // A check on mount to see if the table is already scrolled
  // to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current)
  }, [fetchMoreOnBottomReached, tableContainerRef])

  const handleScroll = useCallback(
    (event: UIEvent<HTMLDivElement>) => fetchMoreOnBottomReached(event.target as HTMLDivElement),
    [fetchMoreOnBottomReached],
  )

  return { handleScroll }
}
