import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryParam, BooleanParam } from 'use-query-params'
import usePrevious from '@rooks/use-previous'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'
import RentalsFilters from 'components/rentals/RentalsFilters/RentalsFilters'
import RentalsSummary from 'components/rentals/RentalsSummary'
import RentalsAnalytics from 'components/rentals/RentalsAnalytics'
import RentalsHosts from 'components/rentals/RentalsHosts'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import {
  selectIsInitialized,
  selectCurrentZone,
  selectMapBaseUrl,
  setIsInitialized,
  DUMMY_ZONE_NAME,
} from 'store/global'
import {
  selectHostsFilters,
  setIsLoadingInitial,
  fetchHostsWithMostListings,
  fetchListingsPerHostAggregations,
  fetchRentalsHosts,
  fetchRentalsSummary,
  applyHostsFilters,
  fetchLocationsHierarchy,
} from 'store/rentals'

import { SECTION_HOST } from './specs'
import filtersService from 'services/filters'
import { hostsFilterGroups, rentalsFilters } from '../../utils/filters'
import NavigationHeader from 'components/NavigationHeader/NavigationHeader'
import { intersection } from 'lodash'
import filters from 'services/filters'
import styles from './Rentals.scss'
import messages from './messages'
const util = require('util')

export default function Rentals() {
  const dispatch = useDispatch()

  const isInitialized = useSelector(selectIsInitialized)
  const mapBaseUrl = useSelector(selectMapBaseUrl)

  const currentZone = useSelector(selectCurrentZone)
  const previousZone = usePrevious(currentZone)

  const hostsFilters = useSelector(selectHostsFilters)
  const [showFilters, setShowFilters] = useState(false)
  const [search, setSearch] = useState('')
  const [searchFields, setSearchFields] = useState([])

  const [showAnalytics = true, setShowAnalytics] = useQueryParam('showAnalytics', BooleanParam)
  const { trackPageView, trackEvent } = useMatomo()

  const refetch = (updatedHostFilters = false) => {
    dispatch(setIsLoadingInitial(true))

    if (updatedHostFilters) {
      dispatch(fetchRentalsHosts({ filters: updatedHostFilters }))
      dispatch(fetchHostsWithMostListings({ filters: updatedHostFilters }))
    }
  }

  const applyFilters = nextFilters => {
    dispatch(applyHostsFilters({ filters: nextFilters }))
  }

  useEffect(() => {
    trackPageView()
    dispatch(setIsInitialized(true))
  }, [])

  useEffect(() => {
    if (!currentZone || currentZone.name === DUMMY_ZONE_NAME) return
    const isZoneFirstSelected = !previousZone

    const isZoneChanged = !!currentZone && !!previousZone && currentZone.id !== previousZone.id

    if (!isZoneFirstSelected && !isZoneChanged) return

    const resetedFilters = filtersService.resetFilters(hostsFilters, rentalsFilters)
    refetch(resetedFilters)
    applyFilters(resetedFilters)
    dispatch(fetchLocationsHierarchy())

    dispatch(fetchListingsPerHostAggregations())
    dispatch(fetchRentalsSummary())
  }, [currentZone])

  const clickOnGraph = hostId => {
    trackEvent({
      category: 'Rentals Map',
      action: `User clicked on global map on host ${hostId}`,
    })
    onFiltersChange({ ...hostsFilters, hostId })
  }

  // const onFiltersChange = nextFilters => {
  //   refetch(nextFilters);
  //   applyFilters(nextFilters);
  // };

  const clearFiltersFromGraph = () => {
    // setQueryParams({ hostId: undefined });
    onFiltersChange({ ...filters, hostsId: undefined })
  }

  const onFieldsChange = nextSearchFields => {
    if (!hostsFilters.search) return

    const fieldsDiff = intersection(hostsFilters.searchFields, nextSearchFields)

    const isSelectionsTheSame =
      fieldsDiff.length === hostsFilters.searchFields.length &&
      hostsFilters.searchFields.length === nextSearchFields.length
    if (isSelectionsTheSame) return

    onFiltersChange({ ...hostsFilters, searchFields: nextSearchFields })
  }

  const onFiltersChange = (updatedFilters = filters) => {
    const nextFilters = { ...updatedFilters, search, searchFields }
    // setQueryParams({ ...nextFilters });
    applyFilters(nextFilters)
    refetch(nextFilters)
    trackEvent({
      category: 'Short Term Rentals Filters',
      action: `User requested for new filters ${util.inspect(nextFilters, {
        showHidden: false,
        depth: null,
      })}`,
    })
  }

  const analyticsAction = showAnalytics ? 'collapse' : 'expand'

  const renderFilters = () => (
    <RentalsFilters
      key={SECTION_HOST}
      mode={SECTION_HOST}
      groups={hostsFilterGroups}
      filters={hostsFilters}
      onChange={onFiltersChange}
      onClose={() => setShowFilters(false)}
      className={cx(styles.filters, { [styles.hidden]: !showFilters })}
      zone={currentZone ? currentZone.name : ''}
    />
  )

  return (
    <div className={styles.container}>
      <nav className={styles.navigationHeaderWrapper}>
        <NavigationHeader />
      </nav>
      <header className={cx('page-header withNavigationHeader', styles.header)}>
        <h1 className={cx('page-title', 'h1')}>
          <FormattedMessage {...messages.header} />
        </h1>
      </header>
      <main className={styles.main}>
        <div className={styles.contentWrapper}>
          <RentalsSummary mode={SECTION_HOST} />
          <section className={cx(styles.analytics)}>
            <div className={styles.analyticsHeader}>
              <h3 className={styles.title}>
                <FormattedMessage {...messages.analytics.header} values={{ type: SECTION_HOST }} tagName='span' />
              </h3>
              <div className={styles.actions}>
                <Button
                  color='primary'
                  outline
                  onClick={() => setShowAnalytics(!showAnalytics)}
                  className={styles.collapse}
                >
                  <Icon name={analyticsAction} size='regular'>
                    <FormattedMessage {...messages.analytics.actions[analyticsAction]} />
                  </Icon>
                </Button>
              </div>
              <span className='dashed-line' />
            </div>
            <div
              className={cx(styles.content, {
                [styles.hidden]: !showAnalytics,
              })}
            >
              <RentalsAnalytics
                isLoading={!mapBaseUrl}
                mode={SECTION_HOST}
                clickOnGraph={clickOnGraph}
                clearFiltersFromGraph={clearFiltersFromGraph}
              />
            </div>
          </section>

          <section
            className={cx(styles.rentalsHostsWrapper, {
              [styles.withFilters]: showFilters,
            })}
          >
            <RentalsHosts
              search={search}
              searchFields={searchFields}
              setSearch={setSearch}
              setSearchFields={setSearchFields}
              size={showAnalytics ? 'small' : 'large'}
              onFiltersChange={onFiltersChange}
              onFieldsChange={onFieldsChange}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
            />
            {isInitialized && renderFilters()}
          </section>
        </div>
      </main>
    </div>
  )
}
