import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'
import styles from './OverviewDialog.styles'
import Chip from '@mui/material/Chip'
import BusinessSource from 'components/BusinessSource'
import { camelCase } from 'lodash'

interface OverviewDialogProps {
  open: boolean
  handleClose: () => void
  details: CompanyDetails
}

interface CompanyDetails {
  specialities?: string[]
  siteId?: string
  firstTaxReturn?: string
  lastTaxReturn?: string
  taxOfficeCivil?: string
  taxCreditsAndIncentives?: string
  socialLinks?: Record<string, string[]>
}

const OverviewDialog = ({ open, handleClose, details }: OverviewDialogProps) => {
  const renderValue = (value?: string) => {
    return value || 'N/A'
  }

  return (
    <Dialog open={open} onClose={handleClose} sx={styles.dialog}>
      <DialogTitle sx={styles.titleContainer}>
        <Typography sx={styles.title}>Company Details</Typography>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={styles.container}>
          <Box sx={styles.detailsContainer}>
            <Box sx={styles.detailsRow}>
              <Typography sx={styles.detailsTitle}>TIN</Typography>
              <Typography sx={styles.detailsValue}>{renderValue(details.siteId)}</Typography>
            </Box>
            <Box sx={styles.detailsRow}>
              <Typography sx={styles.detailsTitle}>First Tax Return</Typography>
              <Typography sx={styles.detailsValue}>{renderValue(details.firstTaxReturn)}</Typography>
            </Box>
            <Box sx={styles.detailsRow}>
              <Typography sx={styles.detailsTitle}>Last Tax Return</Typography>
              <Typography sx={styles.detailsValue}>{renderValue(details.lastTaxReturn)}</Typography>
            </Box>
            <Box sx={styles.detailsRow}>
              <Typography sx={styles.detailsTitle}>Tax Office Civil</Typography>
              <Typography sx={styles.detailsValue}>{renderValue(details.taxOfficeCivil)}</Typography>
            </Box>
            <Box sx={styles.detailsRow}>
              <Typography sx={styles.detailsTitle}>Tax Credits and Incentives</Typography>
              <Typography sx={styles.detailsValue}>{renderValue(details.taxCreditsAndIncentives)}</Typography>
            </Box>
            <Box sx={styles.detailsRow}>
              <Typography sx={styles.detailsTitle}>Social Links</Typography>
              <Box sx={styles.linkWrapper}>
                {details.socialLinks &&
                  Object.keys(details.socialLinks).map(iconName =>
                    details.socialLinks![iconName].map(index => (
                      <BusinessSource
                        key={`${iconName}-${index}`}
                        source={camelCase(iconName)}
                        isOriginal
                        className='iconName'
                      />
                    )),
                  )}
              </Box>
            </Box>
          </Box>
          <Box sx={styles.specialitiesContainer}>
            <Typography sx={styles.detailsTitle}>Specialities</Typography>
            <Box sx={styles.specialitiesChips}>
              {(details.specialities || []).map((speciality, index) => (
                <Chip key={index} label={speciality} sx={styles.chips} />
              ))}
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default OverviewDialog
