export enum tabsIds {
  OVERVIEW,
  EMPLOYEES,
  RELATED_COMPANIES,
  INSIGHTS,
  TAX_RETURN,
  CONTACT_INFO,
}

const tabsList = {
  OVERVIEW: { id: tabsIds.OVERVIEW, label: 'Overview' },
  EMPLOYEES: { id: tabsIds.EMPLOYEES, label: 'Employees' },
  RELATED_COMPANIES: { id: tabsIds.RELATED_COMPANIES, label: 'Related Companies' },
  INSIGHTS: { id: tabsIds.INSIGHTS, label: 'Insights' },
  TAX_RETURN: { id: tabsIds.TAX_RETURN, label: 'Tax Return' },
  CONTACT_INFO: { id: tabsIds.CONTACT_INFO, label: 'Contact Info' },
}

export const getTabsList = () => [
  tabsList.OVERVIEW,
  tabsList.EMPLOYEES,
  tabsList.RELATED_COMPANIES,
  tabsList.INSIGHTS,
  tabsList.TAX_RETURN,
  tabsList.CONTACT_INFO,
]
