import React, { FC } from 'react'

import VerticalTableBase from 'components/verticals/VerticalTableBase/VerticalTableBase'
import { VerticalTableCMPBaseProps } from 'components/verticals/VerticalTableBase/types'

import { CDTFAEntityForTable } from 'containers/CDTFATablePage/types'
import { useCDTFAColumns } from 'components/cdtfa/useCDTFAColumns'

const CDTFATable: FC<VerticalTableCMPBaseProps<CDTFAEntityForTable>> = ({
  verticalEntities,
  fetchNextPage,
  isFetching,
  totalDBRowCount,
  totalFetched,
  sorting,
  setSorting,
  setMergedColumns,
  selectedColumns,
  allColumns,
}) => {
  const columns = useCDTFAColumns()

  return (
    <VerticalTableBase<CDTFAEntityForTable>
      columns={columns}
      avoidBaseColumns
      items={verticalEntities}
      fetchNextPage={fetchNextPage}
      isFetching={isFetching}
      totalFetched={totalFetched}
      totalDBRowCount={totalDBRowCount}
      sorting={sorting}
      setSorting={setSorting}
      setMergedColumns={setMergedColumns}
      selectedColumns={selectedColumns}
      allColumns={allColumns}
    />
  )
}

export default CDTFATable
