export const getNFTHeadingValues = (
  options: null | { key: string; docCount: number; totalRevenueSum: { value: number } }[],
) => {
  let values = {
    text: 'Text',
    activities: 0,
    buy: 0,
    royalties: 0,
    sale: 0,
  }

  if (!options) return values

  options.forEach(option => {
    values = {
      ...values,
      [option?.key?.toLowerCase()]: (option?.totalRevenueSum?.value || 0) + (values?.[option.key] || 0),
    }
  })

  return values
}
