import React from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import TableText from 'components/common/TableText'
import { MRT_ColumnDef } from 'material-react-table'
import { TABLE_TEXT_TYPES } from 'utils/types'
import { useBaseCellStyles } from 'components/verticals/VerticalTableBase/useBaseCellStyles'
import { NOT_AVAILABLE } from 'utils/constants'
import Tooltip from 'components/Tooltip'
import { formatNumber } from 'utils/formatters'
import ReportedStatus from 'components/ReportedStatus/ReportedStatus'
import NoData from 'components/NoData/NoData'
import { FatcaEntityForTable } from 'containers/FatcaTablePage/types'
import { summaryAccessor } from './utils'

export const useFatcaTableColumns = () => {
  const baseCellStyles = useBaseCellStyles()

  const tooltipValues = {
    profileName: 'The profile name as appears in the web platform',
    verifiedName: 'The full name of the profile owner',
    tin: 'The official tax identification number of the identified entity',
    reportStatus:
      'The status of the tax reporting in each tax year after comparing the tax return reportings with the documented revenue',
    firstActivity: 'The date of the first documented business activity as identified by IVIX',
    lastActivity: 'The date of the last documented business activity as identified by IVIX',
    caseID: 'Unique IVIX id number for the case',
  }

  const columns = React.useMemo<MRT_ColumnDef<FatcaEntityForTable>[]>(
    () => [
      {
        header: 'Identification Details',
        Header: <TableText textType={TABLE_TEXT_TYPES.groupHeader}>Identification details</TableText>,
        columns: [
          {
            id: 'revenueSources.profileName.keyword',
            header: 'Profile Names',
            enableSorting: false,
            Header: (
              <Tooltip title={tooltipValues.profileName}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Profile Names</TableText>
              </Tooltip>
            ),
            muiTableBodyCellProps: { sx: { ...baseCellStyles } },
            size: 380,
            accessorFn: (item: FatcaEntityForTable) => {
              const profileNames = item?.revenueSources?.reduce((currentNames, { profileName }) => {
                if (!currentNames.includes(profileName)) {
                  currentNames.push(profileName)
                }
                return currentNames
              }, [] as string[])
              return (
                <Box>
                  <Stack direction='row'>
                    {profileNames?.length === 0 && <></>}
                    {profileNames?.length === 1 && <TableText>{profileNames[0]}</TableText>}
                    {profileNames?.length > 1 && (
                      <Tooltip title={profileNames.join(', ')}>
                        <Stack direction='row' spacing={1}>
                          <TableText>{profileNames[0]}</TableText>
                          <TableText>{`+ ${profileNames.length - 1}`}</TableText>
                        </Stack>
                      </Tooltip>
                    )}
                  </Stack>
                </Box>
              )
            },
          },
          {
            id: 'entityDetails.name.keyword',
            header: 'Verified Name',
            Header: (
              <Tooltip title={tooltipValues.verifiedName}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Verified Name</TableText>
              </Tooltip>
            ),
            muiTableBodyCellProps: {
              sx: { ...baseCellStyles, whiteSpace: 'nowrap' },
            },
            accessorFn: item => (item?.entityDetails?.name ? <TableText>{item.entityDetails.name}</TableText> : <></>),
          },
          {
            id: 'entityDetails.tin',
            header: 'TIN',
            enableSorting: false,
            Header: (
              <Tooltip title={tooltipValues.tin}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>TIN</TableText>
              </Tooltip>
            ),
            muiTableHeadCellProps: {
              sx: { borderBottom: 'none' },
            },
            muiTableBodyCellProps: {
              sx: { ...baseCellStyles },
            },
            size: 150,
            accessorFn: item =>
              item?.entityDetails?.tin ? <TableText>{item.entityDetails.tin}</TableText> : <NoData />,
          },
          {
            id: 'entityDetails.reportedStatus',
            header: 'Report Status',
            enableSorting: false,
            Header: (
              <Tooltip title={tooltipValues.reportStatus}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Report Status</TableText>
              </Tooltip>
            ),
            muiTableBodyCellProps: {
              sx: baseCellStyles,
            },
            size: 180,
            accessorFn: item =>
              item?.entityDetails?.reportedStatus ? (
                <ReportedStatus reportedStatus={item.entityDetails.reportedStatus} id={item.id} />
              ) : (
                <NoData />
              ),
          },
        ],
      },
      {
        header: 'Activity Details',
        Header: <TableText textType={TABLE_TEXT_TYPES.groupHeader}>Activity Details</TableText>,
        columns: [
          {
            id: 'overviewDetails.taxGap',
            header: 'Total unreported earnings',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Total unreported earnings</TableText>,
            enableSorting: false,
            muiTableBodyCellProps: {
              sx: baseCellStyles,
            },
            size: 180,
            accessorFn: item => {
              if (typeof item.overviewDetails.taxGap === 'number') {
                return formatNumber(item.overviewDetails.taxGap)
              }
              return NOT_AVAILABLE
            },
          },
          {
            id: 'totals.attributedRevenue',
            header: 'Total attributable earnings',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Total attributable earnings</TableText>,
            muiTableBodyCellProps: {
              sx: baseCellStyles,
            },
            size: 180,
            accessorFn: item => {
              if (typeof item.totals.attributedRevenue === 'number') {
                return formatNumber(item.totals.attributedRevenue)
              }

              return NOT_AVAILABLE
            },
          },
          {
            id: 'totals.documentedRevenue',
            header: 'Total documented earnings',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Total documented earnings</TableText>,
            muiTableBodyCellProps: {
              sx: baseCellStyles,
            },
            size: 180,
            accessorFn: item => {
              if (typeof item.totals.documentedRevenue === 'number') {
                return formatNumber(item.totals.documentedRevenue)
              }

              return NOT_AVAILABLE
            },
          },
          {
            id: 'overviewDetails.summary',
            header: 'Accounts',
            enableSorting: false,
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Accounts</TableText>,
            muiTableBodyCellProps: {
              sx: { ...baseCellStyles, whiteSpace: 'nowrap' },
            },
            accessorFn: item =>
              summaryAccessor(item?.overviewDetails?.summary || [], [
                'accountNumber',
                'controlledEntity',
                'controlPersonType',
                'bankName',
                'jurisdiction',
              ]),
          },
          {
            id: 'overviewDetails.controllingEntities',
            header: 'Controlling entities',
            enableSorting: false,
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Controlling entities</TableText>,
            muiTableBodyCellProps: {
              sx: { ...baseCellStyles, whiteSpace: 'nowrap' },
            },
            size: 180,
            accessorFn: item =>
              summaryAccessor(item?.overviewDetails?.summary || [], ['controlledEntity', 'controlPersonType']),
          },
          {
            id: 'contactInfo.addresses',
            header: 'Address',
            enableSorting: false,
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Address</TableText>,
            muiTableBodyCellProps: {
              sx: { ...baseCellStyles, whiteSpace: 'nowrap' },
            },
            accessorFn: rowData => {
              if (rowData?.contactInfo?.addresses?.length === 1) return rowData?.contactInfo?.addresses[0]
              if (rowData?.contactInfo?.addresses && rowData?.contactInfo?.addresses?.length > 1) {
                const addresses = rowData?.contactInfo?.addresses

                return (
                  <Tooltip
                    html={
                      <div>
                        {rowData?.contactInfo?.addresses?.map(text => (
                          <React.Fragment key={text}>
                            {text}
                            <br />
                          </React.Fragment>
                        ))}
                      </div>
                    }
                  >
                    {`${addresses[0]} +${addresses.length - 1} more`}
                  </Tooltip>
                )
              }

              return NOT_AVAILABLE
            },
          },
          {
            id: 'caseNumber',
            header: 'Case ID',
            Header: (
              <Tooltip title={tooltipValues.caseID}>
                <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Case ID</TableText>
              </Tooltip>
            ),
            muiTableBodyCellProps: {
              sx: baseCellStyles,
            },
            size: 180,
            accessorFn: item => <TableText>{item?.caseId || NOT_AVAILABLE}</TableText>,
          },
          // {
          //   id: 'entityDetails.match_by',
          //   header: 'Match By',
          //   enableSorting: false,
          //   Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Match By</TableText>,
          //   muiTableBodyCellProps: {
          //     sx: baseCellStyles,
          //   },
          //   size: 180,
          //   accessorFn: item => <TableText>{item?.entityDetails?.matchBy || NOT_AVAILABLE}</TableText>,
          // },
          {
            id: 'entityDetails.location.keyword',
            header: 'Docrefidar',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Docrefidar</TableText>,
            enableSorting: false,
            muiTableBodyCellProps: {
              sx: { ...baseCellStyles, whiteSpace: 'nowrap' },
            },
            accessorFn: rowData => {
              if (rowData?.overviewDetails?.docRefArs?.length === 1) return rowData.overviewDetails.docRefArs[0]
              if (rowData?.overviewDetails?.docRefArs && rowData?.overviewDetails?.docRefArs?.length > 1) {
                const docRefArs = rowData?.overviewDetails?.docRefArs

                return (
                  <Tooltip
                    html={
                      <div>
                        {rowData?.overviewDetails?.docRefArs?.map(text => (
                          <React.Fragment key={text}>
                            {text}
                            <br />
                          </React.Fragment>
                        ))}
                      </div>
                    }
                  >
                    {`${docRefArs[0]} +${docRefArs.length - 1} more`}
                  </Tooltip>
                )
              }

              return NOT_AVAILABLE
            },
          },
        ],
      },
    ],
    [baseCellStyles],
  )

  return columns
}
