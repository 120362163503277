import React, { ReactNode, FC } from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import cx from 'classnames'
import styles from './CustomButton.scss'

interface CustomButtonProps extends ButtonProps {
  label: ReactNode | string
  svgIcon?: ReactNode
  styleType?: 'primary' | 'secondary' | 'tertiary'
  className?: string
}

const CustomButton: FC<CustomButtonProps> = ({ styleType = 'primary', label, svgIcon, className, ...rest }) => (
  <Button className={cx(styles.customButton, styles[styleType], className)} {...rest}>
    {svgIcon}
    {label}
  </Button>
)

export default CustomButton
