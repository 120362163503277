export enum BUSINESSES_KEYS {
  SHOW_MAP = 'showMap',
  SEARCH = 'search',
  SEARCHFIELDS = 'searchFields',
  UNTAXED_INCOME = 'untaxedIncome',
  EASE_OF_COLLECTION = 'easeOfCollection',
  STATUS = 'status',
  BUSINESS_TYPE = 'businessType',
  EMPLOYESS_IN_JURISDICTION = 'employessInJurisdiction',
  LOCATION_IN_JURISDICTION = 'locationInJurisdiction',
}

export enum RENTALS_KEYS {
  ZONE_TYPE = 'zoneType',
  RENTALS_SEARCH = 'search',
  RENTALS_SEARCHFIELDS = 'searchFields',
  SORT = 'sort',
  NUMBER_OF_LISTINGS = 'numberOfListings',
  HOST_ID = 'hostId',
  JOINED_IN = 'joinedIn',
  HOST_TYPE = 'hostType',
  NESTED_HOST_LOCATIONS = 'nestedHostLocations',
  NESTED_LISTING_LOCATIONS = 'nestedListingLocations',
}

export enum NFT_ACTIVITY_KEYS {
  EVENT_TYPES = 'type',
}

export enum NFT_MAPPER_KEYS {
  SEARCH = 'search',
  SEARCHFIELDS = 'searchFields',
  ACTIVITY_TYPE = 'activityType',
  // FIRST_ACTIVITY = 'firstActivity'
}

export enum DISPLAY_AS_OPTIONS {
  CHECKBOXES = 'checkboxes',
  MULTI_SELECT = 'multiSelect',
  RANGE = 'range',
  MONTH_PICKER = 'monthPicker',
  INPUT = 'input',
  NESTED_MULTI_SELECT = 'nestedMultiSelect',
}

export enum TYPES_OF_SHAPE {
  REGULAR = 'regular',
  CIRCLE = 'circle',
}

export enum OPTIONS_FROM_PROPS {
  BUSINESS_TYPES = 'businessTypes',
  HOST_LOCATION = 'hostLocation',
  NFT_EVENT_TYPES = 'type',
}

export interface DateRange {
  start: string
  end: string
}

export interface Picker {
  picker: string
}

type KEYS = BUSINESSES_KEYS | RENTALS_KEYS | NFT_ACTIVITY_KEYS | NFT_MAPPER_KEYS

export interface BaseFilter<K extends KEYS, T> {
  key: K
  type?: any
  initialValue: T
  isActivated?: boolean
}
