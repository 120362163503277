import React from 'react'
import { useSelector } from 'react-redux'
import { selectHostLocations, selectListingLocations } from 'store/rentals'
import Filters from 'components/Filters/Filters'
import { optionsMessages, subjectsMessages } from 'components/rentals/RentalsFilters/messages'
import { UsableFilters } from 'services/filters'
import { RentalsFilterGroups } from 'utils/filters'
import { InjectedIntlProps, injectIntl } from 'react-intl'

interface RentalsFiltersProps {
  key: string
  mode: string
  groups: RentalsFilterGroups
  filters: UsableFilters
  onChange: (nextFilters: UsableFilters) => void
  onClose: () => void
  className: string
  zone?: string
}

const RentalsFilters: React.FC<RentalsFiltersProps & InjectedIntlProps> = props => {
  const { intl, zone } = props

  const hostLocations = useSelector(selectHostLocations)
  const listingLocations = useSelector(selectListingLocations)

  return (
    <Filters
      renderSubject={(subject: string) => intl.formatMessage(subjectsMessages[subject], { zone })}
      renderOption={(option: string) =>
        optionsMessages[option] ? intl.formatMessage(optionsMessages[option]) : option
      }
      hostLocations={hostLocations}
      listingLocations={listingLocations}
      {...props}
    />
  )
}

export default injectIntl(RentalsFilters)
