export const rowHeight = 60

export const sizeToGridHeight = {
  small: 14 * rowHeight,
  large: 20 * rowHeight,
}

const extractHostname = (url: string) => {
  let hostname
  if (url.indexOf('//') > -1) {
    // eslint-disable-next-line prefer-destructuring
    hostname = url.split('/')[2]
  } else {
    // eslint-disable-next-line prefer-destructuring
    hostname = url.split('/')[0]
  }
  // eslint-disable-next-line prefer-destructuring
  hostname = hostname.split(':')[0]
  // eslint-disable-next-line prefer-destructuring
  hostname = hostname.split('?')[0]
  return hostname
}

export const extractRootDomainNoExt = (url: string) => {
  let domain = extractHostname(url)
  const splitArr = domain.split('.')
  const arrLen = splitArr.length

  if (arrLen === 2) {
    // eslint-disable-next-line prefer-destructuring
    domain = splitArr[0]
  } else if (arrLen > 2) {
    domain = splitArr[arrLen - 2]
    // check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
    if (splitArr[arrLen - 2].length === 2 && splitArr[arrLen - 1].length === 2) {
      domain = splitArr[arrLen - 3]
    }
  }
  return domain
}

export enum SECTIONS {
  DOCUMENTED_ACTIVITY = 'documentedActivity',
  CREATED = 'created',
  CRYPTO_WALLETS = 'cryptoWallets',
  CONTACT_INFO = 'contactInfo',
}

export const { DOCUMENTED_ACTIVITY, CREATED, CRYPTO_WALLETS, CONTACT_INFO } = SECTIONS
