// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Overview__ecommerceDatePicker--aCG0s{padding:0}.Overview__ecommerceDatePicker--aCG0s .react-datepicker__input-container input{width:55px}.Overview__ecommerceDatePicker--aCG0s .react-datepicker__year-wrapper{justify-content:center}.Overview__ecommerceDatePicker--aCG0s .react-datepicker-popper{transform:translate3d(0px, 35px, 0px) !important}", "",{"version":3,"sources":["webpack://./app/containers/VerticalPage/nft/Overview/Overview.scss"],"names":[],"mappings":"AAAA,sCACE,SAAA,CAGI,+EACE,UAAA,CAGJ,sEACE,sBAAA,CAEF,+DACE,gDAAA","sourcesContent":[".ecommerceDatePicker {\n  padding: 0;\n  :global {\n    .react-datepicker__input-container {\n      input {\n        width: 55px;\n      }\n    }\n    .react-datepicker__year-wrapper {\n      justify-content: center;\n    }\n    .react-datepicker-popper {\n      transform: translate3d(0px, 35px, 0px) !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ecommerceDatePicker": "Overview__ecommerceDatePicker--aCG0s"
};
export default ___CSS_LOADER_EXPORT___;
