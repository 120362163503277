import React, { useContext, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { VerticalContext } from 'store/verticals/Context'
import { useOverviewTabQuery } from 'containers/VerticalPage/company-overview/OverviewTab/useOverviewTabQuery'
import EmployeeOverviewChart from 'containers/VerticalPage/company-overview/OverviewTab/EmployeeOverviewChart'
import { selectCurrentZone } from 'store/global'
import { useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import styles from './styles'
import ActionsBar from 'containers/VerticalPage/company-overview/OverviewTab/ActionsBar'
import { useDebounce } from 'hooks/useDebounce'
import { Filter } from 'components/FilterDrawer/FilterDrawer.config'
import { useFilterOptionsQuery } from 'hooks/useFilterOptionsQuery'
import { filtersInitialState } from 'containers/VerticalPage/company-overview/OverviewTab/utils'

const margins = 50

interface OverviewTabProps {
  tabsContainerHeight?: number
}

const OverviewTab = ({ tabsContainerHeight }: OverviewTabProps) => {
  const { verticalEntity } = useContext(VerticalContext)
  const currentZone = useSelector(selectCurrentZone)
  const { caseId } = useParams() as { caseId: string }

  const [activeFilters, setActiveFilters] = useState<Filter[]>([])
  const activeFiltersDebounced = useDebounce(activeFilters, 500)

  const { filterOptions } = useFilterOptionsQuery({
    verticalIndices: currentZone?.companyOverviewAggsIndices,
    currentQueryKey: 'company-overview-filter-options-key',
    filtersInitialState,
  })

  const { data: employeeOvertimeChartData } = useOverviewTabQuery({
    currentQueryKey: 'company-overview-charts-data',
    payload: {
      caseId,
      zoneEndpoint: currentZone?.companyOverviewAggsIndices,
      localCountry: verticalEntity?.overviewDetails?.localJurisdiction,
      filters: activeFiltersDebounced,
    },
  })

  const tabHeaderRef = useRef<HTMLDivElement>(null)

  const chartHeight = useMemo(() => {
    const tabHeaderContainer = tabHeaderRef?.current?.getBoundingClientRect()
    return (tabsContainerHeight || 0) - (tabHeaderContainer?.height || 0) - margins
  }, [tabsContainerHeight, tabHeaderRef, employeeOvertimeChartData])

  return (
    <Stack minHeight='100%'>
      <Stack direction='row' alignItems='center' ref={tabHeaderRef}>
        <Typography sx={styles.Title}>Employees Overtime</Typography>
        <ActionsBar activeFilters={activeFilters} setActiveFilters={setActiveFilters} filterOptions={filterOptions} />
      </Stack>
      <Stack width='100%' height={chartHeight} mt={2} paddingBlock={2}>
        {employeeOvertimeChartData && (
          <>
            {employeeOvertimeChartData?.length > 0 ? (
              <EmployeeOverviewChart chartData={employeeOvertimeChartData} />
            ) : (
              <Typography sx={styles.NoDataTitle}>No Data</Typography>
            )}
          </>
        )}
      </Stack>
    </Stack>
  )
}

export default OverviewTab
