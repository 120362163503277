import React, { FC } from 'react'
import Typography, { TypographyProps } from '@mui/material/Typography'

interface GenericTextProps extends TypographyProps {
  children: React.ReactNode
  withTitle?: boolean
}

// Instead of passing specific MUI props, we use MuiCardProps type for full access to all MUI Typography props
const GenericText: FC<GenericTextProps> = ({ children, sx, withTitle = true, ...TypographyProps }) => {
  return (
    <Typography
      variant='body1'
      sx={{
        fontFamily: 'Source Sans Pro',
        ...sx,
      }}
      title={withTitle ? (children as string) : ''}
      {...TypographyProps}
    >
      {children}
    </Typography>
  )
}

export default GenericText
