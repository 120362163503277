import React from 'react'
import TableText from 'components/common/TableText'
import { CompanyOverviewEntityForTable } from 'containers/CompanyOverviewTablePage/types'
import { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'
import { TABLE_TEXT_TYPES } from 'utils/types'
import { NOT_AVAILABLE } from 'utils/constants'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import TableTextCard from 'components/common/TableTextCard'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Tooltip from 'components/Tooltip'
import { businessTypeUrls } from 'containers/VerticalPage/cdtfa/OverviewTab/utils'

const LOCAL_DEPARTMENTS_COUNT = 1

export const useCompanyOverviewColumns = () => {
  const handlePercentCell = (value: number, toFixed = 1) => {
    return value !== undefined && value !== null ? `${(value * 100).toFixed(toFixed)}%` : NOT_AVAILABLE
  }

  const columns = useMemo<MRT_ColumnDef<CompanyOverviewEntityForTable>[]>(
    () => [
      {
        id: 'entityDetails.name.keyword',
        header: 'Company Name',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.companyName} />
          </TableText>
        ),
        accessorFn: rowData => <TableText>{rowData?.entityDetails?.name || NOT_AVAILABLE}</TableText>,
        size: 300,
      },
      {
        id: 'overviewDetails.industry.keyword',
        header: 'Industry',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.industry} />
          </TableText>
        ),
        accessorFn: rowData => <TableText>{rowData?.overviewDetails?.industry || NOT_AVAILABLE}</TableText>,
        size: 300,
        maxSize: 300,
      },
      {
        id: 'entityDetails.founded.keyword',
        header: 'Founded',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.founded} />
          </TableText>
        ),
        accessorFn: rowData => <TableText>{rowData?.entityDetails?.founded || NOT_AVAILABLE}</TableText>,
        size: 100,
      },
      {
        id: 'totals.jurisdictions',
        header: 'Employees in jurisdiction',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.locations} />
          </TableText>
        ),
        accessorFn: rowData => (
          <TableText>
            {Array.isArray(rowData?.totals?.jurisdictions) ? rowData.totals.jurisdictions.length : NOT_AVAILABLE}
          </TableText>
        ),
        size: 150,
      },
      {
        id: 'totals.employeesLocal',
        header: 'Employees Local',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.employeesLocal} />
          </TableText>
        ),
        accessorFn: rowData => <TableText>{rowData?.totals?.employeesLocal ?? NOT_AVAILABLE}</TableText>,
        size: 100,
      },
      {
        id: 'totals.employeesLocalPercent',
        header: 'Employees Local %',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.employeesLocalPercent} />
          </TableText>
        ),
        accessorFn: rowData => <TableText>{handlePercentCell(rowData?.totals?.totalLocalPercent)}</TableText>,
        size: 100,
      },
      {
        id: 'totals.employeesAbroad',
        header: 'Employees Abroad',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.employeesAbroad} />
          </TableText>
        ),
        accessorFn: rowData => <TableText>{rowData?.totals?.employeesAbroad ?? NOT_AVAILABLE}</TableText>,
        size: 100,
      },
      {
        id: 'totals.employeesAbroadPercent',
        header: 'Employees Abroad',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.employeesAbroadPercent} />
          </TableText>
        ),
        accessorFn: rowData => <TableText>{handlePercentCell(rowData?.totals?.employeesAbroadPercent)}</TableText>,
        size: 100,
      },
      {
        id: 'totals.executivesLocal',
        header: 'Executives Local',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.executivesLocal} />
          </TableText>
        ),
        accessorFn: rowData => <TableText>{rowData?.totals?.executivesLocal ?? NOT_AVAILABLE}</TableText>,
        size: 100,
      },
      {
        id: 'totals.executivesLocalPercent',
        header: 'Executives Local',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.executivesLocal} />
          </TableText>
        ),
        accessorFn: rowData => <TableText>{handlePercentCell(rowData?.totals?.executivesLocalPercent)}</TableText>,
        size: 100,
      },
      {
        id: 'totals.nonRnDLocal',
        header: 'Non-R&D Local',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.nonRndLocal} />
          </TableText>
        ),
        accessorFn: rowData => <TableText>{rowData?.totals?.nonRnDLocal ?? NOT_AVAILABLE}</TableText>,
        size: 100,
      },
      {
        id: 'totals.nonRnDLocalPercent',
        header: 'Non-R&D Local %',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.nonRndLocal} />
          </TableText>
        ),
        accessorFn: rowData => <TableText>{handlePercentCell(rowData?.totals?.nonRnDLocalPercent)}</TableText>,
        size: 100,
      },
      {
        id: 'totals.RnDLocal',
        header: 'R&D Local',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.rndLocal} />
          </TableText>
        ),
        accessorFn: rowData => <TableText>{rowData?.totals?.rnDLocal ?? NOT_AVAILABLE}</TableText>,
        size: 100,
      },
      {
        id: 'totals.RnDLocalPercent',
        header: 'R&D Local %',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.rndLocalPercent} />
          </TableText>
        ),
        accessorFn: rowData => <TableText>{handlePercentCell(rowData?.totals?.rnDLocalPercent)}</TableText>,
        size: 100,
      },

      {
        id: 'totals.localDepartments',
        header: 'Local Departments',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.localDepartments} />
          </TableText>
        ),
        accessorFn: rowData => {
          const localDepartments = [...new Set(rowData?.totals?.localDepartments || [])]

          return (
            <Stack direction='row' sx={{ justifyContent: 'flex-start', gap: '10px', flexWrap: 'wrap' }}>
              {!Array.isArray(localDepartments) || !localDepartments?.length ? (
                NOT_AVAILABLE
              ) : localDepartments.length > LOCAL_DEPARTMENTS_COUNT ? (
                <Tooltip
                  theme='transparent'
                  html={
                    <Box>
                      {localDepartments.length >= LOCAL_DEPARTMENTS_COUNT
                        ? localDepartments.slice(LOCAL_DEPARTMENTS_COUNT).map(dep => (
                            <Box mr='10px' sx={{ wordBreak: 'keep-all' }} key={dep}>
                              {dep}
                            </Box>
                          ))
                        : ''}
                    </Box>
                  }
                >
                  <Stack direction='row' alignItems='center' mr='10px' width='300px'>
                    {localDepartments.slice(0, LOCAL_DEPARTMENTS_COUNT).map(dep => (
                      <TableTextCard text={dep} sx={{ mr: '10px' }} key={dep} />
                    ))}
                    <Box sx={{ wordBreak: 'keep-all' }}>
                      {localDepartments.length > LOCAL_DEPARTMENTS_COUNT &&
                        `+${localDepartments.length - LOCAL_DEPARTMENTS_COUNT} more`}
                    </Box>
                  </Stack>
                </Tooltip>
              ) : (
                <Box mr='10px'>
                  {localDepartments.map(dep => (
                    <TableTextCard text={dep} sx={{ mr: '10px' }} key={dep} />
                  ))}
                </Box>
              )}
            </Stack>
          )
        },
        size: 300,
      },
      {
        id: 'entityDetails.location.keyword',
        header: 'Headquarters',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.headquarters} />
          </TableText>
        ),
        accessorFn: rowData => <TableText>{rowData?.entityDetails?.location || NOT_AVAILABLE}</TableText>,
        size: 100,
      },
      {
        id: 'overviewDetails.dataSources',
        header: 'Data Sources',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.dataSources} />
          </TableText>
        ),
        enableSorting: false,
        accessorFn: rowData => {
          if (!rowData.overviewDetails.dataSources?.length) return NOT_AVAILABLE
          const sourcePlatforms = rowData.overviewDetails.dataSources.map(source => source)

          return (
            <Stack gap={1} direction='row'>
              {[...new Set(sourcePlatforms)]
                .sort()
                .map(
                  platform =>
                    businessTypeUrls[platform as keyof typeof businessTypeUrls] && (
                      <Box
                        key={platform}
                        component='img'
                        src={businessTypeUrls[platform as keyof typeof businessTypeUrls]}
                        width={20}
                        height={20}
                        alt={platform}
                      />
                    ),
                )}
            </Stack>
          )
        },
        muiTableBodyCellProps: {
          sx: {
            minWidth: 130,
            maxWidth: 130,
          },
        },
      },
      {
        id: 'overviewDetails.companyType.keyword',
        header: 'Company type',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.companyType} />
          </TableText>
        ),
        accessorFn: rowData => <TableText>{rowData?.overviewDetails?.companyType || NOT_AVAILABLE}</TableText>,
        size: 100,
      },
      {
        id: 'caseId.keyword',
        header: 'Case ID',
        Header: (
          <TableText textType={TABLE_TEXT_TYPES.columnHeader}>
            <FormattedMessage {...messages.caseId} />
          </TableText>
        ),
        accessorFn: rowData => <TableText>{rowData?.caseId || NOT_AVAILABLE}</TableText>,
        size: 100,
      },
    ],
    [],
  )

  return columns
}
