import React, { useCallback, useMemo } from 'react'
import GenericHeading from 'components/common/GenericHeading'
import GenericText from 'components/common/GenericText'
import { NOT_AVAILABLE } from 'utils/constants'
import {
  HeaderTotalAmount,
  TooltipItemContainer,
  TooltipItemDetails,
  TooltipHeading,
  ItemTotalAmount,
  TooltipItemLabel,
} from 'containers/VerticalPage/company-overview/OverviewTab/EmployeeOvertimeTooltip/styles'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

interface LocalEmployeeOvertimeTooltipContentProps {
  localCountry: string
  relevantPayload: any
}

const LocalEmployeeOvertimeTooltipContent = ({
  localCountry,
  relevantPayload,
}: LocalEmployeeOvertimeTooltipContentProps) => {
  const totalAmount = useMemo(() => {
    return relevantPayload?.payload?.totalLocalAmount + relevantPayload?.payload?.totalRestOfTheWorldAmount
  }, [relevantPayload])

  const renderLocalCountryHeader = useCallback(
    () => (
      <Stack sx={TooltipItemContainer}>
        <GenericHeading sx={TooltipHeading}>{localCountry || NOT_AVAILABLE}</GenericHeading>
        <Stack sx={TooltipItemDetails}>
          <GenericText sx={HeaderTotalAmount}>{relevantPayload?.payload?.totalLocalAmount}</GenericText>
          <GenericText sx={HeaderTotalAmount}>
            ({((relevantPayload?.payload?.totalLocalAmount / totalAmount) * 100).toFixed(2)}
            %)
          </GenericText>
        </Stack>
      </Stack>
    ),
    [relevantPayload, localCountry],
  )

  const renderLocalDepartments = useCallback(() => {
    if (!relevantPayload?.payload?.localAmountsByDepratments) return <></>
    const list = relevantPayload?.payload?.localAmountsByDepratments
      ?.sort((a: any, b: any) => b.amount - a.amount)
      .slice(0, 10)
      .map((departmentData: any) => {
        return (
          <Stack direction='row' sx={TooltipItemContainer} key={departmentData?.department}>
            <GenericText sx={TooltipItemLabel}>{departmentData?.department}</GenericText>
            <Stack sx={TooltipItemDetails}>
              <GenericText>{departmentData?.amount}</GenericText>
              <GenericText sx={ItemTotalAmount}>
                ({((departmentData?.amount / totalAmount) * 100).toFixed(2)}
                %)
              </GenericText>
            </Stack>
          </Stack>
        )
      })

    if (relevantPayload?.payload?.localAmountsByDepratments?.length > 10) {
      list.push(<Box>...</Box>)
    }

    return list
  }, [relevantPayload, localCountry])

  return (
    <Stack>
      {renderLocalCountryHeader()}
      {renderLocalDepartments()}
    </Stack>
  )
}

export default LocalEmployeeOvertimeTooltipContent
