export const downloadCSV = (csv: string, fileName: string) => {
  const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8' })
  const csvUrl = URL.createObjectURL(csvData)
  const hiddenElement = document.createElement('a')
  // Use utf-8 BOM to support special characters
  hiddenElement.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv))
  hiddenElement.href = csvUrl
  hiddenElement.target = '_blank'
  hiddenElement.download = fileName
  hiddenElement.click()
  hiddenElement.remove()
}
