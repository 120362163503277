import React from 'react'
import DropdownInput from 'components/DropdownInput'
import { BusinessesBusinessType, NFTEventTypes } from '../../../utils/filters'
import { UsableFilters } from 'services/filters'
import { ISelection, TSubject } from '../Filters'
import { NFTMapperActivityType } from 'utils/filters/nftMapper'
import styles from './MultiSelect.scss'

interface MultiSelectProps {
  data: BusinessesBusinessType | NFTEventTypes | NFTMapperActivityType
  options: string[]
  currentFilters: UsableFilters
  maxShownSelections: number
  renderOption: (option: string) => string
  onMultiSelectChange: (subject: TSubject, selections: Array<ISelection>) => void
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  data,
  options,
  currentFilters,
  maxShownSelections,
  renderOption,
  onMultiSelectChange,
}) => {
  const { key, disableSearch, hasSelectAll } = data

  const dropdownOptions = options.map((option: string) => ({
    value: option,
    label: renderOption(option),
  }))

  const selectionOptions: Array<ISelection> = (currentFilters[key] || []).map((value: string) => ({
    value,
    label: value,
  }))

  return (
    <div className={styles.multiSelectContainer}>
      <DropdownInput
        hasSelectAll={hasSelectAll}
        options={dropdownOptions}
        selections={selectionOptions}
        onChange={(selections: Array<ISelection>) => onMultiSelectChange(key, selections)}
        disableSearch={disableSearch}
      />
    </div>
  )
}

export default MultiSelect
