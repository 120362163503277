import React, { useMemo, FC } from 'react'
import { PieChart, Pie, Cell, Label, Tooltip } from 'recharts'
import cx from 'classnames'
import { getSummaryData, IData, IBusinessSummary } from './utils'
import TextTooltip from 'components/Tooltip'
import styles from './BusinessSummary.module.scss'
import { PieChartData } from './utils'
import { useWindowSize } from 'hooks/useWindowSize'
import { globalTheme } from 'styles/globalTheme'

const BusinessSummary: FC<IBusinessSummary> = ({ overviewData }) => {
  const { width } = useWindowSize()

  const data: IData[] = useMemo(() => getSummaryData(overviewData), [overviewData])
  const pieSize = useMemo(() => {
    if (width <= (globalTheme.breakpoints?.values?.lgPlus || 0)) {
      return {
        size: 115,
        innerRadius: 49,
        outerRadius: 55,
      }
    }

    return {
      size: 157,
      innerRadius: 64,
      outerRadius: 70,
    }
  }, [width])

  const renderPieChartLegendItem = (
    item: PieChartData,
    index: number,
    displayFullLabel?: boolean,
    classNameContainer?: string,
  ) => (
    <div key={index} className={cx(styles.item, classNameContainer)}>
      <div className={styles.dot} style={{ backgroundColor: item.color }} />
      <div className={styles.nameValueWrapper}>
        {item.tooltipLabel && !displayFullLabel ? (
          <TextTooltip
            className={styles.tooltipContainer}
            html={<div className={styles.tooltip}>{item.tooltipLabel}</div>}
            theme='light'
          >
            {item.label}
          </TextTooltip>
        ) : (
          <div className={styles.name}>{displayFullLabel ? item.fullLabel || item.label : item.label}</div>
        )}
        {item.uiValue && <div className={styles.value}>{item.uiValue}</div>}
      </div>
    </div>
  )

  return (
    <div className={styles.container}>
      {data.map((row, index) => (
        // Check if there is data for the pie chart before rendering the div
        <React.Fragment key={index}>
          {row.data.length > 0 && (
            <div className={styles.data}>
              <div className={styles.pieChartWrapper}>
                <PieChart width={pieSize.size} height={pieSize.size}>
                  <Pie
                    data={row.data.map(item => ({ id: item.key, name: item.label, value: item.value }))}
                    cx='50%'
                    cy='50%'
                    innerRadius={pieSize.innerRadius}
                    outerRadius={pieSize.outerRadius}
                    paddingAngle={5}
                    dataKey='value'
                    isAnimationActive={false}
                  >
                    {row.data.map((data, index) => (
                      <Cell key={index} fill={data.color} stroke={data.color} />
                    ))}
                    <Label
                      value={row.pieTitle}
                      position='center'
                      className={styles.pieNumberLabel}
                      fill='#fff'
                      dy={-12}
                    />
                    <Label
                      value={row.pieSubTitle}
                      position='center'
                      className={styles.pieDescriptionLabel}
                      fill='#fff'
                      dy={row.pieDy || 12}
                      width={100}
                    />
                  </Pie>
                </PieChart>
              </div>
              <div className={styles.counts}>
                <div className={styles.title}>{row.title}</div>
                <div className={styles.infoWrapper}>
                  {(row.data.length > 8 ? row.data.slice(0, 7) : row.data).map((item, index) =>
                    renderPieChartLegendItem(item, index, false),
                  )}
                  {row.data.length > 8 && (
                    <div className={styles.item}>
                      <div className={styles.nameValueWrapper}>
                        <TextTooltip
                          className={styles.tooltipContainer}
                          html={
                            <div className={styles.tooltip}>
                              {row.data.map((item, index) =>
                                renderPieChartLegendItem(item, index, true, styles.tooltipCitiesListItem),
                              )}
                            </div>
                          }
                          theme='light'
                          position='bottom'
                        >
                          {`+${row.data.length - 7} ${row.data.length - 7 === 1 ? 'entity' : 'entities'} more`}
                        </TextTooltip>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export default BusinessSummary
