import React from 'react'
import Loader from 'components/Loader'
import styles from './LoadingPage.scss'

export default function LoadingPage() {
  return (
    <div className={styles.container}>
      <Loader type='globalLoading' />
    </div>
  )
}
