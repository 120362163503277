import React, { FC } from 'react'
import MUIBreadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { SxProps, Theme } from '@mui/material'
import { TypographyStyles, LinkStyles } from './styles'

interface IBreadcrumbsData {
  label: string
  href?: string
  onClickCallback?: React.MouseEventHandler<HTMLAnchorElement>
}

interface IBreadcrumbs {
  data: IBreadcrumbsData[]
  containerSx?: SxProps<Theme> | undefined
}

const Breadcrumbs: FC<IBreadcrumbs> = ({ data, containerSx }) => {
  return (
    <Stack spacing={2} sx={containerSx}>
      <MUIBreadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {data.map((breadcrumb, index) => {
          if (breadcrumb.href) {
            return (
              <Link
                underline='hover'
                key={index}
                color='inherit'
                href={breadcrumb.href}
                sx={LinkStyles}
                onClick={breadcrumb.onClickCallback}
              >
                {breadcrumb.label}
              </Link>
            )
          }

          return (
            <Typography key={index} color='text.primary' sx={TypographyStyles}>
              {breadcrumb.label}
            </Typography>
          )
        })}
      </MUIBreadcrumbs>
    </Stack>
  )
}

export default Breadcrumbs
