import { defineMessages } from 'react-intl'

const scope = 'app.components.Filters'

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Filters',
  },
  clear: {
    id: `${scope}.actions.clear`,
    defaultMessage: 'Clear all',
  },
  apply: {
    id: `${scope}.actions.apply`,
    defaultMessage: 'Apply',
  },
})
