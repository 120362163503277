export const Container = {
  backgroundColor: '#fff',
  border: 'solid 1px #DADBDF',
  padding: '24px',
  borderRadius: '8px',
  fontFamily: 'Source Sans Pro',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  textAlign: 'left',
}

export const Logo = {
  objectFit: 'contain',
}

export const Title = {
  fontFamily: 'Dosis',
  fontSize: { md: '18px', lg: '20px', xl: '24px' },
  fontWeight: 600,
}

export const Address = {
  fontFamily: 'DM Sans',
  fontSize: { md: '14px', lg: '15px', xl: '16px' },
  fontWeight: 400,
  color: '#7E8299',
}

export const OverviewMapping = {
  backgroundColor: '#F1F6F9',
  padding: { md: '8px 12px', lg: '10px 14px', xl: '13px 24px' },
  borderRadius: '8px',
}

export const OverviewMappingLabel = {
  fontFamily: 'Source Sans Pro',
  fontSize: { md: '13px', lg: '14px', xl: '16px' },
  fontWeight: 400,
  color: '#7E8BA6',
  lineHeight: 1,
}

export const OverviewMappingValue = {
  fontFamily: 'Dosis',
  fontSize: { md: '14px', lg: '16px', xl: '20px' },
  fontWeight: 600,
  lineHeight: 1,
  mt: 0.8,
}

export const TooltipText = {
  fontFamily: 'Source Sans Pro',
  fontSize: '16px',
  fontWeight: 400,
  color: 'rgba(28, 40, 66, 1)',
}
