import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiService from 'services/api'
import localStorageService from 'services/storage'
import { defaultHostsDisplayedColumns } from 'components/rentals/RentalsHosts/specs'
import { addAlert, DUMMY_ZONE_NAME } from 'store/global'
import { rentalsHostsFilters } from '../../utils/filters'
import _ from 'lodash'

const SLICE_KEY = 'rentals'

export const fetchRentalsSummary = createAsyncThunk(
  `${SLICE_KEY}/fetchRentalsSummary`,
  async (args, { getState, dispatch }) => {
    dispatch(setIsLoadingSummary({ isLoading: true }))
    const { currentZone } = getState().global
    const summary = await apiService.rentalsSummary(currentZone)
    return { summary }
  },
)

export const fetchHostsWithMostListings = createAsyncThunk(
  `${SLICE_KEY}/fetchHostsWithMostListings`,
  async ({ filters = {} }, { getState }) => {
    const { currentZone } = getState().global
    if (!currentZone || currentZone.name === DUMMY_ZONE_NAME) return {}
    const { aggregations } = await apiService.rentalsHostsWithMostListings(currentZone, filters)
    return { aggregations }
  },
)

export const fetchListingsPerHostAggregations = createAsyncThunk(
  `${SLICE_KEY}/fetchListingsPerHostAggregations`,
  async (args, { getState }) => {
    const {
      currentZone: { airbnbIndices },
    } = getState().global
    const { aggregations } = await apiService.rentalsListingsPerHostAggregations(airbnbIndices)
    return { aggregations }
  },
)

export const fetchMapClusterAggregation = createAsyncThunk(
  `${SLICE_KEY}/fetchMapClusterAggregation`,
  async ({ top_left, bottom_right, precision, hostFilters }, { getState }) => {
    const { currentZone } = getState().global
    if (!currentZone || currentZone.name === DUMMY_ZONE_NAME) return {}
    if (top_left.lng === 0 && bottom_right.lng === 0) return {}
    const { points } = await apiService.getMapClusterAggregation(
      currentZone,
      top_left,
      bottom_right,
      precision,
      hostFilters,
    )
    return { points }
  },
)

export const fetchRentalsHosts = createAsyncThunk(
  `${SLICE_KEY}/fetchRentalsHosts`,
  async ({ filters, from }, { getState, dispatch }) => {
    if (from > 0) {
      dispatch(setIsLoadingNextBatch(true))
    }

    const { currentZone } = getState().global
    if (!currentZone || currentZone.name === DUMMY_ZONE_NAME) return {}

    try {
      const { totalHosts, hosts } = await apiService.rentalsHosts(currentZone, filters, from)

      const hostsAllowScrolling = hosts.length > 0

      dispatch(setIsLoadingNextBatch(false))

      return {
        isScrolling: from > 0,
        totalHosts,
        hosts,
        hostsAllowScrolling,
      }
    } catch (err) {
      dispatch(
        addAlert({
          type: 'danger',
          message: 'Unexpected error when fetching short term rental hosts from API',
        }),
      )
    }
  },
)

export const fetchEntitiesForExport = createAsyncThunk(
  `${SLICE_KEY}/fetchEntitiesForExport`,
  async ({ filters }, { getState, dispatch }) => {
    const { currentZone } = getState().global
    if (!currentZone || currentZone.name === DUMMY_ZONE_NAME) return {}

    try {
      const csv = await apiService.exportHosts(currentZone, filters)
      return { csv }
    } catch (err) {
      dispatch(
        addAlert({
          type: 'danger',
          message: 'Unexpected error when fetching short term rental hosts from API',
        }),
      )
    }
  },
)

export const fetchHostListings = createAsyncThunk(
  `${SLICE_KEY}/fetchHostListings`,
  async ({ hostId, from = 0 }, { getState, dispatch }) => {
    const {
      currentZone: { airbnbIndices },
    } = getState().global
    if (from > 0) {
      dispatch(setIsLoadingHostListingsNextBatch(true))
    } else {
      setSelectedHostsListings()
    }

    try {
      const { listings } = await apiService.selectedHostsListings(hostId, from, airbnbIndices)
      dispatch(setIsLoadingHostListingsNextBatch(false))
      return { listings, from }
    } catch (error) {
      dispatch(
        addAlert({
          type: 'danger',
          message: 'Unexpected error when fetching short term rental host listings from API',
        }),
      )
    }
  },
)

export const fetchLocationsHierarchy = createAsyncThunk(
  `${SLICE_KEY}/fetchLocationsHierarchy`,
  async (args, { getState }) => {
    const {
      currentZone: { airbnbIndices },
    } = getState().global
    const { hostLocations, listingLocations } = await apiService.hostsAndListingsLocations(airbnbIndices)
    return { hostLocations, listingLocations }
  },
)

export const initialState = {
  isLoadingSummary: false,
  summary: {
    isInitialized: false,
    hosts: {},
    listings: {},
  },
  hostsWithMostListings: {
    isInitialized: false,
    aggregations: [],
  },
  listingsPerHost: {
    isInitialized: false,
    aggregations: [],
  },
  isLoadingInitial: false,
  isLoadingNextBatch: false,
  isLoadingHostListingsNextBatch: false,
  hostNames: [],

  displayFilters: false,

  // --- Hosts ---
  hostsFilters: rentalsHostsFilters
    .filter(({ isActivated = true }) => isActivated)
    .reduce((acc, { key, initialValue }) => ({ ...acc, [key]: initialValue }), {}),
  totalHosts: 0,
  hosts: [],
  totalDisplayedHosts: 0,
  selectedHostsIds: [],
  hostsDisplayedColumns: defaultHostsDisplayedColumns,
  hostsAllowScrolling: true,
  displayedHost: {},
  currentBusinessReviews: [],
  hostLocations: {},
  listingLocations: {},
}

const extractIds = items => items.map(item => item.id)

const slice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    setIsLoadingSummary(state, { payload = {} }) {
      state.isLoadingSummary = payload.isLoading || false
    },
    setIsLoadingInitial(state, { payload: isLoading = false }) {
      state.isLoadingInitial = isLoading
    },
    setIsLoadingNextBatch(state, { payload: isLoading = false }) {
      state.isLoadingNextBatch = isLoading
    },
    setIsLoadingHostListingsNextBatch(state, { payload: isLoading = false }) {
      state.isLoadingHostListingsNextBatch = isLoading
    },
    setIsDisplayFilters(state, { payload: isDisplay = false }) {
      state.displayFilters = isDisplay
    },
    // --- Hosts ---
    applyHostsFilters(state, { payload = {} }) {
      const { filters } = payload
      state.hostsFilters = filters
    },
    toggleSelectAllHosts(state, { payload = {} }) {
      const { isAllSelected } = payload

      state.selectedHostsIds = isAllSelected ? extractIds(state.hosts) : []
    },
    toggleSelectedHost(state, { payload = {} }) {
      const { hostId } = payload

      const nextSelected = state.selectedHostsIds.includes(hostId)
        ? state.selectedHostsIds.filter(selectedHostId => selectedHostId !== hostId)
        : [...state.selectedHostsIds, hostId]

      state.selectedHostsIds = nextSelected
    },
    setHostsDisplayedColumns(state, { payload = {} }) {
      const { columns = [] } = payload
      state.hostsDisplayedColumns = columns

      localStorageService.displayedSTRColumns = columns
    },
    setDisplayedHost(state, { payload = {} }) {
      const { host } = payload
      state.displayedHost = host || {}
    },
    setEntitiesCSV(state, { payload }) {
      const { csv } = payload
      state.entitiesCSV = csv
    },

    // --- Listings ---
    applyListingsFilters(state, { payload = {} }) {
      const { filters } = payload
      state.listingsFilters = filters
    },
    toggleSelectAllListings(state, { payload = {} }) {
      const { isAllSelected } = payload

      state.selectedListingsIds = isAllSelected ? extractIds(state.listings) : []
    },
    toggleSelectedListing(state, { payload = {} }) {
      const { listingId } = payload

      const nextSelected = state.selectedListingsIds.includes(listingId)
        ? state.selectedListingsIds.filter(selectedListingsId => selectedListingsId !== listingId)
        : [...state.selectedListingsIds, listingId]

      state.selectedListingsIds = nextSelected
    },
    setListingsDisplayedColumns(state, { payload = {} }) {
      const { columns = [] } = payload
      state.listingsDisplayedColumns = columns
    },
    setDisplayedListing(state, { payload = {} }) {
      const { listing } = payload
      state.displayedListing = listing || {}
    },
    setSelectedHostsListings(state, { payload = [] }) {
      state.selectedHostsListings = payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchRentalsSummary.fulfilled, (state, { payload = {} }) => {
        const { summary } = payload
        state.summary = {
          isInitialized: true,
          ...summary,
        }
        state.isLoadingSummary = false
      })
      .addCase(fetchHostsWithMostListings.fulfilled, (state, { payload = {} }) => {
        if (_.isEqual(payload, {})) return
        const { aggregations } = payload
        state.hostsWithMostListings = {
          isInitialized: true,
          aggregations,
        }
      })
      .addCase(fetchListingsPerHostAggregations.fulfilled, (state, { payload = {} }) => {
        const { aggregations } = payload
        state.listingsPerHost = {
          isInitialized: true,
          aggregations,
        }
      })
      .addCase(fetchMapClusterAggregation.fulfilled, (state, { payload = {} }) => {
        if (_.isEqual(payload, {})) return
        const { points } = payload
        if (points && points.length > 0 && points[0].coordinates) {
          state.listingAggregations = points
          return
        }
        const normalizedPoints = points.map(({ count, location }) => ({
          count,
          coordinates: `${location.lat}, ${location.lon}`,
        }))
        state.listingAggregations = normalizedPoints
      })
      .addCase(fetchRentalsHosts.fulfilled, (state, { payload = {} }) => {
        if (_.isEqual(payload, {})) return
        const { isScrolling, totalHosts, hosts, hostsAllowScrolling } = payload
        state.hostsAllowScrolling = hostsAllowScrolling
        state.isLoadingInitial = false
        state.totalHosts = totalHosts
        state.hosts = isScrolling ? [...state.hosts, ...hosts] : hosts
        state.totalDisplayedHosts = state.hosts ? state.hosts.length : 0
      })
      .addCase(fetchEntitiesForExport.fulfilled, (state, { payload = {} }) => {
        if (Object.keys(payload).length === 0) return
        const { csv } = payload
        state.entitiesCSV = csv
      })
      .addCase(fetchHostListings.fulfilled, (state, { payload = {} }) => {
        const { listings, from } = payload
        state.selectedHostsListings = from === 0 ? listings : [...state.selectedHostsListings, ...listings]
      })
      .addCase(fetchLocationsHierarchy.fulfilled, (state, { payload = {} }) => {
        const { hostLocations, listingLocations } = payload
        state.hostLocations = hostLocations
        state.listingLocations = listingLocations
      })
  },
})

export const {
  setIsLoadingInitial,
  setIsLoadingSummary,
  setIsLoadingNextBatch,
  setIsLoadingHostListingsNextBatch,
  setSelectedHostsListings,
  setIsDisplayFilters,
  applyHostsFilters,
  applyListingsFilters,
  toggleSelectAllHosts,
  toggleSelectAllListings,
  toggleSelectedHost,
  toggleSelectedListing,
  setHostsDisplayedColumns,
  setListingsDisplayedColumns,
  setDisplayedHost,
  setEntitiesCSV,
  setDisplayedListing,
} = slice.actions

export const rentalsReducer = slice.reducer
export default slice
