import React, { FC } from 'react'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, ReferenceLine } from 'recharts'
import { FinancialBarChartData } from 'utils/types'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { formatNumber } from 'utils/formatters'

const fontFamily = 'Source Sans Pro'

interface GenericBarsChartProps {
  chartData: FinancialBarChartData[]
  xAxisDataKey: string
  barDataKey1: string
  barDataKey2: string
  stackedBarDataKey1: string
  barsRadius?: [number, number, number, number]
  labels?: { [index: string]: string }
}

const GenericBarsChart: FC<GenericBarsChartProps> = ({
  chartData,
  barDataKey1,
  barDataKey2,
  xAxisDataKey,
  barsRadius = [6, 6, 0, 0],
  stackedBarDataKey1,
  labels = {},
}) => {
  const theme = useTheme()

  const isXxxl = useMediaQuery(theme.breakpoints.up('xxxl'))

  const getTickFontSize = () => (isXxxl ? 38 : 14)
  const getBarSize = () => (isXxxl ? 26 : 16)

  const tickSize = getTickFontSize()
  const barSize = getBarSize()
  const barDataKeyToLabel = {
    reported: 'Reported',
    exact: 'Exact',
    documented: 'Documented',
    extrapolated: 'Extrapolated',
    estimated: 'Estimated',
    ...labels,
  }

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <BarChart data={chartData} barGap={4} barCategoryGap={0}>
        <CartesianGrid vertical={false} stroke='#87cefa' strokeWidth={0.2} strokeDasharray='1' />
        <XAxis
          dataKey={xAxisDataKey}
          tickLine={false}
          fontFamily={fontFamily}
          fontSize={tickSize}
          axisLine={{ stroke: 'transparent' }}
        />
        <ReferenceLine y={0} stroke='#b0b3b6' strokeWidth={2} />

        <Tooltip
          cursor={false}
          filterNull={true}
          contentStyle={{ fontFamily, textTransform: 'capitalize' }}
          formatter={(value: number) => formatNumber(value)}
        />
        <Bar
          dataKey={barDataKey1}
          fill={theme.palette.chartsTheme[barDataKey1]}
          radius={barsRadius}
          barSize={barSize}
          fontFamily={fontFamily}
          name={barDataKeyToLabel[barDataKey1] || barDataKey1}
        />
        <Bar
          dataKey={barDataKey2}
          fill={theme.palette.chartsTheme[barDataKey2]}
          barSize={barSize}
          fontFamily={fontFamily}
          stackId='a'
          name={barDataKeyToLabel[barDataKey2] || barDataKey2}
        />
        <Bar
          dataKey={stackedBarDataKey1}
          fill={theme.palette.chartsTheme[stackedBarDataKey1]}
          barSize={barSize}
          radius={barsRadius}
          fontFamily={fontFamily}
          stackId='a'
          name={barDataKeyToLabel[stackedBarDataKey1] || stackedBarDataKey1}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default GenericBarsChart
