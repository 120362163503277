import { createSelector } from 'reselect'
import { initialState } from './slice'

const selectBusinessState = state => state.business || initialState

export const selectDisplayedBusiness = createSelector(selectBusinessState, state => state.displayedBusiness)

export const selectCurrentBusiness = createSelector(selectBusinessState, state => state.currentBusiness)

export const selectIsLoadingNextBatch = createSelector(selectBusinessState, state => state.isLoadingNextBatch)

export const selectCurrentBusinessReviews = createSelector(selectBusinessState, state => state.currentBusinessReviews)

export const selectCurrentTab = createSelector(selectBusinessState, state => state.currentTab)

export const selectReviewsFilters = createSelector(selectBusinessState, state => state.reviewsFilters)

export const selectProjectsFilters = createSelector(selectBusinessState, state => state.projectsFilters)
