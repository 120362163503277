import { useWindowSize } from 'hooks/useWindowSize'
import { RefObject, useMemo } from 'react'

interface UseCalcContainerHeight {
  containerRef: RefObject<HTMLDivElement>
  childContainerRef: RefObject<HTMLDivElement>
  margins: number
  relevantData: any
  isLoading: boolean
}

export const useCalcContainerHeight = ({
  containerRef,
  childContainerRef,
  margins,
  relevantData,
  isLoading,
}: UseCalcContainerHeight) => {
  const { width, height } = useWindowSize()

  const calcedHeight = useMemo(() => {
    if (containerRef && childContainerRef) {
      const container = containerRef?.current?.getBoundingClientRect()
      const childContainer = childContainerRef?.current?.getBoundingClientRect()
      return (container?.height || 0) - (childContainer?.top || 0) + margins
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, childContainerRef, width, height, relevantData, isLoading])

  return { calcedHeight }
}
