const CommonStyles = {
  fontFamily: 'Source Sans Pro',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0px',
  textAlign: 'left',
}

export const LinkStyles = {
  ...CommonStyles,
  color: '#7E8BA6',
}

export const TypographyStyles = {
  ...CommonStyles,
  color: '#1C2842',
}
