import React, { ReactNode, useMemo } from 'react'
import { originalSourceToImageMap } from '../../../../components/BusinessSource/specs'
import { PLATFORMS, Associates } from 'store/verticals/types'
import Avatar from '@mui/material/Avatar'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import GenericCard from 'components/common/GenericCard'
import GenericCardHeader from 'components/common/GenericCardHeader'
import GenericHeadingLink from 'components/common/GenericHeadingLink'
import UserTooltipItem from 'components/VerticalCard/UserTooltipItem/UserTooltipItem'
import styles from './VerticalCard.scss'

const avatarIcon = require('containers/VerticalPage/nft/assets/avatar.png')

interface VerticalCardProps {
  platform: PLATFORMS
  profileName: string
  profileImage: string
  profileUrl: string
  children: ReactNode
  associates?: Associates[]
}

const VerticalCard = ({ profileName, profileUrl, platform, profileImage, associates, children }: VerticalCardProps) => {
  const theme = useTheme()
  const platformName = platform?.toLocaleLowerCase()

  const href = useMemo(() => {
    if (profileUrl) return profileUrl
    if (profileName) return `https://${profileName}`

    return ''
  }, [profileUrl, profileName])

  return (
    <GenericCard sx={{ height: '100%' }} className={styles.activityOverviewCard}>
      <GenericCardHeader
        sx={{
          bgcolor: theme.palette.platforms[platformName] || theme.palette.secondary.main,
        }}
        title={
          <Stack alignItems='center' justifyContent='space-between' flexDirection='row'>
            <Stack
              sx={{
                width: { md: '26px', lg: '24px', xl: '30px', xxl: '35px', xxxl: '45px' },
                height: { md: '26px', lg: '24px', xl: '30px', xxl: '35px', xxxl: '45px' },
              }}
            >
              {originalSourceToImageMap[platformName] && (
                <img className={styles.platformIcon} src={originalSourceToImageMap[platformName]} alt='Platform' />
              )}
            </Stack>
            {associates && (
              <Stack alignItems='center' flexDirection='row'>
                {associates.map(row => (
                  <UserTooltipItem key={row.id} data={row} />
                ))}
              </Stack>
            )}
          </Stack>
        }
      />
      <CardContent
        sx={{
          padding: { md: '8px', lg: '12px', xl: '12px', xxl: '20px', xxxl: '32px' },
          '&:last-child': {
            paddingBottom: { md: '8px', lg: '10px', xl: '12px', xxl: '20px', xxxl: '32px' },
          },
        }}
      >
        <Box position='relative'>
          <Stack
            sx={{
              transform: 'translateX(-50%)',
              position: 'absolute',
              top: { md: '-35px', lg: '-40px', xl: '-50px', xxl: '-55px', xxxl: '-78px' },
              left: '50%',
              alignItems: 'center',
              width: `calc(100% - ${theme.spacing(1)})`,
            }}
          >
            <Avatar
              sx={{
                width: { md: '45px', lg: '50px', xl: '60px', xxl: '75px', xxxl: '115px' },
                height: { md: '45px', lg: '50px', xl: '60px', xxl: '75px', xxxl: '115px' },
                border: '1px solid white',
              }}
            >
              <CardMedia component='img' image={profileImage || avatarIcon} alt='Avatar' />
            </Avatar>
            <GenericHeadingLink
              href={href}
              headingSx={{
                textTransform: 'capitalized',
                fontSize: { md: '16px', lg: '18px', xl: '22px', xxl: '28px', xxxl: '46px' },
                fontWeight: 600,
              }}
            >
              {profileName?.length >= 25 ? `${profileName?.slice(0, 25)}...` : profileName}
            </GenericHeadingLink>
          </Stack>
        </Box>
        {children}
      </CardContent>
    </GenericCard>
  )
}

export default VerticalCard
