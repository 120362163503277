import React from 'react'
import ConditionalWrap from 'conditional-wrap'

const ConditionalWrapper = ({ condition, wrapper, children }) => (
  <ConditionalWrap condition={condition} wrap={wrapper}>
    {children}
  </ConditionalWrap>
)

export default ConditionalWrapper
