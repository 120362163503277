export const dialog = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      minWidth: '661px',
    },
  },
}

export const title = {
  fontFamily: 'Dosis',
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '32px',
  textAlign: 'left',
  color: '#1c2842',
}

export const container = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}

export const titleContainer = {
  padding: '24px',
}

export const detailsContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '24px',
  width: '60%',
  paddingRight: '24px',
  borderRight: '1px solid #DAD8DF',
}

export const specialitiesContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '12px',
  width: '40%',
  paddingLeft: '24px',
  alignSelf: 'flex-start',
}

export const specialitiesChips = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '12px',
  width: '100%',
}

export const detailsRow = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}

export const detailsTitle = {
  fontFamily: 'Source Sans Pro',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  textAlign: 'left',
  color: '#7E8BA6',
}

export const detailsValue = {
  fontFamily: 'Dosis',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  textAlign: 'left',
  color: '#1C2842',
}

export const chips = {
  backgroundColor: '#E9F0FF',
  padding: '4px 0',
  borderRadius: '8px',
}

export const linkWrapper = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  '& .iconName > img': { width: '20px', height: '20px' },
}

const styles = {
  dialog,
  title,
  container,
  titleContainer,
  detailsContainer,
  specialitiesContainer,
  specialitiesChips,
  detailsRow,
  detailsTitle,
  detailsValue,
  chips,
  linkWrapper,
}

export default styles
