import React, { FC } from 'react'

import VerticalTableBase from 'components/verticals/VerticalTableBase/VerticalTableBase'
import { VerticalTableCMPBaseProps } from 'components/verticals/VerticalTableBase/types'

import { CompanyOverviewEntityForTable } from 'containers/CompanyOverviewTablePage/types'
import { useCompanyOverviewColumns } from 'components/CompanyOverview/useCompanyOverviewColumns'

const CompanyOverviewTable = ({
  verticalEntities,
  fetchNextPage,
  isFetching,
  totalDBRowCount,
  totalFetched,
  sorting,
  setSorting,
  setMergedColumns,
  selectedColumns,
  allColumns,
}: VerticalTableCMPBaseProps<CompanyOverviewEntityForTable>) => {
  const columns = useCompanyOverviewColumns()

  return (
    <VerticalTableBase<CompanyOverviewEntityForTable>
      columns={columns}
      avoidBaseColumns
      items={verticalEntities}
      fetchNextPage={fetchNextPage}
      isFetching={isFetching}
      totalFetched={totalFetched}
      totalDBRowCount={totalDBRowCount}
      sorting={sorting}
      setSorting={setSorting}
      setMergedColumns={setMergedColumns}
      selectedColumns={selectedColumns}
      allColumns={allColumns}
    />
  )
}

export default CompanyOverviewTable
