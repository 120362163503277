import React from 'react'
import { ISummary } from 'store/verticals/types'
import Tooltip from 'components/Tooltip'
import { NOT_AVAILABLE } from 'utils/constants'

export const summaryAccessor = (summary: ISummary[], keys: string[]) => {
  if (summary?.length === 0) return NOT_AVAILABLE

  const textDataArray = summary?.map(row =>
    keys
      .map(key => row[key])
      .filter(row => Boolean(row))
      .join(' | '),
  )

  if (!Array.isArray(textDataArray) || textDataArray.length === 0) return NOT_AVAILABLE

  if (textDataArray?.length === 1) return textDataArray[0]

  if (textDataArray?.length > 1) {
    return (
      <Tooltip
        html={
          <div>
            {textDataArray.map(text => (
              <React.Fragment key={text}>
                {text}
                <br />
              </React.Fragment>
            ))}
          </div>
        }
      >
        {`${textDataArray[0]} +${textDataArray.length - 1} more`}
      </Tooltip>
    )
  }
}
