// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EmployeesTab-module__mainStack--HvsxY h2{font-family:\"Dosis\";font-size:20px;font-weight:600;line-height:32px;text-align:left;color:#1c2842}.EmployeesTab-module__copyBox--_2N0A{display:flex;align-items:center;gap:4px;font-family:\"Source Sans Pro\";font-size:16px;font-weight:400;line-height:24px;text-align:left;color:#2257c5}.EmployeesTab-module__customMuiTooltip--e_IEt{background:#fff !important;color:#1c2842 !important;padding:12px !important;border:1px solid #dadbdf;border-radius:8px !important;max-width:400px !important;font-size:16px !important}.EmployeesTab-module__customMuiTooltip--e_IEt span::before{background-color:#fff;border:1px solid #dadbdf;box-sizing:border-box}", "",{"version":3,"sources":["webpack://./app/containers/VerticalPage/company-overview/EmployeesTab/EmployeesTab.module.scss"],"names":[],"mappings":"AACE,0CACE,mBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,eAAA,CACA,aAAA,CAIJ,qCACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,6BAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,eAAA,CACA,aAAA,CAGF,8CACE,0BAAA,CACA,wBAAA,CACA,uBAAA,CACA,wBAAA,CACA,4BAAA,CACA,0BAAA,CACA,yBAAA,CAGE,2DACE,qBAAA,CACA,wBAAA,CACA,qBAAA","sourcesContent":[".mainStack {\n  h2 {\n    font-family: 'Dosis';\n    font-size: 20px;\n    font-weight: 600;\n    line-height: 32px;\n    text-align: left;\n    color: #1c2842;\n  }\n}\n\n.copyBox {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  font-family: 'Source Sans Pro';\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  text-align: left;\n  color: #2257c5;\n}\n\n.customMuiTooltip {\n  background: white !important;\n  color: #1c2842 !important;\n  padding: 12px !important;\n  border: 1px solid #dadbdf;\n  border-radius: 8px !important;\n  max-width: 400px !important;\n  font-size: 16px !important;\n\n  span {\n    &::before {\n      background-color: white;\n      border: 1px solid #dadbdf;\n      box-sizing: border-box;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainStack": "EmployeesTab-module__mainStack--HvsxY",
	"copyBox": "EmployeesTab-module__copyBox--_2N0A",
	"customMuiTooltip": "EmployeesTab-module__customMuiTooltip--e_IEt"
};
export default ___CSS_LOADER_EXPORT___;
