import React, { FC } from 'react'
import CardHeader, { CardHeaderProps } from '@mui/material/CardHeader'

// Instead of passing specific MUI props, we use CardProps type for full access to all MUI CardHeader props
// No need for children in this case - passing the desired content to the title prop is enough
const GenericCardHeader: FC<CardHeaderProps> = ({ sx, ...genericCardHeaderProps }) => {
  return (
    <CardHeader
      sx={{
        height: { md: '35px', lg: '40px', xl: '50px', xxl: '55px', xxxl: '85px' },
        ...sx,
      }}
      {...genericCardHeaderProps}
    />
  )
}

export default GenericCardHeader
