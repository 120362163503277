import React, { useState } from 'react'
import Icon from 'components/Icon'
import styles from './SearchBar.module.scss'
import Button from 'components/Button'

interface ISearchBar {
  searchValue: string
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
}

const SearchBar = ({ searchValue, setSearchValue }: ISearchBar) => {
  const [isInputVisible, setIsInputVisible] = useState(false)

  const handleIconClick = () => {
    setIsInputVisible(!isInputVisible)
  }

  return (
    <div className={styles.container}>
      <Button onClick={handleIconClick} className={styles.searchButton}>
        <Icon size='standard' name='searchLarge' />
      </Button>
      {isInputVisible && (
        <input
          type='text'
          placeholder='Search'
          className={styles.input}
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        />
      )}
    </div>
  )
}

export default SearchBar
