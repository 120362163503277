export const allResolverTypes = ['gig', 'ecommerce', 'nexus', 'other']
export const realResolverTypes = allResolverTypes.filter(resolver => resolver !== 'other')

export const resolverNameToIcon = {
  nexus: 'placePin',
  ecommerce: 'cart',
  gig: 'economy',
  other: 'bell',
}

export const allEaseOfCollectionOptions = ['a', 'b', 'c', 'd']

export const untaxedIncomeLimits = {
  min: 0,
  max: 999000,
}

export const jurisdictionLimits = {
  min: 0,
  max: 1000,
}

export const documentedIncomeLimits = {
  min: 0,
  max: 999000,
}

export const taxGapLimits = {
  min: 0,
  max: 300000,
}

class BusinessesService {
  sumIncomeByKey(businesses = [], key = 'estimatedIncome') {
    return businesses.reduce((acc, business) => acc + business[key], 0)
  }

  resolverCount(businesses = [], resolverKey = 'nexus') {
    return businesses.filter(({ resolvers }) => resolvers.includes(resolverKey)).length
  }
}

export default new BusinessesService()
