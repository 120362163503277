import { Filter, FilterTypes } from 'components/FilterDrawer/FilterDrawer.config'

export const filtersInitialState: Filter[] = [
  { name: 'experiences.job_department', label: 'Departments', type: FilterTypes.CHIPS, value: [] },
  { name: 'experiences.date_from', label: 'Start Date', type: FilterTypes.DATE_BIT, value: [] },
  { name: 'experiences.date_to', label: 'End Date', type: FilterTypes.DATE_BIT, value: [] },
  { name: 'experiences.status', label: 'Employee Status', type: FilterTypes.CHIPS, value: [] },
  {
    name: 'experiences.is_executive_role',
    label: 'Executives Only',
    activeFilterLabel: 'Executives Only',
    type: FilterTypes.BOOL,
    value: [],
  },
]
