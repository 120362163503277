import React, { FC } from 'react'
import Typography, { TypographyProps } from '@mui/material/Typography'

// Instead of passing specific MUI props, we use MuiCardProps type for full access to all MUI Typography props
const GenericHeading: FC<TypographyProps> = ({ children, variant = 'h6', sx, ...genericHeadingProps }) => {
  return (
    <Typography variant={variant} sx={{ fontFamily: 'Dosis', ...sx }} {...genericHeadingProps}>
      {children}
    </Typography>
  )
}

export default GenericHeading
