import { createSelector } from 'reselect'
import { initialState } from './slice'

const selectNFTState = state => state.nft || initialState

export const selectCurrentNFTEntity = createSelector(selectNFTState, state => state.currentNFTEntity)

export const selectCurrentPeriod = createSelector(selectNFTState, state => state.periodFilter)

export const selectCurrentOverviewForPeriod = createSelector(selectNFTState, state => state.currentOverviewForPeriod)

export const selectCurrentActivity = createSelector(selectNFTState, state => state.currentActivity)

export const selectIsLoadingInitialActivities = createSelector(
  selectNFTState,
  state => state.isLoadingInitialActivities,
)

export const selectIsLoadingPeriodOverview = createSelector(selectNFTState, state => state.isLoadingPeriodOverview)

export const selectIsLoadingNextBatch = createSelector(selectNFTState, state => state.isLoadingNextBatch)

export const selectActivityFilters = createSelector(selectNFTState, state => state.activityFilter)

export const selectNextBatchStartingIndex = createSelector(selectNFTState, state => state.nextBatchStartingIndex)

export const selectActivityCSV = createSelector(selectNFTState, state => state.activityCSV)

export const selectEntityCSV = createSelector(selectNFTState, state => state.entityCSV)

export const selectCurrentGrossForPeriod = createSelector(selectNFTState, state => state.currentGrossForPeriod)

export const selectIsMapOpen = createSelector(selectNFTState, state => state.isMapOpen)
