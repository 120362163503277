import { ChangeEvent } from 'react'
import { Option } from 'react-multi-select-component/dist/lib/interfaces'

export enum SELECT_ALL {
  KEY = 'selectAll',
  VALUE = 'Select All',
}

export type TChangeEvent = ChangeEvent<HTMLInputElement>

export type THandleOptionClick = (e: TChangeEvent, clickedOption: Option, onClick: (e: TChangeEvent) => void) => void
