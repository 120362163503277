import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePermissions } from 'hooks/usePermissions'
import localStorageService from 'services/storage'
import Input from 'components/Input'
import { selectCurrentUser, selectCurrentZone, selectAvailableZones, setCurrentZone } from 'store/global'
import styles from './ZoneSelect.scss'

export default function ZoneSelect() {
  const dispatch = useDispatch()
  const permissions = usePermissions()

  const currentUser = useSelector(selectCurrentUser)
  const currentZone = useSelector(selectCurrentZone)
  const availableZones = useSelector(selectAvailableZones)

  const onZoneSelect = zoneId => {
    const nextZoneId = parseInt(zoneId)
    const nextZone = availableZones.find(({ id }) => id === nextZoneId)

    dispatch(setCurrentZone({ zone: nextZone }))

    // if (permissions.organizationAdmin) {
    localStorageService.setLastSelectedZoneForUser(currentUser, nextZone)
    // }
  }

  return (
    <div className={styles.container}>
      <Input type='select' value={currentZone?.id} onChange={onZoneSelect}>
        {availableZones.map(zone => (
          <option key={zone.id} value={zone.id}>
            {zone.name}
          </option>
        ))}
      </Input>
    </div>
  )
}
