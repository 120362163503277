import { defineMessages } from 'react-intl'

const scope = 'app.containers.Rentals'

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Short Term Rentals',
  },
  filters: {
    id: `${scope}.filters`,
    defaultMessage: 'Filters',
  },
  sections: {
    hosts: {
      id: `${scope}.sections.hosts`,
      defaultMessage: 'Host',
    },
    listings: {
      id: `${scope}.sections.listings`,
      defaultMessage: 'Listing',
    },
  },
  analytics: {
    header: {
      id: `${scope}.analytics.header`,
      defaultMessage: '{type} Analytics',
    },
    actions: {
      collapse: {
        id: `${scope}.actions.collapse`,
        defaultMessage: 'Collapse',
      },
      expand: {
        id: `${scope}.actions.expand`,
        defaultMessage: 'Expand',
      },
    },
  },
  grid: {
    header: {
      id: `${scope}.grid.header`,
      defaultMessage: '{type} Details',
    },
  },
})
