import React, { useEffect } from 'react'
import Stack from '@mui/material/Stack'
import { DetailedChartLegend } from 'components/common/types'
import GenericText from 'components/common/GenericText'
import { NOT_AVAILABLE } from 'utils/constants'
import Box from '@mui/material/Box'
import {
  DetailedLegendContainerStyles,
  DetailedLegendItemStyles,
  LegendCircleStyles,
  MoreResultContainerStyles,
  MoreResultLegendStyles,
  DetailedChartLegendTextStyles,
} from 'components/common/styles'

interface DetailedChartLegendsProps {
  legendsData: DetailedChartLegend[]
  moreResultsCount: string | null
}

const LegendCircle = ({ color }: { color: string }) => (
  <Box
    sx={{
      ...LegendCircleStyles,
      bgcolor: color,
    }}
  ></Box>
)

const LegendText = ({ name }: { name: string }) => (
  <GenericText sx={DetailedChartLegendTextStyles}>{`${name || NOT_AVAILABLE},`}</GenericText>
)

const LegendPercentage = ({ percent }: { percent: string }) => (
  <GenericText sx={{ fontSize: '1em', wordBreak: 'keep-all' }}>{percent || NOT_AVAILABLE}</GenericText>
)

const MoreResultLegend = ({ moreResultsDetails }: { moreResultsDetails: string }) => (
  <Stack sx={MoreResultContainerStyles}>
    <GenericText sx={MoreResultLegendStyles}>{`${moreResultsDetails}`}</GenericText>
  </Stack>
)

const DetailedChartLegends = ({ legendsData, moreResultsCount }: DetailedChartLegendsProps) => (
  <Box sx={DetailedLegendContainerStyles}>
    {legendsData.map(({ name, percent, color }) => (
      <Stack sx={DetailedLegendItemStyles} key={name}>
        <LegendCircle color={color} />
        <LegendText name={name} />
        <LegendPercentage percent={percent} />
      </Stack>
    ))}
    {moreResultsCount && <MoreResultLegend moreResultsDetails={moreResultsCount} />}
  </Box>
)

export default DetailedChartLegends
