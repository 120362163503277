import React, { FC } from 'react'
import Stack from '@mui/material/Stack'

const DashedLine: FC = () => (
  <Stack
    sx={{
      flex: 1,
      borderBlockEnd: '1px dashed #7f8897',
      margin: '0 1rem',
      alignSelf: 'center',
    }}
  />
)

export default DashedLine
