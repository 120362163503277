import { createSelector } from 'reselect'
import { initialState } from './slice'

const selectRentalsState = state => state.rentals || initialState

export const selectIsLoadingSummary = createSelector(selectRentalsState, state => state.isLoadingSummary)

export const selectRentalsSummary = createSelector(selectRentalsState, state => state.summary)

export const selectIsLoadingInitial = createSelector(selectRentalsState, state => state.isLoadingInitial)

export const selectIsLoadingNextBatch = createSelector(selectRentalsState, state => state.isLoadingNextBatch)

export const selectIsLoadingHostListingsNextBatch = createSelector(
  selectRentalsState,
  state => state.isLoadingHostListingsNextBatch,
)

export const selectDisplayedHostListings = createSelector(selectRentalsState, state => state.selectedHostsListings)

export const selectHostsAllowScrolling = createSelector(selectRentalsState, state => state.hostsAllowScrolling)

export const selectHostNames = createSelector(selectRentalsState, state => state.hostNames)

export const selectEntitiesCSV = createSelector(selectRentalsState, state => state.entitiesCSV)

export const selectIsDisplayFilters = createSelector(selectRentalsState, state => state.displayFilters)

export const selectListingsPerHost = createSelector(
  selectRentalsState,
  state => (state.listingsPerHost || {}).aggregations,
)

export const selectHostsWithMostListings = createSelector(
  selectRentalsState,
  state => (state.hostsWithMostListings || {}).aggregations,
)

export const selectListingLocations = createSelector(selectRentalsState, state => state.listingLocations)

// --- Hosts ---

export const selectHostsFilters = createSelector(selectRentalsState, state => state.hostsFilters)

export const selectTotalHosts = createSelector(selectRentalsState, state => state.totalHosts)

export const selectHosts = createSelector(selectRentalsState, state => state.hosts)

export const selectTotalDisplayedHosts = createSelector(selectRentalsState, state => state.totalDisplayedHosts)

export const selectSelectedHostsIds = createSelector(selectRentalsState, state => state.selectedHostsIds)

export const selectHostsDisplayedColumns = createSelector(selectRentalsState, state => state.hostsDisplayedColumns)

export const selectDisplayedHost = createSelector(selectRentalsState, state => state.displayedHost)

export const selectPointsClusters = createSelector(selectRentalsState, state => state.listingAggregations)

export const selectHostLocations = createSelector(selectRentalsState, state => state.hostLocations)
