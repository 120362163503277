import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { green, red } from '@mui/material/colors'
import Circle from '@mui/icons-material/Circle'
import Icon from 'components/Icon'
import styles from './EmployeesTab.module.scss'
import Tooltip from '@mui/material/Tooltip'

interface Item {
  experiences?: {
    name: string
    jobLocation: string
    jobRole: string
    jobDepartment: string
    dateFrom: string
    dateTo: string
    jobDesc: string
    currentJob: boolean
    companyUrl: string
    isExecutiveRole: boolean
    canonicalUrl: string
    status: string
  }[]
  name: string
  jobLocation: string
  jobRole: string
  jobDepartment: string
  dateFrom: string
  dateTo: string
  jobDesc: string
  currentJob: boolean
  companyUrl: string
  isExecutiveRole: boolean
  status: string
  canonicalUrl: string
}

interface CopyBoxProps {
  text: string
}

const CopyBox = ({ text }: CopyBoxProps) => {
  const [isLinkCopied, setIsLinkCopied] = useState(false)

  useEffect(() => {
    if (isLinkCopied) {
      const timer = setTimeout(() => {
        setIsLinkCopied(false)
      }, 3000)

      return () => clearTimeout(timer)
    }
  }, [isLinkCopied])

  const handleCopy = () => {
    navigator.clipboard.writeText(text).then(
      () => {
        setIsLinkCopied(true)
      },
      err => {
        console.error('Could not copy text: ', err)
      },
    )
  }

  return (
    <Box className={styles.copyBox} sx={{ display: 'flex', alignItems: 'center', gap: 1 }} onClick={handleCopy}>
      {isLinkCopied ? (
        <>
          <Icon name='done' size='regular' />
          <Typography color='#7E8BA6'>Link copied</Typography>
        </>
      ) : (
        <>
          <Icon name='copy' size='regular' />
          <Typography>Copy Link</Typography>
        </>
      )}
    </Box>
  )
}

const DescriptionCell = ({ description }: { description: string }) => {
  const [showFullDescription, setShowFullDescription] = useState(false)

  if (!description || description.length <= 56) {
    return <Typography>{description}</Typography>
  }

  return (
    <Box>
      <Typography>{showFullDescription ? description : `${description.substring(0, 56)}...`}</Typography>
      <Button onClick={() => setShowFullDescription(!showFullDescription)} sx={{ textTransform: 'none' }}>
        {showFullDescription ? 'View less' : 'View more'}
      </Button>
    </Box>
  )
}

const formatDate = (timestamp: number) => {
  const date = new Date(timestamp)
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const month = monthNames[date.getMonth()]
  const year = date.getFullYear()
  return `${month} ${year}`
}

const calculatePeriod = (startTimestamp: number, endTimestamp: number) => {
  const startDate = new Date(startTimestamp)
  const endDate = new Date(endTimestamp)

  const diffMilliseconds = endDate.getTime() - startDate.getTime()
  const diffMonths = diffMilliseconds / (1000 * 60 * 60 * 24 * 30.44)

  const years = Math.floor(diffMonths / 12)
  const months = Math.floor(diffMonths % 12)

  let period = ''
  if (years > 0) {
    period += `${years} yr `
  }
  if (months > 0) {
    period += `${months} mos`
  }

  return period.trim()
}

const useEmployeesColumns = () => {
  const hasExperiences = (item: Item) => {
    return item.experiences !== undefined && item.experiences.length > 0
  }

  return [
    {
      id: 'Name',
      header: 'Name',
      grow: 1,
      accessorFn: (item: Item) => {
        let name = hasExperiences(item) ? item.experiences![0].name : item.name
        let icon = null
        let tooltipTitle = ''

        if (hasExperiences(item)) {
          const isExecutive = item.experiences!.some(experience => experience.isExecutiveRole)
          if (isExecutive) {
            icon = <Icon name='businessman' size='regular' />
            tooltipTitle = 'Executive Employee'
          }
        }

        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography>{name}</Typography>
            {icon && (
              <Tooltip title={tooltipTitle} classes={{ tooltip: styles.customMuiTooltip }} arrow>
                <Box>{icon}</Box>
              </Tooltip>
            )}
          </Box>
        )
      },
      muiTableHeadCellProps: {
        sx: {
          color: '#7E8BA6',
          borderBottom: 'none',
        },
      },
      muiTableBodyCellProps: {
        sx: {},
      },
    },
    {
      id: 'Location',
      header: 'Location',
      grow: 1,
      accessorFn: (item: Item) => {
        const field = hasExperiences(item) ? item.experiences![0].jobLocation : item.jobLocation
        return field
      },
      muiTableHeadCellProps: {
        sx: {
          color: '#7E8BA6',
          borderBottom: 'none',
        },
      },
      muiTableBodyCellProps: {
        sx: {
          borderInlineEnd: '1px solid #DFDFDF',
        },
      },
    },
    {
      id: 'JobDepartment',
      header: 'Department',
      grow: 1,
      accessorFn: (item: Item) => {
        const field = hasExperiences(item) ? item.experiences![0].jobDepartment : item.jobDepartment
        return field
      },
      muiTableHeadCellProps: {
        sx: {
          color: '#7E8BA6',
          borderBottom: 'none',
        },
      },
      muiTableBodyCellProps: {
        sx: {
          borderInlineEnd: '1px solid #DFDFDF',
        },
      },
    },
    {
      id: 'JobRole',
      header: 'Job Role',
      grow: 1,
      accessorFn: (item: Item) => {
        const field = hasExperiences(item) ? item.experiences![0].jobRole : item.jobRole
        return field
      },
      muiTableHeadCellProps: {
        sx: {
          color: '#7E8BA6',
          borderBottom: 'none',
        },
      },
      muiTableBodyCellProps: {
        sx: {
          borderInlineEnd: '1px solid #DFDFDF',
        },
      },
    },
    {
      id: 'DateFrom',
      header: 'Start Date',
      grow: 1,
      accessorFn: (item: Item) => {
        const field = hasExperiences(item) ? item.experiences![0].dateFrom : item.dateFrom
        return formatDate(Number(field))
      },
      muiTableHeadCellProps: {
        sx: {
          color: '#7E8BA6',
          borderBottom: 'none',
        },
      },
      muiTableBodyCellProps: {
        sx: {
          borderInlineEnd: '1px solid #DFDFDF',
        },
      },
    },
    {
      id: 'DateTo',
      header: 'End Date',
      grow: 1,
      accessorFn: (item: Item) => {
        const field = hasExperiences(item) ? item.experiences![0].dateTo : item.dateTo
        return field === null ? 'Present' : formatDate(Number(field))
      },
      muiTableHeadCellProps: {
        sx: {
          color: '#7E8BA6',
          borderBottom: 'none',
        },
      },
      muiTableBodyCellProps: {
        sx: {
          borderInlineEnd: '1px solid #DFDFDF',
        },
      },
    },
    {
      id: 'Period',
      header: 'Period',
      grow: 1,
      accessorFn: (item: Item) => {
        const field = hasExperiences(item)
          ? calculatePeriod(Number(item.experiences![0].dateFrom), Number(item.experiences![0].dateTo))
          : calculatePeriod(Number(item.dateFrom), Number(item.dateTo))
        return field
      },
      muiTableHeadCellProps: {
        sx: {
          color: '#7E8BA6',
          borderBottom: 'none',
        },
      },
      muiTableBodyCellProps: {
        sx: {
          borderInlineEnd: '1px solid #DFDFDF',
        },
      },
    },
    {
      id: 'JobDesc',
      header: 'Description',
      grow: 1,
      accessorFn: (item: Item) => {
        const field = hasExperiences(item) ? item.experiences![0].jobDesc : item.jobDesc
        return <DescriptionCell description={field} />
      },
      muiTableHeadCellProps: {
        sx: {
          color: '#7E8BA6',
          borderBottom: 'none',
        },
      },
      muiTableBodyCellProps: {
        sx: {
          borderInlineEnd: '1px solid #DFDFDF',
        },
      },
    },
    {
      id: 'Status',
      header: 'Status',
      grow: 1,
      accessorFn: (item: Item) => {
        const field = hasExperiences(item) ? item.experiences![0].status : item.status
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Circle sx={{ color: field === 'Active' ? green[500] : red[500], mr: 1, fontSize: 10 }} />
            <Typography>{field}</Typography>
          </Box>
        )
      },
      muiTableHeadCellProps: {
        sx: {
          color: '#7E8BA6',
          borderBottom: 'none',
        },
      },
      muiTableBodyCellProps: {
        sx: {
          borderInlineEnd: '1px solid #DFDFDF',
        },
      },
    },
    {
      id: 'Canonial Url',
      header: 'LinkedIn Profile URL',
      grow: 1,
      accessorFn: (item: Item) => {
        const field = hasExperiences(item) ? item.experiences![0]?.canonicalUrl : item.companyUrl
        return <CopyBox text={field} />
      },
      muiTableHeadCellProps: {
        sx: {
          color: '#7E8BA6',
          borderBottom: 'none',
        },
      },
      muiTableBodyCellProps: {
        sx: {
          borderInlineEnd: '1px solid #DFDFDF',
        },
      },
    },
  ]
}

export default useEmployeesColumns
