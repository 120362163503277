import { Period } from 'utils/types'
import { VerticalEntity } from 'store/verticals/types'
import { getYearFromPeriod, numberIsBetween } from 'containers/VerticalPage/common/utils'

const gmapsIconUrl = require('../icons/gmaps.svg')
const forsquareIconUrl = require('../icons/forsquare.svg')
const weedmapsIconUrl = require('../icons/weedmaps.svg')
const yelpIconUrl = require('../icons/yelp.svg')
const tripAdvisorIconUrl = require('../icons/tripadvisor.svg')
const openTableIconUrl = require('../icons/opentable.svg')
const barIconUrl = require('../icons/bar.svg')
const instagramIconUrl = require('../icons/instagram.svg')
const facebookIconUrl = require('../icons/facebook.svg')
const websiteIconUrl = require('../icons/website.svg')
const linkedInIconUrl = require('components/BusinessSource/assets/original/linkedin.svg')
const twitterIconUrl = require('components/BusinessSource/assets/original/twitter.svg')

export interface TaxData {
  date: string
  totalTaxesPaidByCDTFA: number
  totalTaxesPaidByIvix: number
  totalSalesTaxesDue: number
}

export enum BarChartType {
  ANNUALLY,
  QUARTERLY,
}

export const tableHeaders = [
  'Website',
  'Business Name',
  'Main Business Category',
  'Other Business Category',
  'Attributes',
  'Price',
  'Reviews',
  'First web activity',
  'Last web activity',
]

export const businessTypeUrls = {
  YELP_BUSINESS: yelpIconUrl,
  FOURSQUARE_BUSINESS: forsquareIconUrl,
  OPENTABLE: openTableIconUrl,
  TRIPADVISOR_BUSINESS: tripAdvisorIconUrl,
  GMAPS: gmapsIconUrl,
  WEBSITE: websiteIconUrl,
  BAR_BUSINESS: barIconUrl,
  INSTAGRAM: instagramIconUrl,
  FACEBOOK: facebookIconUrl,
  WEEDMAPS_BUSINESS: weedmapsIconUrl,
  LINKEDIN: linkedInIconUrl,
  TWITTER: twitterIconUrl,
}

export const businessTypeLabels = {
  YELP_BUSINESS: 'Yelp',
  FOURSQUARE_BUSINESS: 'Forsquare',
  OPENTABLE: 'Opentable',
  TRIPADVISOR_BUSINESS: 'Tradivisor',
  GMAPS: 'Google Maps',
  WEBSITE: 'Website',
  BAR_BUSINESS: 'BAR',
  INSTAGRAM: 'Instagram',
  FACEBOOK: 'Facebook',
  WEEDMAPS_BUSINESS: 'Weedmaps',
  LINKEDIN: 'LinkedIn',
  TWITTER: 'Twitter',
}

const parseQuarterDate = (dateString: string) => {
  const year = parseInt(dateString.substring(0, 4))
  const quarter = dateString.substring(4)

  return { year, quarter }
}

export const getTotalRevenueFromEntityQuarterly = (entityData: VerticalEntity | null, period?: Period): TaxData[] => {
  if (!entityData) return []

  let startYear = -Infinity
  let endYear = Infinity

  if (period) {
    const selectedPeriod = getYearFromPeriod(period)
    startYear = selectedPeriod.startYear
    endYear = selectedPeriod.endYear
  }

  const data = {
    quarterlySalesTaxesDue: entityData?.totals?.quarterlySalesTaxesDue || {},
    quarterlyAdjustedTaxCdtfa: entityData?.totals?.quarterlyAdjustedTaxCdtfa || {},
    quarterlyAdjustedTaxIvix: entityData?.totals?.quarterlyAdjustedTaxIvix || {},
  }
  const years = new Set<string>()

  for (const category in data) {
    Object.keys(data[category]).forEach(year => years.add(year))
  }

  const result = Array.from(years)
    .sort()
    .map(year => {
      const yearDataObject: TaxData = {
        date: year,
        totalSalesTaxesDue: data?.quarterlySalesTaxesDue?.[year] || 0,
        totalTaxesPaidByCDTFA: data?.quarterlyAdjustedTaxCdtfa?.[year] || 0,
        totalTaxesPaidByIvix: data?.quarterlyAdjustedTaxIvix?.[year] || 0,
      }

      return yearDataObject
    })
    .filter(item => numberIsBetween(parseQuarterDate(item.date).year, startYear, endYear))
    .map(item => {
      const parseQuarterDateValue = parseQuarterDate(item.date)

      return {
        ...item,
        date: `Q${parseQuarterDateValue.quarter?.replace(/^0+/, '')} ${parseQuarterDateValue.year}`,
      }
    })

  return result
}

export const convertQuarterlyRevenueToYearly = (data: TaxData[]) => {
  const dataWithYears = data.map(item => ({
    ...item,
    date: item.date.split(' ')[1],
  }))

  const combinedArray: TaxData[] = []
  dataWithYears.forEach(item => {
    const combinedArrayIndex = combinedArray.findIndex(el => el.date === item.date)

    if (combinedArrayIndex !== -1) {
      combinedArray[combinedArrayIndex] = {
        date: combinedArray[combinedArrayIndex].date,
        totalTaxesPaidByCDTFA: combinedArray[combinedArrayIndex].totalTaxesPaidByCDTFA + item.totalTaxesPaidByCDTFA,
        totalTaxesPaidByIvix: combinedArray[combinedArrayIndex].totalTaxesPaidByIvix + item.totalTaxesPaidByIvix,
        totalSalesTaxesDue: combinedArray[combinedArrayIndex].totalSalesTaxesDue + item.totalSalesTaxesDue,
      }
    } else {
      combinedArray.push(item)
    }
  })

  return combinedArray
}

export const barChartDataKeys = {
  totalTaxesPaidByCDTFA: 'totalTaxesPaidByCDTFA',
  totalTaxesPaidByIvix: 'totalTaxesPaidByIvix',
  totalSalesTaxesDue: 'totalSalesTaxesDue',
}

export const barChartData = [
  { key: barChartDataKeys.totalSalesTaxesDue, label: 'Total Tax Due', color: '#7E8BA6' },
  { key: barChartDataKeys.totalTaxesPaidByIvix, label: 'IVIX Adjusted Tax', color: '#2257C5' },
]

export const findMinMaxValues = (data: TaxData[]): { min: number; max: number } => {
  let min = Infinity
  let max = -Infinity

  data.forEach(entry => {
    min = Math.min(min, entry.totalSalesTaxesDue, entry.totalTaxesPaidByIvix)
    max = Math.max(max, entry.totalSalesTaxesDue, entry.totalTaxesPaidByIvix)
  })

  return { min, max }
}

export const getDividerValueByBarChartType = (barChartType: BarChartType) =>
  barChartType === BarChartType.ANNUALLY ? 10 : 3

export const getPossibleDates = (rangeYears: number[], barChartType: BarChartType) => {
  if (rangeYears.length === 0) {
    return {
      start: {
        min: null,
        max: null,
      },
      end: {
        min: null,
        max: null,
      },
    }
  }

  const divider = getDividerValueByBarChartType(barChartType)
  const dates = {
    start: {
      min: new Date(`${Math.min(...rangeYears)}`),
      max: new Date(`${Math.max(...rangeYears) - divider + 1}`),
    },
    end: {
      min: new Date(`${Math.min(...rangeYears) + divider - 1}`),
      max: new Date(`${Math.max(...rangeYears)}`),
    },
  }

  return dates
}
