import React, { cloneElement, useState, useCallback, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { VerticalQueryParams, IApiZones } from './types'
import { fetchApiZones } from 'services/verticalsApi'
import { getApiZonesByKey } from './utils'

interface IContextWrapper {
  children: React.ReactNode
}

interface ChildProps {
  apiZones?: string
}

const ContextWrapper = ({ children }: IContextWrapper): JSX.Element => {
  const query = new URLSearchParams(useLocation().search)
  const source = query.get('source')
  const apiZoneKey = query.get('zoneKey')

  const [apiZones, setApiZones] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchData = useCallback(async () => {
    if (source === VerticalQueryParams.source) {
      setIsLoading(true)
      const apiZones: IApiZones = await fetchApiZones()
      const zones = getApiZonesByKey(apiZones, apiZoneKey)
      setApiZones(zones || [])
      setIsLoading(false)
    }
  }, [apiZoneKey, source])

  const renderChildrenWithProps = useCallback(
    (child: React.ReactNode) => {
      if (React.isValidElement<ChildProps>(child)) {
        return cloneElement(child, { apiZones: apiZones.join(',') })
      }
      return child
    },
    [apiZones],
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const renderComponent = useMemo(() => {
    if (source === VerticalQueryParams.source) {
      if (isLoading) return <></>
      return <>{React.Children.map(children, renderChildrenWithProps)}</>
    }

    return <>{children}</>
  }, [children, isLoading, renderChildrenWithProps, source])

  return <>{renderComponent}</>
}

export default ContextWrapper
