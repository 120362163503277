import { useEffect, useState } from 'react'
import { fetchSvgContent } from 'services/verticalsApi'

const useFetchSvg = (url: string | undefined) => {
  const [svgBase64, setSvgBase64] = useState<string | null>(null)

  useEffect(() => {
    const fetchSvg = async () => {
      if (url) {
        try {
          const response = await fetchSvgContent(url)
          const encodedResponse = encodeURIComponent(response)
          const base64 = btoa(decodeURIComponent(encodedResponse))
          setSvgBase64(`data:image/svg+xml;base64,${base64}`)
        } catch (error) {
          console.error('Error fetching SVG:', error)
        }
      }
    }

    fetchSvg()
  }, [url])

  return svgBase64
}

export default useFetchSvg
