import { useSelector } from 'react-redux'
import { selectAvailableZones } from 'store/global'

const SINGLE_BUSINESS_PATHNAME = 'singleBusiness'
const NFT_ENTITY_PATHNAME = '/nft/'
const INFLUENCERS_PATHNAME = '/vertical/influencers'

const forbiddenPathnames = [SINGLE_BUSINESS_PATHNAME, NFT_ENTITY_PATHNAME, INFLUENCERS_PATHNAME]

export const shouldDisplaySettings = (user, pathname) => {
  const isForbiddenPathname = forbiddenPathnames.some(path => pathname.includes(path))
  const availableZones = useSelector(selectAvailableZones)
  const allowZoneSelection = availableZones.length > 0
  return !isForbiddenPathname && allowZoneSelection
}
