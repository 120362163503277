import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { LineChartDatum } from 'components/common/types'
import { FetchEOGraphDataProps } from 'containers/VerticalPage/company-overview/OverviewTab/types'
import { fetchEmployeesOverviewChartData } from 'services/verticalsApi'

interface UseOverviewTabQueryProps {
  currentQueryKey: string
  payload: FetchEOGraphDataProps
}

export const useOverviewTabQuery = ({
  currentQueryKey,
  payload,
}: UseOverviewTabQueryProps): UseQueryResult<LineChartDatum[]> =>
  useQuery({
    queryKey: [currentQueryKey, payload.zoneEndpoint, payload.filters, payload.localCountry],
    queryFn: () => (payload?.zoneEndpoint && payload?.localCountry ? fetchEmployeesOverviewChartData(payload) : null),
    refetchOnWindowFocus: false,
  })
