import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Form, FormGroup, Label } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import Input from 'components/Input'
import Button from 'components/Button'
import messages from './messages'
import styles from './LoginForm.scss'

export default function LoginForm(props) {
  const { onSubmit, className } = props

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const onSubmitForm = e => {
    e.preventDefault()

    setIsSubmitted(true)
    onSubmit({ email: email.toLowerCase(), password })
  }

  const invalidEmail = isSubmitted && !email.length
  const invalidPassword = isSubmitted && !password.length

  return (
    <Form onSubmit={onSubmitForm} className={cx(styles.form, className)}>
      <FormGroup>
        <Label for='email' className={styles.label}>
          <FormattedMessage {...messages.email} />
        </Label>
        <Input id='email' name='email' value={email} onChange={value => setEmail(value)} invalid={invalidEmail} />
      </FormGroup>
      <FormGroup>
        <Label for='password' className={styles.label}>
          <FormattedMessage {...messages.password} />
        </Label>
        <Input
          id='password'
          name='password'
          type='password'
          value={password}
          onChange={value => setPassword(value)}
          invalid={invalidPassword}
        />
      </FormGroup>
      <FormGroup className={styles.submit}>
        <Button type='submit' color='primary' outline disabled={invalidEmail || invalidPassword}>
          <FormattedMessage {...messages.submit} />
        </Button>
      </FormGroup>
    </Form>
  )
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
}
