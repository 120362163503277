import { getAbbreviatedNumberString } from 'components/Income/Income'

export interface PieChartData {
  key: string
  color: string
  label: string
  value: number | string
  uiValue?: string
  tooltipLabel?: string
  fullLabel?: string
}

export interface IData {
  title: string
  pieTitle: string
  pieSubTitle: string
  data: PieChartData[]
  pieDy?: number
}

export interface DistributionEntity {
  key: string
  count: number
  percentage: number
}

export interface IOffshoreOverviewData {
  total: number
  identified: number
  unidentified: number
  identifiedPercentage: number
  unidentifiedPercentage: number
  officeDistributionData: DistributionEntity[]
}

export interface IBusinessSummary {
  overviewData?: IOffshoreOverviewData | null
}

const COLORS: string[] = ['#FF6161', '#E200E7', '#1DBFBF', '#DDAAFF', '#0078E7', '#2157C5', '#04CF85', '#568EFF']

export const stringToColor = (str: string): string => {
  let hashCode = 0
  for (let i = 0; i < str.length; i++) {
    hashCode = str.charCodeAt(i) + ((hashCode << 5) - hashCode)
  }

  let color = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hashCode >> (i * 8)) & 0xff
    color += ('00' + value.toString(16)).substr(-2)
  }

  return color
}

export const getSummaryData = (data?: IOffshoreOverviewData | null): IData[] => {
  const identifiedValue = data?.identified !== undefined ? data.identified : 'N/A'
  const identifiedUiValue = data?.identified !== undefined ? getAbbreviatedNumberString(data.identified) : 'N/A'

  const unidentifiedValue = data?.unidentified !== undefined ? data.unidentified : 'N/A'
  const unidentifiedUiValue = data?.unidentified !== undefined ? getAbbreviatedNumberString(data.unidentified) : 'N/A'

  const OfficeDistributionData = data?.officeDistributionData || []
  const totalEntities = OfficeDistributionData.length || 'N/A'

  const taxOfficeEntitiesDistributionData: PieChartData[] = OfficeDistributionData.map((officeData, index) => {
    if (!officeData.key) {
      throw new Error(`Invalid officeData.key at index ${index}`)
    }

    const color = COLORS.length > 0 && index < COLORS.length ? COLORS[index] : stringToColor(officeData.key)
    const label = `${officeData.key.length > 8 ? `${officeData.key.slice(0, 8)}...` : officeData.key} ${
      officeData.count
    } (${officeData.percentage}%)`
    const tooltipLabel = officeData.key
    const fullTooltipLabel = `${officeData.key} ${officeData.count} (${officeData.percentage}%)`

    return {
      key: officeData.key,
      color,
      label,
      tooltipLabel,
      fullLabel: fullTooltipLabel,
      value: officeData.count,
    }
  })

  return [
    {
      title: 'Officers Identification',
      pieTitle: getAbbreviatedNumberString(data?.total || 0),
      pieSubTitle: 'Total Officers',
      data: [
        {
          key: 'identified',
          color: '#7E8BA6',
          label: 'Identified',
          value: identifiedValue,
          uiValue: `${identifiedUiValue} (${data?.identifiedPercentage}%)`,
        },
        {
          key: 'unidentified',
          color: '#FFCC00',
          label: 'Unidentified',
          value: unidentifiedValue,
          uiValue: `${unidentifiedUiValue} (${data?.unidentifiedPercentage}%)`,
        },
      ],
    },
    {
      title: 'Declaration on income from abroad',
      pieTitle: identifiedUiValue,
      pieSubTitle: 'Total Identified Officers',
      pieDy: 20,
      data: [
        { key: 'declared', color: '#7E8BA6', label: 'Declared', value: 15400, uiValue: '15.4K (35%)' },
        { key: 'notDeclared', color: '#00C2FF', label: 'Not declared', value: 28600, uiValue: '28.6K (65%)' },
      ],
    },
    {
      title: 'Tax Office Entities Distribution (Civil)',
      pieTitle: totalEntities.toString(),
      pieSubTitle: 'Total Entities',
      data: taxOfficeEntitiesDistributionData,
    },
  ]
}
