import { activityTypes } from '../Details/utils'

export const getUserKey = (activityType?: string) => {
  if (activityType === activityTypes.SOLD_ITEM) return 'activityType'
  return 'activityUser'
}

export const getActivityDateKey = (activityType?: string) => {
  if (activityType === activityTypes.SOLD_ITEM) return null
  return 'activityDate'
}
