import { COLUMNS_KEYS, searchableColumns } from '../../containers/Businesses/specs'
import { BaseFilter, BUSINESSES_KEYS, DISPLAY_AS_OPTIONS, OPTIONS_FROM_PROPS, Picker, TYPES_OF_SHAPE } from './common'
import { allEaseOfCollectionOptions } from '../../services/businesses'

export enum BUSINESSES_ALL_EASE_OF_COLLECTION_OPTIONS {
  A = 'a',
  B = 'b',
  C = 'c',
  D = 'd',
}

export enum STATUS_OPTIONS {
  NON_FILER = 'Non-Filer',
  UNDER_REPORT = 'Under Report',
}

export enum ALL_RESOLVER_TYPES {
  GIG = 'gig',
  ECOMMERCE = 'ecommerce',
  NEXUS = 'nexus',
  OTHER = 'other',
}

export interface ResolverNameToIcon {
  nexus: string
  ecommerce: string
  gig: string
  other: string
}

export const realResolverTypes = Object.values(ALL_RESOLVER_TYPES).filter(resolver => resolver !== 'other')

export enum RESOLVER_NAME_TO_ICON {
  NEXUS = 'placePin',
  ECOMMERCE = 'cart',
  GIG = 'economy',
  OTHER = 'bell',
}

//the object that Filters.tsx is using after manipulations
export interface BusinessUsableFilters {
  businessType: string[]
  easeOfCollection: BUSINESSES_ALL_EASE_OF_COLLECTION_OPTIONS
  employessInJurisdiction: Picker
  locationInJurisdiction: Picker
  resolver: RESOLVER_NAME_TO_ICON
  search: string
  searchFields: string[]
  showMap: number
  status: STATUS_OPTIONS | null
  untaxedIncome: Picker
}

export const {
  SHOW_MAP,
  SEARCH,
  SEARCHFIELDS,
  UNTAXED_INCOME,
  EASE_OF_COLLECTION,
  STATUS,
  BUSINESS_TYPE,
  EMPLOYESS_IN_JURISDICTION,
  LOCATION_IN_JURISDICTION,
} = BUSINESSES_KEYS

const { CHECKBOXES, RANGE, MULTI_SELECT } = DISPLAY_AS_OPTIONS
const { REGULAR, CIRCLE } = TYPES_OF_SHAPE
const { BUSINESS_TYPES } = OPTIONS_FROM_PROPS

export interface BusinessEaseOfCollection extends BaseFilter<BUSINESSES_KEYS.EASE_OF_COLLECTION, string[]> {
  displayAs: DISPLAY_AS_OPTIONS.CHECKBOXES
  typeOfShape: TYPES_OF_SHAPE.CIRCLE
  options: string[]
  isRow: boolean
  withIcons: boolean
}

type BusinessShowMap = BaseFilter<BUSINESSES_KEYS.SHOW_MAP, number>

type BusinessSearch = BaseFilter<BUSINESSES_KEYS.SEARCH, string>

type BusinessSearchfields = BaseFilter<BUSINESSES_KEYS.SEARCHFIELDS, (COLUMNS_KEYS | undefined)[]>

export interface BusinessUntaxedIncome extends BaseFilter<BUSINESSES_KEYS.UNTAXED_INCOME, Picker> {
  displayAs: DISPLAY_AS_OPTIONS.RANGE
}

export interface BusinessStatus extends BaseFilter<BUSINESSES_KEYS.STATUS, null> {
  displayAs: DISPLAY_AS_OPTIONS.CHECKBOXES
  options: string[]
  typeOfShape: TYPES_OF_SHAPE.REGULAR
  isRow: boolean
  withIcons: boolean
}

export interface BusinessesBusinessType extends BaseFilter<BUSINESSES_KEYS.BUSINESS_TYPE, string[]> {
  displayAs: DISPLAY_AS_OPTIONS.MULTI_SELECT
  optionsFromProps: OPTIONS_FROM_PROPS.BUSINESS_TYPES
  hasSelectAll: boolean
  disableSearch: boolean
  businessTypes: string[]
}

export interface BusinessesEmployeesInJurisdiction
  extends BaseFilter<BUSINESSES_KEYS.EMPLOYESS_IN_JURISDICTION, Picker> {
  displayAs: DISPLAY_AS_OPTIONS.RANGE
}

export interface BusinessesLocationInJurisdiction extends BaseFilter<BUSINESSES_KEYS.LOCATION_IN_JURISDICTION, Picker> {
  displayAs: DISPLAY_AS_OPTIONS.RANGE
}

export type BusinessesFilters = [
  BusinessShowMap,
  BusinessSearch,
  BusinessSearchfields,
  BusinessUntaxedIncome,
  BusinessEaseOfCollection,
  BusinessStatus,
  BusinessesBusinessType,
  BusinessesEmployeesInJurisdiction,
  BusinessesLocationInJurisdiction,
]

export const businessesFilters: BusinessesFilters = [
  {
    key: SHOW_MAP,
    initialValue: 0,
    isActivated: false,
  },
  {
    key: SEARCH,
    initialValue: '',
    isActivated: false,
  },
  {
    key: SEARCHFIELDS,
    initialValue: searchableColumns,
    isActivated: false,
  },
  {
    key: UNTAXED_INCOME,
    displayAs: RANGE,
    initialValue: { picker: 'Picker' },
    isActivated: true,
  },
  {
    key: EASE_OF_COLLECTION,
    displayAs: CHECKBOXES,
    options: allEaseOfCollectionOptions,
    initialValue: [],
    typeOfShape: CIRCLE,
    isRow: false,
    withIcons: false,
    isActivated: true,
  },
  {
    key: STATUS,
    displayAs: CHECKBOXES,
    options: Object.values(STATUS_OPTIONS),
    initialValue: null,
    typeOfShape: REGULAR,
    withIcons: false,
    isRow: false,
    isActivated: true,
  },
  {
    key: BUSINESS_TYPE,
    displayAs: MULTI_SELECT,
    initialValue: [],
    optionsFromProps: BUSINESS_TYPES,
    disableSearch: false,
    hasSelectAll: false,
    isActivated: true,
    businessTypes: [],
  },
  {
    key: EMPLOYESS_IN_JURISDICTION,
    displayAs: RANGE,
    initialValue: { picker: 'Picker' },
    isActivated: true,
  },
  {
    key: LOCATION_IN_JURISDICTION,
    displayAs: RANGE,
    initialValue: { picker: 'Picker' },
    isActivated: true,
  },
]

export interface BusinessesFilterGroups {
  income: BusinessUntaxedIncome[]
  collection: BusinessEaseOfCollection[]
  status: BusinessStatus[]
  industry: [BusinessesBusinessType]
  jurisdiction: [BusinessesEmployeesInJurisdiction, BusinessesLocationInJurisdiction]
}

const [
  showMap,
  search,
  searchFields,
  untaxedIncome,
  easeOfCollection,
  status,
  businessType,
  employessInJurisdiction,
  locationInJurisdiction,
]: BusinessesFilters = businessesFilters

export const businessesFilterGroups: BusinessesFilterGroups = {
  income: [untaxedIncome],
  collection: [easeOfCollection],
  status: [status],
  industry: [businessType],
  jurisdiction: [employessInJurisdiction, locationInJurisdiction],
}
