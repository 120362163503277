import React, { FC, useMemo } from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Slider from '@mui/material/Slider'
import styles from './styles'
import { getAbbreviatedNumberString } from 'components/Income/Income'
import { FilterTypes } from 'components/FilterDrawer/FilterDrawer.config'

interface Mark {
  value: number
  label: string
}

interface IRange {
  rangeType: FilterTypes.ROBUST_RANGE | FilterTypes.RANGE
  title: string
  onMinChange: (value: string) => void
  onMaxChange: (value: string) => void
  minLabel?: string
  maxLabel?: string
  minValue?: string
  maxValue?: string
  minLimit?: number
  maxLimit?: number
  step?: number
  marks?: Mark[]
}

const Range: FC<IRange> = ({
  rangeType,
  title,
  minValue,
  maxValue,
  minLabel,
  maxLabel,
  minLimit,
  maxLimit,
  step,
  marks,
  onMinChange,
  onMaxChange,
}) => {
  const values = useMemo(
    () => ({
      min: minValue || minLimit?.toString() || '0',
      max: maxValue || maxLimit?.toString() || '1000',
    }),
    [minValue, minLimit, maxValue, maxLimit],
  )

  const isRobustRange = rangeType === FilterTypes.ROBUST_RANGE

  return (
    <Stack sx={styles.Container}>
      <Typography sx={styles.Label}>{title}</Typography>
      <Slider
        value={[Number(values.min), Number(values.max)]}
        step={step}
        marks={marks}
        onChange={(e: Event, newNumber: number | number[]) => {
          const newMinValue = Number(newNumber[0]).toString()
          const newMaxValue = Number(newNumber[1]).toString()

          if (newMinValue !== values.min) {
            onMinChange(newMinValue)
          }

          if (newMaxValue !== values.max) {
            onMaxChange(newMaxValue)
          }
        }}
        min={minLimit}
        max={maxLimit}
        sx={styles.RangeSliderRoot}
      />
      {isRobustRange && minLimit && maxLimit && (
        <Stack flexDirection='row' justifyContent='space-between'>
          <Typography sx={styles.LegendTextRangeSlider}>{`$${getAbbreviatedNumberString(minLimit)}`}</Typography>
          <Typography sx={styles.LegendTextRangeSlider}>{`$${getAbbreviatedNumberString(maxLimit)}`}</Typography>
        </Stack>
      )}
      {isRobustRange && (
        <Stack
          flexDirection='row'
          sx={styles.FormControlContainer}
          divider={<Typography sx={styles.Separator}>-</Typography>}
          gap={2}
        >
          <FormControl variant='standard'>
            <InputLabel sx={styles.InputLabel} htmlFor={minLabel}>
              {minLabel}
            </InputLabel>
            <Input
              id={minLabel}
              type='number'
              endAdornment={
                <InputAdornment position='end' sx={styles.Adornment}>
                  $
                </InputAdornment>
              }
              value={values.min}
              sx={styles.Input}
              onChange={e => onMinChange(e.target.value)}
            />
          </FormControl>
          <FormControl variant='standard'>
            <InputLabel sx={styles.InputLabel} htmlFor={maxLabel}>
              {maxLabel}
            </InputLabel>
            <Input
              id={maxLabel}
              type='number'
              endAdornment={
                <InputAdornment position='end' sx={styles.Adornment}>
                  $
                </InputAdornment>
              }
              value={values.max}
              sx={styles.Input}
              onChange={e => onMaxChange(e.target.value)}
            />
          </FormControl>
        </Stack>
      )}
    </Stack>
  )
}

export default Range
