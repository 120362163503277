enum GRID_COLUMN_GROUPS {
  GROUP_SELECTION = 'selection',
  GROUP_DETAILS = 'identificationDetails',
  GROUP_INCOME = 'income',
}

const { GROUP_SELECTION, GROUP_DETAILS, GROUP_INCOME } = GRID_COLUMN_GROUPS

export const COLUMN_SELECT = 'select'
export const COLUMN_UNTAXED_INCOME = 'untaxedIncome'
export const COLUMN_NAME = 'entityName'

export interface GridColumns {
  group: GRID_COLUMN_GROUPS
  key: string
  size: number
  fixedWidth: number
  isPersistent: boolean
  firstInGroup: boolean
  groupHeader: string
  isSearchable: boolean
  isShown: boolean
  isHiddenByDefault: boolean
  minWidth: number
  isSortable: boolean
  headerIcons: string[]
  headerClassName: string
}

export enum MAPPER_COLUMN_KEYS {
  UNTAXED_REVENUE = 'untaxedIncome',
  SELECT = 'select',
  NAME = 'entityName',
  VERIFIE_DENTITY_NAME = 'verifiedEntityName',
  LOCATION = 'location',
  ENTITY_TYPE = 'entityType',
  TIN = 'tin',
  REPORTED_STATUS = 'reportedStatus',
  PLATFORMS = 'platforms',
  DATA_SOURCES = 'dataSources',
  DOCUMENTED_GROSS = 'documentedGross',
  DOCUMENTED_REVENUE = 'documentedRevenue',
  DOCUMENTED_SALES = 'documentedSales',
  DOCUMENTED_ROYALTIES = 'documentedRoyalties',
  DOCUMENTED_BUYS = 'documentedBuys',
}

const {
  UNTAXED_REVENUE,
  SELECT,
  NAME,
  VERIFIE_DENTITY_NAME,
  LOCATION,
  ENTITY_TYPE,
  TIN,
  REPORTED_STATUS,
  PLATFORMS,
  DATA_SOURCES,
  DOCUMENTED_ROYALTIES,
  DOCUMENTED_SALES,
  DOCUMENTED_GROSS,
  DOCUMENTED_REVENUE,
  DOCUMENTED_BUYS,
} = MAPPER_COLUMN_KEYS

export const gridColumns: Partial<GridColumns>[] = [
  {
    group: GROUP_SELECTION,
    key: SELECT,
    size: 1,
    fixedWidth: 80,
    isPersistent: true,
  },
  {
    group: GROUP_DETAILS,
    key: NAME,
    firstInGroup: true,
    groupHeader: 'Identification Details',
    fixedWidth: 300,
    isPersistent: true,
    isSearchable: true,
  },
  {
    group: GROUP_DETAILS,
    key: VERIFIE_DENTITY_NAME,
    minWidth: 300,
    isPersistent: true,
    isSearchable: true,
  },
  {
    group: GROUP_DETAILS,
    key: LOCATION,
    minWidth: 300,
    isPersistent: true,
  },
  {
    group: GROUP_DETAILS,
    key: ENTITY_TYPE,
    fixedWidth: 150,
    isPersistent: true,
  },
  {
    group: GROUP_DETAILS,
    key: TIN,
    size: 1,
  },
  {
    group: GROUP_DETAILS,
    key: REPORTED_STATUS,
    size: 2,
    fixedWidth: 250,
    isHiddenByDefault: String(process.env.PRESENT_LOTTO_INFORMATION).toLowerCase() !== 'true',
  },
  {
    group: GROUP_DETAILS,
    key: PLATFORMS,
    minWidth: 150,
  },
  {
    group: GROUP_DETAILS,
    key: DATA_SOURCES,
    minWidth: 150,
  },
  {
    group: GROUP_INCOME,
    key: DOCUMENTED_GROSS,
    firstInGroup: true,
    groupHeader: 'Documented Activity',
    size: 1,
    minWidth: 180,
    isPersistent: true,
  },
  {
    group: GROUP_INCOME,
    key: DOCUMENTED_REVENUE,
    size: 1,
    minWidth: 180,
    isPersistent: true,
  },
  {
    group: GROUP_INCOME,
    key: DOCUMENTED_SALES,
    size: 1,
    minWidth: 180,
    isPersistent: true,
  },
  {
    group: GROUP_INCOME,
    key: DOCUMENTED_ROYALTIES,
    size: 1,
    minWidth: 180,
    isPersistent: true,
  },
  {
    group: GROUP_INCOME,
    key: DOCUMENTED_BUYS,
    size: 1,
    minWidth: 180,
    isPersistent: true,
  },
  {
    key: UNTAXED_REVENUE,
    fixedWidth: 200,
    headerIcons: ['arrowUp', 'info'],
    isHiddenByDefault: true,
  },
]

export const toggleableColumns = gridColumns.filter(column => !column.isPersistent).map(column => column.key)

export const searchableColumns = gridColumns.filter(column => column.isSearchable).map(column => column.key)

export const defaultEntitiesDisplayedColumns = gridColumns
  .filter(column => !column.isPersistent && !column.isHiddenByDefault)
  .map(column => column.key)
