import React from 'react'
import { default as Select, components } from 'react-select'
import { ISelectedColumn } from 'components/verticals/VerticalTableBase/types'

interface ColumnPickerProps {
  columnsOptions: ISelectedColumn[]
  selectedColumns: ISelectedColumn[]
  setSelectedColumns: React.Dispatch<React.SetStateAction<ISelectedColumn[]>>
  isColumnDropdownOpen: boolean
}

const ColumnPicker = ({
  columnsOptions,
  selectedColumns,
  setSelectedColumns,
  isColumnDropdownOpen,
}: ColumnPickerProps) => {
  const InputOption = (props: any) => {
    return (
      <components.Option {...props}>
        <input type='checkbox' checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
      </components.Option>
    )
  }

  const selectStyles = {
    option: (provided: any) => ({
      ...provided,
      backgroundColor: 'white',
      color: 'black',
      padding: '6px 12px',
    }),
    menu: (provided: any) => ({
      ...provided,
      width: '300px',
      zIndex: 5,
      right: 0,
    }),
  }

  const handleColumnChange = (selectedOptions: any) => {
    setSelectedColumns(selectedOptions)
  }

  return (
    <div>
      <Select
        isMulti
        options={columnsOptions}
        value={selectedColumns}
        onChange={handleColumnChange}
        menuIsOpen={isColumnDropdownOpen}
        defaultValue={columnsOptions}
        components={{
          Option: InputOption,
          Control: () => null,
        }}
        hideSelectedOptions={false}
        styles={selectStyles}
      />
    </div>
  )
}

export default ColumnPicker
