import React from 'react'
import Stack from '@mui/material/Stack'
import GenericHeading from 'components/common/GenericHeading'
import GenericText from 'components/common/GenericText'
import { useTheme } from '@mui/material/styles'
import styles from './VerticalBusinessActivity.scss'

interface VerticalBARevenueSourceDetailsProps {
  headline: string | null
  text: string
  photoUrl?: string
}

const VerticalBARevenueSourceDetails = ({ headline, text, photoUrl }: VerticalBARevenueSourceDetailsProps) => {
  const theme = useTheme()
  return (
    <Stack
      direction='row'
      sx={{
        p: { md: '6px 6px 0', lg: '8px 8px 0', xl: '8px 24px 0', xxl: '12px 26px 0', xxxl: '40px' },
      }}
      className={styles.revenueResourceDetails}
    >
      {photoUrl && <img src={photoUrl} alt='Activity photo' className={styles.detailsImg} />}
      <Stack
        sx={{
          height: { lg: 56, xl: 85, xxl: '120px', xxxl: '200px' },
          overflow: 'auto',
          ...theme.mixins.customScrollBar(),
        }}
      >
        <GenericHeading
          sx={{
            fontSize: { md: 12, lg: 12, xl: 16, xxl: 28, xxxl: 38 },
            fontWeight: 700,
            lineHeight: { md: '18px', lg: '18px', xl: '22px', xxl: 'initial', xxxl: 'initial' },
          }}
        >
          {headline}
        </GenericHeading>
        <GenericText
          sx={{
            fontSize: { md: 12, lg: 12, xl: 14, xxl: 22, xxxl: 32 },
            fontWeight: 300,
            lineHeight: { lg: '16px', xl: '20px', xxl: 'initial', xxxl: 'initial' },
          }}
        >
          {text}
        </GenericText>
      </Stack>
    </Stack>
  )
}

export default VerticalBARevenueSourceDetails
