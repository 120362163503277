import MonthPicker from 'components/MonthPicker/MonthPicker'
import moment from 'moment'
import React from 'react'
import { UsableFilters } from 'services/filters'
import { IDatePickerValue, TSubject } from '../Filters'

interface MonthPickerInputProps {
  subject: TSubject
  currentFilters: UsableFilters
  onInputChange: (subject: TSubject, value: IDatePickerValue) => void
  momentFormat: string
  pickerFormat: string
}

const MonthPickerInput: React.FC<MonthPickerInputProps> = ({
  subject,
  currentFilters,
  onInputChange,
  momentFormat,
  pickerFormat,
}) => {
  const { start, end } = currentFilters[subject] || {}

  const DEFAULT_START = '2007/01'
  const DEFAULT_END = moment().format(momentFormat)

  const onMonthPickerChange = (startDate: string, endDate: string): void => {
    const nextStartDate = startDate || start
    const nextEndDate = endDate || end

    const nextStartDateFormatted = moment(nextStartDate).format(momentFormat)
    const nextEndDateFormatted = moment(nextEndDate).format(momentFormat)

    onInputChange(subject, {
      start: nextStartDateFormatted,
      end: nextEndDateFormatted,
    })
  }

  return (
    <MonthPicker
      start={start || DEFAULT_START}
      end={end || DEFAULT_END}
      format={pickerFormat}
      onChange={({ startDate, endDate }) => onMonthPickerChange(startDate, endDate)}
    />
  )
}

export default MonthPickerInput
