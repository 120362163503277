import React, { FormEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Form, FormGroup, Label } from 'reactstrap'
import { selectCurrentUser, setIsUserPlatformInitialized, setUserPlatform } from 'store/global'
import { setIsUserPlatformsModalDisplayed } from 'store/settings'
import messages from './messages'
import styles from './PlatformsModal.scss'
import { Modal, ModalActions } from 'components/Modal/Modal'
import PlatformSelect from 'components/PlatformSelect/PlatformSelect'

const PlatformsModal: React.FC = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)
  const [selectedPlatform, setSelectedPlatform] = useState<string>(currentUser?.platforms[0] || '')

  const onClose = () => dispatch(setIsUserPlatformsModalDisplayed({ isDisplayed: false }))

  const renderHeader = () => (
    <header className={styles.header}>
      <FormattedMessage {...messages.header} />
    </header>
  )

  const onPlatformSelect = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault()

    dispatch(setUserPlatform({ platform: selectedPlatform }))
    dispatch(setIsUserPlatformInitialized({ isInitialized: true }))
    dispatch(setIsUserPlatformsModalDisplayed({ isDisplayed: false }))
  }

  const formActions = [
    {
      key: 'select',
      type: 'submit',
      outline: true,
      label: <FormattedMessage {...messages.select} />,
      onClick: onPlatformSelect,
    },
  ]

  const fields = {
    selectedZone: {
      key: 'selectedZone',
      label: <FormattedMessage {...messages.selectPlatform} />,
      renderInput: () => <PlatformSelect setSelectedPlatform={setSelectedPlatform} />,
    },
  }

  return (
    <Modal isOpen size='lg' onClose={onClose} header={renderHeader()}>
      <Form className={styles.container}>
        {Object.values(fields).map(({ key, label, renderInput = () => {} }) => (
          <FormGroup key={key} className={styles.group}>
            <>
              <Label for={key} className={styles.label}>
                {label}
              </Label>
              {renderInput()}
            </>
          </FormGroup>
        ))}
        <ModalActions actions={formActions} />
      </Form>
    </Modal>
  )
}

export default PlatformsModal
