import { defineMessages } from 'react-intl'

const scope = 'app.components.RentalsItemAnalytics'

export default defineMessages({
  fields: {
    documentedIncomeForHost: {
      id: `${scope}.fields.documentedIncomeForHost`,
      defaultMessage: 'Documented Revenue',
    },
    reportedIncomeForHost: {
      id: `${scope}.fields.reportedIncomeForHost`,
      defaultMessage: 'Reported Revenue',
    },
    reportedLastYear: {
      id: `${scope}.fields.reportedLastYear`,
      defaultMessage: 'Reported Last Year',
    },
    taxRate: {
      id: `${scope}.fields.taxRate`,
      defaultMessage: 'Tax Rate',
    },
    taxGapForHost: {
      id: `${scope}.fields.taxGapForHost`,
      defaultMessage: 'Tax Gap',
    },
    documentedIncome: {
      id: `${scope}.fields.documentedIncome`,
      defaultMessage: 'Documented Revenue',
    },
    reportedIncome: {
      id: `${scope}.fields.reportedIncome`,
      defaultMessage: 'Reported Revenue',
    },
    occupancyTaxRate: {
      id: `${scope}.fields.occupancyTaxRate`,
      defaultMessage: 'Occupancy TaxRate',
    },
    taxGap: {
      id: `${scope}.fields.taxGap`,
      defaultMessage: 'Tax Gap',
    },
  },
})
