import type { VerticalDocumentActivityStats, VerticalEntity } from 'store/verticals/types'
import type { FinancialBarChartData, GraphData, PieChartData } from 'utils/types'

export enum VERTICAL_ACTIONS {
  VerticalEntity = 'SET_VERTICAL_ENTITY',
  VerticalDocumentActivityStats = 'SET_VERTICAL_DOCUMENT_ACTIVITY_STATS',
  VerticalRevenueBySources = 'SET_VERTICAL_REVENUE_BY_SOURCES',
  VerticalEstimatedRevenue = 'SET_VERTICAL_ESTIMATED_REVENUE',
  VerticalReportedRevenue = 'SET_VERTICAL_REPORTED_REVENUE',

  GraphData = 'SET_GRAPH_DATA',
}

interface VerticalEntityAction {
  type: VERTICAL_ACTIONS.VerticalEntity
  verticalEntity: VerticalEntity
}

interface VerticalDocumentActivityStatsAction {
  type: VERTICAL_ACTIONS.VerticalDocumentActivityStats
  verticalDocumentActivityStats: VerticalDocumentActivityStats
}

interface VerticalRevenueBySourcesAction {
  type: VERTICAL_ACTIONS.VerticalRevenueBySources
  verticalRevenueBySources: PieChartData[] | null
}
interface VerticalEstimatedRevenueAction {
  type: VERTICAL_ACTIONS.VerticalEstimatedRevenue
  verticalEstimatedRevenue: PieChartData[] | null
}

interface VerticalReportedRevenue {
  type: VERTICAL_ACTIONS.VerticalReportedRevenue
  verticalReportedRevenue: FinancialBarChartData[] | null
}

interface GlobalGraphData {
  type: VERTICAL_ACTIONS.GraphData
  graphData: GraphData
}

export type VerticalsAction =
  | VerticalEntityAction
  | VerticalDocumentActivityStatsAction
  | VerticalRevenueBySourcesAction
  | VerticalEstimatedRevenueAction
  | VerticalReportedRevenue
  | GlobalGraphData
