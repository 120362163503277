import { createSelector } from 'reselect'
import { initialState } from './slice'

const selectDashboardState = state => state.dashboard || initialState

export const selectTotalBusinesses = createSelector(selectDashboardState, state => state.totalBusinesses)

export const selectTotals = createSelector(selectDashboardState, state => state.totals)

export const selectEntities = createSelector(selectDashboardState, state => state.entities)

export const selectResolvers = createSelector(selectDashboardState, state => state.resolvers)

export const selectTransmissions = createSelector(selectDashboardState, state => state.transmissions)
