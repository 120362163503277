import { defineMessages } from 'react-intl'

const scope = 'app.containers.CompanyOverviewTablePage'

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Company Overview',
  },
  count: {
    id: `${scope}.count`,
    defaultMessage: 'Company Overview: {displayed} of {total}',
  },
  filters: {
    id: `${scope}.filters`,
    defaultMessage: 'Filters',
  },
})
