import React, { FC, useMemo, useState } from 'react'
import Alert from '@mui/material/Alert'
import { selectCurrentZone } from '../../store/global'
import NavigationHeader from 'components/NavigationHeader/NavigationHeader'
import BusinessesSummary from 'components/BusinessesSummary/BusinessesSummary'
import GridLoader from 'components/GridLoader/GridLoader'
import FreelancersTable from 'components/freelancers/FreelancersTable/FreelancersTable'
import TableActionsBars from 'components/verticals/TableActionsBar/TableActionsBar'

import { BaseTableOverview, CurrentZone } from 'utils/types'
import { FreelancersEntityForTable } from 'containers/FreelancersTablePage/types'

import { useVerticalOverviewQuery } from 'hooks/useVerticalOverviewQuery'
import { useVerticalEntitiesQuery } from 'hooks/useVerticalEntitiesQuery'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import cx from 'classnames'
import messages from './messages'

import styles from './FreelancersTablePage.scss'
import useVerticalSearchAndSort from 'hooks/useVerticalSearchAndSort'
import { getUrlPageValues, removeKeywordSuffix } from '../VerticalPage/common/utils'
import { ISelectedColumn } from 'components/verticals/VerticalTableBase/types'
import { MRT_ColumnDef } from 'material-react-table'
import { useEmptyColumnsQuery } from 'hooks/useEmptyColumnsQuery'

const csvFileName = 'freelancers'

const FreelancersTablePage: FC = () => {
  const { searchValue, searchValueDebounced, sorting, setSearchValue, setSorting } = useVerticalSearchAndSort()
  const [mergedColumns, setMergedColumns] = useState<MRT_ColumnDef<FreelancersEntityForTable>[]>([])
  const [selectedColumns, setSelectedColumns] = useState<ISelectedColumn[]>([])

  const allColumns = useMemo(() => mergedColumns?.flatMap(column => column?.columns ?? []), [mergedColumns]) || []

  const currentZone: CurrentZone = useSelector(selectCurrentZone)

  const { data: emptyColumns } = useEmptyColumnsQuery({
    verticalIndices: currentZone?.gigIndices,
    currentQueryKey: 'gig-entities-key',
    keys: removeKeywordSuffix(allColumns.map(el => el.id || '').filter(key => Boolean(key))),
  })

  // Fetch overview data
  const { data: verticalOverviewData, isLoading: isLoadingOverview } = useVerticalOverviewQuery<BaseTableOverview>({
    verticalIndices: currentZone?.gigIndices,
    currentQueryKey: 'gig-overview-key',
    searchValue: searchValueDebounced,
  })

  // Fetch entities data
  const { verticalEntities, fetchNextPage, isFetching, isLoading, isError } =
    useVerticalEntitiesQuery<FreelancersEntityForTable>({
      verticalIndices: currentZone?.gigIndices,
      currentQueryKey: 'gig-entities-key',
      searchValue: searchValueDebounced,
      sorting,
    })

  return (
    <div className={styles.container}>
      <nav className={styles.navigationHeaderWrapper}>
        <NavigationHeader />
      </nav>
      <header className='page-header withNavigationHeader'>
        <h1 className={cx('page-title', 'h1')}>
          <FormattedMessage {...messages.header} />
        </h1>
      </header>
      <main className={styles.main}>
        <div className={styles.contentWrapper}>
          <section className={styles.summary}>
            <BusinessesSummary
              isLoading={isLoadingOverview}
              summary={{
                entityCounts: verticalOverviewData?.summary.entitiesCount,
                incomeCounts: verticalOverviewData?.summary.incomeCounts,
                topIndustries: verticalOverviewData?.summary.platforms.map(({ source, count }) => [source, count]),
                industriesCount: verticalOverviewData?.summary.platforms.reduce((acc, { count }) => acc + count, 0),
              }}
              isInfluencers
            />
          </section>
          <div className={cx(styles.content, { [styles.withFilters]: false })}>
            <div className={styles.gridContainer}>
              <TableActionsBars
                countMessages={messages.count}
                displayedCount={verticalOverviewData ? verticalEntities?.length ?? null : null}
                totalCount={verticalOverviewData && verticalEntities?.length ? verticalOverviewData.total : null}
                zoneEndpoint={currentZone?.gigIndices}
                csvFileName={csvFileName}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                type={getUrlPageValues()[0]}
                mergedColumns={mergedColumns}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                allColumns={allColumns}
                emptyColumns={emptyColumns}
              />
              {isLoading ? (
                <GridLoader />
              ) : isError ? (
                <Alert severity='error' sx={{ fontFamily: 'Source Sans Pro', fontSize: '1rem', alignItems: 'center' }}>
                  Something went wrong
                </Alert>
              ) : (
                <section className={styles.grid}>
                  <FreelancersTable
                    isFetching={isFetching}
                    fetchNextPage={fetchNextPage}
                    totalFetched={verticalEntities?.length || 0}
                    totalDBRowCount={verticalEntities?.length ? verticalOverviewData?.total ?? 0 : 0}
                    verticalEntities={verticalEntities}
                    sorting={sorting}
                    setSorting={setSorting}
                    setMergedColumns={setMergedColumns}
                    selectedColumns={selectedColumns}
                    allColumns={allColumns}
                  />
                </section>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default FreelancersTablePage
