export enum tabsIds {
  OVERVIEW,
  BUSINESS_ACTIVITY,
  TAX_RETURN,
  CONTACT_INFO,
}

const tabsList = {
  OVERVIEW: { id: tabsIds.OVERVIEW, label: 'Overview' },
  BUSINESS_ACTIVITY: { id: tabsIds.BUSINESS_ACTIVITY, label: 'Business Activity' },
  TAX_RETURN: { id: tabsIds.TAX_RETURN, label: 'Tax Return' },
  CONTACT_INFO: { id: tabsIds.CONTACT_INFO, label: 'Contact Info' },
}

export const getTabsList = () => [
  tabsList.OVERVIEW,
  tabsList.BUSINESS_ACTIVITY,
  tabsList.TAX_RETURN,
  tabsList.CONTACT_INFO,
]
