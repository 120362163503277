import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import ZoneIcon from 'components/ZoneIcon'
import Button from 'components/Button'
import Icon from 'components/Icon'
import propTypes from 'prop-types'
import { logout, selectCurrentUser, selectCurrentZone, selectIsInitialized, selectIsLoggedIn } from 'store/global'
import messages from './messages'
import styles from './Header.scss'

function Header({ intl }) {
  const dispatch = useDispatch()

  const isInitialized = useSelector(selectIsInitialized)
  const currentZone = useSelector(selectCurrentZone)
  const isLoggedIn = useSelector(selectIsLoggedIn)

  const currentUser = useSelector(selectCurrentUser)
  const { email = '', fullName = '' } = currentUser || {}

  const { name = '' } = currentZone || {}
  const currentZoneName = name

  return (
    <header className={styles.header}>
      <Button
        to={isLoggedIn ? '/dashboard' : '/login'}
        color='link'
        className={styles.logoLink}
        title={intl.formatMessage(messages.logo)}
      >
        <Icon name='logo' size='xl' className={styles.logo} />
      </Button>
      <div className={styles.linksWrapper}>
        <ul className={styles.linksList}>
          <li className={styles.linkItem}>
            <Button color='link' to={email ? '/profile' : undefined} title={email} className={styles.profile}>
              <span className={styles.username}>{fullName}</span>
            </Button>
            {isLoggedIn && (
              <Button color='link' onClick={() => dispatch(logout())}>
                <Icon name='logout' size='regular' className={styles.logout} />
              </Button>
            )}
          </li>
          <li className={styles.linkItem}>
            <Button color='link'>
              <Icon name='bell' size='regular' className={styles.bellIcon} />
            </Button>
          </li>
          <li className={styles.linkItem}>
            {currentZoneName && (
              <div className={styles.currentZone}>
                <span className={styles.zoneName}>{currentZoneName}</span>
              </div>
            )}
          </li>
          <li className={styles.linkItem}>
            {currentZoneName && (
              <div className={styles.currentZone}>{isInitialized && <ZoneIcon name={currentZoneName} />}</div>
            )}
          </li>
        </ul>
      </div>
    </header>
  )
}

Header.propTypes = {
  intl: propTypes.object.isRequired,
}

export default injectIntl(Header)
