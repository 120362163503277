import { defineMessages } from 'react-intl'

const scope = 'app.components.RentalsFilters'

export const subjectsMessages = defineMessages({
  zoneType: {
    id: `${scope}.subjects.zoneType`,
    defaultMessage: 'Number Of Listings In {zone} Per Host',
  },
  documentedIncome: {
    id: `${scope}.subjects.documentedIncome`,
    defaultMessage: 'Documented Revenue',
  },
  site: {
    id: `${scope}.subjects.site`,
    defaultMessage: 'Site',
  },
  listingDates: {
    id: `${scope}.subjects.listingDates`,
    defaultMessage: 'Dates',
  },
  numberOfListings: {
    id: `${scope}.subjects.numberOfListings`,
    defaultMessage: 'Total Number Of Listings Per Host',
  },
  numberOfReservations: {
    id: `${scope}.subjects.numberOfReservations`,
    defaultMessage: 'Number Of Reservations',
  },
  daysRented: {
    id: `${scope}.subjects.daysRented`,
    defaultMessage: 'Days Rented',
  },
  taxGap: {
    id: `${scope}.subjects.taxGap`,
    defaultMessage: 'Tax Gap',
  },
  hostId: {
    id: `${scope}.subjects.hostId`,
    defaultMessage: 'Host ID',
  },
  joinedIn: {
    id: `${scope}.subjects.joinedIn`,
    defaultMessage: 'Host Joined in',
  },
  hostType: {
    id: `${scope}.subjects.hostType`,
    defaultMessage: 'Host Type',
  },
  nestedHostLocations: {
    id: `${scope}.subjects.nestedHostLocations`,
    defaultMessage: 'Host Location',
  },
  nestedListingLocations: {
    id: `${scope}.subjects.nestedListingLocations`,
    defaultMessage: 'Has Listings',
  },
})

export const optionsMessages = defineMessages({
  gig: {
    id: `${scope}.options.gig`,
    defaultMessage: 'Gig Economy',
  },
})
