import React, { ReactNode, FC } from 'react'
import Box from '@mui/material/Box'
import { SxProps } from '@mui/material/styles'
import NavigationHeader from 'components/NavigationHeader/NavigationHeader'
import { getUrlPageValues } from '../utils'

interface VerticalPageWrapperProps {
  children?: ReactNode
  graphElement?: ReactNode
  gridContainerSx?: SxProps
}

const VerticalPageWrapper: FC<VerticalPageWrapperProps> = ({ children, graphElement, gridContainerSx = {} }) => {
  const [, verticalType] = getUrlPageValues()

  return (
    <Box
      component='section'
      sx={{
        display: 'grid',
        gridTemplateRows: { md: '5% 95%', xxxl: '4% 96%' },
        height: '100%',
        pb: '20px',
      }}
    >
      <Box sx={{ alignSelf: 'center' }}>
        <NavigationHeader entityParam={verticalType} />
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: 'auto',
          gap: { md: '8px 22px', lg: '14px 22px', lgPlus: '12px 22px', xl: '14px 22px', xxl: '22px', xxxl: '22px' },
          ...gridContainerSx,
        }}
      >
        {children}
        {graphElement && (
          <Box
            sx={{
              gridColumn: '2 / 3',
              gridRow: '1 / 3',
              display: 'grid',
              gridTemplateColumns: '98%',
              gridTemplateRows: '1fr',
            }}
          >
            {graphElement}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default VerticalPageWrapper
