import React from 'react'

const ZoomMinus = () => (
  <svg width='12' height='4' viewBox='0 0 12 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.18984 3.16732H10.6434C11.3006 3.16732 11.8333 2.64498 11.8333 2.00065C11.8333 1.35632 11.3006 0.833989 10.6434 0.833989H7.18984C6.53269 0.833989 4.81008 0.833984 4.81008 0.833984L1.35651 0.833989C0.699356 0.833989 0.166626 1.35632 0.166626 2.00065C0.166626 2.64498 0.699355 3.16732 1.35651 3.16732H4.81008C4.81008 3.16732 6.53269 3.16732 7.18984 3.16732Z'
      fill='#2257C5'
    />
  </svg>
)

export default ZoomMinus
