import React, { ReactNode } from 'react'
import { Position, Tooltip as Tippy, Theme } from 'react-tippy'

const Tooltip = ({
  position,
  title,
  children,
  html,
  theme,
  className,
}: {
  position?: Position
  children: ReactNode
  title?: string
  html?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined
  theme?: Theme | undefined
  className?: string
}) => (
  <Tippy position={position} title={title} arrow={true} html={html} theme={theme || 'dark'} className={className}>
    {children}
  </Tippy>
)

export default Tooltip
