import { defineMessages } from 'react-intl'

const scope = 'app.components.RentalsActions'

export default defineMessages({
  columns: {
    select: {
      id: `${scope}.columns.select`,
      defaultMessage: ' ',
    },
    hostName: {
      id: `${scope}.columns.hostName`,
      defaultMessage: 'Host Name',
    },
    verifiedHostName: {
      id: `${scope}.columns.verifiedHostName`,
      defaultMessage: 'Verified Host Name',
    },
    reportedStatus: {
      id: `${scope}.columns.reportedStatus`,
      defaultMessage: 'Report Status',
    },
    verifiedHostNameOther: {
      id: `${scope}.columns.verifiedHostNameOther`,
      defaultMessage: 'Verified Host Name Other',
    },
    birthday: {
      id: `${scope}.columns.birthday`,
      defaultMessage: 'Birthday',
    },
    hostType: {
      id: `${scope}.columns.hostType`,
      defaultMessage: 'Host Type',
    },
    tin: {
      id: `${scope}.columns.tin`,
      defaultMessage: 'TIN',
    },
    numberOfListings: {
      id: `${scope}.columns.numberOfListings`,
      defaultMessage: 'Active Listings',
    },
    numberOfTotalListings: {
      id: `${scope}.columns.numberOfTotalListings`,
      defaultMessage: 'Total Listings',
    },
    numberOfReviews: {
      id: `${scope}.columns.numberOfReviews`,
      defaultMessage: 'Number of Reviews',
    },
    documentedRevenue: {
      id: `${scope}.columns.documentedRevenue`,
      defaultMessage: 'Documented Revenue',
    },
    reportedRevenue: {
      id: `${scope}.columns.reportedRevenue`,
      defaultMessage: 'Reported Revenue',
    },
    siteProfile: {
      id: `${scope}.columns.siteProfile`,
      defaultMessage: 'Host Verification',
    },
    address: {
      id: `${scope}.columns.address`,
      defaultMessage: 'Address',
    },
    extendedAddress: {
      id: `${scope}.columns.extendedAddress`,
      defaultMessage: 'Extended Address',
    },
    phoneNumber: {
      id: `${scope}.columns.phoneNumber`,
      defaultMessage: 'Phone Number',
    },
    email: {
      id: `${scope}.columns.email`,
      defaultMessage: 'Email',
    },
    webpage: {
      id: `${scope}.columns.webpage`,
      defaultMessage: 'Airbnb Profile',
    },
    taxGap: {
      id: `${scope}.columns.taxGap`,
      defaultMessage: 'Tax Gap',
    },
    listingNumber: {
      id: `${scope}.columns.listingNumber`,
      defaultMessage: 'Listing Number',
    },
    listingAddress: {
      id: `${scope}.columns.listingAddress`,
      defaultMessage: 'Listing Address',
    },
    listingDescription: {
      id: `${scope}.columns.listingDescription`,
      defaultMessage: 'Listing Description',
    },
    listingSites: {
      id: `${scope}.columns.listingSites`,
      defaultMessage: 'Sites',
    },
    firstListedAt: {
      id: `${scope}.columns.firstListedAt`,
      defaultMessage: 'First Listed',
    },
    numberOfReservations: {
      id: `${scope}.columns.numberOfReservations`,
      defaultMessage: 'Number of Reservations',
    },
    daysRented: {
      id: `${scope}.columns.daysRented`,
      defaultMessage: 'Days Rented',
    },
  },
  actions: {
    columns: {
      id: `${scope}.actions.columns`,
      defaultMessage: 'Columns',
    },
    share: {
      id: `${scope}.actions.share`,
      defaultMessage: 'Export',
    },
  },
})
