import React, { FC } from 'react'
import FinancialRow from 'containers/VerticalPage/common/VerticalOverview/FinancialRow'
import { FinancialBarChartData } from 'utils/types'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'

interface Label {
  key: string
  value: string
}

interface FinancialOverviewProps {
  financialData: FinancialBarChartData[]
  labels: Label[]
}

const FinancialOverview: FC<FinancialOverviewProps> = ({ financialData, labels }) => {
  const theme = useTheme()

  return (
    <Stack
      maxWidth='100%'
      spacing={{ lg: 1, xxxl: 2 }}
      sx={{ overflow: 'auto', ...theme.mixins.customScrollBar(), paddingBlockEnd: '2px' }}
    >
      <FinancialRow
        heading=''
        revenues={financialData.map(item => item.date) as (number | null)[]}
        valueSx={{ fontSize: 12, fontWeight: 600, fontFamily: 'Source Sans Pro' }}
      />
      {labels.map(label => (
        <FinancialRow
          key={label.key}
          heading={label.value}
          revenues={financialData.map(item => item[label.key]) as (number | null)[]}
        />
      ))}
    </Stack>
  )
}

export default FinancialOverview
