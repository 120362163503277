import React from 'react'
import PropTypes from 'prop-types'
import { Modal as ReactstrapModal, ModalHeader, ModalBody, FormGroup } from 'reactstrap'
import Button from 'components/Button'
import Icon from 'components/Icon'
import styles from './Modal.scss'

const allowedSizes = ['sm', 'md', 'lg', 'xl']
const [SIZE_SM, SIZE_MD] = allowedSizes

export const Modal = ({
  isOpen = false,
  size = SIZE_SM,
  onClose,
  header,
  children,
  className,
  headerClassName,
  style = {},
}) => (
  <ReactstrapModal isOpen={isOpen} size={size} toggle={onClose} className={className}>
    {header && (
      <ModalHeader toggle={onClose} className={headerClassName}>
        <div className={styles.header}>{header}</div>
        <Button color='link' outline onClick={onClose} className={styles.close}>
          <Icon name='close' size='regular' />
        </Button>
      </ModalHeader>
    )}
    <ModalBody style={style}>{children}</ModalBody>
  </ReactstrapModal>
)

Modal.propTypes = {
  isOpen: PropTypes.bool,
  size: PropTypes.oneOf(allowedSizes),
  onClose: PropTypes.func.isRequired,
  header: PropTypes.any,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  style: PropTypes.any,
}

export const ModalActions = ({ actions }) => (
  <FormGroup className={styles.actions}>
    {actions
      .filter(({ isDisplayed = true }) => isDisplayed)
      .map(({ color = 'primary', label, isDisplayed, ...rest }) => (
        <Button {...rest} color={color} className={styles.action}>
          {label}
        </Button>
      ))}
  </FormGroup>
)
