import React from 'react'
import cx from 'classnames'
import styles from './TitledContent.scss'

export interface ITitledContent {
  title: string
  children: React.ReactNode
  className: string
  titleClassName: string
  contentClassName: string
}

const TitledContent = ({ title = '', children, className, titleClassName, contentClassName }: ITitledContent) => (
  <div className={cx(styles.container, className)}>
    {title && <h5 className={cx(styles.title, titleClassName)}>{title}</h5>}
    <span className={cx(styles.content, contentClassName)}>{children}</span>
  </div>
)

export default TitledContent
