import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePermissions } from 'hooks/usePermissions'
import { FormattedMessage } from 'react-intl'
import { Modal, ModalActions } from 'components/Modal'
import { Form, FormGroup, Label } from 'reactstrap'
import ZoneSelect from 'components/ZoneSelect'
import { selectAvailableZones } from 'store/global'
import { setModalDisplayed } from 'store/settings'
import messages from './messages'
import styles from './SettingsModal.scss'

export default function SettingsModal() {
  const dispatch = useDispatch()
  const permissions = usePermissions()

  const onClose = () => dispatch(setModalDisplayed({ isDisplayed: false }))

  const availableZones = useSelector(selectAvailableZones)
  const allowZoneSelection = availableZones.length > 0

  const renderHeader = () => (
    <header className={styles.header}>
      <FormattedMessage {...messages.header} />
    </header>
  )

  const formActions = [
    {
      key: 'cancel',
      outline: true,
      label: <FormattedMessage {...messages.actions.close} />,
      onClick: onClose,
    },
  ]

  const fields = {
    selectedZone: {
      key: 'selectedZone',
      isDisplayed: allowZoneSelection,
      label: <FormattedMessage {...messages.labels.selectedZone} />,
      renderInput: () => <ZoneSelect />,
    },
  }

  return (
    <Modal isOpen size='lg' onClose={onClose} header={renderHeader()}>
      <Form className={styles.container}>
        {Object.values(fields)
          .filter(({ isDisplayed = true }) => isDisplayed)
          .map(({ key, label, renderInput = () => {} }) => (
            <FormGroup key={key} className={styles.group}>
              <Label for={key} className={styles.label}>
                {label}
              </Label>
              {renderInput()}
            </FormGroup>
          ))}
        <ModalActions actions={formActions} />
      </Form>
    </Modal>
  )
}
