import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Alert from 'components/Alert'
import { selectAlerts, removeAlert } from 'store/global'
import styles from './Alerts.scss'

export default function Alerts() {
  const dispatch = useDispatch()
  const alerts = useSelector(selectAlerts)

  const onDismiss = id => dispatch(removeAlert({ id }))

  return (
    <div className={styles.alerts}>
      {Object.entries(alerts).map(([key, alert]) => (
        <Alert key={key} type={alert.type} message={alert.message} onDismiss={() => onDismiss(alert.id)} />
      ))}
    </div>
  )
}
