import { uniq } from 'lodash'
import { IApiZones } from './types'

export const getApiZonesByKey = (data: IApiZones, key?: string | null): string[] => {
  if (key) {
    return data[key] || []
  }

  const combinedArray = Object.values(data).flat()
  const uniqueArray = uniq(combinedArray)

  return uniqueArray
}
