import React, { useMemo, useState } from 'react'
import Stack from '@mui/material/Stack'
import { Filter } from 'components/FilterDrawer/FilterDrawer.config'
import { FilterOptions } from 'containers/CompanyOverviewTablePage/types'
import FilterDrawer from 'components/FilterDrawer/FilterDrawer'
import Icon from 'components/Icon'
import { filtersInitialState } from 'containers/VerticalPage/company-overview/OverviewTab/utils'
import GenericButton from 'components/common/GenericButton'
import { ActiveDepartments, FilterButton } from './styles'

interface ActionsBarProps {
  filterOptions: FilterOptions
  activeFilters?: Filter[]
  setActiveFilters?: React.Dispatch<React.SetStateAction<Filter[]>>
}

const ActionsBar = ({ activeFilters, setActiveFilters, filterOptions }: ActionsBarProps) => {
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false)

  const handleFilterButtonClick = () => {
    setIsFilterDrawerOpen(!isFilterDrawerOpen)
  }

  const activeDepartmentsString = useMemo(() => {
    const departmentsFilter = activeFilters?.find(filter => filter?.label?.toLowerCase() === 'departments')
    if (!departmentsFilter) return ['All']
    return departmentsFilter.value.map(department => department).join(', ')
  }, [activeFilters])

  return (
    <Stack direction='row'>
      <Stack sx={ActiveDepartments}>Departments: {activeDepartmentsString}</Stack>

      <GenericButton sx={FilterButton} onClick={handleFilterButtonClick}>
        <Icon size='regular' name='filterBlue' />
      </GenericButton>

      {activeFilters && setActiveFilters && filterOptions && (
        <FilterDrawer
          open={isFilterDrawerOpen}
          onClose={() => setIsFilterDrawerOpen(false)}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          filtersInitialState={filtersInitialState}
          filterOptions={filterOptions}
          shouldUseRawRangeValues
        />
      )}
    </Stack>
  )
}

export default ActionsBar
