import React, { useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import MultiSelect from 'react-multi-select-component'
import Input from 'components/Input'
import { Option } from 'react-multi-select-component/dist/lib/interfaces'
import styles from './DropdownInput.scss'

export interface IDropdownInput {
  options: Array<Option>
  selections?: Array<Option>
  dropdownHeader?: string
  allItemsAreSelected?: string
  selectAll?: string
  search?: string
  labelledBy?: string
  disableSearch?: boolean
  hasSelectAll?: boolean
  onChange?: (() => void) | ((arg0: any) => void)
  onToggle?: () => void
  buttonRenderer?: any // Typescript conflict with our function (which returns ReactNode) and their use
  className?: string
}

const DropdownInput = ({
  options,
  selections = [],
  dropdownHeader = '',
  allItemsAreSelected = '',
  selectAll = '',
  search = '',
  labelledBy = '',
  disableSearch = false,
  hasSelectAll = true,
  onChange = () => {},
  onToggle = () => {},
  buttonRenderer,
  className,
}: IDropdownInput) => {
  const [currentSelections, setCurrentSelections] = useState<Option[]>([])

  const overrideStrings = useMemo(() => {
    let settings = {
      selectSomeItems: dropdownHeader,
      allItemsAreSelected: selections
        .map((selection, index) => (index === 0 ? selection.label : `, ${selection.label}`))
        .join(''),
      selectAll,
      search,
    }

    return settings
  }, [selections])

  const ItemRenderer = ({ option, onClick, disabled }: any) => {
    const isSelected =
      (option.label === 'Select All' && selections.length === options.length) ||
      selections.some(selection => selection.value === option.value)

    return (
      <div className={cx('item-renderer', { disabled })}>
        <Input type='checkbox' disabled={disabled} checked={isSelected} onChange={onClick} />
        <span className={cx(styles.label, { [styles.selected]: isSelected })}>{option.label}</span>
      </div>
    )
  }

  useEffect(() => {
    // When there are no options, reset selections
    setCurrentSelections(options?.length > 0 ? selections : [])
  }, [options])

  return (
    <MultiSelect
      options={options}
      value={currentSelections}
      onChange={onChange}
      labelledBy={labelledBy}
      overrideStrings={overrideStrings}
      disableSearch={disableSearch}
      hasSelectAll={hasSelectAll}
      ItemRenderer={ItemRenderer}
      valueRenderer={buttonRenderer}
      onMenuToggle={onToggle}
      className={cx(className, { [styles.withSearch]: !disableSearch, [styles.withCustomButton]: !!buttonRenderer })}
    />
  )
}

export default DropdownInput
