import { VerticalsDetailsAction, VERTICALS_DETAILS_ACTIONS } from 'store/verticalsDetails/actions'
import { VerticalsDetailsState } from 'store/verticalsDetails/types'

export const VerticalsDetailsReducer = (
  state: VerticalsDetailsState,
  action: VerticalsDetailsAction,
): VerticalsDetailsState => {
  switch (action.type) {
    case VERTICALS_DETAILS_ACTIONS.VerticalDetailsDropdownOptions:
      return {
        ...state,
        detailsDropdownOptions: action.detailsDropdownOptions,
      }
    case VERTICALS_DETAILS_ACTIONS.VerticalIsDetailsDropdownOpen:
      return {
        ...state,
        isDetailsDropdownOpen: action.isDetailsDropdownOpen,
      }
    case VERTICALS_DETAILS_ACTIONS.VerticalDetailsDropdownDidSubmit:
      return {
        ...state,
        didSubmitDetailsDropdown: action.didSubmitDetailsDropdown,
      }
    case VERTICALS_DETAILS_ACTIONS.VerticalDetailsFilters:
      return {
        ...state,
        detailsFilters: action.detailsFilters,
      }
    case VERTICALS_DETAILS_ACTIONS.VerticalDetailsSubmittedDropdownOptions:
      return {
        ...state,
        submittedDropdownOptions: action.submittedDropdownOptions,
      }
    default:
      return state
  }
}
