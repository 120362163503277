import Button from 'components/Button'
import Icon from 'components/Icon'
import Tooltip from 'components/Tooltip'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './TableActionsBar.scss'

interface ExportCSVButtonProps {
  exportMessages: FormattedMessage.MessageDescriptor
  isExportDisabled: boolean
  onCSVExport: () => void
}

const ExportCSVButton = ({ exportMessages, isExportDisabled, onCSVExport }: ExportCSVButtonProps) => (
  <Tooltip title='Export Entity Details CSV'>
    <Button color='light' outline className={styles.export} disabled={isExportDisabled} onClick={onCSVExport}>
      <Icon name='upload' size='standard' />
      <FormattedMessage {...exportMessages} />
    </Button>
  </Tooltip>
)

export default ExportCSVButton
