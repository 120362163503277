import GenericHeading from 'components/common/GenericHeading'
import GenericText from 'components/common/GenericText'
import React, { useCallback, useEffect, useMemo } from 'react'
import { NOT_AVAILABLE } from 'utils/constants'
import Stack from '@mui/material/Stack'
import {
  HeaderTotalAmount,
  ItemTotalAmount,
  TooltipHeading,
  TooltipItemContainer,
  TooltipItemDetails,
  TooltipItemLabel,
} from 'containers/VerticalPage/company-overview/OverviewTab/EmployeeOvertimeTooltip/styles'
import Box from '@mui/material/Box'

interface RestEmployeeOvertimeTooltipContentProps {
  localCountry: string
  relevantPayload: any
}

const RestEmployeeOvertimeTooltipContent = ({
  localCountry,
  relevantPayload,
}: RestEmployeeOvertimeTooltipContentProps) => {
  const getHeaderDepartments = (departments: string[]) => {
    if (departments === undefined || departments === null) return NOT_AVAILABLE
    else if (departments?.length === 0) return 'Non-local jurisdictions'
    else return departments.map((department, index) => `${department}${index !== departments?.length - 1 ? ', ' : ''}`)
  }

  const totalAmount = useMemo(() => {
    return relevantPayload?.payload?.totalLocalAmount + relevantPayload?.payload?.totalRestOfTheWorldAmount
  }, [relevantPayload])

  const renderHeader = useCallback(
    () => (
      <Stack sx={TooltipItemContainer}>
        <GenericHeading sx={TooltipHeading}>
          {getHeaderDepartments(relevantPayload?.payload?.restOfTheWorldAmountsByDepratments?.departments)}
        </GenericHeading>
        <Stack sx={TooltipItemDetails}>
          <GenericText sx={HeaderTotalAmount}>{relevantPayload?.payload?.totalRestOfTheWorldAmount}</GenericText>
          <GenericText sx={HeaderTotalAmount}>
            ({((relevantPayload?.payload?.totalRestOfTheWorldAmount / totalAmount) * 100).toFixed(2)}
            %)
          </GenericText>
        </Stack>
      </Stack>
    ),
    [relevantPayload, localCountry],
  )

  const renderCountriesData = useCallback(() => {
    if (!relevantPayload?.payload?.restOfTheWorldAmountsByDepratments?.countriesAmounts) return <></>
    const list = relevantPayload?.payload?.restOfTheWorldAmountsByDepratments?.countriesAmounts
      ?.sort((a: any, b: any) => b.amount - a.amount)
      .slice(0, 10)
      .map((countryData: any) => {
        return (
          <Stack sx={TooltipItemContainer} key={countryData?.country}>
            <GenericText sx={TooltipItemLabel}>{countryData?.country}</GenericText>
            <Stack direction='row' gap='10px'>
              <GenericText>{countryData?.amount}</GenericText>
              <GenericText sx={ItemTotalAmount}>
                ({((countryData?.amount / relevantPayload?.payload?.totalRestOfTheWorldAmount) * 100).toFixed(2)}
                %)
              </GenericText>
            </Stack>
          </Stack>
        )
      })

    if (relevantPayload?.payload?.restOfTheWorldAmountsByDepratments?.countriesAmounts.length > 10) {
      list.push(<Box>...</Box>)
    }

    return list
  }, [relevantPayload, localCountry])

  return (
    <Stack>
      {renderHeader()}
      {renderCountriesData()}
    </Stack>
  )
}

export default RestEmployeeOvertimeTooltipContent
