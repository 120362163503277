export enum FilterTypes {
  ROBUST_RANGE = 'robustRange',
  RANGE = 'range',
  BOOL = 'bool',
  CHIPS = 'chips',
  DATE_BIT = 'dateBit',
  SELECT = 'select',
  YEARS_RANGE_SELECTOR = 'yearsRangeSelector',
  CHECKBOXES = 'checkboxes',
}

export interface Filter {
  name: string
  value: string[]
  label?: string
  type?: FilterTypes
  activeFilterLabel?: string
  minLimit?: number
  maxLimit?: number
  step?: number
  list?: string[]
}

export interface FilterAdditionalData {
  name: string
  type?: FilterTypes
  activeFilterLabel?: string
  minLimit?: number
  maxLimit?: number
  step?: number
  list?: string[]
  tooltipText?: string
}
