export const sections = ['hosts', 'listings']
export const [SECTION_HOST, SECTION_LISTING] = sections

export const rowHeight = 50

export const sizeToGridHeight = {
  small: 14 * rowHeight,
  large: 20 * rowHeight,
}

export const sizeToHostPanelHeight = {
  small: 15.5 * rowHeight,
  large: 21.5 * rowHeight,
}
