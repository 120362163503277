import React, { FC } from 'react'
import Checkbox from '@mui/material/Checkbox'
import { CheckboxProps } from '@mui/material'

const checkboxButtonIconUrl = require('./checkboxButton.svg')
const checkboxButtonActiveIconUrl = require('./checkboxButtonActive.svg')

const CustomCheckboxButtonItem: FC<CheckboxProps> = props => (
  <Checkbox
    icon={<img src={checkboxButtonIconUrl} alt='checkbox button' />}
    checkedIcon={<img src={checkboxButtonActiveIconUrl} alt='selected checkbox button' />}
    sx={{
      padding: 0,
    }}
    {...props}
  />
)

export default CustomCheckboxButtonItem
