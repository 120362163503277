import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import propTypes from 'prop-types'
import cx from 'classnames'
import { injectIntl } from 'react-intl'
import userService from 'services/user'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { selectCurrentUser } from 'store/global'
import { setModalDisplayed } from 'store/settings'
import messages from './messages'
import styles from './Navigation.scss'
import { useHistory } from 'react-router-dom'

import { shouldDisplaySettings } from './specs'

const Navigation = ({ intl }) => {
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)
  const history = useHistory()

  const links = [
    {
      key: 'dashboard',
      path: '/dashboard',
      iconName: 'dashboard',
    },
    {
      key: 'businesses',
      path: '/businesses',
      iconName: 'businesses',
    },
    {
      key: 'rentals',
      path: '/rentals',
      iconName: 'rentals',
    },
    {
      key: 'crypto',
      path: '/nft',
      iconName: 'nft',
    },
    {
      key: 'influencers',
      path: '/influencers',
      iconName: 'influencers',
    },
    {
      key: 'ecommerce',
      path: '/ecommerce',
      iconName: 'ecommerce',
    },
    {
      key: 'freelancers',
      path: '/freelancers',
      iconName: 'freelancers',
    },
    {
      key: 'offshore',
      path: '/offshore',
      iconName: 'offshore',
    },
    {
      key: 'data-science',
      path: '/data-science',
      iconName: 'data-science',
    },
    {
      key: 'company-overview',
      path: '/company-overview',
      iconName: 'company-overview',
    },
    {
      key: 'fatca',
      path: '/fatca',
      iconName: 'fatca',
    },
    {
      key: 'service-providers',
      path: '/service-providers',
      iconName: 'service-providers',
    },
    {
      key: 'management',
      path: '/management',
      iconName: 'organizations',
      isDisplayed: userService.isEitherAdminType,
    },
    {
      key: 'settings',
      onClick: () => dispatch(setModalDisplayed({ isDisplayed: true })),
      iconName: 'settings',
      isDisplayed: user => shouldDisplaySettings(user, history.location.pathname),
    },
  ]

  return (
    <nav className={styles.container}>
      <ul className={styles.links}>
        {links
          .filter(({ isDisplayed = () => true }) => isDisplayed(currentUser))
          .map(({ key, path, onClick, iconName }, index) => (
            <li
              key={key}
              className={cx(styles.link, {
                [styles.last]: index === links.length - 1,
              })}
            >
              <Button
                color='link'
                to={path}
                onClick={onClick}
                title={intl.formatMessage(messages[key])}
                className={styles.button}
              >
                <Icon name={iconName} color='link' />
              </Button>
            </li>
          ))}
      </ul>
    </nav>
  )
}

Navigation.propTypes = {
  intl: propTypes.object.isRequired,
}

export default injectIntl(Navigation)
