import { defineMessages } from 'react-intl'

const scope = 'app.components.ContactCatd'

export default defineMessages({
  phones: {
    id: `${scope}.phones`,
    defaultMessage: 'Phones',
  },
  emails: {
    id: `${scope}.emails`,
    defaultMessage: 'E-mails',
  },
  weblinks: {
    id: `${scope}.weblinks`,
    defaultMessage: 'Web Links',
  },
  addresses: {
    id: `${scope}.addresses`,
    defaultMessage: 'Addresses',
  },
  wallets: {
    id: `${scope}.wallets`,
    defaultMessage: 'Wallets',
  },
})
