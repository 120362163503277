import React from 'react'
import ContentLoader from 'react-content-loader'

const SummaryLoader = () => (
  <ContentLoader
    height={54}
    width={320}
    speed={1}
    viewBox='0 0 220 94'
    backgroundColor='#1C2842'
    foregroundColor='#ecebeb'
  >
    <rect x='0' y='16' rx='3' ry='3' width='100' height='14' />
    <rect x='0' y='54' rx='3' ry='3' width='10' height='10' />
    <rect x='14' y='54' rx='3' ry='3' width='30' height='10' />
    <rect x='0' y='70' rx='3' ry='3' width='50' height='13' />
    <rect x='70' y='54' rx='3' ry='3' width='10' height='10' />
    <rect x='84' y='54' rx='3' ry='3' width='30' height='10' />
    <rect x='70' y='70' rx='3' ry='3' width='50' height='13' />
    <rect x='140' y='54' rx='3' ry='3' width='10' height='10' />
    <rect x='154' y='54' rx='3' ry='3' width='30' height='10' />
    <rect x='140' y='70' rx='3' ry='3' width='50' height='13' />
  </ContentLoader>
)

export default SummaryLoader
