/* eslint-disable no-unused-vars */

/**
 * Create the store with dynamic reducers
 */

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { compose } from 'redux'
import createReducer from './reducers'

export default function createStore(initialState = {}, history) {
  let composeEnhancers = compose

  // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
  /* istanbul ignore next */
  if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
    /* eslint-disable no-underscore-dangle */
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
  }

  // Create the store with two middlewares
  // 2. routerMiddleware: Syncs the location/URL path to the state

  const rootReducer = createReducer()

  const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  })

  const store = configureStore({
    reducer: rootReducer,
    middleware: customizedMiddleware,
  })

  // Extensions
  store.injectedReducers = {} // Reducer registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers))
    })
  }

  return store
}
