export const overviewMapping: {
  key: string
  label: string
  tooltipText?: string
}[] = [
  { key: 'siteId', label: 'Site ID' },
  { key: 'accountId', label: 'Account ID' },
  { key: 'name', label: 'Site Name' },
  { key: 'accountName', label: 'Account Name' },
  { key: 'customerName', label: 'Customer Name' },
  { key: 'accountType', label: 'Account Type' },
  { key: 'naicsCdtfa', label: 'NAICS CDTFA' },
  { key: 'naicsIvix', label: 'NAICS IVIX' },
  {
    key: 'qtrYieldIvix',
    label: 'Total Tax Adjustment',
    tooltipText: 'Total adjusted taxes according to the IVIX model',
  },
  { key: 'salesTaxesDue', label: 'CDTFA Tax Due' },
]
