// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ExportEntityActivityCSV-module__container--nVcNr{margin-right:12px}.ExportEntityActivityCSV-module__container--nVcNr .ExportEntityActivityCSV-module__share--wwci6{width:32px;height:32px;display:flex;align-items:center;justify-content:center;margin-left:auto}.ExportEntityActivityCSV-module__container--nVcNr .ExportEntityActivityCSV-module__share--wwci6:disabled{height:35px}.ExportEntityActivityCSV-module__container--nVcNr .ExportEntityActivityCSV-module__share--wwci6:disabled svg{overflow:hidden;animation:ExportEntityActivityCSV-module__rotation--sW1Kq 6s infinite linear}", "",{"version":3,"sources":["webpack://./app/containers/VerticalPage/nft/ExportEntityActivityCSV/ExportEntityActivityCSV.module.scss"],"names":[],"mappings":"AAAA,kDACE,iBAAA,CACA,gGACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,gBAAA,CACA,yGAKE,WAAA,CAJA,6GACE,eAAA,CACA,4EAAA","sourcesContent":[".container {\n  margin-right: 12px;\n  .share {\n    width: 32px;\n    height: 32px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-left: auto;\n    &:disabled {\n      svg {\n        overflow: hidden;\n        animation: rotation 6s infinite linear;\n      }\n      height: 35px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ExportEntityActivityCSV-module__container--nVcNr",
	"share": "ExportEntityActivityCSV-module__share--wwci6",
	"rotation": "ExportEntityActivityCSV-module__rotation--sW1Kq"
};
export default ___CSS_LOADER_EXPORT___;
