import { useQuery } from '@tanstack/react-query'
import { fetchVerticalTableOverviewData } from 'services/verticalsTablesApi'
import { BaseTableOverview } from 'utils/types'
import { Filter } from 'components/FilterDrawer/FilterDrawer.config'

interface UseVerticalOverviewQueryProps {
  verticalIndices: string
  currentQueryKey: string
  searchValue?: string
  filters?: Filter[]
}

export const useVerticalOverviewQuery = <T extends BaseTableOverview>({
  verticalIndices,
  currentQueryKey,
  searchValue,
  filters,
}: UseVerticalOverviewQueryProps) =>
  useQuery<T | null>({
    queryKey: [currentQueryKey, verticalIndices, searchValue, filters],
    queryFn: () => (verticalIndices ? fetchVerticalTableOverviewData(verticalIndices, searchValue, filters) : null),
  })
