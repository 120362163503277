import React, { createContext, useState, useContext } from 'react'

const SelectedRowsContext = createContext({
  selectedRows: [],
  setSelectedRows: (rows: any) => {},
})

export const SelectedRowsProvider = ({ children }: any) => {
  const [selectedRows, setSelectedRows] = useState([])

  return (
    <SelectedRowsContext.Provider value={{ selectedRows, setSelectedRows }}>{children}</SelectedRowsContext.Provider>
  )
}

export const useSelectedRows = () => useContext(SelectedRowsContext)
