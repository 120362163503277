import React from 'react'
import Chip from '@mui/material/Chip'
import CloseIcon from '@mui/icons-material/Close'
import { format } from 'date-fns'
import { Filter, FilterAdditionalData, FilterTypes } from 'components/FilterDrawer/FilterDrawer.config'
import { handleDeleteFilter, handleDeleteRangeFilter } from 'components/FilterDrawer/utils'
import { getAbbreviatedNumberString } from 'components/Income/Income'
import { getSplitFilterMapping } from './utils'
import modularStyles from './ActiveFilters.module.scss'
import { FilterOptions } from 'containers/CDTFATablePage/types'

interface ActiveFiltersProps {
  activeFilters: Filter[]
  setActiveFilters: React.Dispatch<React.SetStateAction<Filter[]>>
  styles: { [key: string]: string }
  filterOptions?: FilterOptions
  filterAdditionalData?: FilterAdditionalData[]
  setFilters?: React.Dispatch<React.SetStateAction<Filter[]>>
  shouldUseRawRangeValues?: boolean
}

const ActiveFilters = ({
  activeFilters,
  setActiveFilters,
  styles,
  filterAdditionalData,
  filterOptions,
  setFilters,
  shouldUseRawRangeValues,
}: ActiveFiltersProps) => {
  const { rangeFilters, booleanFilters, defaultFilters } = getSplitFilterMapping(activeFilters)

  return (
    <div className={styles.activeFiltersContainer}>
      {activeFilters.length > 0 && (
        <div className={styles.chipsContainer}>
          {defaultFilters
            .flatMap(filter => filter.value.map(value => ({ name: filter.name, value, type: filter.type })))
            .map(({ name, value, type }) => {
              const formattedValue = type === FilterTypes.DATE_BIT ? format(new Date(value), 'MMMM, yyyy') : value

              return (
                <Chip
                  key={`${name}-${value}`}
                  label={formattedValue}
                  onDelete={() => handleDeleteFilter(name, value, activeFilters, setActiveFilters, setFilters)}
                  variant='outlined'
                  color='primary'
                  className={styles.chip}
                  deleteIcon={<CloseIcon fontSize='small' className={styles.deleteIcon} />}
                />
              )
            })}
          {rangeFilters.map((filter, index) => {
            let minValue, maxValue
            if (filter?.type === FilterTypes.ROBUST_RANGE) {
              const additionalData = filterAdditionalData?.find(el => el.name === filter.name)
              minValue = filter.value?.[0] || additionalData?.minLimit
              maxValue = filter.value?.[1] || additionalData?.maxLimit
            } else if (filterOptions && FilterTypes.RANGE === filter.type) {
              const currentFilterOptions = filterOptions[filter.name]
              minValue = filter.value?.[0] || currentFilterOptions[0].value[0]
              maxValue = filter.value?.[1] || currentFilterOptions[0].value[1]
            }

            return (
              <Chip
                key={index}
                label={
                  <div className={modularStyles.rangeFilters}>
                    <span>from</span>
                    <span className={modularStyles.number}>
                      {shouldUseRawRangeValues ? minValue : getAbbreviatedNumberString(Number(minValue))}
                    </span>
                    <span>to</span>
                    <span className={modularStyles.number}>
                      {shouldUseRawRangeValues ? maxValue : getAbbreviatedNumberString(Number(maxValue))}
                    </span>
                  </div>
                }
                onDelete={() => handleDeleteRangeFilter(filter.name, activeFilters, setActiveFilters, setFilters)}
                variant='outlined'
                color='primary'
                className={styles.chip}
                deleteIcon={<CloseIcon fontSize='small' className={styles.deleteIcon} />}
              />
            )
          })}
          {booleanFilters.map((filter, index) => (
            <Chip
              key={index}
              label={<div className={modularStyles.rangeFilters}>{filter.activeFilterLabel}</div>}
              onDelete={() =>
                handleDeleteFilter(filter.name, filter.value?.[0], activeFilters, setActiveFilters, setFilters)
              }
              variant='outlined'
              color='primary'
              className={styles.chip}
              deleteIcon={<CloseIcon fontSize='small' className={styles.deleteIcon} />}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default ActiveFilters
