import { defineMessages } from 'react-intl'

const scope = 'app.components.Grid'

export default defineMessages({
  noResults: {
    id: `${scope}.noResults`,
    defaultMessage: 'No Results',
  },
})
