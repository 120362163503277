import React, { useMemo } from 'react'
import { FormattedNumber, injectIntl } from 'react-intl'
import cx from 'classnames'
import SummarySection from 'components/SummarySection'
import TitledContent from 'components/TitledContent'
import NoData from 'components/NoData'
import BoxDecoration from 'components/BoxDecoration'
import messages from './messages'
import styles from './CDTFATablePageOverview.scss'
import { NOT_AVAILABLE } from 'utils/constants'
import { formatNumber } from 'utils/formatters'
import { CDTFAPieChart } from 'components/cdtfa/CDTFAPieChart'
import { PieChartLegends } from 'components/PieChart/index'

const CDTFA_SITES = [
  {
    key: 'total',
  },
  {
    key: 'updatedNaics',
  },
  {
    key: 'withWebPresence',
  },
]
const CDTFA_ACCOUNTS = [
  {
    key: 'total',
  },
  {
    key: 'underReportedAccounts',
  },
  {
    key: 'totalAdjustedTax',
  },
]

const arcColors = ['#1D60FF', '#79A2F8', '#0D47A1', '#495FA6']

function CDTFATablePageOverview({ intl, isLoading, summary }) {
  const { sitesCount, accountsCount, rawTaxChart } = summary

  const taxChart = useMemo(
    () =>
      rawTaxChart?.map((data, index) => ({
        id: `(${data?.percentageFromTotal ? `${data.percentageFromTotal}%` : NOT_AVAILABLE}) ${data.docsCount}, ${formatNumber(data.taxAmount, null, { style: 'decimal' })}`,
        value: data.taxAmount,
        label: data.key,
        color: arcColors[index],
      })) || [],
    [rawTaxChart],
  )

  return (
    <div className={styles.container}>
      <BoxDecoration />
      <SummarySection name='sites' isLoading={isLoading}>
        {CDTFA_SITES.map(({ key }) => {
          return (
            <TitledContent
              key={key}
              title={messages?.[key] ? intl.formatMessage(messages[key]) : 'Loading'}
              className={cx(styles.entity, styles[key])}
              titleClassName={styles.sectionTitle}
            >
              {sitesCount?.[key] ? <FormattedNumber value={sitesCount?.[key] || 0} /> : NOT_AVAILABLE}
            </TitledContent>
          )
        })}
      </SummarySection>
      <SummarySection name='accounts' isLoading={isLoading}>
        {CDTFA_ACCOUNTS.map(({ key }) => {
          return (
            <TitledContent
              key={key}
              title={messages[key] ? intl.formatMessage(messages[key]) : 'Loading'}
              className={styles.income}
              titleClassName={styles.sectionTitle}
            >
              {!accountsCount?.[key] ? (
                <NoData />
              ) : key === 'totalAdjustedTax' ? (
                formatNumber(accountsCount.totalAdjustedTax, null, { style: 'decimal' })
              ) : (
                <FormattedNumber value={accountsCount[key] || 0} />
              )}
            </TitledContent>
          )
        })}
      </SummarySection>
      <SummarySection name={'adjustedTaxAccounts'} isLoading={isLoading}>
        <CDTFAPieChart
          data={taxChart}
          className={styles.pie}
          innerClassName={styles.totalResolvers}
          underReportedAccounts={accountsCount.underReportedAccounts}
        />
        <PieChartLegends legends={taxChart || []} isCDTFA />
      </SummarySection>
    </div>
  )
}

export default injectIntl(CDTFATablePageOverview)
