import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Input from 'components/Input'
import 'react-input-range/lib/css/index.css'
import styles from './Range.scss'

const _ = require('lodash')

const options = ['Pick', 'Greater than', 'Equal to', 'Less than', 'Between']
const [PICK, MORE, EXACT, FEWER, BETWEEN] = options
const MIN = 'min'
const MAX = 'max'
const EQUAL = 'exact'

export default function Range(props) {
  const { value: activeValue, onChange } = props

  const [value, setValue] = useState({ picker: PICK })

  const resetValues = () => setValue({ picker: PICK })

  useEffect(() => {
    if (activeValue && !_.isEqual(activeValue, {})) {
      setValue(activeValue)
      return
    }

    resetValues()
  }, [activeValue])

  const onManualChange = (type, nextValue) => {
    if (nextValue !== 0 && !nextValue) {
      resetValues()
      return
    }

    const manualValue = {
      ...value,
      [type]: nextValue,
    }

    setValue(manualValue)
  }

  const onBlur = () => onChange(value)

  return (
    <div className={styles.container}>
      <Input
        type='select'
        value={value.picker}
        onChange={picker => {
          setValue({ picker })
          if (picker === PICK) {
            onChange({ picker })
          }
        }}
      >
        {options.map((option, index) => (
          <option key={index}>{option}</option>
        ))}
      </Input>
      {value.picker === FEWER && (
        <div className={styles.inputs}>
          <Input
            type='number'
            value={value[MAX]}
            onChange={nextValue => onManualChange(MAX, nextValue)}
            onBlur={onBlur}
            className={styles.input}
          />
        </div>
      )}
      {value.picker === MORE && (
        <div className={styles.inputs}>
          <Input
            type='number'
            value={value[MIN]}
            onChange={nextValue => onManualChange(MIN, nextValue)}
            onBlur={onBlur}
            className={styles.input}
          />
        </div>
      )}
      {value.picker === EXACT && (
        <div className={styles.inputs}>
          <Input
            type='number'
            value={value[EQUAL]}
            onChange={nextValue => onManualChange(EQUAL, nextValue)}
            onBlur={onBlur}
            className={styles.input}
          />
        </div>
      )}
      {value.picker === BETWEEN && (
        <div className={styles.inputs}>
          <Input
            type='number'
            value={value[MIN]}
            hint='min'
            onChange={nextValue => onManualChange(MIN, nextValue)}
            onBlur={onBlur}
            className={styles.input}
          />
          <span className={styles.separator}>-</span>
          <Input
            type='number'
            hint='max'
            value={value[MAX]}
            onChange={nextValue => onManualChange(MAX, nextValue)}
            onBlur={onBlur}
            className={styles.input}
          />
        </div>
      )}
    </div>
  )
}

Range.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
  ]),
}
