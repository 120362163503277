import { MRT_ColumnDef } from 'material-react-table'
import { BaseEntityForTable } from 'utils/types'

export const mergeColumns = <T extends BaseEntityForTable>(
  baseColumns: MRT_ColumnDef<T>[],
  verticalColumns: MRT_ColumnDef<T>[],
): MRT_ColumnDef<T>[] => {
  const finalColumns = [...baseColumns]
  verticalColumns.forEach(verticalColumn => {
    const columnGroupIndex = finalColumns.findIndex(columnGroup => columnGroup.header === verticalColumn.header)
    if (columnGroupIndex > -1) {
      finalColumns[columnGroupIndex].columns!.push(...verticalColumn.columns!)
    }
  })
  return finalColumns
}
