import { combineReducers } from 'redux'
import languageProviderReducer from 'containers/LanguageProvider/reducer'
import { globalReducer } from 'store/global'
import { settingsReducer } from 'store/settings'
import { dashboardReducer } from 'store/dashboard'
import { businessesReducer } from 'store/businesses'
import { businessReducer } from 'store/business'
import { businessRequestsReducer } from 'store/businessRequests'
import { organizationsReducer } from 'store/organizations'
import { rentalsReducer } from 'store/rentals'
import { NFTReducer } from 'store/nft'
import { NFTMapperReducer } from 'store/nftMapper'

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    language: languageProviderReducer,
    global: globalReducer,
    settings: settingsReducer,
    dashboard: dashboardReducer,
    businesses: businessesReducer,
    business: businessReducer,
    businessRequests: businessRequestsReducer,
    organizations: organizationsReducer,
    rentals: rentalsReducer,
    nft: NFTReducer,
    nftMapper: NFTMapperReducer,
    ...injectedReducers,
  })

  return rootReducer
}
