import React, { ReactNode } from 'react'
import styles from './styles.module.scss'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
    this.resetError = this.resetError.bind(this)
  }

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error('Uncaught error:', error, errorInfo)
  }

  componentDidMount(): void {
    window.addEventListener('click', this.resetError)
  }

  resetError() {
    if (this.state.hasError) {
      this.setState({ hasError: false })
    }
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <div className={styles.container}>
          <h2>Something went wrong.</h2>
          <p>We are sorry for the inconvenience. Please try again later.</p>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
