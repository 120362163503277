import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Form } from 'reactstrap'
import DropdownInput from 'components/DropdownInput'
import Button from 'components/Button'
import Input from 'components/Input'
import Icon from 'components/Icon'
import { selectCurrentZone } from 'store/global'
import messages from './messages'
import cx from 'classnames'
import styles from './GridActions.scss'

function GridActions(props) {
  const {
    intl,
    search,
    setSearch,
    searchFields,
    setSearchFields,
    withSearch = true,
    searchableFields = [],
    onFieldsChange = () => {},
    allColumns = [],
    displayedColumns = [],
    onColumnsChange,
    onSearch = () => {},
    withShare = false,
    onShare = () => {},
    isShareDisabled = false,
    filters = {},
    isReducedSearchSize,
    children,
  } = props

  const currentZone = useSelector(selectCurrentZone)

  const { search: activeSearch = '' } = filters

  const [searchChips, setSearchChips] = useState([])
  const [didClearSearch, setDidClearSearch] = useState(false)

  const resetSearchChips = () => {
    setSearchChips([])
    setSearchFields(searchableFields.map(field => field.value))
  }

  useEffect(() => {
    setSearch('')
    resetSearchChips()
  }, [currentZone])

  useEffect(() => {
    configureSearchFields(searchableFields)
  }, [])

  const onSearchSubmit = e => {
    e.preventDefault()
    if (search !== activeSearch) onSearch()
  }

  const configureSearchFields = selections => {
    if (selections.length === 0) return

    const selectionsMap = selections.reduce(
      (currentSelections, selection) => ({
        searchFields: [...currentSelections.searchFields, selection.value],
        searchChips: [...currentSelections.searchChips, { label: selection.label, value: selection.value }],
      }),
      { searchFields: [], searchChips: [] },
    )

    setSearchFields(selectionsMap.searchFields)

    const areAllFieldsSelected =
      searchableFields.length === selectionsMap.searchChips.length && searchableFields.length > 0

    setSearchChips(areAllFieldsSelected ? [] : selectionsMap.searchChips)
  }

  const onRemoveChip = clickedChip => {
    if (searchChips.length === 1) {
      resetSearchChips()
      return
    }

    const searchFieldIndex = searchFields.findIndex(field => field === clickedChip.value)
    const nextSearchFields = [...searchFields]
    nextSearchFields.splice(searchFieldIndex, 1)
  }

  useEffect(() => {
    onFieldsChange(searchFields)
  }, [searchFields])

  const handleSearchClear = () => {
    setSearch('')
    resetSearchChips()
    setDidClearSearch(true)
  }

  useEffect(() => {
    if (didClearSearch) {
      onSearch()
      setDidClearSearch(false)
    }
  }, [didClearSearch])

  const renderColumnsButton = () => (
    <Button color='light' outline className={styles.action}>
      <Icon name='columns' size='regular'>
        <FormattedMessage {...messages.actions.columns} />
      </Icon>
    </Button>
  )

  const renderSearchFieldsButton = () => (
    <Button color='link'>
      <Icon name='columns' size='regular' />
    </Button>
  )

  return (
    <div className={styles.actions}>
      {withSearch && (
        <div className={styles.search}>
          <Form onSubmit={onSearchSubmit}>
            <Input
              icon='search'
              prefix={intl.formatMessage(messages.search.prefix)}
              searchChips={searchChips}
              onRemoveChip={onRemoveChip}
              placeholder={intl.formatMessage(messages.search.placeholder)}
              value={search}
              onClear={handleSearchClear}
              onChange={nextSearch => setSearch(nextSearch)}
              className={cx(styles.searchInput, {
                [styles.withOpenMap]: isReducedSearchSize,
              })}
            />
          </Form>
          <DropdownInput
            hasSelectAll={false}
            disableSearch
            options={searchableFields}
            selections={searchableFields.filter(({ value }) => searchFields.includes(value))}
            onChange={selections => configureSearchFields(selections)}
            onToggle={isOpened => !isOpened && onFieldsChange(searchFields)}
            buttonRenderer={renderSearchFieldsButton}
            className={styles.dropdown}
          />
        </div>
      )}
      <DropdownInput
        hasSelectAll
        disableSearch
        options={allColumns}
        selections={allColumns.filter(({ value }) => displayedColumns.includes(value))}
        onChange={onColumnsChange}
        buttonRenderer={renderColumnsButton}
      />
      {withShare && (
        <Button color='light' outline onClick={onShare} className={styles.share} disabled={isShareDisabled}>
          <Icon name='share' size='regular'>
            <FormattedMessage {...messages.actions.share} />
          </Icon>
        </Button>
      )}
      {children}
    </div>
  )
}

GridActions.propTypes = {
  withShare: PropTypes.bool,
  withSearch: PropTypes.bool,
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  searchFields: PropTypes.array.isRequired,
  setSearchFields: PropTypes.func.isRequired,
  searchableFields: PropTypes.array,
  onFieldsChange: PropTypes.func,
  allColumns: PropTypes.array,
  displayedColumns: PropTypes.array,
  onColumnsChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  onShare: PropTypes.func,
  isShareDisabled: PropTypes.bool,
  filters: PropTypes.object,
  children: PropTypes.any,
}

export default injectIntl(GridActions)
