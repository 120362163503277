import React from 'react'
import Drawer from '@mui/material/Drawer'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Icon from 'components/Icon'
import styles from './GenericDrawer.module.scss'
import Divider from '@mui/material/Divider'

interface GenericDrawerProps {
  open: boolean
  onClose: () => void
  title: string
  iconName?: string
  children: React.ReactNode
  optionalAction?: () => void
  optionalActionLabel?: string
}

const GenericDrawer = ({
  open,
  onClose,
  title,
  iconName,
  children,
  optionalAction,
  optionalActionLabel,
}: GenericDrawerProps) => {
  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Stack spacing={2} sx={{ height: '100%' }}>
        <div className={styles.drawerContainer}>
          <div className={styles.drawerHeader}>
            <div className={styles.iconContainer}>
              {iconName && <Icon size='standard' name={iconName} />}
              <h2 className={styles.iconContainer_Title}>{title}</h2>
            </div>
            <div className={styles.actionsContainer}>
              {optionalAction && optionalActionLabel && (
                <>
                  <Button className={styles.optionalButton} onClick={optionalAction}>
                    {optionalActionLabel}
                  </Button>
                  <Divider orientation='vertical' variant='middle' flexItem />
                </>
              )}
              <Button onClick={onClose} className={styles.closeButton}>
                <Icon size='standard' name='close' />
              </Button>
            </div>
          </div>
          <div className={styles.childrenContainer}>{children}</div>
        </div>
      </Stack>
    </Drawer>
  )
}

export default GenericDrawer
