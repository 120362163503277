import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  AbstractEventObject,
  Core as CSCore,
  EdgeDefinition,
  NodeDefinition,
  Stylesheet as CSStylesheet,
} from 'cytoscape'
import CytoscapeComponent from 'react-cytoscapejs'
import { useDispatch } from 'react-redux'
import { setGraphData } from 'store/global'
import layout from './layout'
import { alternateStyle, defaultStyle } from './stylesheet'
import { CSEventHandlers, TCSEventHandlersIncludingInjected } from './edgehandles'
import HeaderActions from './HeaderActions/HeaderActions'
import { tabsIds } from './HeaderActions/utils'
import ZoomGraph from './ZoomGraph/ZoomGraph'
import styles from './Graph.scss'

export interface IElemDetails {
  group: 'nodes' | 'edges'
  properties: { [key: string]: any }
}

export interface Action {
  name: 'remove' | 'add' | 'hide'
  param: any
}

export interface MenuItem {
  label: string
  action: (target: AbstractEventObject['target']) => void
}

export interface MenuPosition {
  x: number
  y: number
}

interface IGraphProps {
  nodes: NodeDefinition[]
  edges: EdgeDefinition[]
  setShowGraph: React.Dispatch<React.SetStateAction<boolean>>
}

interface HoveredNodeData {
  label: null | string
  x: number
  y: number
}

const styleSheets = {
  default: defaultStyle,
  alternative: alternateStyle,
}

const Graph = ({ nodes, edges, setShowGraph }: IGraphProps) => {
  const dispatch = useDispatch()

  const [tabItem, setTabItem] = useState<number>(0)
  const [hoveredNodeData, setHoveredNodeData] = useState<HoveredNodeData>({ label: null, x: 0, y: 0 })

  // we are using ref for saving previous label value when doing on hover
  // to avoid many times re-render
  const prevLabelRef = useRef(null)

  // Cytoscape instance
  const cyRef = useRef<CSCore | null>(null)
  const currStyle = useRef<keyof typeof styleSheets>('default')
  // Entity graph and general graph require different layout configurations
  const layoutState = useRef({
    ...layout,
    randomize: true,
    quality: 'default',
    initialEnergyOnIncremental: 0.3,
  })

  useEffect(
    () =>
      // Clean up
      () => {
        dispatch(setGraphData({ nodes: [], edges: [] }))
        if (cyRef.current) cyRef.current.destroy()
      },
    [],
  )

  const initListeners = () => {
    if (!cyRef.current) return

    cyRef.current.off('dblclick').on('dblclick', e => {
      // Open nodes url on double click
      const clickedElement = e.target.json()
      if (clickedElement.group === 'nodes') {
        if (!clickedElement.data.labels.some((x: string) => x.includes('PHOTO'))) {
          window.open(clickedElement.data.key, '_blank')
        }
      }
    })

    cyRef.current.on('mousemove', e => {
      const element = e.target.json()
      if (element.group === 'nodes') {
        if (!element.data.labels.some((x: string) => x.includes('PHOTO'))) {
          const label = element.data.key

          if (label !== prevLabelRef.current) {
            prevLabelRef.current = label
            const position = {
              x: e.originalEvent.x,
              y: e.originalEvent.y,
            }
            setHoveredNodeData({ label, ...position })
          }
        }
      } else if (prevLabelRef.current !== null) {
        prevLabelRef.current = null
        setHoveredNodeData({ label: null, x: 0, y: 0 })
      }
    })
  }

  const unbindListeners = () => {
    if (!cyRef.current) return
    CSEventHandlers.forEach(handler => {
      if (cyRef.current) {
        cyRef.current.unbind(handler as TCSEventHandlersIncludingInjected)
      }
    })
  }

  const init = useCallback(
    (cy: CSCore) => {
      if (!cyRef.current) cyRef.current = cy
      unbindListeners()
      initListeners()
    },
    [nodes, edges],
  )

  const resize = () => cyRef.current?.center()
  useEffect(() => {
    window.addEventListener('resize', resize)

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  const addPhotoStyles = useMemo(() => {
    const styles: CSStylesheet[] = []
    nodes.forEach(node => {
      if (node.data?.desc) {
        styles.push({
          selector: `node[desc="${node.data.desc}"]`,
          css: {
            'border-color': '#5d7281',
            'background-image': node.data.key,
            // @ts-ignore
            'background-image-crossorigin': 'null',
            color: 'white',
            'text-background-opacity': 0.6,
            'text-background-shape': 'roundrectangle',
            'text-background-padding': '0.5px',
          },
        })
      }
    })
    return styles
  }, [nodes])

  const onZoom = (zoomValue: number) => {
    const cy = cyRef?.current

    if (cy) {
      let zoom = cy.zoom()
      zoom *= zoomValue
      cy.zoom(zoom)
    }
  }

  return (
    <div className={styles.container}>
      <HeaderActions tabItem={tabItem} setTabItem={setTabItem} onCloseGraph={() => setShowGraph(false)} />
      {tabItem === tabsIds.GRAPH && (
        <>
          <CytoscapeComponent
            elements={CytoscapeComponent.normalizeElements({
              nodes,
              edges,
            })}
            layout={layoutState.current}
            cy={cy => init(cy)}
            userZoomingEnabled={false}
            zoom={1}
            stylesheet={[...styleSheets[currStyle.current], ...addPhotoStyles]}
            className={styles.graph}
          />
          <ZoomGraph onZoom={onZoom} />
          {hoveredNodeData.label && (
            <div className={styles.hoveredNodeData} style={{ left: hoveredNodeData.x, top: hoveredNodeData.y }}>
              {hoveredNodeData.label}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default memo(Graph)
