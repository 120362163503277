import { defineMessages } from 'react-intl'

const scope = 'app.containers.FreelancersTablePage'

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Freelancers Table',
  },
  count: {
    id: `${scope}.count`,
    defaultMessage: 'Freelancers: {displayed} of {total}',
  },
  filters: {
    id: `${scope}.filters`,
    defaultMessage: 'Filters',
  },
})
