import { Filter, FilterTypes } from 'components/FilterDrawer/FilterDrawer.config'
import { ICompanyOverviewSummary } from 'containers/CompanyOverviewTablePage/types'

export const PIE_LEGENDS_COLORS: Record<keyof ICompanyOverviewSummary, string[]> = {
  industries: ['#2157C5', '#FFCC00', '#90ED7D', '#E200E7', '#00C2FF'],
  employees: ['#FFB0B0', '#FF6161', '#FFDBBD', '#F7A35C', '#FFEFB8'],
  companies: ['#7CB5EC', '#1DBFBF', '#90ED7D', '#F7A35C', '#E200E7'],
}

export const filtersInitialState: Filter[] = [
  {
    type: FilterTypes.CHIPS,
    name: 'overviewDetails.industry',
    label: 'Industry',
    value: [],
  },
  {
    type: FilterTypes.CHIPS,
    name: 'entityDetails.location',
    label: 'Headquarters Location',
    value: [],
  },
  {
    type: FilterTypes.CHIPS,
    name: 'totals.jurisdictions',
    label: 'Jurisdictions',
    value: [],
  },
  {
    type: FilterTypes.CHIPS,
    name: 'totals.localDepartments',
    label: 'Local Departments',
    value: [],
  },
  {
    type: FilterTypes.BOOL,
    name: 'totals.structureChange',
    label: 'Structure Changed',
    activeFilterLabel: 'Structure Changed',
    value: [],
  },
]

export const structureChangeTooltip = {
  name: 'totals.structureChange',
  tooltipText:
    'This filter identifies companies that have experienced a significant reduction in their local workforce, specifically a decrease of at least 30% year-over-year, in any given year. The filter is applicable only if the reduction amounts to a minimum of 10 employees, helping you pinpoint substantial structural changes within companies.',
}
