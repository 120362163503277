import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { injectIntl } from 'react-intl'
import RentalsMap from 'components/rentals/RentalsMap'
import RentalsItemAnalytics from 'components/rentals/RentalsItemAnalytics'
import RentalsItemGrid from 'components/rentals/RentalsItemGrid'
import Button from 'components/Button'
import UserAvatar from 'components/UserAvatar'
import Icon from 'components/Icon'
import BusinessSource from '../../BusinessSource'
import { sizeToHostPanelHeight } from 'containers/Rentals/specs'
import {
  setDisplayedHost,
  selectDisplayedHostListings,
  setSelectedHostsListings,
  selectIsLoadingHostListingsNextBatch,
  fetchHostListings,
} from 'store/rentals'
import { selectCurrentZone, selectPlatform, selectStaticFilesUrl } from 'store/global'
import { camelCase } from 'lodash'
import styles from './RentalsHostPanel.scss'

import { gridColumns } from './specs'
import { extractRootDomainNoExt } from '../../../containers/NFT/specs'

function RentalsHostPanel({ intl, height = sizeToHostPanelHeight.small, host, previousZone }) {
  const dispatch = useDispatch()

  const platform = useSelector(selectPlatform)
  const staticFilesUrl = useSelector(selectStaticFilesUrl)
  const relevantListings = useSelector(selectDisplayedHostListings)
  const isLoadingNextBatch = useSelector(selectIsLoadingHostListingsNextBatch)
  const currentZone = useSelector(selectCurrentZone)

  const sites = typeof host.hostVerificationPage === 'string' ? [host.hostVerificationPage] : host.hostVerificationPage

  const userAvatarImageUrl =
    platform === 'saas' ? host.airbnbProfilePhoto : `http://${staticFilesUrl}/images/hosts/${host.hostSiteId}.jpg`

  const onClose = () => {
    dispatch(setDisplayedHost())
    dispatch(setSelectedHostsListings())
  }

  const onLoadMore = () => {
    if (isLoadingNextBatch) return

    dispatch(
      fetchHostListings({
        hostId: host.hostSiteId,
        from: relevantListings.length,
      }),
    )
  }

  useEffect(() => {
    if (currentZone !== previousZone) onClose()
  }, [currentZone])

  return (
    <div className={styles.container} style={{ height }}>
      <header className={styles.header}>
        <div
          className={cx(styles.avatar, {
            [styles.verifiedAvatarContainer]: host.isIdentityVerified === 1,
          })}
        >
          <UserAvatar imageUrl={userAvatarImageUrl} alt={host.hostName} />
          {host.isIdentityVerified === 1 && (
            <Icon className={styles.verifiedIcon} name='verified-filled' size='medium' />
          )}
        </div>
        <div className={styles.hostDetails}>
          {host.isIdentityVerified === 1 && <h3 className={styles.title}>{host.verifiedHostName}</h3>}
          {host.isIdentityVerified === 0 && <h3 className={styles.title}>{host.hostName}</h3>}
          {host.isIdentityVerified === 0 && (
            <span className={styles.single}>
              <a href={host.airbnbHostProfile} target='_blank' rel='noreferrer'>
                <BusinessSource source='airbnb' isClickable />
              </a>
            </span>
          )}
          {host.isIdentityVerified === 1 && (
            <div className={styles.multiple}>
              <span>
                <a href={host.airbnbHostProfile} target='_blank' rel='noreferrer'>
                  <BusinessSource source='airbnb' isClickable />
                </a>
              </span>
              <span>
                {sites
                  .filter(url => url)
                  .map(url => {
                    const domain = extractRootDomainNoExt(url)
                    return (
                      <a key={url} href={url} target='_blank' rel='noreferrer'>
                        <BusinessSource source={camelCase(domain)} isClickable className={styles.svg} />
                      </a>
                    )
                  })}
              </span>
            </div>
          )}
          <section className={styles.detailsContainer}>
            <div className={styles.details}>
              <span className={styles.icon}>
                <Icon name='home' size='regular'>
                  {String(process.env.PRESENT_TOTO_INFORMATION).toLowerCase() === 'true' &&
                    host?.manualAdditions?.entityAddress && <>Lives in {host?.manualAdditions?.entityAddress}</>}
                  {host.hostStateLocator &&
                    (String(process.env.PRESENT_TOTO_INFORMATION).toLowerCase() !== 'true' ||
                      !host?.manualAdditions?.entityAddress) && <>Lives in {host.hostStateLocator}</>}
                </Icon>
              </span>
              <span className={styles.icon}>
                {String(process.env.PRESENT_TOTO_INFORMATION).toLowerCase() === 'true' &&
                  host?.manualAdditions?.birthday && (
                    <Icon name='cake' size='regular'>
                      {String(process.env.PRESENT_TOTO_INFORMATION).toLowerCase() === 'true' &&
                        host?.manualAdditions?.birthday &&
                        host?.manualAdditions?.birthday.split('/').length === 3 &&
                        new Date(host?.manualAdditions?.birthday).toLocaleDateString('en-us', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })}
                      {String(process.env.PRESENT_TOTO_INFORMATION).toLowerCase() === 'true' &&
                        host?.manualAdditions?.birthday &&
                        host?.manualAdditions?.birthday.split('/').length === 2 &&
                        new Date(`2001/${host?.manualAdditions?.birthday}`).toLocaleDateString('en-us', {
                          month: 'long',
                          day: 'numeric',
                        })}
                    </Icon>
                  )}
              </span>
            </div>

            <div className={styles.details}>
              <span className={styles.icon}>
                <Icon name='calendar' size='regular'>
                  Joined in{' '}
                  {new Date(host.hostMemberSince).toLocaleDateString('en-us', {
                    year: 'numeric',
                    month: 'long',
                  })}
                </Icon>
              </span>
              <span className={styles.icon}>
                <Icon name='star' size='regular'>
                  <span>{host.numberOfReviews} Reviews</span>
                </Icon>
              </span>
            </div>
          </section>
        </div>
        <div className={styles.actions}>
          <Button color='link' outline onClick={onClose} className={styles.close}>
            <Icon name='close' size='regular' className={styles.closeIcon} />
          </Button>
        </div>
      </header>
      <main className={styles.sections}>
        <section className={cx(styles.section, styles.analytics)}>
          <RentalsItemAnalytics type='host' item={host} />
        </section>
        <section className={cx(styles.section, styles.map)}>
          <RentalsMap listType='listings' clusterMode={false} showHeader={false} />
        </section>
        <section className={cx(styles.section, styles.listings)}>
          <RentalsItemGrid
            columns={gridColumns}
            rows={relevantListings}
            onScroll={onLoadMore}
            onRowClick={() => {}}
            headerRowHeight={50}
          />
        </section>
      </main>
    </div>
  )
}

RentalsHostPanel.propTypes = {
  height: PropTypes.number,
  host: PropTypes.object.isRequired,
  previousZone: PropTypes.object,
}

export default injectIntl(RentalsHostPanel)
