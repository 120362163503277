const keys = [
  'username',
  'returnUrl',
  'displayedColumns',
  'displayedSTRColumns',
  'businessesFilters',
  'hostsFilters',
  'listingsFilters',
  'lastSelectedJurisdictionForUser',
  'nftActivityFilters',
]
const [
  KEY_USERNAME,
  KEY_RETURN_URL,
  KEY_DISPLAYED_COLUMNS,
  KEY_DISPLAYED_STR_COLUMNS,
  KEY_BUSINESSES_FILTERS,
  KEY_HOSTS_FILTERS,
  KEY_LISTINGS_FILTERS,
  KEY_LAST_ZONE_FOR_USER,
  KEY_NFT_ACTIVITY_FILTERS,
] = keys

class LocalStorageService {
  get username() {
    return localStorage.getItem(KEY_USERNAME)
  }

  set username(name) {
    localStorage.setItem(KEY_USERNAME, name)
  }

  get returnUrl() {
    return localStorage.getItem(KEY_RETURN_URL)
  }

  set returnUrl(returnUrl) {
    localStorage.setItem(KEY_RETURN_URL, returnUrl)
  }

  clearReturnUrl() {
    localStorage.removeItem(KEY_RETURN_URL)
  }

  get displayedColumns() {
    const columns = localStorage.getItem(KEY_DISPLAYED_COLUMNS) || ''

    return !!columns && columns.split(',')
  }

  set displayedColumns(columns) {
    localStorage.setItem(KEY_DISPLAYED_COLUMNS, columns.join(','))
  }

  get displayedSTRColumns() {
    const columns = localStorage.getItem(KEY_DISPLAYED_STR_COLUMNS) || ''

    return !!columns && columns.split(',')
  }

  set displayedSTRColumns(columns) {
    localStorage.setItem(KEY_DISPLAYED_STR_COLUMNS, columns.join(','))
  }

  getPersistedFilters(key) {
    const filters = localStorage.getItem(key)
    return JSON.parse(filters)
  }

  setPersistedFilters(key, filters) {
    const stringifiedFilters = JSON.stringify(filters)
    localStorage.setItem(key, stringifiedFilters)
  }

  get businessesFilters() {
    return this.getPersistedFilters(KEY_BUSINESSES_FILTERS)
  }

  set businessesFilters(filters) {
    this.setPersistedFilters(KEY_BUSINESSES_FILTERS, filters)
  }

  get nftActivityFilters() {
    return this.getPersistedFilters(KEY_NFT_ACTIVITY_FILTERS)
  }

  set nftActivityFilters(filters) {
    this.setPersistedFilters(KEY_NFT_ACTIVITY_FILTERS, filters)
  }

  get hostsFilters() {
    return this.getPersistedFilters(KEY_HOSTS_FILTERS)
  }

  set hostsFilters(filters) {
    this.setPersistedFilters(KEY_HOSTS_FILTERS, filters)
  }

  get listingsFilters() {
    return this.getPersistedFilters(KEY_LISTINGS_FILTERS)
  }

  set listingsFilters(filters) {
    this.setPersistedFilters(KEY_LISTINGS_FILTERS, filters)
  }

  remove(key) {
    localStorage.removeItem(key)
  }

  clear() {
    localStorage.clear()
  }

  clearAllFilters() {
    this.remove(KEY_BUSINESSES_FILTERS)
    this.remove(KEY_HOSTS_FILTERS)
    this.remove(KEY_LISTINGS_FILTERS)
  }

  get lastSelectedZoneForUser() {
    const zoneForUser = localStorage.getItem(KEY_LAST_ZONE_FOR_USER)

    if (!zoneForUser) return {}
    const [userPart, zonePart] = zoneForUser.split('&')

    if (!userPart || !zonePart) return {}
    const [userKey, email] = userPart.split('=')
    const [zoneKey, zoneId] = zonePart.split('=')

    return {
      email,
      zoneId,
    }
  }

  setLastSelectedZoneForUser(user = {}, zone = {}) {
    const zoneForUser = `user=${user.email}&zoneId=${zone.id}`
    localStorage.setItem(KEY_LAST_ZONE_FOR_USER, zoneForUser)
  }
}

export default new LocalStorageService()
