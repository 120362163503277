import React, { useMemo } from 'react'
import TitledData from 'components/common/TitledData'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import { RevenueSourceOverview } from 'store/verticals/types'
import { splitCamelCase } from 'utils/splitCamelCase'
import { formatNumber } from 'utils/formatters'
import { formatDate } from 'containers/VerticalPage/common/ActivityOverviewCard/utils'

enum FORMAT_HANDLERS {
  NumberValue = 'numberValue',
  FirstActivity = 'firstActivity',
  LastActivity = 'lastActivity',
}

const formatHandlersBySourceMap = {
  [FORMAT_HANDLERS.NumberValue]: (value: number) => value,
  [FORMAT_HANDLERS.FirstActivity]: (value: string) => formatDate(value),
  [FORMAT_HANDLERS.LastActivity]: (value: string) => formatDate(value),
}

interface RevenueSourcesOverviewProps {
  revenueSources: RevenueSourceOverview
}

const RevenueSourcesOverview = ({ revenueSources }: RevenueSourcesOverviewProps) => {
  const theme = useTheme()
  const processedRevenueSources = useMemo(() => {
    if (!revenueSources) return []
    const currentRevenueSources = Object.entries(revenueSources).map(([overviewKey, overviewValue]) => {
      // Make sure to use the correct format handler for the current key
      const isDateKey =
        overviewKey === (FORMAT_HANDLERS.FirstActivity as keyof RevenueSourceOverview) ||
        overviewKey === (FORMAT_HANDLERS.LastActivity as keyof RevenueSourceOverview)
      const currentFormatHandler = formatHandlersBySourceMap[isDateKey ? overviewKey : FORMAT_HANDLERS.NumberValue]
      return {
        key: overviewKey,
        value: currentFormatHandler(
          isDateKey
            ? overviewValue
            : formatNumber(overviewValue, null, { style: overviewKey === 'totalRevenue' ? 'currency' : 'decimal' }),
        ),
      }
    })
    const revenueSourcesSortedByKey = currentRevenueSources.sort((a, b) => a.key.localeCompare(b.key))
    return revenueSourcesSortedByKey
  }, [revenueSources])

  return (
    <Stack
      component='ul'
      direction='row'
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        mt: { md: '25px', lg: '35px', xl: '50px', xxl: '72px', xxxl: '120px' },
      }}
    >
      {processedRevenueSources
        .sort((a, b) => a.key.localeCompare(b.key))
        .map(({ key, value }) => (
          <TitledData
            key={key}
            title={splitCamelCase(key)}
            data={value}
            sx={{ flexDirection: 'column-reverse' }}
            titleSx={{
              color: theme.palette.textColor.lightGray,
              fontSize: { md: '10px', lg: '10px', xl: '12px', xxl: '16px', xxxl: '24px' },
              fontFamily: 'Source Sans Pro',
            }}
            dataSx={{
              fontSize: { md: '13px', lg: '14px', xl: '16px', xxl: '22px', xxxl: '34px' },
              letterSpacing: '0.431px',
              color: '#282828',
            }}
          />
        ))}
    </Stack>
  )
}

export default RevenueSourcesOverview
