import { defineMessages } from 'react-intl'

const scope = 'app.components.RentalsItemGrid'

export default defineMessages({
  columns: {
    listingSiteId: {
      id: `${scope}.columns.listingSiteId`,
      defaultMessage: 'Listing ID',
    },
    listingSites: {
      id: `${scope}.columnsites`,
      defaultMessage: 'URL',
    },
    listingAddress: {
      id: `${scope}.columns.listingAddress`,
      defaultMessage: 'Address',
    },
    documentedIncome: {
      id: `${scope}.columns.documentedIncome`,
      defaultMessage: 'Documented Revenue',
    },
    numberOfReservations: {
      id: `${scope}.columns.numberOfReservations`,
      defaultMessage: 'Number of Reservations',
    },
    averageDailyRate: {
      id: `${scope}.columns.numberOfReservations`,
      defaultMessage: 'Average Daily Rate',
    },
    reservationDates: {
      id: `${scope}.columns.reservationDates`,
      defaultMessage: 'Reservation Dates',
    },
    totalDays: {
      id: `${scope}.columns.totalDays`,
      defaultMessage: 'Total Days',
    },
    totalIncome: {
      id: `${scope}.columns.totalIncome`,
      defaultMessage: 'Total Income',
    },
    guestInfo: {
      id: `${scope}.columns.guestInfo`,
      defaultMessage: 'Guest Details',
    },
    platform: {
      id: `${scope}.columns.platform`,
      defaultMessage: 'Platform',
    },
  },
})
