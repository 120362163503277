import React from 'react'
import PropTypes from 'prop-types'
import { FormattedNumber } from 'react-intl'
import { linearGradientDef } from '@nivo/core'
import { ResponsiveBar } from '@nivo/bar'
import styles from './BarChart.scss'

const BarChartTooltip = ({ value, tooltipLabel }) => (
  <span className={styles.barCustomTooltip}>
    {tooltipLabel}: <FormattedNumber value={value} />
  </span>
)

const BarChart = ({
  withLabel = false,
  enableGridY = true,
  keys = ['count'],
  indexBy = 'key',
  padding = 0.4,
  data,
  tooltipLabel,
  xLabelsRotation = -90,
  clickOnGraph = () => {},
}) => (
  <div className={styles.wrapper}>
    <ResponsiveBar
      animate
      enableGridY={enableGridY}
      data={data}
      keys={keys}
      indexBy={indexBy}
      margin={{ top: 20, right: 20, bottom: 30, left: 60 }}
      borderRadius={2}
      padding={padding}
      colors={['#6480E2']}
      motionStiffness={90}
      motionDamping={15}
      enableLabel={withLabel}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisBottom={{
        tickSize: 0,
        tickRotation: xLabelsRotation,
      }}
      axisLeft={{
        tickSize: 0,
      }}
      tooltip={props => <BarChartTooltip {...props} tooltipLabel={tooltipLabel} />}
      onClick={clickOnGraph}
      defs={[
        linearGradientDef('arrows', [
          { offset: 0, color: 'inherit', opacity: 0.4 },
          { offset: 100, color: 'inherit', opacity: 0.8 },
        ]),
      ]}
      fill={[
        {
          match: {
            id: 'count',
          },
          id: 'arrows',
        },
      ]}
    />
  </div>
)

BarChart.propTypes = {
  padding: PropTypes.number,
  withLabel: PropTypes.bool,
  enableGridY: PropTypes.bool,
  indexBy: PropTypes.string,
  keys: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.array.isRequired,
  tooltipLabel: PropTypes.any,
  xLabelsRotation: PropTypes.number,
  clickOnGraph: PropTypes.func,
}

export default BarChart
