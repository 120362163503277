import autobind from 'autobind-decorator'

export const MIN_PASSWORD_LENGTH = 8
const PASSWORD_POLICY_REGEX = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'

export const checkPasswordPolicy = password => {
  const policy = new RegExp(PASSWORD_POLICY_REGEX)
  return policy.test(password)
}

export const generateNewPassword = () => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()'
  const charactersLength = characters.length
  for (let i = 0; i < 8; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

@autobind
class UserService {
  isSuperuser(user = {}) {
    const { roleId = 1 } = user
    return roleId === 2
  }

  isOrganizationAdmin(user = {}) {
    const { roleId = 1 } = user
    return roleId === 3
  }

  isEitherAdminType(user = {}) {
    return this.isSuperuser(user) || this.isOrganizationAdmin(user)
  }

  isBasicUser(user = {}) {
    const { roleId = 1 } = user
    return roleId === 1
  }
}

export default new UserService()
