import React from 'react'

const noDiffIcon = (transform: string) => (
  <g clipPath='url(#clip0_2020_62890)' transform={transform}>
    <path
      d='M16.8975 3.10249C13.0943 -0.700792 6.90578 -0.700868 3.10246 3.10249C-0.70082 6.90577 -0.70082 13.0943 3.10246 16.8975C4.94484 18.7399 7.39446 19.7546 10 19.7546C12.6055 19.7546 15.0551 18.74 16.8975 16.8975C20.7008 13.0942 20.7008 6.90577 16.8975 3.10249ZM16.1478 16.1477C12.758 19.5376 7.24216 19.5377 3.85232 16.1477C0.462446 12.7579 0.462446 7.24211 3.85232 3.85224C5.49444 2.21012 7.67773 1.30574 10 1.30574C12.3223 1.30574 14.5056 2.21012 16.1478 3.85224C19.5377 7.24211 19.5377 12.7579 16.1478 16.1477Z'
      fill='#7E8BA6'
      stroke='#7E8BA6'
      strokeWidth='0.5'
    />
    <path
      d='M14.4094 7.58203H5.59051C5.29027 7.58203 5.04688 7.82543 5.04688 8.12566C5.04688 8.4259 5.29027 8.6693 5.59051 8.6693H14.4094C14.7096 8.6693 14.953 8.4259 14.953 8.12566C14.953 7.82539 14.7096 7.58203 14.4094 7.58203Z'
      fill='#7E8BA6'
      stroke='#7E8BA6'
      strokeWidth='0.5'
    />
    <path
      d='M14.4094 11.3262H5.59051C5.29027 11.3262 5.04688 11.5696 5.04688 11.8698C5.04688 12.17 5.29027 12.4134 5.59051 12.4134H14.4094C14.7096 12.4134 14.953 12.17 14.953 11.8698C14.953 11.5696 14.7096 11.3262 14.4094 11.3262Z'
      fill='#7E8BA6'
      stroke='#7E8BA6'
      strokeWidth='0.5'
    />
  </g>
)

const downDiff = (transform: string) => (
  <path
    d='M14.5 12L16.79 9.71L11.91 4.83L7.91 8.83L0.5 1.41L1.91 0L7.91 6L11.91 2L18.21 8.29L20.5 6V12H14.5Z'
    fill='#F44336'
    transform={transform}
  />
)

const upDiff = (transform: string) => (
  <path
    d='M14 0L16.29 2.29L11.41 7.17L7.41 3.17L0 10.59L1.41 12L7.41 6L11.41 10L17.71 3.71L20 6V0H14Z'
    fill='#34C759'
    transform={transform}
  />
)

export const getIconByDiffValueNumber = (
  diffValueNumber: number,
  pathTransform: string,
  noDiffPathTransform: string,
) => {
  if (diffValueNumber > 0) {
    return upDiff(pathTransform)
  }
  if (diffValueNumber < 0) {
    return downDiff(pathTransform)
  }

  return noDiffIcon(noDiffPathTransform)
}
