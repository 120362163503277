import React, { FC, ReactNode } from 'react'
import Stack from '@mui/material/Stack'

interface DetailsWrapperProps {
  children: ReactNode
}

const DetailsWrapper: FC<DetailsWrapperProps> = ({ children }) => (
  <Stack
    sx={{
      display: 'grid',
      gridTemplateRows: {
        md: 'auto 33vh',
        lg: 'auto 37vh',
        lgPlus: 'auto 37vh',
        xl: 'auto 39vh',
        xxl: 'auto 42vh',
        xxxl: 'auto 50vh',
      },
      gap: { md: '12px', xxl: '20px' },
    }}
  >
    {children}
  </Stack>
)

export default DetailsWrapper
