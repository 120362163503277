import { useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { PeriodProps } from 'components/common/DatePicker/utils'
import { Period } from 'utils/types'
import { VerticalContext } from 'store/verticals/Context'
import { CurrentZone } from 'utils/types'
import { selectCurrentZone } from 'store/global'

const usePeriodSetter = (defaultDate: Period) => {
  const [period, setPeriod] = useState({ start: defaultDate.start, end: defaultDate.end })

  const handlePeriodChange = (newPeriod: PeriodProps) => {
    const { startDate, endDate } = newPeriod
    const formattedStartDate = moment(startDate).format('YYYY-MM-DD')
    const formattedEndDate = moment(endDate).format('YYYY-MM-DD')
    setPeriod({ start: formattedStartDate, end: formattedEndDate })
  }

  return {
    period,
    handlePeriodChange,
  }
}

const useChartDataFetching = () => {
  const { getVerticalPieChartsData, getVerticalBarChartsData } = useContext(VerticalContext)
  const currentZone: CurrentZone = useSelector(selectCurrentZone)

  const [isLoadingPies, setIsLoadingPies] = useState(false)
  const [isLoadingBar, setIsLoadingBar] = useState(false)

  const fetchPieChartsData = async (currentEntityId: string, currentPeriod: Period) => {
    if (currentZone?.influencersActivityIndices) {
      setIsLoadingPies(true)
      await getVerticalPieChartsData(currentEntityId, currentPeriod, currentZone.influencersActivityIndices)
      setIsLoadingPies(false)
    }
  }

  const fetchBarChartData = async (currentEntityId: string, currentPeriod: Period) => {
    if (currentZone?.influencersActivityIndices) {
      setIsLoadingBar(true)
      await getVerticalBarChartsData(currentEntityId, currentPeriod, currentZone.influencersActivityIndices)
      setIsLoadingBar(false)
    }
  }

  return {
    isLoadingPies,
    isLoadingBar,
    fetchPieChartsData,
    fetchBarChartData,
  }
}

export { usePeriodSetter, useChartDataFetching }
