// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__container--z5ZsE{display:flex;justify-content:center;align-items:center;flex-direction:column;height:100%;width:100%}", "",{"version":3,"sources":["webpack://./app/utils/ErrorBoundary/styles.module.scss"],"names":[],"mappings":"AAAA,iCACI,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,qBAAA,CACA,WAAA,CACA,UAAA","sourcesContent":[".container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    height: 100%;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles-module__container--z5ZsE"
};
export default ___CSS_LOADER_EXPORT___;
