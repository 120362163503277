import React from 'react'

const ZoomPlus = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.30891 8.30952H12.1078C12.8307 8.30952 13.4167 7.72352 13.4167 7.00065C13.4167 6.27778 12.8307 5.69178 12.1078 5.69178H8.30891V1.89286C8.30891 1.16999 7.72291 0.583984 7.00004 0.583984C6.27717 0.583984 5.69117 1.16999 5.69117 1.89286V5.69178H1.89225C1.16938 5.69178 0.583374 6.27778 0.583374 7.00065C0.583374 7.72352 1.16938 8.30952 1.89225 8.30952H5.69117V12.1084C5.69117 12.8313 6.27717 13.4173 7.00004 13.4173C7.72291 13.4173 8.30891 12.8313 8.30891 12.1084V8.30952Z'
      fill='#2257C5'
    />
  </svg>
)

export default ZoomPlus
