import React from 'react'
import { useSelector } from 'react-redux'
import { injectIntl, FormattedMessage, FormattedNumber } from 'react-intl'
import PropTypes from 'prop-types'
import cx from 'classnames'
import TitledContent from 'components/TitledContent'
import RowLoader from 'components/RowLoader'
import Icon from 'components/Icon'
import Income from 'components/Income'
import NoData from 'components/NoData'
import { SECTION_HOST, SECTION_LISTING } from 'containers/Rentals/specs'
import { selectIsLoadingSummary, selectRentalsSummary } from 'store/rentals'
import messages from './messages'
import styles from './RentalsSummary.scss'

const SECTION_SEPARATOR = 'separator'
const sections = [SECTION_HOST, SECTION_SEPARATOR, SECTION_LISTING]

const fields = ['total', 'documentedIncome', 'reportedIncome', 'taxGap']

const [FIELD_TOTAL] = fields

function RentalsSummary({ intl, mode = SECTION_HOST }) {
  const isLoading = useSelector(selectIsLoadingSummary)
  const summary = useSelector(selectRentalsSummary)

  const renderContent = (section, field) => {
    if (isLoading) return <RowLoader />

    const value = (summary[section] || {})[field] || 0

    if (!value || !Number.isInteger(value)) return <NoData />
    if (field === FIELD_TOTAL) return <FormattedNumber value={value} />

    return <Income value={value} />
  }

  return (
    <div className={cx(styles.container, styles[mode])}>
      {sections.map(section => {
        if (section === SECTION_SEPARATOR) {
          return <div key={section} className={styles.separator} />
        }

        const iconName = `${section}-${mode === section ? 'active' : 'inactive'}`

        return (
          <section
            key={section}
            className={cx(styles.section, styles[section], {
              [styles.active]: section === mode,
            })}
          >
            <div className={styles.sectionLogo}>
              <Icon path='rentals' name={iconName} size='huge' />
              <h3 className={styles.sectionHeader}>
                <FormattedMessage {...messages.headers[section]} />
              </h3>
            </div>
            {fields.map(field => (
              <TitledContent
                key={field}
                title={intl.formatMessage(messages.fields[field], {
                  type: section,
                })}
                className={styles.field}
                titleClassName={styles.fieldTitle}
                contentClassName={cx(styles.fieldContent, styles[field])}
              >
                {renderContent(section, field)}
              </TitledContent>
            ))}
          </section>
        )
      })}
    </div>
  )
}

RentalsSummary.propTypes = {
  intl: PropTypes.object.isRequired,
  isSpaced: PropTypes.bool,
  mode: PropTypes.string,
}

export default injectIntl(RentalsSummary)
