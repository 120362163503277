import React from 'react'
import VerticalTableBase from 'components/verticals/VerticalTableBase/VerticalTableBase'
import { VerticalTableCMPBaseProps } from 'components/verticals/VerticalTableBase/types'
import { FatcaEntityForTable } from 'containers/FatcaTablePage/types'
import { useFatcaTableColumns } from './useFatcaTableColumns'

const FatcaTable = ({
  verticalEntities,
  fetchNextPage,
  isFetching,
  totalDBRowCount,
  totalFetched,
  sorting,
  setSorting,
  setMergedColumns,
  selectedColumns,
  allColumns,
}: VerticalTableCMPBaseProps<FatcaEntityForTable>) => {
  const columns = useFatcaTableColumns()

  return (
    <VerticalTableBase<FatcaEntityForTable>
      columns={columns}
      avoidBaseColumns
      items={verticalEntities}
      fetchNextPage={fetchNextPage}
      isFetching={isFetching}
      totalFetched={totalFetched}
      totalDBRowCount={totalDBRowCount}
      sorting={sorting}
      setSorting={setSorting}
      setMergedColumns={setMergedColumns}
      selectedColumns={selectedColumns}
      allColumns={allColumns}
    />
  )
}

export default FatcaTable
