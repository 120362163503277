import React, { useState, useEffect, FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import Button from 'components/Button'
import Tooltip from 'components/Tooltip'
import Icon from 'components/Icon'
import { selectCurrentZone } from 'store/global'
import { fetchActivityExport, selectActivityCSV, setActivityCSV } from 'store/nft'
import { downloadCSV } from 'utils/downloadCSV'
import styles from './ExportEntityActivityCSV.module.scss'

interface IExportEntityActivityCSV {
  selectedActivityTypes: string[]
  selectedRevenueSources: string[]
  datePeriod: { start: string; end: string }
}

const ExportEntityActivityCSV: FC<IExportEntityActivityCSV> = ({
  selectedActivityTypes,
  datePeriod,
  selectedRevenueSources,
}) => {
  const dispatch = useDispatch()
  const { entityId } = useParams<{ entityId: string }>()
  const currentZone = useSelector(selectCurrentZone)
  // const currentPeriod = useSelector(selectCurrentPeriod)
  const activityCSV = useSelector(selectActivityCSV)

  const [isShareDisabled, setIsShareDisabled] = useState<boolean>(false)
  const { trackEvent } = useMatomo()

  const onCSVExport = () => {
    setIsShareDisabled(true)

    const payload = {
      activityIndices: currentZone.nftActivityIndices,
      entityId,
      filters: {
        activityType: selectedActivityTypes,
        revenueSource: selectedRevenueSources,
      },
      currentPeriod: datePeriod,
    }

    //@ts-ignore - gotta give type inside slice, but it's a js file
    dispatch(fetchActivityExport({ ...payload }))
    trackEvent({ category: 'Export', action: 'User requested for activity CSV' })
  }

  useEffect(() => {
    if (!activityCSV) return
    const csvFileName = 'EntityActivity.csv'
    downloadCSV(activityCSV, csvFileName)
    setIsShareDisabled(false)
    dispatch(setActivityCSV({ csv: null }))
  }, [activityCSV])

  return (
    <div className={styles.container}>
      <Tooltip title='Export Entity Activity CSV'>
        <Button color='light' outline className={styles.share} disabled={isShareDisabled} onClick={onCSVExport}>
          <Icon name='share' size='regular' />
        </Button>
      </Tooltip>
    </div>
  )
}

export default ExportEntityActivityCSV
