import React from 'react'
import cx from 'classnames'
import styles from './BoxDecoration.scss'

const BoxDecoration = () => (
  <>
    <ins className={cx(styles.decoration, styles.left, styles.top)} />
    <ins className={cx(styles.decoration, styles.left, styles.bottom)} />
    <ins className={cx(styles.decoration, styles.right, styles.top)} />
    <ins className={cx(styles.decoration, styles.right, styles.bottom)} />
  </>
)

export default BoxDecoration
