import React from 'react'
import PropTypes from 'prop-types'

function FormattedLargeNumber({ value = 0 }) {
  let formattedValue = value
  let suffix = ''

  if (value > 1000000) {
    formattedValue = Number(value / 1000).toFixed(1)
    suffix = 'M'
  } else if (value > 3000) {
    formattedValue = Number(value / 1000).toFixed(1)
    suffix = 'K'
  }

  return (
    <span>
      {formattedValue}
      {suffix}
    </span>
  )
}

FormattedLargeNumber.propTypes = {
  value: PropTypes.number,
}

export default FormattedLargeNumber
