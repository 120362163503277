import { defineMessages } from 'react-intl'

const scope = 'app.containers.TableActionsBar'

export default defineMessages({
  export: {
    id: `${scope}.export`,
    defaultMessage: 'Export',
  },
})
