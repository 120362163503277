import React, { createContext, useReducer } from 'react'
import { VERTICALS_DETAILS_ACTIONS } from 'store/verticalsDetails/actions'
import { VerticalsDetailsReducer } from 'store/verticalsDetails/reducer'
import type {
  DropdownOption,
  InfluencersFilters,
  IVerticalDetailsContext,
  VerticalDetailsProviderProps,
  VerticalsDetailsState,
} from 'store/verticalsDetails/types'

const initialState: VerticalsDetailsState = {
  detailsDropdownOptions: [],
  isDetailsDropdownOpen: false,
  didSubmitDetailsDropdown: false,
  detailsFilters: [],
  submittedDropdownOptions: [],
}

export const VerticalsDetailsContext = createContext({} as IVerticalDetailsContext)

const VerticalDetailsContextProvider = ({ children }: VerticalDetailsProviderProps) => {
  const [state, dispatch] = useReducer(VerticalsDetailsReducer, initialState)

  const setDetailsDropdownOptions = (detailsDropdownOptions: DropdownOption[]) => {
    dispatch({
      type: VERTICALS_DETAILS_ACTIONS.VerticalDetailsDropdownOptions,
      detailsDropdownOptions,
    })
  }

  const setIsDetailsDropdownOpen = (isDetailsDropdownOpen: boolean) => {
    dispatch({
      type: VERTICALS_DETAILS_ACTIONS.VerticalIsDetailsDropdownOpen,
      isDetailsDropdownOpen,
    })
  }

  const setDidSubmitDetailsDropdown = (didSubmitDetailsDropdown: boolean) => {
    dispatch({
      type: VERTICALS_DETAILS_ACTIONS.VerticalDetailsDropdownDidSubmit,
      didSubmitDetailsDropdown,
    })
  }

  const setDetailsFilters = (detailsFilters: InfluencersFilters) => {
    dispatch({
      type: VERTICALS_DETAILS_ACTIONS.VerticalDetailsFilters,
      detailsFilters,
    })
  }

  const setSubmittedDropdownOptions = (submittedDropdownOptions: DropdownOption[]) => {
    dispatch({
      type: VERTICALS_DETAILS_ACTIONS.VerticalDetailsSubmittedDropdownOptions,
      submittedDropdownOptions,
    })
  }

  return (
    <VerticalsDetailsContext.Provider
      value={{
        setDetailsDropdownOptions,
        setIsDetailsDropdownOpen,
        setDidSubmitDetailsDropdown,
        setDetailsFilters,
        setSubmittedDropdownOptions,
        detailsDropdownOptions: state.detailsDropdownOptions,
        isDetailsDropdownOpen: state.isDetailsDropdownOpen,
        didSubmitDetailsDropdown: state.didSubmitDetailsDropdown,
        detailsFilters: state.detailsFilters,
        submittedDropdownOptions: state.submittedDropdownOptions,
      }}
    >
      {children}
    </VerticalsDetailsContext.Provider>
  )
}

export default VerticalDetailsContextProvider
