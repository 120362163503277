import React from 'react'
import Tooltip, { TooltipProps as MuiTooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import Stack from '@mui/material/Stack'

const GenericChartTooltip = ({ sx, children }: Partial<MuiTooltipProps>) => {
  return (
    <Tooltip
      arrow
      title={<span>title</span>}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            color: 'black',
            boxShadow: 1,
            fontSize: 11,
          },
        },
        arrow: {
          sx: {
            color: 'rgba(255, 255, 255, 0.8)',
          },
        },
      }}
    >
      <Stack>{children}</Stack>
    </Tooltip>
  )
}

export default GenericChartTooltip
