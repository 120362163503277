export enum tabsIds {
  OVERVIEW,
  REVIEWS,
  CONTACT_INFO,
}

const tabsList = {
  OVERVIEW: { id: tabsIds.OVERVIEW, label: 'Overview' },
  REVIEWS: { id: tabsIds.REVIEWS, label: 'Reviews' },
  CONTACT_INFO: { id: tabsIds.CONTACT_INFO, label: 'Contact Info' },
}

export const getTabsList = () => [tabsList.OVERVIEW, tabsList.REVIEWS, tabsList.CONTACT_INFO]
