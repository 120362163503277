import React from 'react'
import VerticalTableBase from 'components/verticals/VerticalTableBase/VerticalTableBase'
import { VerticalTableCMPBaseProps } from 'components/verticals/VerticalTableBase/types'
import { OffshoreEntityForTable } from 'containers/OffshoreTablePage/types'
import { useOffshoreTableColumns } from './useOffshoreTableColumns'

const OffshoreTable = ({
  verticalEntities,
  fetchNextPage,
  isFetching,
  totalDBRowCount,
  totalFetched,
  sorting,
  setSorting,
  setMergedColumns,
  selectedColumns,
  allColumns,
}: VerticalTableCMPBaseProps<OffshoreEntityForTable>) => {
  const columns = useOffshoreTableColumns()

  return (
    <VerticalTableBase<OffshoreEntityForTable>
      columns={columns}
      avoidBaseColumns
      items={verticalEntities}
      fetchNextPage={fetchNextPage}
      isFetching={isFetching}
      totalFetched={totalFetched}
      totalDBRowCount={totalDBRowCount}
      sorting={sorting}
      setSorting={setSorting}
      setMergedColumns={setMergedColumns}
      selectedColumns={selectedColumns}
      allColumns={allColumns}
    />
  )
}

export default OffshoreTable
