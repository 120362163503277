import React from 'react'

const CloseIcon = () => (
  <svg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.9097 0.79244L12.1172 0L6.90967 5.20749L1.70218 0L0.909668 0.79244L6.11719 5.99997L0.909668 11.2075L1.70218 11.9999L6.90967 6.79244L12.1172 11.9999L12.9097 11.2075L7.70214 5.99997L12.9097 0.79244Z'
      fill='#2257C5'
    />
  </svg>
)

export default CloseIcon
