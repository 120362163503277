import { Theme } from '@mui/material/styles'

export const TooltipPaper = {
  minWidth: 264,
  minHeight: '100%',
  bgcolor: '#ffffff',
  p: '12px 16px',
  borderRadius: '8px',
  border: '1px solid #DAD8DF',
}

export const TooltipItemContainer = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBlock: '4px',
}

export const TooltipHeading = {
  fontWeight: 'bold',
  fontFamily: 'Dosis',
  fontSize: '20px',
  lineHeight: '32px',
  textTransform: 'capitalize',
  display: 'inline-block',
  maxWidth: 150,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginInlineEnd: '10px',
}

export const TooltipItemDetails = {
  flexDirection: 'row',
  gap: '10px',
  alignSelf: 'center',
  fontSize: '20px',
}

export const HeaderAmount = {
  fontSize: '20px',
}

export const TooltipItemLabel = {
  marginInlineEnd: '10px',
}

export const HeaderTotalAmount = (theme: Theme) => ({
  color: theme.palette.textColor.lighterGray,
  fontSize: '20px',
  alignSelf: 'center',
})

export const ItemTotalAmount = (theme: Theme) => ({
  color: theme.palette.textColor.lighterGray,
})

export const ContentItemContainer = {}
