import React from 'react'
import { camelCase } from 'lodash'
import cx from 'classnames'
import { FormGroup, Label } from 'reactstrap'
import Input from 'components/Input'
import Button from 'components/Button'
import Icon from 'components/Icon'
import styles from './CheckboxGroup.scss'

const allowedTypes = ['regular', 'circle']
const [TYPE_REGULAR, TYPE_CIRCLE] = allowedTypes

export interface ICheckboxGroup {
  type?: string
  isRow?: boolean
  name: string
  withLabel?: boolean
  withIcons?: boolean
  options: Array<string>
  selections: Array<string>
  customValues?: Array<string>
  onChange: (arg0: string) => void
}

const CheckboxGroup = ({
  type = TYPE_REGULAR,
  isRow = false,
  name,
  withLabel = false,
  withIcons = false,
  options,
  selections,
  onChange,
  customValues,
}: ICheckboxGroup) => (
  <FormGroup className={cx(styles.container, styles[type], { [styles.row]: isRow })}>
    {options.map((option, index) => {
      const isChecked = selections.includes(option)

      return (
        <Button
          key={index}
          onClick={() => onChange(option)}
          className={cx('btn-fake', styles.wrapper, styles[type], { [styles.checked]: isChecked })}
        >
          <Input
            type='checkbox'
            id={`${name}-${index}`}
            name={name}
            checked={isChecked}
            onChange={() => onChange(option)}
            className={cx(styles.checkbox, styles[type])}
            wrapperClassName={styles.inputWrapper}
          />
          {withIcons && <Icon size='small' name={camelCase(option)} className={styles.icon} />}
          {type === TYPE_REGULAR && (
            <span className={styles.option}>{!!customValues ? customValues[index] : option}</span>
          )}
          {type === TYPE_CIRCLE && (
            <>
              <div className={styles.shape}>
                <div className={styles.inner} />
              </div>
              <span className={styles.label}>{option}</span>
            </>
          )}
          {withLabel && <Label for={`${name}-${index}`}>{option}</Label>}
        </Button>
      )
    })}
  </FormGroup>
)

export default CheckboxGroup
