import moment from 'moment'
import { NOT_AVAILABLE } from 'utils/constants'

export const formatDate = (input: number | string) => {
  if (typeof input === 'number') {
    return input.toString()
  } else if (typeof input === 'string') {
    const parsedDate = moment(input, ['YY-MM-DDTHH:mm:ss', 'YYYY-MM-DD'])

    if (parsedDate.isValid()) {
      return parsedDate.format('MMM. YYYY')
    } else if (/^\d{4}\/\d{2}\/\d{2}$/.test(input)) {
      const date = moment(input, 'YYYY-MM-DD')

      if (date.isValid()) {
        return date.format('MMM. YYYY')
      }

      return 'N/A'
    } else if (/^\d{4}$/.test(input)) {
      return input
    }
  }

  return NOT_AVAILABLE
}
