import { DropdownOption } from 'store/verticalsDetails/types'

export enum VERTICALS_DETAILS_ACTIONS {
  VerticalDetailsDropdownOptions = 'SET_VERTICAL_DETAILS_DROPDOWN_OPTIONS',
  VerticalIsDetailsDropdownOpen = 'SET_VERTICAL_DETAILS_DROPDOWN_OPEN',
  VerticalDetailsDropdownDidSubmit = 'SET_VERTICAL_DETAILS_DROPDOWN_DID_SUBMIT',
  VerticalDetailsFilters = 'SET_VERTICAL_DETAILS_FILTERS',
  VerticalDetailsSubmittedDropdownOptions = 'SET_VERTICAL_DETAILS_SUBMITTED_DROPDOWN_OPTIONS',
}

interface VerticalDetailsDropdownOptionsAction {
  type: VERTICALS_DETAILS_ACTIONS.VerticalDetailsDropdownOptions
  detailsDropdownOptions: DropdownOption[]
}

interface VerticalDetailsIsDropdownOpenAction {
  type: VERTICALS_DETAILS_ACTIONS.VerticalIsDetailsDropdownOpen
  isDetailsDropdownOpen: boolean
}

interface VerticalDetailsDropdownDidSubmitAction {
  type: VERTICALS_DETAILS_ACTIONS.VerticalDetailsDropdownDidSubmit
  didSubmitDetailsDropdown: boolean
}

interface VerticalDetailsDetailsFiltersAction {
  type: VERTICALS_DETAILS_ACTIONS.VerticalDetailsFilters
  detailsFilters: Record<string, string>[]
}

interface VerticalDetailsSubmittedDropdownOptionsAction {
  type: VERTICALS_DETAILS_ACTIONS.VerticalDetailsSubmittedDropdownOptions
  submittedDropdownOptions: DropdownOption[]
}

export type VerticalsDetailsAction =
  | VerticalDetailsDropdownOptionsAction
  | VerticalDetailsIsDropdownOpenAction
  | VerticalDetailsDropdownDidSubmitAction
  | VerticalDetailsDetailsFiltersAction
  | VerticalDetailsSubmittedDropdownOptionsAction
