import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { ResponsivePie } from '@nivo/pie'
import styles from './PieChart.scss'

const chartColors = ['#79A2F8', '#0D47A1', '#2155c0', '#495FA6']

export const PieChart = ({ data, isInteractive = false, children, className, innerClassName }) => (
  <div className={cx(styles.pieContainer, className)}>
    {!!children && <span className={cx(styles.inner, innerClassName)}>{children}</span>}
    <ResponsivePie
      data={data}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      innerRadius={0.95}
      padAngle={2}
      cornerRadius={45}
      // colors={[styles.nexus, styles.ecommerce, styles.gig, styles.other]}
      colors={chartColors}
      enableRadialLabels={false}
      enableSlicesLabels={false}
      animate
      motionStiffness={90}
      motionDamping={15}
      isInteractive={isInteractive}
    />
  </div>
)

PieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  isInteractive: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
}
