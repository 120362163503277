import React, { FC } from 'react'
import Card, { CardProps } from '@mui/material/Card'

// Instead of passing specific MUI props, we use CardProps type for full access to all MUI Card props
const GenericCard: FC<CardProps> = ({ children, sx, ...genericCardProps }) => {
  return (
    <Card sx={{ boxShadow: 0, ...sx }} {...genericCardProps}>
      {children}
    </Card>
  )
}

export default GenericCard
