export const overviewMapping: {
  key: string
  label: string
  tooltipText?: string
}[] = [
  { key: 'location', label: 'Main Site' },
  { key: 'founded', label: 'Founded' },
  { key: 'industry', label: 'Industry' },
  { key: 'allEmployees', label: 'All Employees' },
  { key: 'jurisdictions', label: 'Jurisdictions' },
  { key: 'companyType', label: 'Company Type' },
]
