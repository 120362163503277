export const getYears = (yearsBack: number): number[] => {
  const currentYear = new Date().getFullYear()
  const yearsArray: number[] = []

  for (let i = 0; i <= yearsBack; i++) {
    const year = currentYear - i
    if (year !== 2019) {
      yearsArray.push(year)
    }
  }

  return yearsArray.reverse()
}
