import { useQuery } from '@tanstack/react-query'
import { fetchCDTFATableOverviewData } from '../../services/verticalsTablesApi'
import { Filter } from 'components/FilterDrawer/FilterDrawer.config'

interface UseVerticalOverviewQueryProps {
  verticalIndices: string
  currentQueryKey: string
  searchValue: string
  filters?: Filter[]
}

export const useCDTFAOverviewQuery = ({
  verticalIndices,
  currentQueryKey,
  searchValue,
  filters,
}: UseVerticalOverviewQueryProps) =>
  useQuery({
    queryKey: [currentQueryKey, verticalIndices, searchValue, filters],
    queryFn: ({ signal }) =>
      verticalIndices ? fetchCDTFATableOverviewData(verticalIndices, searchValue, filters, signal) : null,
    refetchOnWindowFocus: false,
  })
