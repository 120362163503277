import { createSelector } from 'reselect'
import { initialState } from './slice'

const selectNFTMapperState = state => state.nftMapper || initialState

export const selectTotalNFTEntities = createSelector(selectNFTMapperState, state => state.totalNFTEntities)

export const selectSummary = createSelector(selectNFTMapperState, state => state.summary)

export const selectIsLoadingSummary = createSelector(selectNFTMapperState, state => state.isLoadingSummary)

export const selectIsLoadingInitial = createSelector(selectNFTMapperState, state => state.isLoadingInitial)

export const selectIsLoadingNextBatch = createSelector(selectNFTMapperState, state => state.isLoadingNextBatch)

export const selectNextBatchStartingIndex = createSelector(selectNFTMapperState, state => state.nextBatchStartingIndex)

export const selectEntities = createSelector(selectNFTMapperState, state =>
  state.entities.map((entity, index) => ({
    ...entity,
    index,
  })),
)

export const selectEntitiesDisplayedColumns = createSelector(
  selectNFTMapperState,
  state => state.entitiesDisplayedColumns,
)

export const selectFilters = createSelector(selectNFTMapperState, state => state.filters)

export const selectFilterOptions = createSelector(selectNFTMapperState, state => state.filterOptions)
