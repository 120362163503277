import React from 'react'
import Stack from '@mui/material/Stack'
import GenericText from 'components/common/GenericText'
import { SxProps } from '@mui/material'

interface TableTextCard {
  text: string
  sx?: SxProps
}

const TableTextCard = ({ text, sx = {} }: TableTextCard) => {
  return (
    <Stack
      sx={{
        bgcolor: '#E9F0FF',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '4px 12px',
        display: 'inline-block',
        maxWidth: 250,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        borderRadius: '8px',
        ...sx,
      }}
    >
      <GenericText>{text}</GenericText>
    </Stack>
  )
}

export default TableTextCard
