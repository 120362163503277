import React from 'react'
import Icon from 'components/Icon'
import styles from './SearchChips.scss'
import { OnRemoveChip } from 'components/Input/Input'

export interface SearchChip {
  value: string
  label: string
}

interface SearchChipsProps {
  chips: SearchChip[]
  onRemoveChip: OnRemoveChip
}

const SearchChips: React.FC<SearchChipsProps> = ({ chips, onRemoveChip }) => (
  <ul className={styles.searchChips}>
    {chips.map(chip => (
      <li key={chip.value}>
        <span className={styles.chipLabel}>{chip.label}</span>
        <span className={styles.closeIconWrapper} onClick={() => onRemoveChip(chip)}>
          <Icon name='close' size='small' />
        </span>
      </li>
    ))}
  </ul>
)

export default SearchChips
