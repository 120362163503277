import { createSelector } from 'reselect'
import { initialState } from './slice'

const selectBusinessRequestsState = state => state.businessRequests || initialState

export const selectIsLoading = createSelector(selectBusinessRequestsState, state => state.isLoading)

export const selectBusinessRequests = createSelector(selectBusinessRequestsState, state => state.businessRequests)

export const selectCurrentRequest = createSelector(selectBusinessRequestsState, state => state.currentRequest)
