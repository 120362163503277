import { defineMessages } from 'react-intl'

const scope = 'app.components.RentalsSummary'

export default defineMessages({
  headers: {
    hosts: {
      id: `${scope}.headers.hosts`,
      defaultMessage: 'Hosts',
    },
    listings: {
      id: `${scope}.headers.listings`,
      defaultMessage: 'Listings',
    },
  },
  fields: {
    total: {
      id: `${scope}.fields.total`,
      defaultMessage: 'Number of {type}',
    },
    documentedIncome: {
      id: `${scope}.fields.documentedIncome`,
      defaultMessage: 'Documented Revenue',
    },
    reportedIncome: {
      id: `${scope}.fields.reportedIncome`,
      defaultMessage: 'Reported Revenue',
    },
    taxGap: {
      id: `${scope}.fields.taxGap`,
      defaultMessage: 'Tax Gap',
    },
  },
})
