import React, { FC, useMemo } from 'react'

import { InfluencersEntityForTable } from 'containers/InfluencersTablePage/types'
import VerticalTableBase from 'components/verticals/VerticalTableBase/VerticalTableBase'
import { VerticalTableCMPBaseProps } from 'components/verticals/VerticalTableBase/types'
import TableText from 'components/common/TableText'
import Income from 'components/Income'

import { formatNumber } from 'utils/formatters'

import { MRT_ColumnDef } from 'material-react-table'
import { TABLE_TEXT_TYPES } from 'utils/types'
import { useBaseCellStyles } from 'components/verticals/VerticalTableBase/useBaseCellStyles'
import { NOT_AVAILABLE } from 'utils/constants'

const InfluencersTable: FC<VerticalTableCMPBaseProps<InfluencersEntityForTable>> = ({
  verticalEntities,
  fetchNextPage,
  isFetching,
  totalDBRowCount,
  totalFetched,
  sorting,
  setSorting,
  setMergedColumns,
  selectedColumns,
  allColumns,
}) => {
  const baseCellStyles = useBaseCellStyles()
  const revenueStyles = {
    muiTableHeadCellProps: {
      sx: {
        borderBottom: 'none',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        ...baseCellStyles,
      },
    },
    size: 150,
  }
  const columns = useMemo<MRT_ColumnDef<InfluencersEntityForTable>[]>(
    () => [
      {
        header: 'Revenue',
        Header: <TableText textType={TABLE_TEXT_TYPES.groupHeader}>Revenue</TableText>,
        columns: [
          {
            id: 'totals.documentedRevenue',
            header: 'Documented Revenue',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Documented Revenue</TableText>,
            accessorFn: item => (
              <TableText>
                {item?.totals?.documentedRevenue ? <Income value={item.totals.documentedRevenue} /> : NOT_AVAILABLE}
              </TableText>
            ),
            ...revenueStyles,
            size: 200,
          },
          {
            id: 'totals.estimatedRevenue',
            header: 'Estimated Revenue',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Estimated Revenue</TableText>,
            accessorFn: item => (
              <TableText>
                {item?.totals?.estimatedRevenue ? <Income value={item?.totals?.estimatedRevenue} /> : NOT_AVAILABLE}
              </TableText>
            ),
            ...revenueStyles,
            size: 180,
          },
        ],
      },
      {
        header: 'Activity Details',
        columns: [
          {
            id: 'totals.totalMedia',
            header: 'Total Media',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Total Media</TableText>,
            muiTableBodyCellProps: {
              sx: { ...baseCellStyles },
            },
            accessorFn: item => (
              <TableText>
                {formatNumber(item?.totals.totalMedia, null, {
                  style: 'decimal',
                })}
              </TableText>
            ),
          },
          {
            id: 'totals.totalFollowers',
            header: 'Total Followers',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Total Followers</TableText>,
            muiTableBodyCellProps: {
              sx: { ...baseCellStyles },
            },
            accessorFn: item => (
              <TableText>
                {formatNumber(item?.totals.totalFollowers, null, {
                  style: 'decimal',
                })}
              </TableText>
            ),
          },
        ],
      },
    ],
    [],
  )

  return (
    <VerticalTableBase<InfluencersEntityForTable>
      columns={columns}
      items={verticalEntities}
      fetchNextPage={fetchNextPage}
      isFetching={isFetching}
      totalFetched={totalFetched}
      totalDBRowCount={totalDBRowCount}
      sorting={sorting}
      setSorting={setSorting}
      setMergedColumns={setMergedColumns}
      selectedColumns={selectedColumns}
      allColumns={allColumns}
    />
  )
}

export default InfluencersTable
