import React, { FC } from 'react'
import Link, { LinkProps } from '@mui/material/Link'

interface GenericLinkProps extends LinkProps {
  href: string
}

const GenericLink: FC<GenericLinkProps> = ({ children, variant = 'body1', href, ...genericLinkProps }) => {
  return (
    <Link
      component='a'
      target='_blank'
      href={href}
      variant={variant}
      sx={{
        fontFamily: 'Source Sans Pro',
        color: 'secondary.main',
        textDecoration: 'none',
      }}
      {...genericLinkProps}
    >
      {children}
    </Link>
  )
}

export default GenericLink
