export enum tabsIds {
  OVERVIEW,
  TAX_RETURN,
  CONTACT_INFO,
  ACTIVITY,
}

const tabsList = {
  OVERVIEW: { id: tabsIds.OVERVIEW, label: 'Overview' },
  TAX_RETURN: { id: tabsIds.TAX_RETURN, label: 'Tax Return' },
  CONTACT_INFO: { id: tabsIds.CONTACT_INFO, label: 'Contact Info' },
  ACTIVITY: { id: tabsIds.ACTIVITY, label: 'Activity' },
}

export const getTabsList = () => [tabsList.OVERVIEW, tabsList.ACTIVITY, tabsList.TAX_RETURN, tabsList.CONTACT_INFO]
