import { getAbbreviatedNumberString } from 'components/Income/Income'

export interface PieChartData {
  key: string
  color: string
  label: string
  value: number | string
  uiValue?: string
  tooltipLabel?: string
  fullLabel?: string
}

export interface IData {
  title: string
  pieTitle: string
  pieSubTitle: string
  data: PieChartData[]
  pieDy?: number
}

export interface DistributionEntity {
  key: string
  count: number
  percentage: number
}

export interface IFatcaOverviewData {
  totalEntities: number
  identified: number
  unidentified: number
  identifiedPercentage: number
  unidentifiedPercentage: number
  totalRevenue: number
  reported: number
  unreported: number
  reportedPercentage: number
  unreportedPercentage: number
  totalUnreportedEarningRange: {
    min: number
    max: number
  }
}

export interface IBusinessSummary {
  overviewData?: IFatcaOverviewData | null
}

export const getSummaryData = (data?: IFatcaOverviewData | null): IData[] => {
  const identifiedValue = data?.identified !== undefined ? data.identified : 'N/A'
  const identifiedUiValue = data?.identified !== undefined ? getAbbreviatedNumberString(data.identified) : 'N/A'

  const unidentifiedValue = data?.unidentified !== undefined ? data.unidentified : 'N/A'
  const unidentifiedUiValue = data?.unidentified !== undefined ? getAbbreviatedNumberString(data.unidentified) : 'N/A'

  const reportedValue = data?.reported !== undefined ? data.reported : 'N/A'
  const reportedUiValue = data?.reported !== undefined ? getAbbreviatedNumberString(data.reported) : 'N/A'

  const unreportedValue = data?.unreported !== undefined ? data.unreported : 'N/A'
  const unreportedUiValue = data?.unreported !== undefined ? getAbbreviatedNumberString(data.unreported) : 'N/A'

  return [
    {
      title: 'Entities',
      pieTitle: getAbbreviatedNumberString(data?.totalEntities || 0),
      pieSubTitle: 'Total Entities',
      data: [
        {
          key: 'identified',
          color: '#7E8BA6',
          label: 'Identified',
          value: identifiedValue,
          uiValue: `${identifiedUiValue} (${data?.identifiedPercentage}%)`,
        },
        {
          key: 'unidentified',
          color: '#FFCC00',
          label: 'Unidentified',
          value: unidentifiedValue,
          uiValue: `${unidentifiedUiValue} (${data?.unidentifiedPercentage}%)`,
        },
      ],
    },
    {
      title: 'Attributable Earnings',
      pieTitle: getAbbreviatedNumberString(data?.totalRevenue || 0),
      pieSubTitle: 'Attributable Earnings',
      pieDy: 20,
      data: [
        {
          key: 'reported',
          color: '#7E8BA6',
          label: 'Reported',
          value: reportedValue,
          uiValue: `${reportedUiValue} (${data?.reportedPercentage}%)`,
        },
        {
          key: 'unReported',
          color: '#00C2FF',
          label: 'Unreported',
          value: unreportedValue,
          uiValue: `${unreportedUiValue} (${data?.unreportedPercentage}%)`,
        },
      ],
    },
  ]
}
