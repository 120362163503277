import { defineMessages } from 'react-intl'

const scope = 'app.containers.Header'

export default defineMessages({
  logo: {
    id: `${scope}.logo`,
    defaultMessage: 'IVIX',
  },
})
