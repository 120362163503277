import { Filter, FilterTypes } from 'components/FilterDrawer/FilterDrawer.config'

export const filtersInitialState: Filter[] = [
  {
    type: FilterTypes.CHECKBOXES,
    name: 'overviewDetails.categories',
    label: 'Activity Type',
    value: [],
  },
]
