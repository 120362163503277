import { useQuery } from '@tanstack/react-query'
import { fetchEmptyTableValues } from 'services/verticalsTablesApi'

interface UseVerticalOverviewQueryProps {
  verticalIndices: string
  currentQueryKey: string
  keys: string[]
}

export const useEmptyColumnsQuery = <T extends string[]>({
  verticalIndices,
  currentQueryKey,
  keys,
}: UseVerticalOverviewQueryProps) =>
  useQuery<T | null>({
    queryKey: [currentQueryKey, verticalIndices, keys],
    queryFn: () => (verticalIndices && keys.length > 0 ? fetchEmptyTableValues(verticalIndices, keys) : null),
  })
