import { useState } from 'react'

interface UseTabsProps {
  defaultTab: number
}

export const useTabs = ({ defaultTab }: UseTabsProps) => {
  const [activeTab, setActiveTab] = useState<number>(defaultTab)

  const handleTabClick = (event: React.SyntheticEvent<Element, Event>, value: number) => {
    setActiveTab(value)
  }

  return { activeTab, handleTabClick }
}
