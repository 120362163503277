import { defineMessages } from 'react-intl'

const scope = 'app.containers.ServiceProvidersTablePage'

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Service Providers Table',
  },
  count: {
    id: `${scope}.count`,
    defaultMessage: 'Service Providers: {displayed} of {total}',
  },
  filters: {
    id: `${scope}.filters`,
    defaultMessage: 'Filters',
  },
})
