import React, { CSSProperties, ReactNode } from 'react'
import { CartesianGrid, LineChart, ResponsiveContainer, XAxis, YAxis, Label } from 'recharts'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { LineChartDatum } from 'components/common/types'
import { DataKey } from 'recharts/types/util/types'
import { LabelPosition } from 'recharts/types/component/Label'

interface GenericLineChartProps {
  chartData: LineChartDatum[]
  children: ReactNode
  xAxisDataKey: DataKey<string>
  xLabel?: string
  yLabel?: string
  xLabelPosition?: LabelPosition
  yLabelPosition?: LabelPosition
  xAxisDistanceY?: number
  yAxisDistanceX?: number
  labelstyle?: CSSProperties
  yLabelAngle?: number
  tick?: { fill: string }
}

const GenericLineChart = ({
  chartData,
  xAxisDataKey,
  xLabel,
  yLabel,
  xLabelPosition,
  yLabelPosition,
  children,
  xAxisDistanceY,
  yAxisDistanceX,
  labelstyle,
  yLabelAngle,
  tick,
}: GenericLineChartProps) => {
  const theme = useTheme()

  const isXxxl = useMediaQuery(theme.breakpoints.up('xxxl'))

  const getTickFontSize = () => (isXxxl ? 38 : 14)
  const tickSize = getTickFontSize()

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <LineChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid vertical={false} />
        <XAxis dataKey={xAxisDataKey} fontSize={tickSize} tick={tick}>
          <Label
            value={xLabel}
            position={xLabelPosition}
            style={labelstyle}
            dy={xAxisDistanceY} // Move left
          />
        </XAxis>
        <YAxis tick={tick} fontSize={tickSize}>
          <Label
            value={yLabel}
            angle={yLabelAngle}
            position={yLabelPosition}
            style={labelstyle}
            dx={yAxisDistanceX} // Move down
          />
        </YAxis>
        {children}
      </LineChart>
    </ResponsiveContainer>
  )
}

export default GenericLineChart
