import React, { FC } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'

interface ChartLegendProps {
  legendNames: string[]
}

const ChartLegend: FC<ChartLegendProps> = ({ legendNames }) => {
  const theme = useTheme()

  return (
    <Stack
      sx={{
        display: 'flex',
        alignItems: 'center',
        mt: { md: '4px', lg: '6px', xl: '10px', xxl: '26px', xxxl: '38px' },
      }}
    >
      <Stack component='ul' fontFamily='Source Sans Pro' spacing={1}>
        {legendNames.map(name => (
          <Stack component='li' direction='row' alignItems='center' spacing={1} key={name}>
            <Box
              component='span'
              sx={{
                bgcolor: theme.palette.chartsTheme[name?.toLowerCase()],
                width: 8,
                height: 8,
                borderRadius: '50%',
              }}
            />
            <Box
              component='span'
              sx={{
                textTransform: 'capitalize',
                color: '#010101',
                fontSize: { md: '13px', lg: '14px', xl: '16px', xxl: '20px', xxxl: '34px' },
                lineHeight: { md: '13px', lg: '14px', xl: '18px', xxl: 'initial', xxxl: 'initial' },
              }}
            >
              {name}
            </Box>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}

export default ChartLegend
