import { useQuery } from '@tanstack/react-query'
import ApiService from 'services/api'

interface UseCountDocumentsProps {
  verticalIndex?: string
  currentQueryKey: string
}

export const useCountDocuments = ({ verticalIndex, currentQueryKey }: UseCountDocumentsProps) =>
  useQuery({
    queryKey: [currentQueryKey, verticalIndex],
    queryFn: () => (verticalIndex ? ApiService.getTotalDocumentsCount(verticalIndex) : 0),
  })
