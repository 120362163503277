const ToggleRoot = {
  padding: 0,
  width: '40px',
  height: '24px',
  '.MuiSwitch-track': {
    backgroundColor: '#7E8BA6',
    borderRadius: '24px',
  },
  '.MuiButtonBase-root': {
    padding: '4px',
    '.MuiSwitch-thumb': {
      width: '16px',
      height: '16px',
      boxShadow: 'none',
      backgroundColor: '#fff',
    },
    '&.Mui-checked+.MuiSwitch-track': {
      opacity: 1,
      backgroundColor: '#2257C5',
    },
    '&.Mui-checked': {
      transform: 'translateX(15px)',
    },
  },
}

const styles = {
  ToggleRoot,
}

export default styles
