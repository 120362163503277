export enum tabsIds {
  OVERVIEW,
  TAX_RETURN,
  CONTACT_INFO,
  SOLD_ITEMS,
  REVIEWS,
}

export const activityTypes = {
  SOLD_ITEM: 'Sold Item',
  REVIEW: 'Review',
}

const tabsList = {
  OVERVIEW: { id: tabsIds.OVERVIEW, label: 'Overview' },
  TAX_RETURN: { id: tabsIds.TAX_RETURN, label: 'Tax Return' },
  CONTACT_INFO: { id: tabsIds.CONTACT_INFO, label: 'Contact Info' },
  SOLD_ITEMS: { id: tabsIds.SOLD_ITEMS, label: 'Sold Items' },
  REVIEWS: { id: tabsIds.REVIEWS, label: 'Reviews' },
}

export const getTabsList = () => [
  tabsList.OVERVIEW,
  tabsList.SOLD_ITEMS,
  tabsList.REVIEWS,
  tabsList.TAX_RETURN,
  tabsList.CONTACT_INFO,
]
