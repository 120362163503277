import { GraphData } from 'utils/types'

const getArrayWithCounts = (arr: string[]): { key: string; label: string }[] => {
  const counts = {}
  const countsArray: { key: string; label: string }[] = []

  arr.forEach(el => {
    if (counts[el]) {
      counts[el] = counts[el] + 1
    } else {
      counts[el] = 1
    }
  })

  Object.keys(counts).forEach(key => {
    countsArray.push({
      key,
      label: `${key} (${counts[key]})`,
    })
  })

  return countsArray
}

export const getLabels = (graph: GraphData) => {
  const labels: string[] = []

  graph.nodes.forEach(node => {
    if (Array.isArray(node.data.labels)) {
      labels.push(...node.data.labels)
    }
  })

  return getArrayWithCounts(labels)
}

export const getTypes = (graph: GraphData) => {
  const types: string[] = []

  graph.edges.forEach(edge => {
    if (Array.isArray(edge.data.type)) {
      types.push(...edge.data.type)
    }
  })

  return getArrayWithCounts(types)
}
