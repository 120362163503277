import React from 'react'
import PropTypes from 'prop-types'
import styles from './UserAvatar.scss'

export default function UserAvatar({ imageUrl, alt }) {
  return imageUrl ? (
    <img src={imageUrl} key={imageUrl} alt={alt} className={styles.image} />
  ) : (
    <img src={require('./assets/avatar.png')} alt={alt} className={styles.image} />
  )
}

UserAvatar.propTypes = {
  imageUrl: PropTypes.string,
  alt: PropTypes.string,
}
