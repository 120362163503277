import React, { useMemo } from 'react'
import { LineChartDatum } from 'components/common/types'
import { EmployeeOverviewDataKeys } from 'containers/VerticalPage/company-overview/OverviewTab/types'
import GenericChartTooltip from 'components/common/GenericChartTooltip'
import LocalEmployeeOvertimeTooltipContent from 'containers/VerticalPage/company-overview/OverviewTab/EmployeeOvertimeTooltip/LocalEmployeeOvertimeTooltipContent'
import RestEmployeeOvertimeTooltipContent from 'containers/VerticalPage/company-overview/OverviewTab/EmployeeOvertimeTooltip/RestEmployeeOvertimeTooltipContent'
import { TooltipPaper } from 'containers/VerticalPage/company-overview/OverviewTab/EmployeeOvertimeTooltip/styles'
import { TooltipProps } from 'recharts'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

interface EmployeeOvertimeTooltip extends TooltipProps<number, string> {
  chartData: LineChartDatum[]
  hoveredDot: EmployeeOverviewDataKeys | null
  localCountry: string
}

const EmployeeOvertimeTooltip = ({ payload, hoveredDot, localCountry }: EmployeeOvertimeTooltip) => {
  const relevantPayload = useMemo(() => payload?.find(p => p.dataKey === hoveredDot), [hoveredDot, payload])

  if (!hoveredDot) return null
  return (
    <GenericChartTooltip>
      <Box position='relative'>
        <Stack sx={TooltipPaper}>
          {hoveredDot === EmployeeOverviewDataKeys.localAmount ? (
            <LocalEmployeeOvertimeTooltipContent localCountry={localCountry} relevantPayload={relevantPayload} />
          ) : (
            <RestEmployeeOvertimeTooltipContent localCountry={localCountry} relevantPayload={relevantPayload} />
          )}
        </Stack>
      </Box>
    </GenericChartTooltip>
  )
}

export default EmployeeOvertimeTooltip
