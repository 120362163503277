import { defineMessages } from 'react-intl'

const scope = 'app.components.PlatformsModal'

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Platforms',
  },
  close: {
    id: `${scope}.actions.close`,
    defaultMessage: 'Close',
  },
  select: {
    id: `${scope}.actions.select`,
    defaultMessage: 'Select',
  },
  selectPlatform: {
    id: `${scope}.actions.selectPlatform`,
    defaultMessage: 'Select Platform',
  },
})
