import React, { useContext, useMemo } from 'react'
import { camelCase } from 'lodash'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import { VerticalContext } from 'store/verticals/Context'
import { getContactInfoForMapping } from './utils'
import styles from './styles'
import { Link } from 'react-router-dom'
import BusinessSource from 'components/BusinessSource'

const ContactInfoTab = () => {
  const { verticalEntity } = useContext(VerticalContext)

  const data = useMemo(() => getContactInfoForMapping(verticalEntity?.contactInfo), [verticalEntity?.contactInfo])

  return (
    <Stack divider={<Divider orientation='horizontal' sx={{ mt: 3, mb: 2 }} />}>
      {data.map((row, rowIndex) => (
        <Stack key={rowIndex}>
          <Typography sx={styles.Title}>{row.title}</Typography>
          <Stack flexDirection='row' alignItems='center' flexWrap='wrap'>
            {row.data.map((item, itemIndex) => (
              <Stack
                key={itemIndex}
                flexDirection='row'
                alignItems='center'
                sx={{ ...styles.Item, backgroundColor: item.color || styles.Item.backgroundColor }}
                mt={2}
              >
                {item?.IconComponent && (
                  <item.IconComponent width={24} height={24} htmlColor='rgba(126, 139, 166, 1)' sx={{ mr: 1 }} />
                )}
                {item.iconName && (
                  <BusinessSource source={camelCase(item.iconName)} isOriginal imageClassName='iconName' />
                )}
                <Typography fontFamily='Source Sans Pro'>
                  {item.url ? (
                    <Link
                      to={{ pathname: item.url }}
                      target='_blank'
                      style={{ color: '#1C2842', textTransform: 'capitalize' }}
                    >
                      {item.value}
                    </Link>
                  ) : (
                    item.value
                  )}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      ))}
    </Stack>
  )
}

export default ContactInfoTab
