import { defineMessages } from 'react-intl'

const scope = 'app.components.MapHeader'

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: '{type} Locations',
  },
  expand: {
    id: `${scope}.expand`,
    defaultMessage: 'Expand',
  },
  collapse: {
    id: `${scope}.collapse`,
    defaultMessage: 'Collapse',
  },
})
