import React, { ReactNode } from 'react'
import Stack from '@mui/material/Stack'
import { SxProps, useTheme } from '@mui/material/styles'
import GenericText from 'components/common/GenericText'

interface TitledDataProps {
  title: string
  data: string | ReactNode
  sx?: SxProps
  dataSx?: SxProps
  titleSx?: SxProps
}

const TitledData = ({ title, titleSx, data, dataSx, sx }: TitledDataProps) => {
  const theme = useTheme()
  return (
    <Stack component='li' spacing={1} sx={{ flexDirection: 'column', ...sx }}>
      <GenericText
        sx={{
          fontSize: 15,
          textTransform: 'capitalize',
          textAlign: 'center',
          color: theme.palette.textColor.lightGray,
          ...titleSx,
        }}
      >
        {title}
      </GenericText>
      <Stack sx={{ fontSize: 22, fontFamily: 'Dosis', fontWeight: 600, textAlign: 'center', ...dataSx }}>{data}</Stack>
    </Stack>
  )
}

export default TitledData
