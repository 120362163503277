const Container = {}

const Label = {
  fontFamily: 'Source Sans Pro',
  fontSize: '16px',
  fontWeight: 400,
  color: '#1C2842',
}

const FormControlContainer = {
  mt: 2,
}

const Separator = {
  transform: 'translateY(22px)',
  fontSize: '16px',
  fontFamily: 'Source Sans Pro',
}

const InputLabel = {
  fontFamily: 'Montserrat',
  color: '#1C2842',
  fontSize: '14px',
  fontWeight: 400,
  margin: 0,
  transform: 'none',
  top: '-8px',
  '&.MuiInputLabel-shrink': {
    color: '#1C2842',
  },
}

const Input = {
  border: '1px solid #DADBDF',
  borderRadius: '8px',
  padding: '8px 12px',
  height: '40px',
  '&::before': {
    display: 'none',
  },
  input: {
    fontFamily: 'Montserrat',
    color: '#1C2842',
    fontSize: '14px',
    fontWeight: 400,
    padding: 0,
  },
}

const Adornment = {
  'p.MuiTypography-root': {
    fontFamily: 'Montserrat',
    color: '#1C2842',
    fontSize: '14px',
    fontWeight: 400,
  },
}

const RangeSliderRoot = {
  mt: 2,
  '.MuiSlider-rail': {
    backgroundColor: '#E9ECF1',
    opacity: 1,
  },
  '.MuiSlider-track': {
    backgroundColor: '#2257C5',
    borderColor: '#2257C5',
  },
  '.MuiSlider-thumb': {
    backgroundColor: '#fff',
    border: 'solid 5px #2257C5',
    boxShadow: 'none',
  },
  '.MuiSlider-mark': {
    width: '6px',
    height: '16px',
    bgcolor: '#2257C5',
    borderRadius: '3px',
    opacity: 1,
  },
}

const LegendTextRangeSlider = {
  fontFamily: 'Source Sans Pro',
  fontSize: '16px',
  fontWeight: 400,
}

const styles = {
  Container,
  Label,
  Input,
  InputLabel,
  FormControlContainer,
  Separator,
  Adornment,
  RangeSliderRoot,
  LegendTextRangeSlider,
}

export default styles
