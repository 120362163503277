import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import VerticalBAHeading from 'containers/VerticalPage/common/VerticalBusinessActivity/VerticalBAHeading'
import VerticalBARevenueSource from 'containers/VerticalPage/common/VerticalBusinessActivity/VerticalBARevenueSource'
import VerticalBARevenueSourceDetails from 'containers/VerticalPage/common/VerticalBusinessActivity/VerticalBARevenueSourceDetails'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import { VerticalTabsHeadingLoader } from 'containers/VerticalPage/common/Loaders'
import GenericHeading from 'components/common/GenericHeading'
import DatePicker from 'components/common/DatePicker'
import { getDefaultDateValues } from 'containers/VerticalPage/common/utils'
import { VerticalsDetailsContext } from 'store/verticalsDetails/Context'
import { useDebounce } from 'hooks/useDebounce'
import Loader from 'components/Loader/Loader'
import { useDocumentActivitiesQuery } from 'containers/VerticalPage/common/useDocumentActivitiesQuery'
import { selectCurrentZone } from 'store/global'
import { usePeriodSetter } from 'containers/VerticalPage/common/hooks'
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowVirtualizer,
  useMaterialReactTable,
  MRT_TableOptions,
} from 'material-react-table'
import { VerticalDocumentActivity } from 'store/verticals/types'
import { CurrentZone } from 'utils/types'
import { useCountDocuments } from 'hooks/useCountDocuments'
import { useTableInfiniteScroll } from 'containers/VerticalPage/common/useTableInfiniteScroll'
import { formatNumber } from 'utils/formatters'

const calendarIconUrl = require('components/common/images/calendar.svg')

const periodFormat = 'MMM. yyyy'
const periodPopperPlacement = 'bottom-end'
const PAGE_SIZE = 10
const margins = 100

interface ActivityProps {
  tableHeight?: number
}

const Activity: FC<ActivityProps> = ({ tableHeight }) => {
  const { detailsFilters } = useContext(VerticalsDetailsContext)
  const theme = useTheme()
  const defaultDateValues = getDefaultDateValues()
  const currentZone: CurrentZone = useSelector(selectCurrentZone)
  const [totalDBRowCount, setTotalDBRowCount] = useState(0)
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const activityContainerRef = useRef<HTMLDivElement>(null)

  const { period: datePeriod, handlePeriodChange: handleBusinessActivityPeriodChange } =
    usePeriodSetter(defaultDateValues)

  const { entityId } = useParams<{ entityId: string }>()

  const datePeriodDebounced = useDebounce(datePeriod, 1000)

  const { data: docsCountData } = useCountDocuments({
    verticalIndex: currentZone.cdtfaActivityIndices,
    currentQueryKey: 'cdtfa-activities-key',
  })

  const { verticalDocumentActivity, isFetching, isLoading, fetchNextPage, totalFetched } = useDocumentActivitiesQuery({
    entityId,
    detailsFilters,
    zoneActivityIndices: currentZone.cdtfaActivityIndices,
    currentPeriod: datePeriodDebounced,
    pageSize: PAGE_SIZE,
    activityType: undefined,
    sort: [],
  })

  const { handleScroll } = useTableInfiniteScroll({
    pageSize: PAGE_SIZE,
    totalFetched,
    totalDBRowCount,
    isFetching,
    tableContainerRef,
    fetchNextPage,
  })

  useEffect(() => {
    setTotalDBRowCount(docsCountData?.totalCount || 0)
  }, [docsCountData])

  const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer<HTMLDivElement, HTMLTableRowElement>>(null)

  const columns = useMemo<MRT_ColumnDef<VerticalDocumentActivity>[]>(
    () => [
      {
        id: 'Review Source',
        header: 'Review Source',
        Header: null,
        grow: 1,
        accessorFn: item => (
          <VerticalBARevenueSource
            user={item.activityUser}
            userPhoto={item.activityUserPhoto}
            activityType={item.activityType}
            activityUrl={item.activityUrl}
            platform={item.activitySource}
            activityDate={item.activityDate}
            withSourceLink={false}
          />
        ),
        muiTableBodyCellProps: {
          sx: {
            borderInlineEnd: '1px solid #DFDFDF',
          },
        },
      },
      {
        id: 'Review Source Details',
        header: 'Review Source Details',
        Header: null,
        grow: 100,
        accessorFn: item => (
          <Box sx={{ maxWidth: '80%' }}>
            <VerticalBARevenueSourceDetails
              headline={item?.activityHeadline}
              text={item.activityText}
              photoUrl={item.activityPhoto}
            />
          </Box>
        ),
      },
    ],
    [],
  )

  const tableOptions: MRT_TableOptions<VerticalDocumentActivity> = useMemo(
    () => ({
      columns,
      data: verticalDocumentActivity || [],
      state: {
        isLoading: isFetching && !verticalDocumentActivity?.length,
      },
      enableStickyHeader: true,
      enableRowSelection: false,
      enableColumnOrdering: true,
      enableGlobalFilter: false,
      enableTopToolbar: false,
      enablePagination: false,
      enableSorting: false,
      enableBottomToolbar: false,
      enableColumnActions: false,
      rowVirtualizerInstanceRef: rowVirtualizerInstanceRef,
      rowVirtualizerOptions: { overscan: 4 },
      layoutMode: 'grid-no-grow' as const,
      muiTableContainerProps: {
        // Get access to the table container element
        onScroll: handleScroll,
        sx: {
          mt: '20px',
          flex: 1,
          height: (tableHeight || 0) - margins,
          ...theme.mixins.customScrollBar(),
        },
      },
      muiTablePaperProps: {
        sx: {
          borderRadius: 0,
          flex: 1,
          ...theme.mixins.customScrollBar(),
        },
      },
      muiTableHeadRowProps: {
        sx: { display: 'none' },
      },
      muiTableBodyRowProps: ({ row }: { row: MRT_Row<VerticalDocumentActivity> }) => ({
        onClick: () => window.open(row.original.activityUrl, '_blank'),
        sx: { cursor: 'pointer' },
      }),
    }),
    [columns, verticalDocumentActivity, handleScroll, theme.mixins, tableHeight, isFetching],
  )

  const table = useMaterialReactTable(tableOptions)

  const renderHeader = useCallback(
    () =>
      isLoading ? (
        <VerticalTabsHeadingLoader />
      ) : (
        <VerticalBAHeading>
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Stack direction='row' alignItems='center' spacing={{ lg: 1, xxxl: 3 }}>
              <GenericHeading
                sx={{
                  fontSize: { md: 16, lg: 18, xl: 20, xxl: 34, xxxl: 44 },
                  fontWeight: 600,
                }}
              >
                Site Reviews
              </GenericHeading>
            </Stack>
            <DatePicker
              periodStart={datePeriod.start}
              periodEnd={datePeriod.end}
              handlePeriodChange={handleBusinessActivityPeriodChange}
              format={periodFormat}
              popperPlacement={periodPopperPlacement}
              isCharts
              iconUrl={calendarIconUrl}
            />
          </Stack>
        </VerticalBAHeading>
      ),
    [datePeriod, handleBusinessActivityPeriodChange, isLoading, totalDBRowCount],
  )

  return (
    <Stack
      ref={activityContainerRef}
      sx={{
        bgcolor: 'white',
        overflow: 'auto',
        ...theme.mixins.customScrollBar(),
        flex: 1,
      }}
    >
      {renderHeader()}

      <Stack
        sx={{
          overflow: isLoading ? 'hidden' : 'auto',
          ...theme.mixins.customScrollBar(),
        }}
      >
        <MaterialReactTable table={table} />
        {isFetching && verticalDocumentActivity?.length && (
          <Box
            sx={{
              position: 'absolute',
              bottom: '20px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <Loader type='scrolling' />
          </Box>
        )}
      </Stack>
    </Stack>
  )
}

export default Activity
