import React from 'react'
import Icon from 'components/Icon'
import styles from './SearchBar.module.scss'
import Button from 'components/Button'

interface ISearchBar {
  searchValue: string
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
}

const SearchBar = ({ searchValue, setSearchValue }: ISearchBar) => {
  return (
    <div className={styles.container}>
      <Button disabled className={styles.searchButton}>
        <Icon size='standard' name='search-lg-black' />
      </Button>
      <input
        type='text'
        className={styles.input}
        placeholder='Search by name, url, job role or description keyword'
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
      />
    </div>
  )
}

export default SearchBar
