import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FormattedNumber } from 'react-intl'
import ConditionalWrapper from 'components/ConditionalWrapper'
import FormattedLargeNumber from 'components/FormattedLargeNumber'
import Icon from 'components/Icon'
import Tooltip from 'components/Tooltip'
import { PieLegendPropType } from './props'
import styles from './PieChartLegends.scss'

const allowedTypes = ['percent', 'currency']
const [TYPE_PERCENT, TYPE_CURRENCY] = allowedTypes

const allowedShapes = ['square', 'circle']
const [SHAPE_SQUARE, SHAPE_CIRCLE] = allowedShapes

const allowedDirections = ['row', 'column']
const [DIRECTION_ROW] = allowedDirections

export const PieChartLegends = ({
  type = TYPE_PERCENT,
  direction = DIRECTION_ROW,
  shape = SHAPE_SQUARE,
  isTitled = false,
  legends,
  className,
  isCDTFA = false,
}) => (
  <ul className={cx(styles.legends, styles[direction], className)}>
    {legends.map(({ id, value, icon, label, color }) => {
      return (
        <li key={id} className={cx(styles.legend, { [styles.titled]: isTitled })}>
          <span className={cx(styles.shape, styles[shape], { [styles.innerCDTFA]: isCDTFA })}>
            {shape === SHAPE_CIRCLE && (
              <span className={cx(styles.inner, { [styles.innerCDTFA]: isCDTFA })} style={{ background: color }}>
                ○
              </span>
            )}
          </span>
          <span className={cx(styles.wrapper, { [styles.titled]: isTitled })}>
            <span className={styles.value}>
              {isCDTFA && <span>{label}</span>}
              {type === TYPE_PERCENT && !isCDTFA && <FormattedNumber value={value} style={type} />}
              {type === TYPE_CURRENCY && !isCDTFA && <FormattedLargeNumber value={value} />}
            </span>
            <ConditionalWrapper
              condition={!!icon}
              wrapper={children => (
                <Icon name={icon} direction='left' size='medium' className={styles.icon}>
                  {children}
                </Icon>
              )}
            >
              <span className={cx(styles.label, { [styles.cdtfaLabel]: isCDTFA })}>
                <Tooltip title={id}>{id}</Tooltip>
              </span>
            </ConditionalWrapper>
          </span>
        </li>
      )
    })}
  </ul>
)

PieChartLegends.propTypes = {
  isTitled: PropTypes.bool,
  type: PropTypes.oneOf(allowedTypes),
  direction: PropTypes.oneOf(allowedDirections),
  shape: PropTypes.oneOf(allowedShapes),
  legends: PropTypes.arrayOf(PieLegendPropType).isRequired,
  className: PropTypes.string,
  isCDTFA: PropTypes.bool,
}
