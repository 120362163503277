import React, { FC } from 'react'
import GenericHeading from 'components/common/GenericHeading'
import { SxProps } from '@mui/material/styles'

interface VerticalBAHeadingProps {
  customSx?: SxProps
  children: React.ReactNode
}

const VerticalBAHeading: FC<VerticalBAHeadingProps> = ({ children, customSx }) => {
  return (
    <GenericHeading sx={{ textTransform: 'uppercase', fontWeight: '600', fontSize: { xs: 14, xl: 18 }, ...customSx }}>
      {children}
    </GenericHeading>
  )
}

export default VerticalBAHeading
