import React from 'react'
import cx from 'classnames'
import MultiSelect from 'react-multi-select-component'
import Input from 'components/Input'
import { Option } from 'react-multi-select-component/dist/lib/interfaces'
import styles from './NestedDropdownInput.scss'
import filtersService from 'services/filters'
import { SELECT_ALL, TChangeEvent, THandleOptionClick } from 'components/Filters/NestedMultiSelect/utils'
import { FixedNestedOptions } from '../../utils/filters'

interface NestedDropdownInputProps {
  options: Array<Option>
  selections?: Array<Option>
  dropdownHeader?: string
  allItemsAreSelected?: string
  selectAll?: string
  search?: string
  labelledBy?: string
  disableSearch?: boolean
  hasSelectAll?: boolean
  onChange?: (() => void) | ((arg0: any) => void)
  onToggle?: () => void
  buttonRenderer?: any // Typescript conflict with our function (which returns ReactNode) and their use
  className?: string
  selectionsMap: FixedNestedOptions
  handleOptionClick: THandleOptionClick
  parseOptionKey: (option: Option) => Record<string, string> | null
}

interface IItemRenderer {
  option: Option
  onClick: (e: TChangeEvent) => void
  disabled: false
}

const NestedDropdownInput: React.FC<NestedDropdownInputProps> = ({
  options,
  selections = [],
  dropdownHeader = 'Search & Select',
  allItemsAreSelected = '',
  selectAll = '',
  search = '',
  labelledBy = '',
  disableSearch = false,
  hasSelectAll = true,
  onChange = () => {},
  onToggle = () => {},
  buttonRenderer,
  className,
  selectionsMap,
  handleOptionClick,
  parseOptionKey,
}) => {
  const overrideStrings = {
    selectSomeItems: dropdownHeader,
    allItemsAreSelected: selections
      .map((selection, index) => (index === 0 ? selection.label : `, ${selection.label}`))
      .join(''),
    selectAll,
    search,
  }

  const itemRenderer = ({ option, onClick, disabled }: IItemRenderer) => {
    if (!option.key) return

    const parsedOptionKey = option.key !== SELECT_ALL.KEY ? JSON.parse(option.key) : null
    const currentGroupOptions: string[] = selectionsMap[parsedOptionKey?.groupHeader]?.groupOptions
    const currentGroupItemSelections: string[] = selectionsMap[parsedOptionKey?.groupHeader]?.itemSelections

    const isAllGroupOptionsSelected =
      option.key !== SELECT_ALL.VALUE && currentGroupItemSelections?.length === currentGroupOptions?.length

    const isHalfChecked =
      currentGroupItemSelections?.length > 0 && currentGroupItemSelections?.length < currentGroupOptions?.length

    const isNestedItem = parsedOptionKey?.groupOption
    const isFlatGroup = currentGroupOptions?.length === 0

    const isSelectAllOption = option.label === SELECT_ALL.VALUE

    const isSelected =
      (isSelectAllOption && filtersService.checkIsSelectAllActive(selectionsMap)) ||
      selections.some(selection => selection.value === option.value) ||
      (isHalfChecked && !!!isNestedItem) ||
      (!isSelectAllOption && !isFlatGroup && isAllGroupOptionsSelected)

    const isSomeGroupOptionsSelected = currentGroupItemSelections?.length > 0

    const selectionsCounter: JSX.Element | null = isSomeGroupOptionsSelected ? (
      <span className={styles.selectionsCounter}>
        ({currentGroupItemSelections.length}/<span className={styles.grayText}>{currentGroupOptions.length}</span>)
      </span>
    ) : null

    return (
      <div className={cx(styles.nestedItemRenderer, { [styles.nestedItem]: !!isNestedItem, disabled })}>
        <Input
          type='checkbox'
          disabled={disabled}
          checked={isSelected}
          isHalfChecked={isHalfChecked && !isNestedItem}
          onChange={e => handleOptionClick(e, option, onClick)}
        />
        <span className={cx(styles.label, { [styles.selected]: isSelected })}>{option.label}</span>
        {!isNestedItem && !isFlatGroup && selectionsCounter && (
          <span className={cx(styles.selectionsCount, { [styles.selected]: isSelected })}>{selectionsCounter}</span>
        )}
      </div>
    )
  }

  const filterOptions = (options: Option[], filter: string) => {
    if (!filter || filter.length === 0) return options

    const filterRegex = new RegExp(filter, 'i')

    // Get matched options including their headers
    return options.reduce((filteredOptions: Option[], option: Option) => {
      const isOptionMatch = !!option?.value && !!option?.value.match(filterRegex)

      if (!isOptionMatch) return filteredOptions

      const parsedOptionKey = parseOptionKey(option)

      if (!parsedOptionKey) return filteredOptions

      const doesHeaderExist = filteredOptions.some((filteredOption: Option) => {
        const parsedFilteredOptionKey = parseOptionKey(filteredOption)
        const currentOptionHeader = parsedOptionKey.groupHeader
        const filteredOptionHeader = parsedFilteredOptionKey?.groupHeader
        return currentOptionHeader === filteredOptionHeader
      })

      const shouldAddHeader: boolean = filteredOptions.length === 0 || !doesHeaderExist

      if (!shouldAddHeader) return [...filteredOptions, option]

      // Add matched option's header
      const optionHeader: Option | undefined = options.find(currentOption => {
        //Checking if matched option is header to skip the rest
        const isHeaderOption = !option?.key?.includes('groupOption')
        if (isHeaderOption) return null

        const parsedCurrentOptionKey = parseOptionKey(currentOption)
        const isGroupHeader = !currentOption?.key?.includes('groupOption')
        const isSelectAll = currentOption?.value !== SELECT_ALL.VALUE
        const isMatchedHeader = parsedCurrentOptionKey?.groupHeader === parsedOptionKey.groupHeader

        return isGroupHeader && isSelectAll && isMatchedHeader
      })

      if (!optionHeader) return [...filteredOptions, option]

      return [...filteredOptions, optionHeader, option]
    }, [])
  }

  return (
    <MultiSelect
      options={options}
      value={selections}
      onChange={onChange}
      labelledBy={labelledBy}
      overrideStrings={overrideStrings}
      filterOptions={filterOptions}
      disableSearch={disableSearch}
      hasSelectAll={hasSelectAll}
      ItemRenderer={itemRenderer}
      valueRenderer={buttonRenderer}
      onMenuToggle={onToggle}
      className={cx(className, { [styles.withSearch]: !disableSearch, [styles.withCustomButton]: !!buttonRenderer })}
    />
  )
}

export default NestedDropdownInput
