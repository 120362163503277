// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".serviceProviderTable__platform-icons-container--PEEj6{display:flex;gap:5px}.serviceProviderTable__platform-icons-container--PEEj6 .serviceProviderTable__tag--X_hQ6{display:inline-flex;gap:5px;align-self:center;align-items:center;background:#e5e5e5;padding:2px 4px;border-radius:3px}", "",{"version":3,"sources":["webpack://./app/components/serviceProviders/ServiceProvidersTable/serviceProviderTable.scss"],"names":[],"mappings":"AAAA,uDACE,YAAA,CACA,OAAA,CACA,yFACE,mBAAA,CACA,OAAA,CACA,iBAAA,CACA,kBAAA,CACA,kBAAA,CACA,eAAA,CACA,iBAAA","sourcesContent":[".platform-icons-container {\n  display: flex;\n  gap: 5px;\n  .tag {\n    display: inline-flex;\n    gap: 5px;\n    align-self: center;\n    align-items: center;\n    background: #e5e5e5;\n    padding: 2px 4px;\n    border-radius: 3px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"platform-icons-container": "serviceProviderTable__platform-icons-container--PEEj6",
	"platformIconsContainer": "serviceProviderTable__platform-icons-container--PEEj6",
	"tag": "serviceProviderTable__tag--X_hQ6"
};
export default ___CSS_LOADER_EXPORT___;
