import React from 'react'
import { camelCase } from 'lodash'
import BusinessSource from 'components/BusinessSource'
import styles from './BusinessDataSources.scss'

export interface IBusinessType {
  name: string
}

export interface IBusiness {
  dataSources: Array<string>
}

function BusinessDataSources({ dataSources }: IBusiness) {
  return (
    <ul className={styles.sources}>
      {dataSources.map((source: string, index: number) => (
        <li key={index}>
          <BusinessSource source={camelCase(source)} isDisabled />
        </li>
      ))}
    </ul>
  )
}

export default BusinessDataSources
