import { defineMessages } from 'react-intl'

const scope = 'app.components.GridActions'

export default defineMessages({
  search: {
    placeholder: {
      id: `${scope}.search.placeholder`,
      defaultMessage: ' ',
    },
    prefix: {
      id: `${scope}.search.prefix`,
      defaultMessage: 'Search for...',
    },
    suffix: {
      id: `${scope}.search.suffix`,
      defaultMessage: 'in',
    },
  },
  actions: {
    columns: {
      id: `${scope}.actions.columns`,
      defaultMessage: 'Columns',
    },
    share: {
      id: `${scope}.actions.share`,
      defaultMessage: 'Export',
    },
    showMap: {
      id: `${scope}.actions.showMap`,
      defaultMessage: 'Show Map',
    },
    refresh: {
      id: `${scope}.actions.refresh`,
      defaultMessage: 'Refresh List',
    },
  },
})
