import { defineMessages } from 'react-intl'

const scope = 'app.components.RentalsHosts'

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Hosts Details | Displaying {displayed} of {total}',
  },
  filters: {
    id: `${scope}.filters`,
    defaultMessage: 'Filters',
  },
})
