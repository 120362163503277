import React from 'react'
import { FormattedMessage } from 'react-intl'

import BusinessSource from 'components/BusinessSource'
import Icon from 'components/Icon'
import { camelCase } from 'lodash'

import cx from 'classnames'
import styles from './ContactCard.scss'
import messages from './messages'

// eslint-disable-next-line no-unused-vars,unused-imports/no-unused-vars
const phonesStub: Array<string> = [
  '+34 944 76 11 24',
  '+34 944 76 11 24',
  '+34 944 76 11 24',
  '+34 944 76 11 24',
  '+34 944 76 11 24',
  '+34 944 76 11 24',
  '+34 944 76 11 24',
  '+34 944 76 11 24',
  '+34 944 76 11 24',
  '+34 944 76 11 24',
  '+34 944 76 11 24',
]

// eslint-disable-next-line no-unused-vars,unused-imports/no-unused-vars
const emailsStub: Array<string> = [
  'aVeryLongMailThatProbablyIsTooWideForTheScreenAndWeNeedToAdaptItToLookBetter@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
  'dor@ivix.ai',
]

export interface IWeblinks {
  [index: string]: Array<string>
}

export type IContactType = 'weblinks' | 'phones' | 'emails' | 'addresses' | 'wallets'

export interface IContactCard {
  type: IContactType
  emails: Array<string>
  phones: Array<string>
  websiteLinks: IWeblinks
  isNFT: boolean
  isInfluencers: boolean
  addresses?: Array<string>
  wallets?: Array<string>
}

// eslint-disable-next-line no-unused-vars,unused-imports/no-unused-vars
const weblinksStub: IWeblinks = {
  facebook: ['https://www.facebook.com/voestalpine/', 'https://www.facebook.com/voestalpine/'],
  gmaps: [
    'http://www.google.com/maps/place/voestalpine+High+Performance+Metals+Ib%C3%A9rica+S.A.U.%2C+Zamudio/@43.2755406,-2.8534386,17z/data=!4m5!3m4!1s0xd4e48b5ef376891:0x877489afff4fae49!8m2!3d43.2755406!4d-2.8534386',
  ],
  instagram: [
    'https://www.instagram.com/voestalpine/',
    'https://www.instagram.com/voestalpine/',
    'https://www.instagram.com/voestalpine/',
  ],
  linkedin: [
    'https://www.linkedin.com/company/voestalpine/',
    'https://www.linkedin.com/company/voestalpine/',
    'https://www.linkedin.com/company/voestalpine/',
  ],
  pinterest: [
    'https://www.pinterest.com/voestalpine/',
    'https://www.pinterest.com/voestalpine/',
    'https://www.pinterest.com/voestalpine/',
  ],
  twitter: ['https://twitter.com/voestalpine', 'https://twitter.com/statuses'],
}

export default function ContactCard({
  type,
  websiteLinks,
  phones,
  emails,
  isNFT,
  addresses,
  isInfluencers,
  wallets,
}: IContactCard) {
  const renderValueWithName = (value: string, name: string, iconName: string) => (
    <a href={value} target='_blank' rel='noreferrer'>
      <span className={styles.name}>
        <BusinessSource source={camelCase(iconName)} isHoverable isClickable />
      </span>
      {name}
    </a>
  )

  const getDisplayName = (key: string) => {
    if (key === 'gmaps' || key === 'googleMaps') return 'Google Maps'
    if (key === 'bbb') return 'BBB'
    if (key === 'angiesList' || key === 'angies') return "Angie's List"
    return key
  }

  const renderWebLinks = () =>
    Object.keys(websiteLinks).map(key => {
      const source = getDisplayName(key)
      if (!websiteLinks[key]) return
      // eslint-disable-next-line multiline-ternary
      return websiteLinks[key].map((url, index) => {
        const articleKey = websiteLinks[key].length === 1 ? key : `${key} ${index + 1}`
        const valueName = websiteLinks[key].length === 1 ? source : `${source} ${index + 1}`
        return (
          <article key={articleKey} className={styles.item}>
            <h5 className={styles.header}>
              <span className={styles.value}>{renderValueWithName(url, valueName, camelCase(source))}</span>
            </h5>
          </article>
        )
      })
    })

  const renderItemContents = (contents: Array<string>) => {
    if (contents.length === 1) return contents[0]

    return contents
      .filter(element => !!element)
      .map((element, index) => (
        <li key={index} className={styles.listItem}>
          {element}
        </li>
      ))
  }

  return (
    <div
      className={cx(styles.container, { [styles.nftContainer]: isNFT, [styles.influencersContainer]: isInfluencers })}
    >
      <div className={styles.title}>
        {(type !== 'addresses' || (type === 'addresses' && isInfluencers)) && (
          <Icon name={type} size='regular' className={styles.icon}>
            <FormattedMessage {...messages[type]} />
          </Icon>
        )}
      </div>
      <div className={styles.inner}>
        <div className={styles.content}>
          {type === 'weblinks' && renderWebLinks()}
          {type === 'phones' && renderItemContents(phones)}
          {type === 'emails' && renderItemContents(emails)}
          {type === 'wallets' && wallets && renderItemContents(wallets)}
          {isInfluencers && addresses && type === 'addresses' && renderItemContents(addresses)}
        </div>
      </div>
    </div>
  )
}
