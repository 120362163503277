import { createSlice } from '@reduxjs/toolkit'

const SLICE_KEY = 'settings'

export const initialState = {
  isModalDisplayed: false,
  isUserPlatformsModalDisplayed: false,
}

const slice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    setModalDisplayed(state, { payload = {} }) {
      const { isDisplayed = false } = payload
      state.isModalDisplayed = isDisplayed
    },
    setIsUserPlatformsModalDisplayed(state, { payload = {} }) {
      const { isDisplayed = false } = payload
      state.isUserPlatformsModalDisplayed = isDisplayed
    },
  },
})

export const { setModalDisplayed, setIsUserPlatformsModalDisplayed } = slice.actions

export const settingsReducer = slice.reducer
